import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { createAd } from "../../../actions/adsSection";
import Input from '../../../components/Input/Input';
import * as Yup from 'yup';
import { useFormik } from 'formik';
// material
import { Alert, Snackbar, Stack, TextField, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import * as React from "react";
import Grid from '@material-ui/core/Grid';
import { ClassNames } from '@emotion/react';
import { getPost } from '../../../actions/posts';

// ----------------------------------------------------------------------

const CMS_Add_Ad = ({ postId }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [openSnack, setSnackOpen] = useState(false);
    const [success, setSuccess] = useState(undefined);
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleShowPassword = () => setShowPassword(!showPassword);

    const [images, setImages] = useState("");
    const [imagesPreview, setImagesPreview] = useState("");


    let ads = [];

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false)
    };


    const onChange = e => {
        if (e.target.name === 'image') {

            const reader = new FileReader();

            reader.onload = () => {
                if (reader.readyState === 2) {
                    setImagesPreview(reader.result)
                    setImages(reader.result)
                }
            }

            reader.readAsDataURL(e.target.files[0])

        }
    }

    const handleRegisterSubmit = async (values) => {
        setLoading(true);

        const formData = new FormData();

        formData.set('image', images);

        for (var pair of formData.entries()) {

            setImages(pair[1]);
        }




        const form =
        {
            companyName: values.companyName,
            title: values.title,
            description: values.description,
            link: values.link,
            post: postId,
            image: images
        }

        const success = await dispatch(createAd(form));
        if (success) {
            setLoading(false);
        } else setLoading(false)

        setSuccess(success)
        setSnackOpen(true)
    };
    const validationSchema = Yup.object({
        companyName: Yup.string()
            .required('Company Name required'),
        title: Yup.string()
            .required('Title required'),
        description: Yup.string()
            .required('Description required'),
        link: Yup.string()
            .required('Link required')
    });

    const formik = useFormik({
        initialValues: {
            companyName: '',
            title: '',
            description: '',
            link: ''
        },
        validationSchema,
        onSubmit: (values) => {
            handleRegisterSubmit(values);
        }
    });
    return (
        <Grid container>
            <Grid item md={12} align="center">
                <form onSubmit={formik.handleSubmit}>
                    <Stack spacing={3}>
                        <Typography variant="h3" sx={{ marginBottom: "30px" }}>Create Advertisement</Typography>
                        <Grid container>
                            <Grid item md={6}>
                                <div style={{ marginBottom: "30px", width: '80%' }}>
                                    <Input required name="companyName" label="companyName" handleChange={formik.handleChange} handleBlur={formik.handleBlur} autoFocus half />
                                    {formik.touched.companyName && formik.errors.companyName ? <div style={{ color: 'red' }}>{formik.errors.companyName}</div> : null}
                                </div>

                                <div style={{ marginBottom: "30px", width: '80%' }}>
                                    <Input required name="title" label="Title" handleChange={formik.handleChange} handleBlur={formik.handleBlur} type="text" />
                                    {formik.touched.title && formik.errors.title ? <div style={{ color: 'red' }}>{formik.errors.title}</div> : null}
                                </div>
                            </Grid>

                            <Grid item md={6}>
                                <div style={{ marginBottom: "30px", width: '80%' }}>
                                    <Input required name="description" label="Description" handleChange={formik.handleChange} handleBlur={formik.handleBlur} type="text" />
                                    {formik.touched.description && formik.errors.description ? <div style={{ color: 'red' }}>{formik.errors.description}</div> : null}
                                </div>
                                <div style={{ marginBottom: "30px", width: '80%' }}>
                                    <Input required name="link" label="Link" handleChange={formik.handleChange} handleBlur={formik.handleBlur} type="text" />
                                    {formik.touched.link && formik.errors.link ? <div style={{ color: 'red' }}>{formik.errors.link}</div> : null}
                                </div >
                            </Grid>
                            <Grid align="left" style={{ marginLeft: "5%" }}>

                                <div className='form-group' >
                                    <label style={{ fontSize: "20px" }}>Image</label>

                                    <div className='custom-file'>
                                        <input
                                            type='file'
                                            name='image'
                                            className='custom-file-input'
                                            id='customFile'
                                            accept="images/*"
                                            onChange={onChange}
                                        />
                                        <label className='custom-file-label' htmlFor='customFile'>
                                            Choose Image
                                        </label>
                                    </div>

                                    <img src={imagesPreview} key={imagesPreview} alt="Images Preview" className="mt-3 mr-2" width="50%" height="60%" />
                                </div>
                            </Grid>
                        </Grid>
                        <div style={{ alignContent: 'center' }}>
                            <LoadingButton
                                size="large"
                                type="submit"
                                variant="outlined"
                                loading={loading}
                                sx={{ borderColor: "black", color: "black" }}
                            >
                                Create Ad
                            </LoadingButton>
                        </div>
                    </Stack>
                </form>
            </Grid>
            {
                success ? (
                    <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                        <Alert onClose={handleSnackClose} severity="success">
                            Ad Created Successfully
                        </Alert>
                    </Snackbar>
                ) : (
                    <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                        <Alert onClose={handleSnackClose} severity="error">
                            Couldn't Create Ad
                        </Alert>
                    </Snackbar>
                )
            }
        </Grid>
    );
}

export default CMS_Add_Ad;
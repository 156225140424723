
import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ThreeDRotationIcon from '@material-ui/icons/ThreeDRotation';
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import { IconButton,Stack, useTheme } from "@material-ui/core";

import { Paper, CircularProgress, Divider, Container } from '@material-ui/core';


import Grid from '@material-ui/core/Grid';
import AdsCard from "../../../components/AdsCard/adsCard";
import useStyles from './style';
import Carousel, { Modal, ModalGateway } from "react-images";
import { render } from "@testing-library/react";
import { useState } from "react";
import HomePagePostCard from '../../../components/HomePagePostCards/homepagepostCard';

import { Fab } from "@material-ui/core";
import Popup360 from "../../../components/Popup360";
import Iframe from 'react-iframe';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, Navigate } from 'react-router-dom'
import { getPost, getPostsBySearch,deletePost, edit360Link, deleteCMSPost } from '../../../actions/posts';
import ImgAds from '../../../components/AdsImgCard/ImgAds';
import { getAllPostImgAds } from '../../../actions/ImgAd';
import * as Yup from 'yup';
import { useFormik} from 'formik';
import Input from '../../../components/Input/Input';
import { LoadingButton } from '@material-ui/lab';

const images = [
    {
        source:
            "https://scontent.fbey5-2.fna.fbcdn.net/v/t1.6435-9/118044936_3230546223699800_5531336755102412999_n.jpg?_nc_cat=104&ccb=1-3&_nc_sid=973b4a&_nc_ohc=dIc2jGAVNl8AX8r0v8Z&_nc_ht=scontent.fbey5-2.fna&oh=95752eb5b74b9acbcd2ea067de4d38ea&oe=60CDEC89",
        id: 1,
        caption: "Lorem ipsum dolor sit amet, consectetur adipisicing elit - 1"
    },
    {
        source:
            "https://scontent.fbey5-2.fna.fbcdn.net/v/t1.6435-9/118044936_3230546223699800_5531336755102412999_n.jpg?_nc_cat=104&ccb=1-3&_nc_sid=973b4a&_nc_ohc=dIc2jGAVNl8AX8r0v8Z&_nc_ht=scontent.fbey5-2.fna&oh=95752eb5b74b9acbcd2ea067de4d38ea&oe=60CDEC89",
        id: 2,
        caption: "Lorem ipsum dolor sit amet, consectetur adipisicing elit - 2"
    },
    {
        source:
            "https://scontent.fbey5-2.fna.fbcdn.net/v/t1.6435-9/118044936_3230546223699800_5531336755102412999_n.jpg?_nc_cat=104&ccb=1-3&_nc_sid=973b4a&_nc_ohc=dIc2jGAVNl8AX8r0v8Z&_nc_ht=scontent.fbey5-2.fna&oh=95752eb5b74b9acbcd2ea067de4d38ea&oe=60CDEC89",
        id: 3,
        caption: "Lorem ipsum dolor sit amet, consectetur adipisicing elit - 3"
    },
    {
        source:
            "https://scontent.fbey5-2.fna.fbcdn.net/v/t1.6435-9/118044936_3230546223699800_5531336755102412999_n.jpg?_nc_cat=104&ccb=1-3&_nc_sid=973b4a&_nc_ohc=dIc2jGAVNl8AX8r0v8Z&_nc_ht=scontent.fbey5-2.fna&oh=95752eb5b74b9acbcd2ea067de4d38ea&oe=60CDEC89",
        id: 4,
        caption: "Lorem ipsum dolor sit amet, consectetur adipisicing elit - 4"
    },
    {
        source:
            "https://images.pexels.com/photos/12795/pexels-photo-12795.jpeg?cs=srgb&dl=speed-racing-speedway-racing-car-12795.jpg&fm=jpg",
        id: 5,
        caption: "Lorem ipsum dolor sit amet, consectetur adipisicing elit - 5"
    },
    {
        source:
            "https://images.pexels.com/photos/163221/motorcycles-race-helmets-pilots-163221.jpeg?cs=srgb&dl=green-and-black-76-kawasaki-motogp-rider-163221.jpg&fm=jpg",
        id: 6,
        caption: "Lorem ipsum dolor sit amet, consectetur adipisicing elit - 6"
    },
    {
        source:
            "https://images.pexels.com/photos/1402787/pexels-photo-1402787.jpeg?cs=srgb&dl=photo-of-audi-parked-near-trees-1402787.jpg&fm=jpg",
        id: 7,
        caption: "Lorem ipsum dolor sit amet, consectetur adipisicing elit - 7"
    },
    {
        source:
            "https://images.pexels.com/photos/164634/pexels-photo-164634.jpeg?cs=srgb&dl=mercedes-benz-parked-in-a-row-164634.jpg&fm=jpg",
        id: 8,
        caption: "Lorem ipsum dolor sit amet, consectetur adipisicing elit - 8"
    }
];
const CMSPostDetails = () => {


    const classes = useStyles();
    const theme = useTheme();
    const [buttonPopup, setButtonPopup] = useState(false)

    // let modalIsOpen = false;
    let open;
    let currentIndex = 0;
    const [modalIsOpen, isOpen] = useState(false);

    const { post, posts, isLoading } = useSelector((state) => state.posts);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [currentId, setCurrentId] = useState(0);
    const [openSnack, setSnackOpen] = useState(false);
    const [success,setSuccess] = useState(undefined);
    const [loading, setLoading] = useState(false);
    
    
    useEffect(() => {
        dispatch(getPost(id));
    }, [id]);

    useEffect(() => {
        if (post) {
            dispatch(getPostsBySearch({ search: 'none' }));
        }
    }, [post]);

    const handleRegisterSubmit = async (values) => {
        setLoading(true)
        const form=
            {
              Link360 :values.Link360
            }
          const success = await dispatch(edit360Link(id,form));
          if(success)
          {
            setLoading(false)
            navigate(`/CMS/posts/${id}`)
          }else setLoading(false)
    
        setSuccess(success)
        setSnackOpen(true)
      };
    const formik = useFormik({
            initialValues: {
               // Link360:{post}
            },
             //validationSchema,
            onSubmit: (values) => {
            handleRegisterSubmit(values);
            }
        });
    const openPost = (_id) => navigate(`/posts/${_id}`);

    if (isLoading) {
        return (

            <CircularProgress size="4em" />

        );
    }


    const startModal = () => {
        isOpen(true);
    };
    const closeModel = () => {
        isOpen(false);
    }
    const CustomModalFooter = ({ currentIndex, views }) => {
        const activeView = currentIndex + 1;
        const totalViews = views.length;

        if (!activeView || !totalViews) return null;
        return (
            <span class="react-images__footer__count css-w6xjhe css-1ycyyax">{activeView} / {totalViews}</span>
        );
    };

      const validationSchema = Yup.object({
        Link360: Yup.string()
        .matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            'Enter correct url!'
        )
        .required('Please enter website'),
      });
    if (!post) return null;
    return (
        <>
        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mb: 5 }}
        >
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
          <Button
                disableRipple
                variant="contained"
                color="secondary"
                onClick ={() => {dispatch(deleteCMSPost(id));
                    navigate('/CMS/CMSposts')
                } }
            >
                Delete Post&nbsp;
            </Button>
          </Stack> 
        </Stack>
            
            <div className="row">
               
                <div className="content_container">
                    <div className="left">
                       
                        <div>
                            <img className={classes.mainImage} src="https://techcrunch.com/wp-content/uploads/2019/03/blueground-apartment-2-2-2.jpg?w=1390&crop=1"
                                width="650px" height={470} onClick={startModal} />
                        </div>
                    </div>
                    <div className="right">
                        <img src="https://scontent.fbey5-2.fna.fbcdn.net/v/t1.6435-9/117725658_3233339230087166_4794605818012220252_n.jpg?_nc_cat=110&ccb=1-3&_nc_sid=973b4a&_nc_ohc=PlSg6R9CpTMAX8PPDP1&_nc_ht=scontent.fbey5-2.fna&oh=6d71ef2b765bd68b1fedd2891accd0e8&oe=60CCC493"
                            width="250px" height={150} />
                        <img src="https://scontent.fbey5-2.fna.fbcdn.net/v/t1.6435-9/117725658_3233339230087166_4794605818012220252_n.jpg?_nc_cat=110&ccb=1-3&_nc_sid=973b4a&_nc_ohc=PlSg6R9CpTMAX8PPDP1&_nc_ht=scontent.fbey5-2.fna&oh=6d71ef2b765bd68b1fedd2891accd0e8&oe=60CCC493"
                            width="250px" height={150} />
                        <div className="container">
                            <img src="https://scontent.fbey5-2.fna.fbcdn.net/v/t1.6435-9/117725658_3233339230087166_4794605818012220252_n.jpg?_nc_cat=110&ccb=1-3&_nc_sid=973b4a&_nc_ohc=PlSg6R9CpTMAX8PPDP1&_nc_ht=scontent.fbey5-2.fna&oh=6d71ef2b765bd68b1fedd2891accd0e8&oe=60CCC493"
                                width="250px" height={150} />
                            <div className="middle">
                                <div className="text"><AddToPhotosIcon /><br /> 4 Images</div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalGateway>
                    {modalIsOpen ? (
                        <Modal onClose={closeModel}>
                            <Carousel
                                currentIndex={currentIndex}
                                components={{ FooterCount: CustomModalFooter }}
                                views={images}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
                <Card className={classes.moveLeft}>
                    <div className={classes.details}>
                        <h1 className={classes.h1}>Owner Information</h1>
                        <div>
                             <Grid item xs={12} lg={6}>
                                <p className={classes.paper}>Email: {post.email}</p>
                                <p className={classes.paper}>Phone: {post.phoneNumber}</p>
                            </Grid>
                        </div>
                    </div>
                </Card>
                <br />
                <div className={classes.moveLeft}>
                    <h1 className={classes.h1}>Overview</h1>
                    <div className={classes.root_grid}>
                       
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={6}>
                                <p className={classes.paper}>Bedrooms:  {post.numberOfBedrooms}</p>
                                <p className={classes.paper}>Bathrooms:  {post.numberOfBathrooms}</p>
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <p className={classes.paper}>Area:  {post.surfaceArea}</p>
                                <p className={classes.paper}>Floor:  {post.floor}</p>
                            </Grid>

                        </Grid>
                        <br /><br />
                    </div>
                    <h1 className={classes.h1}>Description</h1>
                    <p className={classes.description}>{post.description} 
                    </p>
                    <br />
                    <h1 className={classes.h1}>Interior</h1>
                    <div className={classes.root_grid}>
                        <Grid container spacing={2}>
                            <Grid item xs={7} lg={2}>
                                <p className={classes.paper}>Kitchen Area:  {post.kitchenArea}</p>
                                {/* <p className={classes.paper}>Type environment:</p> */}
                            </Grid>
                            {/* <Grid item xs={5} lg={4}>
                                <p className={classes.paper}>Urbain</p>
                                <p className={classes.paper}>Urbain</p>
                            </Grid>
                            <Grid item xs={7} lg={2}>
                                <p className={classes.paper}>Type environment:</p>
                                <p className={classes.paper}>Type environment:</p>
                            </Grid>
                            <Grid item xs={5} lg={4}>
                                <p className={classes.paper}>Urbain</p>
                                <p className={classes.paper}>Urbain</p>
                            </Grid> */}
                        </Grid>
                    </div>
                </div>
                <br /><br />
                <br /><br />
                <div className={classes.moveLeft}>
                    <h1 className={classes.h1}>Exterior</h1>
                    <div className={classes.root_grid}>
                        <Grid container spacing={2}>
                            <Grid item xs={7} lg={2}>
                                <p className={classes.paper}>Parking Space:  {post.parkingSpace.toString()}</p>
                                <p className={classes.paper}>Dwelling:{post.dwelling.toString()}</p>
                            </Grid>
                            <Grid item xs={5} lg={4}>
                                <p className={classes.paper}>Brick Roof: {post.brickRoof.toString()}</p>
                                <p className={classes.paper}>OutBuildings: {post.outBuildings.toString()}</p>
                            </Grid>
                            <Grid item xs={7} lg={2}>
                                <p className={classes.paper}>Concierge:  {post.caretakerConcierge.toString()}</p>
                                <p className={classes.paper}>Garden: {post.garden.toString()}</p>
                            </Grid>
                            <Grid item xs={5} lg={4}>
                                <p className={classes.paper}>Swimming Pool: {post.swimmingPool.toString()}</p>
                                <p className={classes.paper}>Terrace: {post.terrace.toString()}</p>
                            </Grid>
                        </Grid>
                        <br /><br />
                    </div>
                    <h1 className={classes.h1}>Additional Features</h1>
                    <div className={classes.root_grid}>
                        <Grid container spacing={2}>
                            <Grid item xs={5} lg={4}>
                                <p className={classes.paper}>Heating Type:  {post.heatingType}</p>
                                <p className={classes.paper}>Cooling Type:  {post.coolingType}</p>
                            </Grid>
                            <Grid item xs={7} lg={2}>
                                <p className={classes.paper}>Elevator: {post.elevator.toString()}</p>
                                <p className={classes.paper}>Furnished: {post.furnished.toString()}</p>
                            </Grid>
                            <Grid item xs={5} lg={4}>
                                <p className={classes.paper}>Internet Access: {post.internetAccess.toString()}</p>
                                <p className={classes.paper}>Video Phone: {post.videoPhone.toString()}</p>
                            </Grid>
                            <Grid item xs={7} lg={2}>
                                <p className={classes.paper}>Special Needs Accessability: {post.specialNeedsAccessibility.toString()}</p>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.moveLeft}>
                        <h1 className={classes.h1}>Permits</h1>
                        <div className={classes.root_grid}>
                        
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={6}>
                                    <p className={classes.paper}>Pets:  {post.pets.toString()}</p>
                                    <p className={classes.paper}>Smoking:  {post.smoking.toString()}</p>
                                </Grid>

                                <Grid item xs={12} lg={6}>
                                    <p className={classes.paper}>Big Parties:  {post.bigParties.toString()}</p>
                                    <p className={classes.paper}>Loud Music:  {post.loudMusic.toString()}</p>
                                </Grid>

                            </Grid>
                            <br /><br />
                        </div>
                    </div>
                    <div>
                    <form onSubmit={formik.handleSubmit}>
                                <Stack spacing={3}>
                                <Stack direction={{ xs: 'column', sm: 'row',lg:'row' }} spacing={2}>
                                    <div style={{width:'50%'}}>
                                    <Input required name="Link360" label="360 Link " handleChange={formik.handleChange} handleBlur={formik.handleBlur} autoFocus half defaultValue={post.Link360} />
                                    {formik.touched.Link360 && formik.errors.Link360 ? <div style={{color:'red'}}>{formik.errors.Link360}</div>:null}
                                    </div>
                                    </Stack>
                                <div style={{alignContent:'center'}}>
                                <LoadingButton
                                    disableRipple
                                    color="secondary"
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    loading={loading}
                                >
                                    Update
                                </LoadingButton>
                                
                                </div>
                                </Stack>
                        </form>
                    </div>
                    <div className={classes.moveLeft}>
                    
                        <h1 className={classes.h1}>Retated Ads</h1>
                        <Stack
                        direction="row"
                        flexWrap="wrap-reverse"
                        alignItems="center"
                        justifyContent="flex-start"
                        sx={{ mb: 5 }}
                        >
                        <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
                            <Button
                                disableRipple
                                variant="contained"
                                color="secondary"
                                onClick ={() => dispatch(navigate(`/CMS/posts/${id}/createImgAd`))}
                            >
                                Create Image Ad&nbsp;
                            </Button>
                        </Stack> 
                    </Stack>
                    
                        <ImgAds setCurrentId={id} /> 
                    </div>
                </div>
                <br /><br />
                <br /><br /><br /><br /><br /><br />
            </div>
        </>
    )

}
export default CMSPostDetails
import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { IconButton, InputAdornment } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { ErrorMessage } from "formik";
import { alpha, styled } from '@material-ui/core/styles';
import Cookies from 'js-cookie';
const MUITextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const ProfileField = ({ name, label, placeholder, bottom, width, type, value, handleChange, handleShowPassword, rows, multi, onBlur }) => {
  const [arLang, setArLang] = useState(false);
  let cookieLanguage = Cookies.get('i18next');
  useEffect(() => {
    if (cookieLanguage == "ar") {
      setArLang(true);
    }
  }, [])
  return (
    arLang ? (
      <MUITextField
        fullWidth
        name={name}
        label={label}
        variant="filled"
        rows={rows}
        multiline={multi}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={handleChange}
        defaultValue={value}
        size="medium"
        type={type}
        style={{ marginBottom: `${bottom}`, width: `${width}`, direction: "ltr" }}
        InputProps={name === 'currentPassword' || name === 'newPassword' ? {
          endAdornment: (
            <InputAdornment position="end" >
              <IconButton onClick={handleShowPassword}>
                {type === 'password' ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        } : null}
      />
    ) : (
      <MUITextField
        fullWidth
        name={name}
        label={label}
        variant="filled"
        rows={rows}
        multiline={multi}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={handleChange}
        defaultValue={value}
        size="medium"
        type={type}
        style={{ marginBottom: `${bottom}`, width: `${width}` }}
        InputProps={name === 'currentPassword' || name === 'newPassword' ? {
          endAdornment: (
            <InputAdornment position="end" >
              {
                arLang ? (
                  <IconButton onClick={handleShowPassword}  >
                    {type === 'password' ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ) : (
                  <IconButton onClick={handleShowPassword}>
                    {type === 'password' ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                )
              }
            </InputAdornment>
          ),
        } : null}
      />
    )
  )
}
export default ProfileField;
import { Navigate, useNavigate, useRoutes } from 'react-router-dom';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import NavbarLayout from './layouts/NavbarLayout';
import CMSNavBarLayout from './layouts/CMSNavBarLayout';
import BuyPage from "../src/pages/buyPage/buyPage";
import Page404 from "../src/pages/404/Page404"
import Signup from './pages/Signup/Register';
import Login from './pages/Login/Login';
import Bundle from './pages/Bundle/bundle';
import PostDetail from "./pages/PostDetail/postDetail";
import HomePage from "./pages/HomePage/homePage";
import RentPage from './pages/rentPage/rentPage';
import Profile from "./pages/Profile/Profile";
import Termsofservice from './pages/About/Termsofservices';
import PrivacyPolicy from './pages/About/PrivacyPolicy';
import FAQ from './pages/About/FAQ';
import ContactUs from "./pages/ContactUsPage/ContactUsPage";
import ForgetPassword from "./pages/Reset/forgetPassword";
import NewPassword from "./pages/Reset/newPassword";
import SuccessfulChange from "./pages/Reset/successfulChange";
import AddListingStepper from "./pages/AddListingStepper/AddListingStepper";
import EditListing from "./pages/EditListingStepper/EditListing";
import UnlockListing from "./pages/EditListingStepper/UnlockListing";
import RentMap from './pages/rentPage/rentMap'
import BuyMap from './pages/buyPage/buyMap'
import WishList from './pages/Wishlist/WishList';
import CMSUsersPage from './pages/CMS/Users/Users';
import CMSUserDetails from './pages/CMS/CMSUserDetails/CMSUserDetails';
import CMSPostsPage from './pages/CMS/Posts/Posts';
import CMSPostDetails from './pages/CMS/CMSPostDetails/CMSPostDetails';
import CMSCreateImgAd from './pages/CMS/CreateImgAds/CMSCreateImgAd';
import CMSImgAdDetails from './pages/CMS/CMSImgAdDetails/CMSImgAdDetails';
import CMSAds from './pages/CMS/CMSAds/CMSAds';
import CMSAdDetails from './pages/CMS/CMSAdDetails/CMSAdDetails';
import CMSCreateAd from './pages/CMS/CMSCreateAd/CMSCreateAd';
import CMSAdEdit from './pages/CMS/CMSAdEdit/CMSAdEdit';
import CMSImgAdEdit from './pages/CMS/CMSImgAdEdit/CMSImgAdEdit';
import MyPosts from './pages/OwnedPosts/myPosts';
import SearchPage from './pages/SearchedPosts/searchedPosts';
import React from "react";
import NotLoggedIn from "./components/NotLoggedIn/notLoggedIn";
import CMS_Home from './pages/CMS/AdminHome/cms_home';
import Request360 from './pages/Request360/Request360';
import EmailConfirmation from "./pages/emailConfirmation";
import ActivateAccount from "./pages/ActivateAccount";
import About from './pages/About/About';
import PostsPage from './pages/Posts/App.js'
import { useDispatch } from 'react-redux';
import * as actionType from './constants/actionTypes';

export default function Router() {
  const LoggedUser = JSON.parse(localStorage.getItem('profile'))
  const checkAdmin = () => {
    let payLoad = atob(LoggedUser.token.split('.')[1]);
    let isValid;
    JSON.parse(payLoad).role ? JSON.parse(payLoad).role === 1 ? isValid = true : isValid = false : isValid = false;
    return isValid;
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = () => {
    dispatch({ type: actionType.LOGOUT });
    navigate('/auth/login');
  };
  const check = () => {
    let payLoad = atob(LoggedUser.token.split('.')[1]);
    if (payLoad) {
      if (!(JSON.parse(payLoad).exp > Date.now() / 1000)) {
        logout();
        return false;
      } else {
        return true;
      }
      //return JSON.parse(payLoad).exp > Date.now() / 1000;
    } else {
      return true;
    }
  }
  return useRoutes([
    {
      path: '/',
      element: <NavbarLayout />,
      children: [
        { path: '/', element: <Navigate to="/landing" replace /> },
        { path: 'landing', element: <HomePage /> },
        { path: 'createpost', element: LoggedUser !== null ? check() ? <AddListingStepper /> : <NotLoggedIn /> : <NotLoggedIn /> },
        { path: 'posts1', element: <BuyPage /> },
        // { path: 'posts', element: <PostsPage /> },
        { path: 'posts', element: <Navigate to="/posts/searchb?searchQuery=&cityQuery=&propertyTypeQuery=" replace /> },
        { path: '/posts/:id', element: <PostDetail /> },
        { path: 'pay', element: <Bundle /> },
        { path: 'profile', element: LoggedUser !== null ? check() ? <Profile /> : <NotLoggedIn /> : <NotLoggedIn /> },
        { path: 'privacypolicy', element: <PrivacyPolicy /> },
        { path: 'termsofservice', element: <Termsofservice /> },
        { path: 'faq', element: <FAQ /> },
        { path: 'aboutUs', element: <About /> },
        { path: 'contact', element: <ContactUs /> },
        { path: 'request360', element: <Request360 /> },
        { path: 'myposts/:id', element: LoggedUser !== null ? check() ? <MyPosts /> : <NotLoggedIn /> : <NotLoggedIn /> },
        { path: '/posts/search', element: <RentPage /> },
        // { path: '/posts/searchb', element: <BuyPage /> },
        { path: '/posts/searchb', element: <PostsPage /> },
        { path: '/posts/searchh', element: <SearchPage /> },
        { path: 'wishlist', element: LoggedUser !== null ? check() ? <WishList /> : <NotLoggedIn /> : <NotLoggedIn /> },
        { path: 'posts/edit/:id', element: <EditListing /> },
        { path: 'posts/unlock/:id', element: <UnlockListing /> },
        { path: 'rent/map', element: <RentMap /> },
        { path: 'buy/map', element: <BuyMap /> },
      ]
    },
    {
      path: '/auth',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Signup /> },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/auth/404" /> },
        { path: 'resetPassword', element: <ForgetPassword /> },
        { path: 'resetPassword/:token', element: <NewPassword /> },
        { path: 'authentication/emailSent', element: <EmailConfirmation /> },
        { path: 'authentication/activate/:token', element: <ActivateAccount /> },
        { path: 'successfulChange', element: <SuccessfulChange /> },
      ]
    },
    { path: '*', element: <Navigate to="/auth/404" replace /> },
    //////////////////////////////
    {
      path: '/CMS',
      element: LoggedUser !== null ? (checkAdmin() ? <CMSNavBarLayout /> : <Navigate to="/auth/404" />) : <NotLoggedIn />,
      children: [
        { path: '/CMS', element: <Navigate to="/CMS/landingAdmin" replace /> },
        { path: 'landingAdmin', element: <CMS_Home /> },
        { path: 'CMSposts', element: <CMSPostsPage />, },
        { path: '/posts/:id', element: <CMSPostDetails /> },
        { path: '/posts/:id/createImgAd', element: <CMSCreateImgAd /> },
        { path: '/posts/:id/ImgAdDetails', element: <CMSImgAdDetails /> },
        { path: '/imgAd/:id/editImgAd', element: <CMSImgAdEdit /> },
        { path: 'CMSusers', element: <CMSUsersPage /> },
        { path: '/user/:id', element: <CMSUserDetails /> },
        { path: 'CMSads', element: <CMSAds /> },
        { path: '/ad/:id', element: <CMSAdDetails /> },
        { path: '/ad/createAd', element: <CMSCreateAd /> },
        { path: '/ad/:id/editAd', element: <CMSAdEdit /> },
      ]
    },
  ]);
}

import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles((theme) => ({
  button3D: {
    position: "absolute",
    backgroundColor: 'rgba(255,255,255,0)',
    border: '1px solid rgba(4,135,186,0.5) !important',
    color: 'white',
    transition: 'background-color 200ms ease-in-out',
    "&:hover": {
      backgroundColor: '#0487BA !important',
      color: 'black'
    },
  },
  phoneEmailButtons: {
    backgroundColor: 'rgba(255,255,255,0)',
    border: '1px solid green !important',
    transition: 'background-color 500ms ease-in-out',
    color: 'green',
    "&:hover": {
      backgroundColor: 'green !important',
      color: 'white'
    },
  },
  phoneEmailButtons1: {
    backgroundColor: 'rgba(255,255,255,0)',
    border: '1px solid rgba(4,135,186,0.5) !important',
    transition: 'background-color 500ms ease-in-out',
    "&:hover": {
      backgroundColor: '#0487BA !important',
      color: 'white'
    },
  },
  button3D1: {
    backgroundColor: 'rgba(19,32,49,.65)',
    border: '1px solid rgba(4,135,186,0.5) !important',
    color: '#0487BA',
    "&:hover": {
      backgroundColor: 'rgba(19,32,49,1)',
    },
  },
  frame: {
    marginLeft: "100px",
    marginTop: "100px"
  },
  SideButton: {
    float: "right"
  },
  Featured: {
    position: "absolute"
  }
}));

export default useStyles
import React, { useMemo, useEffect, useState } from 'react'
import { Button, Grid } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import UseStyles from './style'
import { Link } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import { experimentalStyled as styled } from '@material-ui/core/styles';
import Page from '../../components/Page';
import { Trans, useTranslation } from 'react-i18next';
import CardMedia from '@material-ui/core/CardMedia';

const RootStyle = styled(Page)(({ theme }) => ({
    paddingBottom: theme.spacing(10)
}));
const About = () => {
    const { t, i18n } = useTranslation();
    const classes = UseStyles();

    return (
        <>
            <RootStyle title="Privacy Policy | eAkary">
                <Container>

                    <Grid container alignItems="center" justify="space-between">
                        <Typography className={classes.typoTitle} component="h4" variant="h4">
                            {t("description.about")}
                        </Typography>
                    </Grid>

                    <Grid container alignItems="center" justify="space-between">
                        <Typography variant="subtitle2" component="p" className={classes.typoDate}>
                            Last updated on May 31, 2021.
                        </Typography>

                    </Grid>
                    <Grid container alignItems="center" justify="space-between">
                        <Typography className={classes.typoTitle} component="h5" variant="h5">
                            {t("description.lebanonleading")}
                        </Typography>
                    </Grid>

                    <Grid container alignItems="center" justify="space-between">
                        <Typography variant="body2" component="p" className={classes.typoText}>
                            {t("description.lebanonleadinganswer")}
                        </Typography>

                    </Grid>
                    <Grid container alignItems="center" justify="space-between">
                        <Typography className={classes.typoTitle} component="h5" variant="h5">
                            II. {t("description.realestateagency")}
                        </Typography>


                    </Grid>

                    <Grid container alignItems="center" justify="space-between">
                        <Typography variant="body2" component="p" className={classes.typoText}>
                            {t("description.realestateagencyanswer")}
                            <Typography variant="body2" component="p" className={classes.typoText}>
                                {t("description.realestateagencyanswer2")}
                            </Typography>

                        </Typography>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-around"
                        alignItems="center"
                        marginTop={10}
                    >
                        <Grid
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <p style={{ textAlign: 'center', fontWeight: 'bold', color: '#122b63', fontSize: 33 }}>150,000</p>
                            <p style={{ textAlign: 'center', fontWeight: '600', color: '#122b63', fontSize: 25 }}>classfields</p>
                        </Grid>
                        <Grid
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <p style={{ textAlign: 'center', fontWeight: 'bold', color: '#122b63', fontSize: 33 }}>+ 600,000</p>
                            <p style={{ textAlign: 'center', fontWeight: '600', color: '#122b63', fontSize: 25 }}>visits /day</p>
                        </Grid>
                        <Grid
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <p style={{ textAlign: 'center', fontWeight: 'bold', color: '#122b63', fontSize: 33 }}>+ 100</p>
                            <p style={{ textAlign: 'center', fontWeight: '600', color: '#122b63', fontSize: 25 }}>employees</p>
                        </Grid>
                    </Grid>


                </Container>
            </RootStyle>
        </>
    )
}

export default About
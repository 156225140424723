import React, { useState, useEffect } from 'react'
import { Stepper, Step, StepLabel } from "@material-ui/core";
import Bundle from "../Bundle/bundle";
import GetStarted from "../../components/Steppers/GetStarted";
import Typography from "@material-ui/core/Typography";
import MainInformation from "../../components/Steppers/MainInformation";
import Images from "../../components/Steppers/Images";
import AdditionalFeaturesEdit from "../../components/Steppers/AdditionalFeaturesEdit";
import { getPost, unlockPost } from "../../actions/posts";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Finish from "../../components/Steppers/Finish";
import { useDispatch, useSelector } from "react-redux";
import NotLoggedIn from '../../components/NotLoggedIn/notLoggedIn';
import { useParams, useNavigate, Navigate } from 'react-router-dom'
import PreviewEdit from "../../components/Steppers/PreviewEdit";
import Payment from "../../components/Steppers/Payment";
import axios from "axios";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { experimentalStyled as styled } from '@material-ui/core/styles';
import Page from '../../components/Page';
const RootStyle = styled(Page)(({ theme }) => ({
    paddingBottom: theme.spacing(10)
}));
const steps = ['Bundle', 'Preview', 'Payment'];

const UnlockListingStepper = ({ currentId }) => {

    const user = JSON.parse(localStorage.getItem('profile'));
    const dispatch = useDispatch()
    const [activeStep, setActiveStep] = useState(0);
    const [postData, setPostData] = useState({ duration: '', featured: false, bundleChosen: '', listingType: 'buy', images: [], propertyType: 'Apartment', street: '', city: '', building: '', floor: '', additionalDetail: '', title: '', description: '', surfaceArea: '', email: '', phoneNumber: '', constructionDate: '', constructionCondition: '', price: '', numberOfRooms: '', numberOfBedrooms: '', numberOfBathrooms: '', kitchenArea: '', parkingSpace: false, dwelling: false, brickRoof: false, outBuildings: false, caretakerConcierge: false, garden: false, swimmingPool: false, terrace: false, elevator: false, furnished: false, internetAccess: false, videoPhone: false, specialNeedsAccessibility: false, heatingType: '', coolingType: '', pets: false, smoking: false, bigParties: false, loudMusic: false });
    const [bundleChosen, setBundle] = useState('Basic');
    const [bundlePrice, setPrice] = useState("");
    const [markers, setMarkers] = useState({});
    const { post } = useSelector((state) => state.posts);
    const [selectedPayment, setPayment] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [imageFileList, setFileList] = useState([]);
    const [mainImage, setMainImage] = useState([]);//Ralph
    const [uploadPercentage, setUploadPercentage] = useState(0)
    let UploadedImages = [];
    let UploadMainImage = {};
    // const stripe = useStripe();
    // const elements = useElements();

    const { id } = useParams();
    useEffect(() => {
        dispatch(
            getPost(id));
        setPostData(post)

    }, [id]);



    const Stripe = async (values, marker) => {
        //
        //     if (!stripe || !elements) {
        //
        //         setLoading(false)
        //         return;
        //     }
        //
        //     const res = await axios.post('http://localhost:5000/stripe/pay', form);
        //
        //     const clientSecret = res.data['client_secret'];
        //
        //     const result = await stripe.confirmCardPayment(clientSecret, {
        //         payment_method: {
        //             card: elements.getElement(CardElement),
        //             billing_details: {
        //                 email: values.email,
        //                 phone: values.phoneNumber,
        //                 name: values.firstName + ' ' + values.lastName
        //             },
        //         },
        //     });
        //
        //     if (result.error) {
        //         setLoading(false)
        //         setError(result.error.message)
        //         return;
        //     } else {
        //
        //         if (result.paymentIntent.status === 'succeeded') {
        //             setSuccess(true)
        //             setLoading(false)
        //             handleImageSubmit(marker)
        //         }
        //     }
    }


    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleNextFree = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 2);
    };


    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleChange = input => e => {
        setPostData({ ...postData, [input]: e.target.value });
    }

    const handleImageSubmit = async (marker) => {

        setLoading(true)
        // setTimeout(function() {
        const form = {
            duration: postData.duration,
            featured: postData.featured,
            bundleChosen: postData.bundleChosen,
            listingType: postData.listingType,
            mainImage: UploadMainImage,
            images: UploadedImages,
            propertyType: postData.propertyType,
            street: postData.street,
            city: postData.city,
            building: postData.building,
            floor: postData.floor,
            numberOfFloors: postData.numberOfFloors,
            additionalDetail: postData.additionalDetail,
            title: postData.title,
            description: postData.description,
            surfaceArea: postData.surfaceArea,
            email: postData.email,
            phoneNumber: postData.phoneNumber,
            constructionDate: postData.constructionDate,
            constructionCondition: postData.constructionCondition,
            price: postData.price,
            numberOfRooms: postData.numberOfRooms,
            numberOfBedrooms: postData.numberOfBedrooms,
            numberOfBathrooms: postData.numberOfBathrooms,
            kitchenArea: postData.kitchenArea,
            parkingSpace: postData.parkingSpace,
            dwelling: postData.dwelling,
            brickRoof: postData.brickRoof,
            outBuildings: postData.outBuildings,
            caretakerConcierge: postData.caretakerConcierge,
            garden: postData.garden,
            swimmingPool: postData.swimmingPool,
            terrace: postData.terrace,
            elevator: postData.elevator,
            facingStreet: postData.facingStreet,
            inTheWood: postData.inTheWood,
            flatLand: postData.flatLand,
            furnished: postData.furnished,
            internetAccess: postData.internetAccess,
            videoPhone: postData.videoPhone,
            specialNeedsAccessibility: postData.specialNeedsAccessibility,
            heatingType: postData.heatingType,
            coolingType: postData.coolingType,
            secureAccess: postData.secureAccess,
            showRoom: postData.showRoom,
            receptionDesk: postData.receptionDesk,
            pets: postData.pets,
            smoking: postData.smoking,
            bigParties: postData.bigParties,
            loudMusic: postData.loudMusic,
            mapPin: marker
        }

        // if(postData.propertyType === 'Land'){
        //     setPostData({...postData,constructionCondition: '',heatingType: '', coolingType: ''})
        //     const result = await dispatch(createPost(postData));
        //     const delayInMilliseconds = 2000;
        //     setTimeout(function() {
        //         if(result){
        //             setLoading(false);
        //             if(bundleChosen === 'free')
        //             {
        //                 handleNextFree()
        //             }else handleNext();
        //         }else setLoading(false)
        //     }, delayInMilliseconds);
        // }else {
        // handleImageSubmit();
        const result = await dispatch(unlockPost(id, form));
        const delayInMilliseconds = 2000;
        setTimeout(function () {
            if (result) {
                setLoading(false);
                handleNext();
            }
        }, delayInMilliseconds);
        //
        // }

    };



    // const handleSubmitMainImage = () => {
    //     // event.preventDefault();
    //     setLoading(true)
    //     // let filesUrl = [];
    //     let options = {
    //         onUploadProgress: (progressEvent) => {
    //             const { loaded, total } = progressEvent;
    //             let percent = Math.floor((loaded * 100) / total)

    //             if (percent < 100) {
    //                 setUploadPercentage(percent)
    //             }
    //         }
    //     }

    //     let uploaders = mainImage.fileList.map(file => {

    //         let formData = new FormData();
    //         // add one or more of your files in FormData
    //         // again, the original file is located at the `originFileObj` key
    //         // const formData = new FormData();
    //         formData.append("file", file.originFileObj);
    //         formData.append("tags", `codeinfuse, medium, gist`);
    //         formData.append("upload_preset", "testing"); // Replace the preset name with your own
    //         formData.append("api_key", "211986464428528"); // Replace API key with your own Cloudinary key
    //         formData.append("timestamp", (Date.now() / 1000) | 0);
    //         // formData.append("file", this.state.fileList[0].originFileObj);

    //         return axios.post("https://api.cloudinary.com/v1_1/ralphb21/image/upload", formData, options, {
    //             headers: { "X-Requested-With": "XMLHttpRequest" },
    //         }).then(response => {
    //             const data = response.data;
    //             const fileURL = data.secure_url // You should store this URL for future references in your app
    //             UploadMainImage.push(fileURL)
    //         })

    //     })

    //     //Once all the files are uploaded
    //     axios.all(uploaders).then(async () => {
    //         // ... perform after upload is successful operation
    //         // await axios.post("http://localhost:5000/api/pins", markers).then((res)=>{
    //         //     if(res){
    //         //         handleImageSubmit(res.data._id);
    //         //         setFileList([])
    //         //     }
    //         // });
    //         // handleImageSubmit(markers);
    //         handleSubmit();
    //         setMainImage([]);
    //         // setFileList([])
    //     });
    // };

    const GetStepContent = (activeStep) => {
        switch (activeStep) {
            case 0: return (<Bundle handleNext={handleNext} bundle={bundleChosen} setBundle={setBundle} setPrice={setPrice} price={bundlePrice} post={postData} setPost={setPostData} />)
            case 1: return (<PreviewEdit handleSubmit={handleImageSubmit} handleBack={handleBack} handleNext={handleNext} loading={isLoading} bundle={bundleChosen} values={postData} price={bundlePrice} paymentSelected={selectedPayment} setPayment={setPayment} />)
            case 2: return (<Payment stripe={Stripe} handleBack={handleBack} values={postData} setPost={setPostData} handleSubmit={handleImageSubmit} bundle={bundleChosen} loading={isLoading} price={bundlePrice} paymentSelected={selectedPayment} />)
            default: return
        }
    }

    if (!user?.result?.email) {
        return (
            <NotLoggedIn />
        );
    }
    return (
        <>
            <RootStyle title="Unlock Listing | eAkary">
                <Stepper activeStep={activeStep} alternativeLabel>
                    {
                        steps.map((step) => (
                            <Step key={step}>
                                <StepLabel>{step}</StepLabel>
                            </Step>
                        ))
                    }
                </Stepper>
                {activeStep === steps.length ? <Finish /> : (GetStepContent(activeStep))
                }
            </RootStyle>
        </>
    )

}
export default UnlockListingStepper
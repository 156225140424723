import React, { useState } from "react";
import usePlacesAutocomplete, { getGeocode, getLatLng, } from "use-places-autocomplete";
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from "@reach/combobox";
import { GoogleMap, useLoadScript, Marker, InfoWindow, } from "@react-google-maps/api";
import "@reach/combobox/styles.css";
import './searchStyle.css'
import { Container, Grid } from "@material-ui/core";


let Resadress = []
export default function Search({ panTo, markers, setMarkers }) {
    // const [markers, setMarkers] = useState([]);

    const { ready, value, suggestions: { status, data }, setValue, clearSuggestions } = usePlacesAutocomplete({
        requestOptions: {
            location: {
                lat: () => 43.6532,
                lng: () => -79.3832,
            },
            radius: 200 * 1000,
        },
    });

    return (
        // <Grid xs={12} sm={12} md={12} lg={12} >

            <div className="searchRent">

                <Combobox
                    onSelect={async (address) => {

                        setValue(address, false)
                        clearSuggestions();
                        try {
                            const results = await getGeocode({ address });
                            Resadress = (results[0].formatted_address)
                            const { lat, lng } = await getLatLng(results[0]);
                            const newMarker = {
                                lat: lat,
                                lng: lng,
                            };
                            const res = newMarker;
                            setMarkers([...markers, res]);
                            panTo({ lat, lng });
                            // setMarkers([...markers, { lat, lng }]);


                        } catch (error) {
                        }
                    }}

                >
                    <ComboboxInput value={value} onChange={(e) => {
                        setValue(e.target.value);
                    }}
                        disabled={!ready}
                        placeholder="Enter Address"
                    />
                    <ComboboxPopover>
                        <ComboboxList>
                            {status === "OK" && data.map(({ id, description }) => (
                                <ComboboxOption key={id} value={description} />
                            ))}
                        </ComboboxList>
                    </ComboboxPopover>
                </Combobox>
            </div >
        // </Grid>
    );
}
import * as React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

function EmptyQuerySnackBar({ isOpen, handleClose }) {

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>

            <Snackbar
                open={isOpen}
                autoHideDuration={4000}
                onClose={handleClose}
                message="Enter a search query!"
                action={action}
            />
        </div>
    );
}
export default EmptyQuerySnackBar;
import React, { memo } from 'react'
import { Grid, IconButton, Hidden, Tooltip } from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import LandscapeIcon from '@material-ui/icons/Landscape';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import UseStyles from './styles';
import { useNavigate } from 'react-router-dom';

import useMediaQuery from '@material-ui/core/useMediaQuery';
function LandPropertyItem({ altitude, _id, surfaceArea }) {
    const isMobileScreen = useMediaQuery('(max-width:600px)');

    const navigate = useNavigate();
    const isTabletScreen = useMediaQuery('(max-width:1070px)');
    const classes = UseStyles('Land', isMobileScreen, isTabletScreen);
    return (
        <Grid xs={12} item container sx={{ ...classes.footer }} direction="row" justifyContent="space-between" alignItems="flex-end" >

            <Grid container item sx={{ ...classes.footerInfo, flex: '4' }} justifyContent="space-around" alignItems="center" >
                <Grid item sx={{ ...classes.footerText }}><Tooltip title="Surface Area"><span>< ZoomOutMapIcon sx={{ fontSize: '1.3rem' }} /> {` ${surfaceArea}sqft`}</span></Tooltip></Grid>
                <Grid item sx={{ ...classes.footerText }}><Tooltip title="Altitude"><span>< LandscapeIcon sx={{ fontSize: '1.3rem' }} />  <Hidden smDown>Altitude:</Hidden> {`${altitude}m`}</span></Tooltip></Grid>

            </Grid>
            <Grid onClick={() => navigate(`/posts/${_id}`)} container sx={{ ':hover': { cursor: 'pointer !important', background: 'rgba(0, 196, 46,0.5)' }, 
            transition: 'background 200ms ease-in-out', height: '60%', backgroundColor: 'rgba(0,0,0,0.7)', flex: '1',borderLeft: '2px solid white' }} 
            justifyContent="center" alignItems="center" item >< MoreHorizIcon sx={{ color: 'white' }} fontSize="large" />
            <IconButton size="small" sx={{ ...classes.footerText }}></IconButton></Grid>
        </Grid >
    )
}

export default memo(LandPropertyItem)
import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import {styled} from "@material-ui/styles";
import Pic from '../../images/real-estate-websites.jpg'

const RootStyle = styled('div')({
    display: 'flex',
    '& > *': {
        margin: 8,
    },
});
const ImageAvatars = ({firstname}) => {
    return (
        <RootStyle>
            <Avatar alt={firstname} sx={{width:'150px',height:'150px',marginTop:5}} />
        </RootStyle>
    );
}
export default ImageAvatars;
import React, { useState, useEffect } from 'react'
import { Stepper, Step, StepLabel } from "@material-ui/core";
import Bundle from "../Bundle/bundle";
import GetStartedEdit from "../../components/Steppers/GetStartedEdit";
import Typography from "@material-ui/core/Typography";
import MainInformation from "../../components/Steppers/MainInformation";
import Images from "../../components/Steppers/Images";
import AdditionalFeaturesEdit from "../../components/Steppers/AdditionalFeaturesEdit";
import { getPost, updatePost } from "../../actions/posts";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Finish from "../../components/Steppers/Finish";
import { useDispatch, useSelector } from "react-redux";
import NotLoggedIn from '../../components/NotLoggedIn/notLoggedIn';
import { useParams, useNavigate, Navigate } from 'react-router-dom'
import Preview from "../../components/Steppers/Preview";
import Payment from "../../components/Steppers/Payment";
import axios from "axios";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { experimentalStyled as styled } from '@material-ui/core/styles';
import Page from '../../components/Page';
const RootStyle = styled(Page)(({ theme }) => ({
    paddingBottom: theme.spacing(10)
}));
const steps = ['Get Started', 'Main Information', 'Images', 'Additional Features'];

const EditListingStepper = ({ currentId }) => {

    const user = JSON.parse(localStorage.getItem('profile'));
    const dispatch = useDispatch()
    const [activeStep, setActiveStep] = useState(0);
    const [postData, setPostData] = useState();
    const [bundleChosen, setBundle] = useState('Basic');
    const [bundlePrice, setPrice] = useState("");
    const [markers, setMarkers] = useState({});
    const { post } = useSelector((state) => state.posts);
    const [selectedPayment, setPayment] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [imageFileList, setFileList] = useState([]);
    const [mainImage, setMainImage] = useState([]);//Ralph
    const [uploadPercentage, setUploadPercentage] = useState(0)
    let UploadedImages = [];
    let UploadMainImage = {};
    const mapPin = { type: postData ? postData.mapPin : null }


    // const stripe = useStripe();
    // const elements = useElements();

    const { id } = useParams();

    useEffect(async () => {
        const data = await dispatch(
            getPost(id));


        if (data !== undefined) {
            setPostData(data)
            setMarkers(data.mapPin)
        }

    }, []);

    //   useEffect( () => {
    //     setPostData(post)
    //     setMarkers(mapPin.type)
    // }, []);





    const Stripe = async (values, marker) => {
        //
        //     if (!stripe || !elements) {
        //
        //         setLoading(false)
        //         return;
        //     }
        //
        //     const res = await axios.post('http://localhost:5000/stripe/pay', form);
        //
        //     const clientSecret = res.data['client_secret'];
        //
        //     const result = await stripe.confirmCardPayment(clientSecret, {
        //         payment_method: {
        //             card: elements.getElement(CardElement),
        //             billing_details: {
        //                 email: values.email,
        //                 phone: values.phoneNumber,
        //                 name: values.firstName + ' ' + values.lastName
        //             },
        //         },
        //     });
        //
        //     if (result.error) {
        //         setLoading(false)
        //         setError(result.error.message)
        //         return;
        //     } else {
        //
        //         if (result.paymentIntent.status === 'succeeded') {
        //             setSuccess(true)
        //             setLoading(false)
        //             handleImageSubmit(marker)
        //         }
        //     }
    }


    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleNextFree = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 2);
    };


    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleChange = input => e => {
        setPostData({ ...postData, [input]: e.target.value });
    }

    const handleImageSubmit = async (marker) => {
        // setTimeout(function() {
        const form = {
            duration: postData.duration,
            featured: postData.featured,
            bundleChosen: postData.bundleChosen,
            listingType: postData.listingType,
            mainImage: UploadMainImage,
            images: UploadedImages,
            propertyType: postData.propertyType,
            street: postData.street,
            city: postData.city,
            building: postData.building,
            floor: postData.floor,
            numberOfFloors: postData.numberOfFloors,
            additionalDetail: postData.additionalDetail,
            title: postData.title,
            description: postData.description,
            surfaceArea: postData.surfaceArea,
            email: postData.email,
            phoneNumber: postData.phoneNumber,
            constructionDate: postData.constructionDate,
            constructionCondition: postData.constructionCondition,
            price: postData.price,
            numberOfRooms: postData.numberOfRooms,
            numberOfBedrooms: postData.numberOfBedrooms,
            numberOfBathrooms: postData.numberOfBathrooms,
            kitchenArea: postData.kitchenArea,
            parkingSpace: postData.parkingSpace,
            dwelling: postData.dwelling,
            brickRoof: postData.brickRoof,
            outBuildings: postData.outBuildings,
            caretakerConcierge: postData.caretakerConcierge,
            garden: postData.garden,
            swimmingPool: postData.swimmingPool,
            terrace: postData.terrace,
            elevator: postData.elevator,
            facingStreet: postData.facingStreet,
            inTheWood: postData.inTheWood,
            flatLand: postData.flatLand,
            furnished: postData.furnished,
            internetAccess: postData.internetAccess,
            videoPhone: postData.videoPhone,
            specialNeedsAccessibility: postData.specialNeedsAccessibility,
            heatingType: postData.heatingType,
            coolingType: postData.coolingType,
            secureAccess: postData.secureAccess,
            showRoom: postData.showRoom,
            receptionDesk: postData.receptionDesk,
            pets: postData.pets,
            smoking: postData.smoking,
            bigParties: postData.bigParties,
            loudMusic: postData.loudMusic,
            mapPin: marker
        }

        // if(postData.propertyType === 'Land'){
        //     setPostData({...postData,constructionCondition: '',heatingType: '', coolingType: ''})
        //     const result = await dispatch(createPost(postData));
        //     const delayInMilliseconds = 2000;
        //     setTimeout(function() {
        //         if(result){
        //             setLoading(false);
        //             if(bundleChosen === 'free')
        //             {
        //                 handleNextFree()
        //             }else handleNext();
        //         }else setLoading(false)
        //     }, delayInMilliseconds);
        // }else {
        // handleImageSubmit();
        const result = await dispatch(updatePost(id, form));
        const delayInMilliseconds = 2000;
        setTimeout(function () {
            if (result) {
                setLoading(false);
                handleNext();
            }
        }, delayInMilliseconds);
        //
        // }

    };

    const handleSubmitMainImage = () => {
        // event.preventDefault();
        setLoading(true)
        // let filesUrl = [];
        let options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total)

                if (percent < 100) {
                    setUploadPercentage(percent)
                }
            }
        }

        let uploaders = mainImage.fileList.map(file => {

            let formData = new FormData();
            // add one or more of your files in FormData
            // again, the original file is located at the `originFileObj` key
            // const formData = new FormData();
            formData.append("file", file.originFileObj);
            formData.append("tags", `codeinfuse, medium, gist`);
            formData.append("upload_preset", "testing"); // Replace the preset name with your own
            formData.append("api_key", "211986464428528"); // Replace API key with your own Cloudinary key
            formData.append("timestamp", (Date.now() / 1000) | 0);
            // formData.append("file", this.state.fileList[0].originFileObj);

            return axios.post("https://api.cloudinary.com/v1_1/ralphb21/image/upload", formData, options, {
                headers: { "X-Requested-With": "XMLHttpRequest" },
            }).then(response => {
                const data = response.data;
                const public_id = data.public_id
                const fileURL = data.secure_url // You should store this URL for future references in your app
                UploadMainImage.url = fileURL
                UploadMainImage.public_id = public_id
            })

        })

        //Once all the files are uploaded
        axios.all(uploaders).then(async () => {
            // ... perform after upload is successful operation
            // await axios.post("http://localhost:5000/api/pins", markers).then((res)=>{
            //     if(res){
            //         handleImageSubmit(res.data._id);
            //         setFileList([])
            //     }
            // });
            // handleImageSubmit(markers);
            handleSubmit();
            setMainImage([]);
            // setFileList([])
        });
    }


    const handleSubmit = () => {
        // event.preventDefault();
        setLoading(true)
        // let filesUrl = [];
        let options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total)

                if (percent < 100) {
                    setUploadPercentage(percent)
                }
            }
        }

        let uploaders = imageFileList.fileList.map(file => {

            let formData = new FormData();
            // add one or more of your files in FormData
            // again, the original file is located at the `originFileObj` key
            // const formData = new FormData();
            formData.append("file", file.originFileObj);
            formData.append("tags", `codeinfuse, medium, gist`);
            formData.append("upload_preset", "testing"); // Replace the preset name with your own
            formData.append("api_key", "211986464428528"); // Replace API key with your own Cloudinary key
            formData.append("timestamp", (Date.now() / 1000) | 0);
            // formData.append("file", this.state.fileList[0].originFileObj);

            return axios.post("https://api.cloudinary.com/v1_1/ralphb21/image/upload", formData, options, {
                headers: { "X-Requested-With": "XMLHttpRequest" },
            }).then(response => {
                const data = response.data;
                const public_id = data.public_id;
                const fileURL = data.secure_url; // You should store this URL for future references in your app
                UploadedImages.push({ url: fileURL, public_id: public_id });
                // UploadedImages.url = fileURL
                // UploadedImages.public_id = public_id
            })

        })

        //Once all the files are uploaded
        axios.all(uploaders).then(async () => {
            // ... perform after upload is successful operation
            // await axios.post("http://localhost:5000/api/pins", markers).then((res)=>{
            //     if(res){
            //         handleImageSubmit(res.data._id);
            //         setFileList([])
            //     }
            // });
            handleImageSubmit(markers);
            setFileList([])
        });
    };


    // const handleSubmitMainImage = () => {
    //     // event.preventDefault();
    //     setLoading(true)
    //     // let filesUrl = [];
    //     let options = {
    //         onUploadProgress: (progressEvent) => {
    //             const { loaded, total } = progressEvent;
    //             let percent = Math.floor((loaded * 100) / total)

    //             if (percent < 100) {
    //                 setUploadPercentage(percent)
    //             }
    //         }
    //     }

    //     let uploaders = mainImage.fileList.map(file => {

    //         let formData = new FormData();
    //         // add one or more of your files in FormData
    //         // again, the original file is located at the `originFileObj` key
    //         // const formData = new FormData();
    //         formData.append("file", file.originFileObj);
    //         formData.append("tags", `codeinfuse, medium, gist`);
    //         formData.append("upload_preset", "testing"); // Replace the preset name with your own
    //         formData.append("api_key", "211986464428528"); // Replace API key with your own Cloudinary key
    //         formData.append("timestamp", (Date.now() / 1000) | 0);
    //         // formData.append("file", this.state.fileList[0].originFileObj);

    //         return axios.post("https://api.cloudinary.com/v1_1/ralphb21/image/upload", formData, options, {
    //             headers: { "X-Requested-With": "XMLHttpRequest" },
    //         }).then(response => {
    //             const data = response.data;
    //             const fileURL = data.secure_url // You should store this URL for future references in your app
    //             UploadMainImage.push(fileURL)
    //         })

    //     })

    //     //Once all the files are uploaded
    //     axios.all(uploaders).then(async () => {
    //         // ... perform after upload is successful operation
    //         // await axios.post("http://localhost:5000/api/pins", markers).then((res)=>{
    //         //     if(res){
    //         //         handleImageSubmit(res.data._id);
    //         //         setFileList([])
    //         //     }
    //         // });
    //         // handleImageSubmit(markers);
    //         handleSubmit();
    //         setMainImage([]);
    //         // setFileList([])
    //     });
    // };

    const GetStepContent = (activeStep) => {
        switch (activeStep) {
            case 0: return (<GetStartedEdit setPost={setPostData} handleChange={handleChange} post={postData} handleNext={handleNext} />)
            case 1: return (<MainInformation markers={markers} setMarkers={setMarkers} setPost={setPostData} values={postData} handleChange={handleChange} handleNext={handleNext} handleBack={handleBack} />)
            case 2: return (<Images bundle={bundleChosen} setPost={setPostData} post={postData} fileList={imageFileList} setFileList={setFileList} mainImage={mainImage} setMainImage={setMainImage} handleNext={handleNext} handleBack={handleBack} />)
            case 3: return (<AdditionalFeaturesEdit handleSubmit={handleSubmitMainImage} setPost={setPostData} handleChange={handleChange} values={postData} handleBack={handleBack} />)
            default: return
        }
    }

    if (!user?.result?.email) {
        return (
            <NotLoggedIn />
        );
    }
    return (
        <>
            <RootStyle title="Edit Listing | eAkary">
                <Stepper activeStep={activeStep} alternativeLabel>
                    {
                        steps.map((step) => (
                            <Step key={step}>
                                <StepLabel>{step}</StepLabel>
                            </Step>
                        ))
                    }
                </Stepper>
                {activeStep === steps.length ? <Finish values={postData} /> : (GetStepContent(activeStep))
                }
            </RootStyle>
        </>
    )

}
export default EditListingStepper
import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import FeaturedPost from './featuredPostCard';
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"
import "./styles.css";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Pagination, Navigation
} from 'swiper/core';
SwiperCore.use([Pagination, Navigation]);
const Posts = ({ setCurrentId }) => {
  const { posts, isLoading } = useSelector((state) => state.posts);
  const is560pxScreen = useMediaQuery('min-width:560px');
  if (!posts.length && !isLoading) return 'No posts';
  return (
    isLoading ?
      <Grid container justifyContent="center">
        <CircularProgress />
      </Grid> :
      (
        <Swiper navigation={true} breakpoints={{
          0: {
            "slidesPerView": 1,
            "spaceBetween": 0,
            "slidesPerGroup": 1
          },
          386: {
            "slidesPerView": 1.2,
            "spaceBetween": 0,
            "slidesPerGroup": 1
          },
          560: {
            "slidesPerView": 2,
            "spaceBetween": 14,
            "slidesPerGroup": 1
          },
          1070: {
            "slidesPerView": 3,
            "spaceBetween": 20,
            "slidesPerGroup": 2
          }
        }} loop={true} loopFillGroupWithBlank={true} className="mySwiper" style={{ padding: '0 2em', justifyContent: 'flex-start' }}>
          {posts?.map((post) => {
            const dateNow = new Date();
            const expiredAt = Date.parse(post.expireAt);
            if (expiredAt !== undefined) {
              if (dateNow > expiredAt) {
                return null
              } else {
                return (
                  <SwiperSlide key={post._id} style={{ justifyContent: is560pxScreen ? 'center !important' : 'flex-start !important' }}  >
                    <FeaturedPost post={post} setCurrentId={setCurrentId} />
                  </SwiperSlide>
                )
              }
            } else return (
              <SwiperSlide key={post._id} style={{ justifyContent: "flex-start" }}>
                <FeaturedPost post={post} setCurrentId={setCurrentId} />
              </SwiperSlide>
            )
          })}
        </Swiper>
      )
  );
};
export default Posts;

import React, { useState } from 'react'
import {
    CircularProgress,
    Container,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    LinearProgress,
    Radio
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import UseStyles from "./style";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import StripeComponent from "../stripe/stripecomponent";
import Dialog from "@material-ui/core/Dialog";
import PayPalBtn from "../PayPal/PaypalButton";
// import { withStyles } from "@material-ui/styles";
import { blue } from "@material-ui/core/colors";
import Stripe from '../../images/stripe.svg';
import Paypal from '../../images/paypal.svg';
import editprofile from "../../images/editProfile.svg";
import { Trans, useTranslation } from 'react-i18next';

const Payment = ({ setOpenStripe, openStripe, ERROR, handleSubmitPaypal, stripe, handleBack, handleSubmit, loading, price, paymentSelected, setPost, values, bundle, loadingStripe, succeed, error }) => {

    const { t, i18n } = useTranslation();

    const [open, setOpen] = useState(false);

    const [selectedPayment, setSelectedPayment] = React.useState('');

    const handleChange = (event) => {
        setSelectedPayment(event.target.value);
    };


    const paypalSubscribe = (data, actions) => {
        // return actions.subscription.create({
        //     // 'plan_id': "P-5AJ86519Y6533850DMDSW7JI",
        //     bundle === 'Gold' ? ('plan_id': "P-9V619590R9082872EMEVIXOY" ):
        //     bundle === 'Platinum' ? 'plan_id': "P-0UN833643N7125428MEVJDCA":
        //     'plan_id': "P-99R558948L855602KMEWN3UY",
        // //        'plan_id': "P-0UN833643N7125428MEVJDCA",
        // //        'plan_id': "P-99R558948L855602KMEWN3UY"
        // });
        // return actions.subscription.create(
        //     bundle === 'Gold' ? { 'plan_id': "P-9V619590R9082872EMEVIXOY" } :
        //         bundle === 'Platinum' ? { 'plan_id': "P-0UN833643N7125428MEVJDCA" } :
        //             { 'plan_id': "P-99R558948L855602KMEWN3UY" }
        // );

        //testing 1$ on gold
        return actions.subscription.create(
            bundle === 'Gold' ? { 'plan_id': "P-84R06249G71383728MEJI46I" } :
                bundle === 'Platinum' ? { 'plan_id': "P-0UN833643N7125428MEVJDCA" } :
                    { 'plan_id': "P-99R558948L855602KMEWN3UY" }
        );
    };

    const paypalOnError = (err) => {
    }

    const paypalOnApprove = (data, detail) => {
        handleSubmitPaypal()
    };

    return (

        <div>
            <Container>
                <Grid container justifyContent="center">
                    {paymentSelected === 'stripe' ?
                        <Grid>
                            <StripeComponent setOpenStripe={setOpenStripe} openStripe={openStripe} ERROR={ERROR} stripe={stripe} handleSubmit={handleSubmit} price={price} setPost={setPost} succeed={succeed} loading={loadingStripe} error={error}
                                values={values} />
                        </Grid>
                        :
                        <>
                        <Grid container sx={{mb:2, mt:5}} justifyContent="center">  
                        <Box
                    width={300}
                    component="img"
                    src={Paypal}
                    />
                    </Grid>
                    <Grid container sx={{mb:2, mt:2}} justifyContent="center">
                                <Typography variant="subtitle1" sx={{mb:2}}>
                                Click on the button to proceed to your payment.
                                </Typography>
                                </Grid>

                            <Grid container sx={{mb:2, mt:5}} justifyContent="center">
                                <PayPalBtn style={{ width: '100%' }}
                                    //amount="40"
                                    currency="USD"
                                    createSubscription={paypalSubscribe}
                                    onApprove={paypalOnApprove}
                                    catchError={paypalOnError}
                                    onError={paypalOnError}
                                    onCancel={paypalOnError}
                                />
                                  </Grid>
                            </>
                    }
                </Grid>

                {loading ? (
                    <>
                        <Typography variant='h4'>
                            <Trans i18nKey="description.waiting">
                                Waiting for your property to be added
                            </Trans>
                        </Typography>
                        <Box sx={{ width: '100%', marginTop: '5em' }}>
                            <LinearProgress />
                        </Box>
                    </>
                ) : (
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            <Trans i18nKey="description.back">
                                Back
                            </Trans>
                        </Button>

                        {paymentSelected !== 'stripe' && paymentSelected !== 'paypal' ? (
                            <>
                                <Box sx={{ flex: '1 1 auto' }} />

                                <Button onClick={() => handleSubmit()}>
                                    <Trans i18nKey="description.submit">
                                        Submit
                                    </Trans>
                                </Button>
                            </>
                        ) : null}
                    </Box>
                )
                }
            </Container>
        </div>
    )
}
export default Payment

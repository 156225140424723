import { makeStyles } from '@material-ui/styles';

const UseStyles = makeStyles((theme) => ({
    root: { 
      '&:hover': {
        boxShadow: " 4px 7px 17px -11px rgba(66,66,66,0.75)",    
      }
    },
    blockedRoot:{
        minHeight:360,
        maxHeight: 360,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    mediaBlocked: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    avatar: {
      backgroundColor: "red",
    },
    typoName: {
       
    },
    typoPrice: {
      marginBottom: 5
       
    },
    typoSize: {
        
        marginBottom: 5
    },
    typoLocation: {
        
    
    },
    Favorite: {
        float: 'right',
        cursor: "pointer",
    },
    Featured: {
      position: "absolute"
    }
    
  //   '@media (max-width: 1296px)': {
  //     root: {
  //       width: "270px"
  //     },
      
  //   },
  //   '@media (max-width: 1280px)': {
  //     root: {
  //       width: "320px"
  //     },
  //   },
  //   '@media (max-width: 1156px)': {
  //     root: {
  //       width: "320px"
  //     },
      
  //   },
  //   '@media (max-width: 1110px)': {
  //     root: {
  //       width: "300px",
  //     },
  //     icons: {
  //       marginLeft: "-10px"
  //     }
      
  //   },
  //   '@media (max-width: 1063px)': {
  //     root: {
  //       width: "280px",
  //     },
  //     icons: {
  //       marginLeft: "-10px"
  //     }
      
  //   },
  //   '@media (max-width: 1010px)': {
  //     root: {
  //       width: "270px",
  //     },
  //     icons: {
  //       marginLeft: "-10px"
  //     }
      
  //   },
  //   '@media (max-width: 540px)': {
  //     root: {
  //       marginLeft: "-70px"
  //     },
      
  //   },
  //   '@media (max-width: 415px)': {
  //     root: {
  //       marginLeft: "-120px"
  //     },
      
  //   },
  //   '@media (max-width: 412px)': {
  //     root: {
  //       marginLeft: "-110px"
  //     },
      
  //   },
  //   '@media (max-width: 375px)': {
  //     root: {
  //       width: "345px",
  //     },
  //     ShareIcon: {
  //       marginLeft: "-10px"
  //     },
  //   },
  //   '@media (max-width: 376px)': {
  //     root: {
  //       marginLeft: "-140px"
  //     },
      
  //   },
  //   '@media (max-width: 360px)': {
  //     root: {
  //       width: "300px",
  //       marginLeft: "-150px"
  //     },
      
  //   },
  //   '@media (max-width: 320px)': {
  //     root: {
  //       width: "270px"
  //     },
      
  //   },
  //   '@media (max-width: 280px)': {
  //     root: {
  //       width: "270px",
  //       marginLeft: "-140px"
  //     },
      
  //   },
  //   // root: {
  //   //   [theme.breakpoints.down('xs')]: {
  //   //     width: "360px",
  //   //   },
  //   //   [theme.breakpoints.down('md')]: {
  //   //     width: "270px",
  //   //   },
  //   //   [theme.breakpoints.down('lg')]: {
  //   //     width: "345px",
  //   //     // marginRight: "30px",
  //   //     // padding: "0px"
  //   //   },
  //   // },
    
  //   gridBottomSpace: {
  //       marginBottom: "30px"
  //   }
  }));

export default UseStyles
import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';

import ServiceCard from './serviceCard';
import { Component } from 'react';
import i18n from 'i18next';

const SERVICES = [

  {
    img: "/static/illustrations/buy_service.svg",
    title: "Buy",
    desc: "Find your place with an immersive photo experience and the most listings, including things you won’t find anywhere else.",
    action: "Search Homes",
    link: "/buy",
  },
  {
    img: "/static/illustrations/rent_service.svg",
    title: "Rent",
    desc: "We’re creating a seamless online experience – from shopping on the largest rental network, to applying, to paying rent.",
    action: "Rent Homes",
    link: "/rent",
  },
  {
    img: "/static/illustrations/sell_service.svg",
    title: "Sell",
    desc:  "Whether you get a cash offer through eAkary Offers® or choose to sell traditionally, we can help you navigate a successful sale.",
    action: "List property ",
    link: "/createpost",
  },
]

class ServicesCards extends Component {
  render() {


    return (
      <Grid container spacing={3} justifyContent="center">
        {SERVICES.map((option) => (
          <Grid item xs={12} sm={6} md={4}>
            <ServiceCard img={option.img}
              title={option.title}
              desc={option.desc}
              action={option.action}
              link={option.link} />
          </Grid>
        ))}
      </Grid>

    );
  };
};
export default ServicesCards;
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify-icons/eva/menu-2-fill';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Stack, Grid, AppBar, Toolbar, IconButton, Button, Typography } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
// components
import { MHidden } from '../../components/@material-extend';
//
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import Request360 from '../../pages/Request360/Request360';
import NotificationsPopover from './NotificationsPopover';
import Logo from '../../components/Logo';
import ThreeDRotationIcon from '@material-ui/icons/ThreeDRotation';
import Cookies from 'js-cookie';
//
import { useNavigate, useLocation, Link as RouterLink, } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import decode from 'jwt-decode';
import * as actionType from '../../constants/actionTypes';
import { Trans, useTranslation } from 'react-i18next';
import Link from '@material-ui/core/Link';
// ----------------------------------------------------------------------
const DRAWER_WIDTH = 0;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;
const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backgroundColor: "white",
  [theme.breakpoints.up('lg')]: {
    width: `100%`
  }
}));
const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
}));
// ----------------------------------------------------------------------
DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};
export default function DashboardNavbar({ onOpenSidebar }) {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const token = user?.token;
    if (token) {
      const decodedToken = decode(token);
      if (decodedToken.exp * 1000 < new Date().getTime()) logout();
    }
    setUser(JSON.parse(localStorage.getItem('profile')));
    if (cookieLanguage == "ar") {
      setArLang(true);
    }
  }, [location])
  const logout = () => {
    dispatch({ type: actionType.LOGOUT });
    navigate('/auth/login');
    setUser(null);
  };
  const request360 = () => {
    navigate(`/request360`);
  }
  //////////////////////////////////////
  const { i18n } = useTranslation();
  const [arLang, setArLang] = useState(false);
  let cookieLanguage = Cookies.get('i18next');
  return (
    <>
      <RootStyle >
        <Typography
          fontSize={{
            xs: "8px",
            sm: "15px",
            md: "15px",
            lg: "17px",
            xl: "20px",
          }}
          style={{ backgroundColor: '#0487BA', color: 'black', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: '3px' }}
        >
          <Trans i18nKey="description.banner">
            For any Request, please contact us via
          </Trans>
          <a
            href={"mailto:Anthonytannoury@gmail.com"}
            style={{ color: "black" }}
          >
            <strong> Anthonytannoury@gmail.com </strong>
          </a>
        </Typography>
        <ToolbarStyle>
          <MHidden width="mdUp">
            <IconButton onClick={onOpenSidebar} sx={{ color: 'text.primary' }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                <Icon icon={menu2Fill} />
                <div style={{ fontSize: '10px' }}>Menu</div>
              </div>
            </IconButton>
          </MHidden>
          <MHidden width="mdUp">
            <Grid container justifyContent="center">
              <Logo sx={{ mt: "5px" }} />
            </Grid>
          </MHidden>
          <MHidden width="mdDown">
            <Logo />
          </MHidden>
          <Box sx={{ flexGrow: 1 }} />
          <MHidden width="mdDown">
            <Stack direction="row" justifyContent="flex-end" spacing={{ xs: 3.5, sm: 3.5 }}>
              <Button component={RouterLink} to="/posts" sx={{ color: 'black', ':hover': { color: 'black' } }}>
                <Trans i18nKey="description.posts">
                  Posts
                </Trans>
              </Button>
              <Button component={RouterLink} to="/createpost" sx={{ color: 'black', ':hover': { color: 'black' } }}>
                <Trans i18nKey="description.addListingPage">
                  Add Listing
                </Trans>
              </Button>
              <Button onClick={() => request360()} sx={{ color: 'black' }}>
                <Trans i18nKey="description.virtualTour">
                  Virtual Tour
                </Trans>
              </Button>
            </Stack>
          </MHidden>
          <Stack direction="row" spacing={{ xs: 1.5, sm: 2.5 }}>
            {user?.result?.email ? (
              <>
                <LanguagePopover />
                <AccountPopover />
              </>
            ) : (
              <>
                <LanguagePopover />
                <MHidden width="mdDown">
                  <Button component={RouterLink} to="/auth/login" sx={{ color: 'black', ':hover': { color: 'black' } }} disableElevation>
                    <Trans i18nKey="description.loginPage">
                      Login
                    </Trans>
                  </Button>
                </MHidden>
              </>
            )}
          </Stack>
        </ToolbarStyle>
      </RootStyle>
    </>
  );
}

import './App.css';
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import Hidden from '@material-ui/core/Hidden';
import { useNavigate, useLocation, Link as RouterLink, } from 'react-router-dom';
import Container from '@material-ui/core/Container'
import { Grid, TextField } from '@material-ui/core';
import Inputt from "./buy_rent";
import { useDispatch } from 'react-redux';
import YouNeedToLogInSnackBar from './YouNeedToLogInSnackBar'
import TagsInput from './TagsInput'
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import { alpha, styled } from '@material-ui/core/styles';
import { Paper, IconButton } from '@material-ui/core';
import TuneIcon from '@material-ui/icons/Tune';
import { getPostsBySearchb } from '../../actions/posts';
import UseStyles from "./styles";
import { Button, Typography, Divider, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, FormControl, FormControlLabel, Checkbox } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { MHidden } from '../../components/@material-extend';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Slider from '@material-ui/core/Slider';
import Pagination from './Pagination';
import Posts from './Posts'
import { Trans, useTranslation } from 'react-i18next';
import SortPopover from './SortingComp';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
]
const PropertyTypes = [
  {
    value: '',
    label: '',
  },
  {
    value: 'Apartment',
    label: 'Apartment',
  },
  {
    value: 'Garden',
    label: 'Garden',
  },
  {
    value: 'Garage',
    label: 'Garage',
  },
  {
    value: 'Office',
    label: 'Office',
  },
  {
    value: 'Villa',
    label: 'Villa',
  },
  {
    value: 'Land',
    label: 'Land',
  },
];
const constructionConditions = [
  {
    value: '',
    label: '',
  },
  {
    value: 'Old',
    label: 'Old',
  },
  {
    value: 'New',
    label: 'New',
  },
  {
    value: 'Needs-Renovation',
    label: 'Needs Renovation',
  },
];
const ListingTypes = [
  {
    value: '',
    label: '',
  },
  {
    value: 'buy',
    label: 'Buy',
  },
  {
    value: 'rent',
    label: 'Rent',
  },
];
const heatingTypes = [
  {
    value: '',
    label: '',
  },
  {
    value: 'Furnaces',
    label: 'Furnaces',
  },
  {
    value: 'Boilers',
    label: 'Boilers',
  },
  {
    value: 'Heat-Pumps',
    label: 'Heat Pumps',
  },
  {
    value: 'Gas-Fired-Space-Heaters',
    label: 'Gas-Fired Space Heaters',
  },
  {
    value: 'Wood-Burning-and-Pellet-Stoves',
    label: 'Wood-Burning and Pellet Stoves',
  },
  {
    value: 'Fireplaces',
    label: 'Fireplaces',
  },
  {
    value: 'Radiant-Floor-Heat',
    label: 'Radiant Floor Feat',
  },
];
const coolingTypes = [
  {
    value: '',
    label: '',
  },
  {
    value: 'Central-Air',
    label: 'Central Air',
  },
  {
    value: 'Room-Air-Conitioners',
    label: 'Room Air Conditioners',
  },
  {
    value: 'Evaporative-Coolers',
    label: 'Evaporative Coolers',
  },
  {
    value: 'State-of-the-Art-Cooling',
    label: 'State of the Art Cooling',
  },
];
function PostsPage() {
  const [results, setResults] = useState([])
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const handleSnackBarClose = () => {
    setIsSnackBarOpen(false);
  }
  const [listingType, setListingType] = useState('buy');
  const classes = UseStyles();
  const MUITextField = styled((props) => (
    <TextField InputProps={{ disableUnderline: true }} {...props} />
  ))(({ theme }) => ({
    '& .MuiFilledInput-root': {
      border: '1px solid #e2e2e1',
      overflow: 'hidden',
      borderRadius: 4,
      backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&.Mui-focused': {
        backgroundColor: 'transparent',
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));
  const query = useQuery();
  let page = query.get('page') || 1;
  const [toSort, setToSort] = useState(query.get('toSort') || false);
  const [sortByIncreasingOrder, setSortByIncreasingOrder] = useState(query.get('sortByIncreasingOrder') || false);
  const [sortByDate, setSortByDate] = useState(query.get('sortByDate') || false);
  // const [page, setPage] = useState(query.get('page') || 1);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [isBuy, setIsBuy] = useState(true);
  const [city, setCity] = useState(query.get('cityQuery')?.split(',') || []);
  useEffect(() => {
    if (isBuy) {
      setListingType('buy')
    } else {
      setListingType('rent')
    }
  }, [isBuy])
  useEffect(() => { if (listingType === 'buy' || listingType === 'Buy') { setIsBuy(true); } else { setIsBuy(false); }; searchPost() }, [listingType])
  // const isBuyOrRent = (isBuy) => {
  //   if (isBuy) {
  //     setListingType('rent')
  //     setIsBuy(false)
  //     searchPost();
  //   } else if (!isBuy) {
  //     setIsBuy(true)
  //     searchPost();
  //   }
  // }
  function valuetext(value) {
    return `$${value}`;
  }
  const handleChangeArea = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setValue3([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setValue3([clamped - minDistance, clamped]);
      }
    } else {
      setValue3(newValue);
    }
    setArea({ ...Area, minArea: value3[0], maxArea: value3[1] })
  };
  const handleChange2 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setValue2([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setValue2([clamped - minDistance, clamped]);
      }
    } else {
      setValue2(newValue);
    }
    setPrice({ ...price, minPrice: value2[0], maxPrice: value2[1] })
  };
  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      // searchPost();
    }
  };
  const dialogClick = () => {
    searchPost();
    setFiltered(true);
    handleDialogClose();
  }
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const getQuery = () => {
    const garden = features.garden;
    const parkingSpace = features.parkingSpace;
    const dwelling = features.dwelling
    const brickRoof = features.brickRoof
    const outBuildings = features.outBuildings
    const caretakerConcierge = features.caretakerConcierge
    const swimmingPool = features.swimmingPool
    const terrace = features.terrace
    const elevator = features.elevator
    const facingStreet = features.facingStreet
    const inTheWood = features.inTheWood
    const flatLand = features.flatLand
    const furnished = features.furnished
    const internetAccess = features.internetAccess
    const videoPhone = features.videoPhone
    const specialNeedsAccessibility = features.specialNeedsAccessibility
    const secureAccess = features.secureAccess
    const showRoom = features.showRoom
    const receptionDesk = features.receptionDesk
    const pets = features.pets
    const smoking = features.smoking
    const bigParties = features.bigParties
    const loudMusic = features.loudMusic
    if (valueMap?.trim() || propertyType?.trim() || city.length > 0 || listingType?.trim() || coolingType?.trim() || heatingType?.trim() || constructionCondition?.trim() || valueMap ||
      propertyType ||
      listingType ||
      coolingType ||
      heatingType ||
      constructionCondition ||
      garden ||
      minPrice ||
      maxPrice ||
      maxArea ||
      minArea ||
      numberOfBedrooms ||
      numberOfBathrooms ||
      parkingSpace ||
      dwelling ||
      brickRoof ||
      outBuildings ||
      caretakerConcierge ||
      swimmingPool ||
      terrace ||
      elevator ||
      facingStreet ||
      inTheWood ||
      flatLand ||
      furnished ||
      internetAccess ||
      videoPhone ||
      specialNeedsAccessibility ||
      secureAccess ||
      showRoom ||
      receptionDesk ||
      pets ||
      smoking ||
      bigParties ||
      loudMusic ||
      numberOfBathrooms ||
      numberOfBedrooms ||
      minPrice ||
      maxPrice
    ) { return `/posts/searchb?searchQuery=${valueMap}&cityQuery=${city}&propertyTypeQuery=${propertyType}&coolingTypeQuery=${coolingType}&heatingTypeQuery=${heatingType}&constructionConditionQuery=${constructionCondition}&listingTypeQuery=${listingType}&parkingSpaceQuery=${parkingSpace}&gardenQuery=${garden}&dwellingQuery=${dwelling}&brickRoofQuery=${brickRoof}&outBuildingsQuery=${outBuildings}&caretakerConciergeQuery=${caretakerConcierge}&swimmingPoolQuery=${swimmingPool}&terraceQuery=${terrace}&elevatorQuery=${elevator}&facingStreetQuery=${facingStreet}&inTheWoodQuery=${inTheWood}&flatLandQuery=${flatLand}&furnishedQuery=${furnished}&internetAccessQuery=${internetAccess}&videoPhoneQuery=${videoPhone}&specialNeedsAccessibilityQuery=${specialNeedsAccessibility}&secureAccessQuery=${secureAccess}&showRoomQuery=${showRoom}&receptionDeskQuery=${receptionDesk}&petsQuery=${pets}&smokingQuery=${smoking}&bigPartiesQuery=${bigParties}&loudMusicQuery=${loudMusic}&minPriceQuery=${minPrice}&maxPriceQuery=${maxPrice}&numberOfBedroomsQuery=${numberOfBedrooms}&numberOfBathroomsQuery=${numberOfBathrooms}&minAreaQuery=${minArea}&maxAreaQuery=${maxArea}&toSort=${toSort}&sortByIncreasingOrder=${sortByIncreasingOrder}&sortByDate=${sortByDate}` }
  }
  const searchPost = (fromPagination = false) => {
    const fromPaginator = query.get('frmPg') || 'n';
    if (fromPaginator !== 'y') { page = 1 }
    if (!fromPagination) { page = 1; }
    window.scrollTo(0, 0);
    setResults([])
    const garden = features.garden;
    const parkingSpace = features.parkingSpace;
    const dwelling = features.dwelling
    const brickRoof = features.brickRoof
    const outBuildings = features.outBuildings
    const caretakerConcierge = features.caretakerConcierge
    const swimmingPool = features.swimmingPool
    const terrace = features.terrace
    const elevator = features.elevator
    const facingStreet = features.facingStreet
    const inTheWood = features.inTheWood
    const flatLand = features.flatLand
    const furnished = features.furnished
    const internetAccess = features.internetAccess
    const videoPhone = features.videoPhone
    const specialNeedsAccessibility = features.specialNeedsAccessibility
    const secureAccess = features.secureAccess
    const showRoom = features.showRoom
    const receptionDesk = features.receptionDesk
    const pets = features.pets
    const smoking = features.smoking
    const bigParties = features.bigParties
    const loudMusic = features.loudMusic
    if (valueMap?.trim() || propertyType?.trim() || city?.length > 0 || listingType?.trim() || coolingType?.trim() || heatingType?.trim() || constructionCondition?.trim() || valueMap ||
      propertyType ||
      listingType ||
      coolingType ||
      heatingType ||
      constructionCondition ||
      garden ||
      minPrice ||
      maxPrice ||
      maxArea ||
      minArea ||
      numberOfBedrooms ||
      numberOfBathrooms ||
      parkingSpace ||
      dwelling ||
      brickRoof ||
      outBuildings ||
      caretakerConcierge ||
      swimmingPool ||
      terrace ||
      elevator ||
      facingStreet ||
      inTheWood ||
      flatLand ||
      furnished ||
      internetAccess ||
      videoPhone ||
      specialNeedsAccessibility ||
      secureAccess ||
      showRoom ||
      receptionDesk ||
      pets ||
      smoking ||
      bigParties ||
      loudMusic ||
      numberOfBathrooms ||
      numberOfBedrooms ||
      minPrice ||
      maxPrice
    ) {
      dispatch(getPostsBySearchb({
        toSort,
        sortByIncreasingOrder,
        sortByDate,
        page,
        valueMap,
        city,
        propertyType,
        listingType,
        heatingType,
        coolingType,
        constructionCondition,
        garden,
        minPrice,
        maxPrice,
        maxArea,
        minArea,
        numberOfBedrooms,
        numberOfBathrooms,
        parkingSpace,
        dwelling,
        brickRoof,
        outBuildings,
        caretakerConcierge,
        swimmingPool,
        terrace,
        elevator,
        facingStreet,
        inTheWood,
        flatLand,
        furnished,
        internetAccess,
        videoPhone,
        specialNeedsAccessibility,
        secureAccess,
        showRoom,
        receptionDesk,
        pets,
        smoking,
        bigParties,
        loudMusic,
      }));
      // navigate(`/posts/search?searchQuery=${search}&propertyTypeQuery=${propertyType}&listingTypeQuery=${listingType}&minPriceQuery=${minPrice}&maxPriceQuery=${maxPrice}`);
      navigate(`/posts/searchb?searchQuery=${valueMap}&cityQuery=${city}&propertyTypeQuery=${propertyType}&coolingTypeQuery=${coolingType}&heatingTypeQuery=${heatingType}&constructionConditionQuery=${constructionCondition}&listingTypeQuery=${listingType}&parkingSpaceQuery=${parkingSpace}&gardenQuery=${garden}&dwellingQuery=${dwelling}&brickRoofQuery=${brickRoof}&outBuildingsQuery=${outBuildings}&caretakerConciergeQuery=${caretakerConcierge}&swimmingPoolQuery=${swimmingPool}&terraceQuery=${terrace}&elevatorQuery=${elevator}&facingStreetQuery=${facingStreet}&inTheWoodQuery=${inTheWood}&flatLandQuery=${flatLand}&furnishedQuery=${furnished}&internetAccessQuery=${internetAccess}&videoPhoneQuery=${videoPhone}&specialNeedsAccessibilityQuery=${specialNeedsAccessibility}&secureAccessQuery=${secureAccess}&showRoomQuery=${showRoom}&receptionDeskQuery=${receptionDesk}&petsQuery=${pets}&smokingQuery=${smoking}&bigPartiesQuery=${bigParties}&loudMusicQuery=${loudMusic}&minPriceQuery=${minPrice}&maxPriceQuery=${maxPrice}&numberOfBedroomsQuery=${numberOfBedrooms}&numberOfBathroomsQuery=${numberOfBathrooms}&minAreaQuery=${minArea}&maxAreaQuery=${maxArea}&toSort=${toSort}&sortByIncreasingOrder=${sortByIncreasingOrder}&sortByDate=${sortByDate}&page=${page}`);
    } else {
      navigate('/');
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page])
  const minDistance = 10;
  const [openDiag, setOpenDiag] = useState(false);
  const handleDialogClose = () => setOpenDiag(false);
  const handleClickOpen = () => {
    setOpenDiag(true);
  };
  const navigate = useNavigate();
  const [value2, setValue2] = React.useState([0, 5000000]);
  const [value3, setValue3] = React.useState([0, 50000]);
  // useEffect(() => { if (isBuy) { setValue2([0, 5000000]) } else { setValue2([0, 3000]) } }, [isBuy])
  const minPrice = value2[0];
  const maxPrice = value2[1];
  const minArea = value3[0];
  const maxArea = value3[1];
  const [propertyType, setPropertyType] = useState(query.get('propertyTypeQuery' || ''));
  const [constructionCondition, setConstructionCondition] = useState('');
  const [valueMap, setValueMap] = useState('');
  const [coolingType, setCoolingType] = useState('');
  const [heatingType, setHeatingType] = useState('');
  const [filtered, setFiltered] = useState(false);
  const [price, setPrice] = useState({ minPrice: 0, maxPrice: 50000000 });
  const [Area, setArea] = useState({ minArea: 0, maxArea: 5000 });
  const [numberOfBathrooms, setNumberOfBathrooms] = useState('');
  const [numberOfBedrooms, setNumberOfBedrooms] = useState('');
  const handleReset = () => {
    setFiltered(false)
    setValueMap('')
    setPropertyType('')
    setCoolingType('')
    setHeatingType('')
    setArea({ minArea: 0, maxArea: 5000 })
    setPrice({ minPrice: 0, maxPrice: 50000000 })
    setNumberOfBathrooms('')
    setNumberOfBedrooms('')
    setListingType('')
    setConstructionCondition('')
    setFeatures({
      garden: false, parkingSpace: false, dwelling: false,
      brickRoof: false,
      outBuildings: false,
      caretakerConcierge: false,
      swimmingPool: false,
      terrace: false,
      elevator: false,
      facingStreet: false,
      inTheWood: false,
      flatLand: false,
      furnished: false,
      internetAccess: false,
      videoPhone: false,
      specialNeedsAccessibility: false,
      secureAccess: false,
      showRoom: false,
      receptionDesk: false,
      pets: false,
      smoking: false,
      bigParties: false,
      loudMusic: false
    })
    navigate('/posts/searchb?searchQuery=&cityQuery=&propertyTypeQuery=');
    window.location.reload(false);
  };
  const is536pxScreen = useMediaQuery('(min-width:536px)');
  const [features, setFeatures] = useState({
    garden: false,
    parkingSpace: false,
    dwelling: false,
    brickRoof: false,
    outBuildings: false,
    caretakerConcierge: false,
    swimmingPool: false,
    terrace: false,
    elevator: false,
    facingStreet: false,
    inTheWood: false,
    flatLand: false,
    furnished: false,
    internetAccess: false,
    videoPhone: false,
    specialNeedsAccessibility: false,
    secureAccess: false,
    showRoom: false,
    receptionDesk: false,
    pets: false,
    smoking: false,
    bigParties: false,
    loudMusic: false
  })
  useEffect(() => {
    let propertyTypeQuery = query.get('propertyTypeQuery') || false;
    let cityQuery = query.get('cityQuery') || false;
    const garden = features.garden;
    const parkingSpace = features.parkingSpace;
    const dwelling = features.dwelling
    const brickRoof = features.brickRoof
    const outBuildings = features.outBuildings
    const caretakerConcierge = features.caretakerConcierge
    const swimmingPool = features.swimmingPool
    const terrace = features.terrace
    const elevator = features.elevator
    const facingStreet = features.facingStreet
    const inTheWood = features.inTheWood
    const flatLand = features.flatLand
    const furnished = features.furnished
    const internetAccess = features.internetAccess
    const videoPhone = features.videoPhone
    const specialNeedsAccessibility = features.specialNeedsAccessibility
    const secureAccess = features.secureAccess
    const showRoom = features.showRoom
    const receptionDesk = features.receptionDesk
    const pets = features.pets
    const smoking = features.smoking
    const bigParties = features.bigParties
    const loudMusic = features.loudMusic
    if (!propertyTypeQuery && !cityQuery) {
      navigate(`/posts/searchb?searchQuery=&cityQuery=&propertyTypeQuery=`); return
    }
    if (valueMap.trim() || propertyType.trim() || city.length > 0 || listingType.trim() || coolingType.trim() || heatingType.trim() || constructionCondition.trim() || valueMap ||
      propertyType ||
      listingType ||
      coolingType ||
      heatingType ||
      constructionCondition ||
      garden ||
      minPrice ||
      maxPrice ||
      maxArea ||
      minArea ||
      numberOfBedrooms ||
      numberOfBathrooms ||
      parkingSpace ||
      dwelling ||
      brickRoof ||
      outBuildings ||
      caretakerConcierge ||
      swimmingPool ||
      terrace ||
      elevator ||
      facingStreet ||
      inTheWood ||
      flatLand ||
      furnished ||
      internetAccess ||
      videoPhone ||
      specialNeedsAccessibility ||
      secureAccess ||
      showRoom ||
      receptionDesk ||
      pets ||
      smoking ||
      bigParties ||
      loudMusic ||
      numberOfBathrooms ||
      numberOfBedrooms ||
      minPrice ||
      maxPrice
    ) {
      dispatch(getPostsBySearchb({
        toSort,
        sortByIncreasingOrder,
        sortByDate,
        page,
        valueMap,
        city: cityQuery,
        propertyType: propertyTypeQuery,
        listingType,
        heatingType,
        coolingType,
        constructionCondition,
        garden,
        minPrice,
        maxPrice,
        maxArea,
        minArea,
        numberOfBedrooms,
        numberOfBathrooms,
        parkingSpace,
        dwelling,
        brickRoof,
        outBuildings,
        caretakerConcierge,
        swimmingPool,
        terrace,
        elevator,
        facingStreet,
        inTheWood,
        flatLand,
        furnished,
        internetAccess,
        videoPhone,
        specialNeedsAccessibility,
        secureAccess,
        showRoom,
        receptionDesk,
        pets,
        smoking,
        bigParties,
        loudMusic,
      }));
    } else { navigate(`/posts/searchb?searchQuery=${valueMap}&cityQuery=${city}&propertyTypeQuery=${propertyType}&coolingTypeQuery=${coolingType}&heatingTypeQuery=${heatingType}&constructionConditionQuery=${constructionCondition}&listingTypeQuery=${listingType}&parkingSpaceQuery=${parkingSpace}&gardenQuery=${garden}&dwellingQuery=${dwelling}&brickRoofQuery=${brickRoof}&outBuildingsQuery=${outBuildings}&caretakerConciergeQuery=${caretakerConcierge}&swimmingPoolQuery=${swimmingPool}&terraceQuery=${terrace}&elevatorQuery=${elevator}&facingStreetQuery=${facingStreet}&inTheWoodQuery=${inTheWood}&flatLandQuery=${flatLand}&furnishedQuery=${furnished}&internetAccessQuery=${internetAccess}&videoPhoneQuery=${videoPhone}&specialNeedsAccessibilityQuery=${specialNeedsAccessibility}&secureAccessQuery=${secureAccess}&showRoomQuery=${showRoom}&receptionDeskQuery=${receptionDesk}&petsQuery=${pets}&smokingQuery=${smoking}&bigPartiesQuery=${bigParties}&loudMusicQuery=${loudMusic}&minPriceQuery=${minPrice}&maxPriceQuery=${maxPrice}&numberOfBedroomsQuery=${numberOfBedrooms}&numberOfBathroomsQuery=${numberOfBathrooms}&minAreaQuery=${minArea}&maxAreaQuery=${maxArea}&page=${page}&toSort=${toSort}&sortByIncreasingOrder=${sortByIncreasingOrder}&sortByDate=${sortByDate}`) }
  }, [])
  useEffect(() => {
    setFiltered(false)
    setValueMap('')
    setCoolingType('')
    setHeatingType('')
    setNumberOfBathrooms('')
    setNumberOfBedrooms('')
    setFeatures({
      garden: false, parkingSpace: false, dwelling: false,
      brickRoof: false,
      outBuildings: false,
      caretakerConcierge: false,
      swimmingPool: false,
      terrace: false,
      elevator: false,
      facingStreet: false,
      inTheWood: false,
      flatLand: false,
      furnished: false,
      internetAccess: false,
      videoPhone: false,
      specialNeedsAccessibility: false,
      secureAccess: false,
      showRoom: false,
      receptionDesk: false,
      pets: false,
      smoking: false,
      bigParties: false,
      loudMusic: false
    })
  }, [propertyType])
  useEffect(() => { searchPost() }, [toSort, sortByIncreasingOrder, sortByDate])
  const isMobileScreen = useMediaQuery('max-width:600px');
  return (
    <><Container className="postsPage" >
      {/* <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={8} md={3} sx={{ height: '2em' }}>
        </Grid>
      </Grid> */}
      <Hidden mdDown> {/*lg, xl,md*/}
        <Inputt setIsBuy={setIsBuy} isBuy={isBuy} />
        <Container>
          <Grid container>
            <Grid item xs={12} sx={{ mb: 2 }}>
            </Grid>
          </Grid>
        </Container>
        <div className='container'>
          <div className='Left' style={{ position: 'sticky', top: '8rem', marginTop: '1rem' }}>
            <div style={{ position: 'sticky', top: '8rem', marginTop: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <div className='box' style={{ padding: '1rem' }} >
                <p style={{ textAlign: 'center' }}>
                  {/* <MUITextField
                  fullWidth
                  size="small"
                  style={{ width: "200px" }}
                  label='Location'
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  variant="filled"
                /> */}
                  <Paper style={{ padding: '0.25rem', width: '200px', height: '48px', border: '1px solid gray' }}
                    component="div"
                    elevation={0}
                    sx={{ display: 'flex', flexShrink: '1', background: 'white !important', alignItems: 'center', justifyContent: 'space-around' }}
                  >
                    <TagsInput
                      selectedTags={(items) => setCity(items)}
                      variant="standard"
                      id="tags"
                      name="tags"
                      fullWidth
                      results={results}
                      setResults={setResults}
                      placeholder="Search by location ..."
                    />
                  </Paper>
                </p>
                {/* <input style={{ width: '100%', height: '100%',border:'2px solid black' }} type="text" id="iname" name="fname" placeholder='Location' /></p> */}
                {/* <p style={{ textAlign: 'center', width: '200px', overflow: 'hidden' }}>
                <Map setValueMap={setValueMap} />
              </p> */}
                <p style={{ textAlign: 'center' }}>
                  <MUITextField
                    fullWidth
                    size="small"
                    id="outlined-select-property-type"
                    // className={classes.row}
                    style={{ width: "200px" }}
                    select
                    label='Property Type'
                    onKeyDown={handleKeyPress}
                    // renderValue={(value) => `${propertyType}`}
                    value={propertyType}
                    onChange={(e) => { setPropertyType(e.target.value) }}
                    SelectProps={{
                      native: true,
                    }}
                    // helperText="Please select your currency"
                    variant="filled"
                  >
                    {PropertyTypes.map((option) => (
                      <option key={option.value} value={option.value} >
                        {option.label}
                      </option>
                    ))}
                  </MUITextField>
                </p>
                <p style={{ textAlign: 'center' }}>
                  <MUITextField
                    size="small"
                    id="outlined-select-contruction-condition"
                    // className={classes.row}
                    style={{ width: "200px" }}
                    select
                    label='Construction Condition'
                    onKeyDown={handleKeyPress}
                    // renderValue={(value) => `${constructionCondition}`} 
                    value={constructionCondition}
                    onChange={(e) => setConstructionCondition(e.target.value)}
                    SelectProps={{
                      native: true,
                    }}
                    // helperText="Please select your currency"
                    variant="filled"
                  >
                    {constructionConditions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </MUITextField>
                </p>
                <p style={{ textAlign: 'center' }}
                >
                  <button class="button1" type="button" onClick={handleClickOpen}>
                    <TuneIcon /> More Filters
                  </button>
                </p>
                <p style={{ textAlign: 'center' }}>
                  <button class="button" type="submit" onClick={searchPost} >
                    <FontAwesomeIcon icon={faSearch} className='postPagesearch'></FontAwesomeIcon>
                    Search
                  </button>
                </p>
              </div>
            </div>
          </div>
          <div className='Right'>
            <div style={{ float: 'right', marginRight: '2.2rem', display: 'flex', justifyContent: 'space-between' }}>
              <Button component={RouterLink} to={`/${isBuy ? 'buy' : 'rent'}/map`} variant="outlined" color="secondary"
              > {t("description.mapView")} </Button>
              <div style={{ marginLeft: '6px' }}>
                <SortPopover setToSort={setToSort} setSortByIncreasingOrder={setSortByIncreasingOrder} setSortByDate={setSortByDate} />
              </div>
            </div>
            <Posts setIsSnackBarOpen={setIsSnackBarOpen} page={page} />
          </div>
        </div>
      </Hidden>
      <Hidden mdUp> {/*sm*/}
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <div style={{ display: 'flex', width: isMobileScreen ? '100%' : '85%', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column' }}>
            <Inputt setIsBuy={setIsBuy} isBuy={isBuy} />
            <div className="posts-searchBar" style={{ width: '100%', alignSelf: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', marginBottom: '2rem' }}>
              <Paper
                component="div"
                elevation={0}
                sx={{ padding: '0.25rem', border: '2px solid black', width: '100%', display: 'flex', flexShrink: '1', background: 'white !important', alignItems: 'center', justifyContent: 'space-around' }}
              >
                {/* <InputBase
                sx={{ ml: 1, flex: 1, width: '100%', height: '100%' }}
                placeholder="Location"
                inputProps={{ 'aria-label': 'Search by location or property id...' }}
                value={city} onChange={(e) => setCity(e.target.value)}
                variant="outlined"
              /> */}
                <div style={{ overflowX: 'hidden', width: '100%' }} >
                  <TagsInput
                    selectedTags={(items) => setCity(items)}
                    variant="standard"
                    id="tags"
                    name="tags"
                    fullWidth
                    results={results}
                    setResults={setResults}
                    placeholder="Search by location ..."
                  />
                </div>
                <IconButton onClick={() => searchPost()} sx={{ p: '10px', borderRadius: '50%', background: '#2a9cd7' }} aria-label="menu">
                  <SearchIcon sx={{ color: 'white' }} />
                </IconButton>
                <IconButton onClick={handleClickOpen}>
                  <TuneIcon />
                </IconButton>
              </Paper>
            </div>
          </div>
          <div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
              <Button component={RouterLink} to={`/${isBuy ? 'buy' : 'rent'}/map`} variant="outlined" color="secondary"
                startIcon={<ExploreOutlinedIcon sx={{ marginLeft: "5px" }} />}> {t("description.mapView")} </Button>
              <div style={{ marginLeft: '6px' }}>
                <SortPopover setToSort={setToSort} setSortByIncreasingOrder={setSortByIncreasingOrder} setSortByDate={setSortByDate} />
              </div>
            </div>

            <Posts setIsSnackBarOpen={setIsSnackBarOpen} page={page} />
          </div>
        </Grid>
      </Hidden>
      {/* MORE FILTERS DIALOG */}
      <Dialog open={openDiag} onClose={handleDialogClose}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', padding: '0.5rem' }}><span>More Filters</span><FontAwesomeIcon onClick={handleDialogClose} icon={faTimesCircle} className='bed'></FontAwesomeIcon></DialogTitle>
        <DialogContent>
          <Grid container justifyContent="center" alignItems="center">
            <MHidden width="mdUp">
              <Grid container>
                {/* <Grid item xs={12} sx={{ mb: 2 }}>
                  <MUITextField
                    fullWidth
                    size="small"
                    id="outlined-select-listing-type"
                    // className={classes.row}
                    style={{ width: "150px", marginRight: "15px" }}
                    select
                    label={t("description.listingType")}
                    onKeyDown={handleKeyPress}
                    // renderValue={(value) => `${listingType}`} 
                    value={listingType}
                    onChange={(e) => { setListingType(e.target.value); handleDialogClose() }}
                    SelectProps={{
                      native: true,
                    }}
                    // helperText="Please select your currency"
                    variant="filled"
                    >
                    {ListingTypes.map((option) => (
                      <option key={option.value} value={option.value}>
                      {option.label}
                      </option>
                      ))}
                      </MUITextField>
                      
                      
                    </Grid> */}

                <Grid container item xs={12} sx={{ flexDirection: is536pxScreen ? 'row' : 'column' }}>
                  <Grid item xs={6} sx={{ m: 0 }}>
                    <MUITextField
                      fullWidth
                      size="small"
                      id="outlined-select-property-type"
                      // className={classes.row}
                      style={{ width: "200px" }}
                      select
                      label={t("description.propType")}
                      onKeyDown={handleKeyPress}
                      // renderValue={(value) => `${propertyType}`} 
                      value={propertyType}
                      onChange={(e) => setPropertyType(e.target.value)}
                      SelectProps={{
                        native: true,
                      }}
                      // helperText="Please select your currency"
                      variant="filled"
                    >
                      {PropertyTypes.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </MUITextField>
                  </Grid>
                  <Grid item xs={6} sx={{ m: 0 }}>
                    <MUITextField
                      size="small"
                      id="outlined-select-contruction-condition"
                      // className={classes.row}
                      style={{ width: "200px" }}
                      select
                      label="Contruction Condition"
                      onKeyDown={handleKeyPress}
                      // renderValue={(value) => `${constructionCondition}`}
                      value={constructionCondition}
                      onChange={(e) => setConstructionCondition(e.target.value)}
                      SelectProps={{
                        native: true,
                      }}
                      // helperText="Please select your currency"
                      variant="filled"
                    >
                      {constructionConditions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </MUITextField>
                  </Grid>
                </Grid>
              </Grid>
            </MHidden>
            <Grid container>
              <Grid item xs={12} >
                <Typography component="h4" variant="h4" align="left" sx={{ mb: 2 }}>
                  {t("description.price")}
                </Typography>
                <Typography component="h6" variant="h6" align="left" sx={{ mb: 2, mt: 2 }}>
                  ${value2[0]} - ${value2[1]}
                </Typography>
                <Slider
                  getAriaLabel={() => 'Minimum distance shift'}
                  value={value2}
                  min={0}
                  max={1000000}
                  step={1000}
                  onChange={handleChange2}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  disableSwap
                />
                <Divider light style={{ color: "#091A7A" }} sx={{ mb: 3, mt: 3 }} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} >
                <Typography component="h4" variant="h4" c align="left" sx={{ mb: 2 }}>
                  {t("description.area")}
                </Typography>
                <Typography component="h6" variant="h6" align="left" sx={{ mb: 2, mt: 2 }}>
                  {value3[0]}sqft - {value3[1]}sqft
                </Typography>
                <Slider
                  getAriaLabel={() => 'Minimum distance shift'}
                  value={value3}
                  min={50}
                  max={5000}
                  step={50}
                  onChange={handleChangeArea}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  disableSwap
                />
                <Divider light style={{ color: "#091A7A" }} sx={{ mb: 3, mt: 3 }} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} >
                {propertyType === 'Apartment' ||
                  propertyType === 'Villa' ||
                  propertyType === 'Office' ? (
                  <Grid container direction="column" item xs={12}>
                    <Typography justify="flex-start" className={classes.dislpayflex} variant="h4"
                      component="h2" gutterBottom>
                      <Trans i18nKey="description.interior">
                        Interior
                      </Trans>
                    </Typography>
                    {propertyType !== 'Office' ? (
                      <MUITextField
                        sx={{ mb: 2 }}
                        onKeyDown={handleKeyPress}
                        // renderValue={(value) => `${numberOfBedrooms}`} 
                        value={numberOfBedrooms}
                        onChange={(e) => setNumberOfBedrooms(e.target.value)}
                        id="numberofBedrooms"
                        className={classes.row}
                        label={t("description.nbrOfBedrooms")}
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="filled"
                      />) : null
                    }
                    <MUITextField
                      // renderValue={(value) => `${numberOfBathrooms}`} 
                      value={numberOfBathrooms}
                      onChange={(e) => setNumberOfBathrooms(e.target.value)}
                      id="numberofBathrooms"
                      className={classes.row}
                      label={t("description.nbrOfBathrooms")}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="filled"
                    />
                  </Grid>
                ) : null
                }
                {propertyType === 'Apartment' ||
                  propertyType === 'Villa' ||
                  propertyType === 'Office' ? (
                  <Grid container direction="column" item xs={12} sx={{ mt: 5, mb: 5 }}>
                    <Typography className={classes.dislpayflex} variant="h4" component="h2" gutterBottom>
                      <Trans i18nKey="description.exterior">
                        Exterior
                      </Trans>
                    </Typography>
                    <FormControl component="fieldset">
                      <FormGroup aria-label="position" column>
                        <FormControlLabel
                          control={<Checkbox
                            checked={features.parkingSpace}
                            onChange={() => setFeatures({ ...features, parkingSpace: !features.parkingSpace })}
                            color="primary" />}
                          label={t("description.parkingSpace")}
                          name="parkingSpace"
                          labelPlacement="end"
                        />
                        {propertyType !== 'Office' ? (
                          <FormControlLabel
                            control={<Checkbox
                              checked={features.dwelling}
                              onChange={() => setFeatures({ ...features, dwelling: !features.dwelling })} color="primary" />}
                            label={t("description.dwelling")}
                            name="dwelling"
                            labelPlacement="end"
                          />) : null
                        }
                        <FormControlLabel
                          control={<Checkbox
                            checked={features.brickRoof}
                            onChange={() => setFeatures({ ...features, brickRoof: !features.brickRoof })} color="primary" />}
                          label={t("description.brickRoof")}
                          name="brickRoof"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          control={<Checkbox
                            checked={features.outBuildings}
                            onChange={() => setFeatures({ ...features, outBuildings: !features.outBuildings })} color="primary" />}
                          label={t("description.Outbuildings")}
                          name="outBuildings"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          control={<Checkbox
                            checked={features.caretakerConcierge}
                            onChange={() => setFeatures({ ...features, caretakerConcierge: !features.caretakerConcierge })} color="primary" />}
                          label={t("description.caretaker")}
                          name="caretakerConcierge"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          control={<Checkbox
                            checked={features.garden}
                            onChange={() => setFeatures({ ...features, garden: !features.garden })} color="primary" />}
                          label={t("description.garden")}
                          name="garden"
                          labelPlacement="end"
                        />
                        {propertyType !== 'Office' ? (
                          <FormControlLabel
                            control={<Checkbox
                              checked={features.swimmingPool}
                              onChange={() => setFeatures({ ...features, swimmingPool: !features.swimmingPool })} color="primary" />}
                            label={t("description.pool")}
                            name="swimmingPool"
                            labelPlacement="end"
                          />) : null
                        }
                        <FormControlLabel
                          control={<Checkbox
                            checked={features.terrace}
                            onChange={() => setFeatures({ ...features, terrace: !features.terrace })} color="primary" />}
                          label={t("description.terrace")}
                          name="terrace"
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>) : propertyType === 'Land' ? (
                    <Grid container direction="column" item xs={12} sx={{ mt: 5, mb: 5 }}>
                      <Typography className={classes.dislpayflex} variant="h4" component="h2" gutterBottom>
                        <Trans i18nKey="description.land">
                          Land
                        </Trans>
                      </Typography>
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position" column>
                          <FormControlLabel
                            control={<Checkbox
                              checked={features.facingStreet}
                              onChange={() => setFeatures({ ...features, facingStreet: !features.facingStreet })}
                              color="primary" />}
                            label={t("description.facingStreet")}
                            name="facingStreet"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            control={<Checkbox
                              checked={features.inTheWood}
                              onChange={() => setFeatures({ ...features, inTheWood: !features.inTheWood })} color="primary" />}
                            label={t("description.intheWoods")}
                            name="inTheWood"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            control={<Checkbox
                              checked={features.flatLand}
                              onChange={() => setFeatures({ ...features, flatLand: !features.flatLand })} color="primary" />}
                            label={t("description.flatLand")}
                            name="flatLand"
                            labelPlacement="end"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  ) : null
                }
              </Grid>
            </Grid>
            <Divider light style={{ color: "#091A7A" }} color="secondary" sx={{ mb: 3, mt: 3 }} />
            <Grid item xs={12}>
              {propertyType !== 'Land' ? (
                <Grid container direction="column" item xs={12}>
                  <Typography className={classes.dislpayflex} variant="h4" component="h2" gutterBottom>
                    <Trans i18nKey="description.additionalFeatures">
                      Additional Features
                    </Trans>
                  </Typography>
                  {propertyType === 'Apartment' ||
                    propertyType === 'Villa' ||
                    propertyType === 'Office' ? (
                    <>
                      <MUITextField
                        sx={{ mb: 2 }}
                        id="heatingType"
                        className={classes.row}
                        select
                        label={t("description.heating")}
                        onKeyDown={handleKeyPress}
                        // renderValue={(value) => `${heatingType}`} 
                        value={heatingType}
                        onChange={(e) => setHeatingType(e.target.value)}
                        SelectProps={{
                          native: true,
                        }}
                        helperText={t("description.heatingH")}
                        variant="filled"
                      >
                        {heatingTypes.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </MUITextField>
                      <MUITextField
                        id="coolingType"
                        className={classes.row}
                        select
                        label={t("description.cooling")}
                        onKeyDown={handleKeyPress}
                        // renderValue={(value) => `${coolingType}`} 
                        value={coolingType}
                        onChange={(e) => setCoolingType(e.target.value)}
                        SelectProps={{
                          native: true,
                        }}
                        helperText={t("description.coolingH")}
                        variant="filled"
                      >
                        {coolingTypes.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </MUITextField>
                    </>
                  ) : null
                  }
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" column>
                      {propertyType !== 'Garden' ? (
                        <FormControlLabel
                          control={<Checkbox
                            checked={features.elevator}
                            onChange={() => setFeatures({ ...features, elevator: !features.elevator })} color="primary" />}
                          label={t("description.elevator")}
                          name="elevator"
                          labelPlacement="end"
                        />
                      ) : null
                      }
                      {propertyType !== 'Garage' ? (
                        <>
                          <FormControlLabel
                            control={<Checkbox
                              checked={features.furnished}
                              onChange={() => setFeatures({ ...features, furnished: !features.furnished })} color="primary" />}
                            label={t("description.furnished")}
                            name="furnished"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            control={<Checkbox
                              checked={features.internetAccess}
                              onChange={() => setFeatures({ ...features, internetAccess: !features.internetAccess })} color="primary" />}
                            label={t("description.internet")}
                            name="internetAccess"
                            labelPlacement="end"
                          />
                        </>
                      ) : null
                      }
                      {propertyType !== 'Garage' || propertyType !== 'Garden' ? (
                        <FormControlLabel
                          control={<Checkbox
                            checked={features.videoPhone}
                            onChange={() => setFeatures({ ...features, videoPhone: !features.videoPhone })} color="primary" />}
                          label={t("description.videoP")}
                          name="videoPhone"
                          labelPlacement="end"
                        />
                      ) : null
                      }
                      <FormControlLabel
                        control={<Checkbox
                          checked={features.specialNeedsAccessibility}
                          onChange={() => setFeatures({ ...features, specialNeedsAccessibility: !features.specialNeedsAccessibility })} color="primary" />}
                        label={t("description.sna")}
                        name="specialNeedsAccessibility"
                        labelPlacement="end"
                      />
                      {propertyType === 'Office' ? (
                        <>
                          <FormControlLabel
                            control={<Checkbox
                              checked={features.secureAccess}
                              onChange={() => setFeatures({ ...features, secureAccess: !features.secureAccess })} color="primary" />}
                            label={t("description.secureAccess")}
                            name="secureAccess"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            control={<Checkbox
                              c checked={features.showRoom}
                              onChange={() => setFeatures({ ...features, showRoom: !features.showRoom })} color="primary" />}
                            label={t("description.showRoom")}
                            name="showRoom"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            control={<Checkbox
                              checked={features.receptionDesk}
                              onChange={() => setFeatures({ ...features, receptionDesk: !features.receptionDesk })} color="primary" />}
                            label={t("description.receptionDesk")}
                            name="receptionDesk"
                            labelPlacement="end"
                          />
                        </>
                      ) : null
                      }
                    </FormGroup>
                  </FormControl>
                </Grid>
              ) : null
              }
              {/* </Grid>
                <Grid item xs={12} lg={6}> */}
              <Grid container direction="column" item xs={12} lg={6}>
                <Typography className={classes.dislpayflex} sx={{ mt: 5 }} variant="h4" component="h2" gutterBottom>
                  <Trans i18nKey="description.permits">
                    Permits
                  </Trans>
                </Typography>
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" column>
                    <FormControlLabel
                      control={<Checkbox
                        checked={features.pets}
                        onChange={() => setFeatures({ ...features, pets: !features.pets })} color="primary" />}
                      label={t("description.pets")}
                      name="pets"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={<Checkbox
                        checked={features.smoking}
                        onChange={() => setFeatures({ ...features, smoking: !features.smoking })} color="primary" />}
                      label={t("description.smoking")}
                      name="smoking"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={<Checkbox color="primary" />}
                      checked={features.bigParties}
                      onChange={() => setFeatures({ ...features, bigParties: !features.bigParties })}
                      label={t("description.bigP")}
                      name="bigParties"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={<Checkbox
                        checked={features.loudMusic}
                        onChange={() => setFeatures({ ...features, loudMusic: !features.loudMusic })} color="primary" />}
                      label={t("description.loudM")}
                      name="loudMusic"
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid >
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReset}>{t("description.reset")}</Button>
          <Button variant="contained" onClick={dialogClick}>{t("description.search")}</Button>
        </DialogActions>
      </Dialog>
      <YouNeedToLogInSnackBar isOpen={isSnackBarOpen} handleClose={handleSnackBarClose} />
    </Container >
      <Grid container justifyContent="center" alignItems="center" sx={{ marginBottom: '2rem' }}> <Pagination query={getQuery()} page={page} searchPost={searchPost} /></Grid></>
  );
}
export default PostsPage;

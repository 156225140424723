import * as Yup from 'yup';
import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon, InlineIcon } from '@iconify/react';
import eyeFill from '@iconify-icons/eva/eye-fill';
import eyeOffFill from '@iconify-icons/eva/eye-off-fill';
import Input from '../Input/Input'
// material
import {
  Button,
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel, Snackbar, Alert
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { LoadingButton } from '@material-ui/lab';
import { signin } from "../../actions/auth";

// ----------------------------------------------------------------------

function LoginForm() {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openSnack, setSnackOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const LoginSchema = Yup.object({
    email: Yup.string().email(t("description.emailMustbeValid")).required(t("description.emailMustbeValid")),
    password: Yup.string().required(t("description.passRequired"))
  });

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false)
  };

  const handleSignInSubmit = async (values) => {
    setLoading(true)
    let email = values.email;
    email=email.trim();
    email=email.charAt(0).toLowerCase() + email.slice(1);
    const form = {
      email: email,
      password: values.password
    }
    const success = await dispatch(signin(form))
    if (success === "user") {
      setLoading(false)
      navigate('/');
    }
    else if (success === "admin") {
      setLoading(false)
      navigate('/CMS/landingAdmin');
    }
    else setLoading(false)
    setSuccess(success)
    setSnackOpen(true)
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      handleSignInSubmit(values)
    }
  });
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  

  return (

    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={3}>
        <div sx={{ marginBottom: 20 }}>
          <Input name="email" label={t("description.emailAdd")} handleBlur={formik.handleBlur} handleChange={formik.handleChange} type="email" />
          {formik.touched.email && formik.errors.email ?<span style={{ fontSize: '15px' }}>  <div style={{ color: 'red' }}>{formik.errors.email}</div></span> : null}
        </div>
        <div sx={{ marginBottom: 20 }}>
          <Input name="password" label={t("description.password")} handleBlur={formik.handleBlur} handleChange={formik.handleChange} type={showPassword ? 'text' : 'password'} handleShowPassword={handleShowPassword} />
          {formik.touched.password && formik.errors.password ?<span style={{ fontSize: '15px' }}>  <div style={{ color: 'red' }}>{formik.errors.password}</div></span> : null}

        </div>
      </Stack>
      {
        success !== undefined ? (
          success ? null : (
            <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
              <Alert onClose={handleSnackClose} severity="error">
                <Trans i18nKey="description.incorrect">
                  Email or password is not correct
                </Trans>
              </Alert>
            </Snackbar>
          )) : null
      }
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>

        <Link component={RouterLink} variant="subtitle2" to="/auth/resetPassword">
          <Trans i18nKey="description.forgetPass">
            Forgot password?
          </Trans>
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={loading}
      >
        <Trans i18nKey="description.loginPage">
          Login
        </Trans>
      </LoadingButton>
    </form>

  );
}

export default LoginForm;
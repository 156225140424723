import React from 'react';
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import useState from "react-hook-use-state";
import { deleteAccount, updateUser } from "../../actions/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next';
import { deleteAd } from '../../actions/adsSection';
import { Container, Box, Grid, Typography, Alert, Snackbar, Slide } from "@material-ui/core";

const DialogDeleteAd = ({ open, id, close }) => {
    const dispatch = useDispatch();
    const [openSnack, setSnackOpen] = useState(false);
    const [success, setSuccess] = useState(false);

    const deleteAnAd = async (id) => {
        dispatch(deleteAd(id));

        if (success) {
            setSuccess(true);

            setSnackOpen(true);
            setTimeout(function () {
                window.location.reload();
            }, 1000);
        }
        else {
            setSnackOpen(false);
        }
    };

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };


    const { i18n } = useTranslation();

    return (
        <>
            <Dialog
                open={open}
                keepMounted
                onClose={close}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Delete Ad?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Trans i18nKey="description.deleteAd">
                            Are you sure you want to delete this ad as there is no going back
                        </Trans>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} color="primary">
                        <Trans i18nKey="description.cancel">
                            Cancel
                        </Trans>
                    </Button>
                    <Button onClick={() => deleteAnAd(id)} color="primary">
                        <Trans i18nKey="description.delete">
                            Delete
                        </Trans>
                    </Button>
                </DialogActions>
            </Dialog>
            {
                success ? (
                    <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                        <Alert onClose={handleSnackClose} severity="success">
                            <Trans>
                                Ad Deleted successfully
                            </Trans>
                        </Alert>
                    </Snackbar>
                ) : (
                    <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                        <Alert onClose={handleSnackClose} severity="error">
                            <Trans i18nKey="description.wefound">
                                Couldn't Delete Ad
                            </Trans>
                        </Alert>
                    </Snackbar>
                )
            }
        </>
    )
}
export default DialogDeleteAd;
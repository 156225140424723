import React, { useMemo, useState } from 'react'
import { Container, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import UseStyles from "./style";
import Image1 from "../../assets/upload-image.jpg";
import Map from "../Map/Map";
import Search from '../Map/Search';
import { useDropzone } from "react-dropzone";
import axios from "axios";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import DateFnsUtils from '@date-io/date-fns';
// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
// } from '@material-ui/pickers';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import InputAdornment from '@material-ui/core/InputAdornment';
import { LoadingButton } from "@material-ui/lab";
import { useFormik } from "formik";
import { Trans, useTranslation } from 'react-i18next';
import { alpha, styled } from '@material-ui/core/styles';
const MUITextField = styled((props) => (
    <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
    '& .MuiFilledInput-root': {
        border: '1px solid #e2e2e1',
        overflow: 'hidden',
        borderRadius: 4,
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&.Mui-focused': {
            backgroundColor: 'transparent',
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },
    },
}));
const constructionConditions = [
    {
        value: '',
        label: '',
    },
    {
        value: 'Old',
        label: 'Old',
    },
    {
        value: 'New',
        label: 'New',
    },
    {
        value: 'Needs-Renovation',
        label: 'Needs Renovation',
    },
];
const today = new Date(Date.now());
const todaysDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
const cities = [
    {
        value: '',
        label: '',
    },
    {
        value: 'Akkar',
        label: 'Akkar',
    },
    {
        value: 'Aley',
        label: 'Aley',
    }, {
        value: 'Baabda',
        label: 'Baabda',
    }, {
        value: 'Baalbek',
        label: 'Baalbek',
    }, {
        value: 'Batroun',
        label: 'Batroun',
    }, {
        value: 'Beirut',
        label: 'Beirut',
    }, {
        value: 'Bint-Jbeil',
        label: 'Bint-Jbeil',
    }, {
        value: 'Bsharri',
        label: 'Bsharri',
    }, {
        value: 'Byblos',
        label: 'Byblos',
    }, {
        value: 'Chouf',
        label: 'Chouf',
    }, {
        value: 'Hasbaya',
        label: 'Hasbaya',
    }, {
        value: 'Hermel',
        label: 'Hermel',
    }, {
        value: 'Jezzine',
        label: 'Jezzine',
    }, {
        value: 'Keserwan',
        label: 'Keserwan',
    }, {
        value: 'Koura',
        label: 'Koura',
    }, {
        value: 'Marjeyoun',
        label: 'Marjeyoun',
    }, {
        value: 'Matn',
        label: 'Matn',
    }, {
        value: 'Miniyeh-Daniyeh',
        label: 'Miniyeh-Daniyeh',
    }, {
        value: 'Nabatieh',
        label: 'Nabatieh',
    }, {
        value: 'Rashaya',
        label: 'Rashaya',
    }, {
        value: 'Sidon',
        label: 'Sidon',
    },
    {
        value: 'Tripoli',
        label: 'Tripoli',
    }, {
        value: 'Tyre',
        label: 'Tyre',
    }, {
        value: 'Zahle',
        label: 'Zahle',
    }, {
        value: 'Zgharta',
        label: 'Zgharta',
    },
];
const MainInformation = ({ handleBack, handleNext, values, handleChange, setPost, markers, setMarkers }) => {
    const [files, setFiles] = useState([]);
    const classes = UseStyles();
    const { t, i18n } = useTranslation();
    return (
        <form onSubmit={() => handleNext()}>
            <div style={{ marginTop: 20 }}>
                <Container>
                    <Grid container direction="column" >
                        <Grid className={classes.marginContainer} item container >
                            <Grid item xs={12}>
                                <div className={classes.row}>
                                    <Typography variant="h4" component="h2" gutterBottom>
                                        <Trans i18nKey="description.address">
                                            Address
                                        </Trans>
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid justify="flex-end" className={classes.dislpayflex} item xs={12} sm={12} md={12} lg={12}>
                                <Grid item xs={0} sm={1} md={1} lg={1} />
                                <Grid item xs={12} sm={5} md={5} lg={5}>
                                    <div className={classes.row}>
                                        <MUITextField className={classes.fullwidth} label={t("description.street")} variant="filled" type="text" name="street" id="street" value={values.street} onChange={handleChange('street')} required />
                                    </div>
                                    {values.propertyType === "Apartment" ? (
                                        <div className={classes.row}>
                                            <MUITextField className={classes.fullwidth} label={t("description.blg")} variant="filled"
                                                type="text" name="building" id="building" value={values.building}
                                                onChange={handleChange('building')} required />
                                        </div>
                                    ) : null
                                    }
                                </Grid>
                                <Grid item xs={0} sm={1} md={1} lg={1} />
                                <Grid item xs={12} sm={5} md={5} lg={5}>
                                    <div className={classes.row}>
                                        <MUITextField
                                            id="outlined-select-construction-condition"
                                            // className={classes.row}
                                            style={{ width: "100%" }}
                                            select
                                            name='city'
                                            label={t("description.city")}
                                            value={values.city}
                                            renderValue={(value) => `${value}`}
                                            onChange={handleChange('city')}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            // helperText="Please select your currency"
                                            variant="filled"
                                        >
                                            {cities.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </MUITextField>
                                    </div>
                                    {values.propertyType === 'Apartment' || values.propertyType === 'Villa' ? (
                                        values.propertyType === 'Apartment' ? (
                                            <div className={classes.row}>
                                                <MUITextField className={classes.fullwidth} label={t("description.floor")} variant="filled"
                                                    type="number" name="floor" id="floor" value={values.floor}
                                                    onChange={handleChange('floor')} required />
                                            </div>
                                        ) : (
                                            <div className={classes.row}>
                                                <MUITextField
                                                    fullWidth
                                                    value={values.numberOfFloors} onChange={handleChange('numberOfFloors')}
                                                    id="numberOfFloors"
                                                    className={classes.fullwidth}
                                                    label={t("description.nbrOfFloors")}
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="filled"
                                                    required
                                                />
                                            </div>
                                        )
                                    ) : null
                                    }
                                </Grid>
                                <Grid item xs={0} sm={1} md={1} lg={1} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Grid item xs={0} sm={1} md={1} lg={1} />
                                <Grid item xs={12} sm={10} md={10} lg={10}>
                                    <div className={classes.row} style={{ marginTop: 10 }}>
                                        <div className={classes.row}>
                                            <Map markers={markers} setMarkers={setMarkers} />
                                        </div>
                                        {/* <Map /> */}
                                    </div>
                                    <Grid item xs={1} sm={1} md={1} lg={1} />
                                    <Grid item xs={12} sm={10} md={10} lg={12}>
                                        <div className={classes.row}>
                                            <Typography variant="h4" component="h2" gutterBottom>
                                                <Trans i18nKey="description.basicInfo">
                                                    Basic Information
                                                </Trans>
                                            </Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid justify="flex-end" className={classes.dislpayflex} sx={{ flexDirection: { xs: 'column', sm: 'row' } }} item xs={12} sm={12} md={12} lg={12}>
                                    <Grid item xs={0} sm={1} md={1} lg={1} />
                                    <Grid item xs={12} sm={5} md={5} lg={5}>
                                        <div className={classes.row}>
                                            <MUITextField label={t("description.title")} variant="filled" className={classes.fullwidth} type="text" name="title" id="title" value={values.title} onChange={handleChange('title')} required />
                                        </div>
                                        <div className={classes.row}>
                                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
          disableToolbar
          variant="outline"
          format="MM/dd/yyyy"
          margin="normal"
          id="date-picker-inline"
          label={t("description.constDate")}
          value={values.constructionDate} onChange={handleChange('constructionDate')}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        </MuiPickersUtilsProvider> */}
                                            <MUITextField className={classes.fullwidth} label={t("description.constDate")} variant="filled" type="date" defaultValue="" name="constructionDate" id="constructionDate" value={values.constructionDate} onChange={handleChange('constructionDate')} focused required inputProps={{
                                                max: todaysDate
                                            }} />
                                        </div>
                                        <div className={classes.row}>
                                            <MUITextField
                                                id="filled-multiline-static"
                                                label={t("description.desc")}
                                                className={classes.fullwidth}
                                                multiline
                                                name='description'
                                                rows={8}
                                                defaultValue=""
                                                variant="filled"
                                                value={values.description}
                                                onChange={handleChange('description')}
                                                required
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={0} sm={1} md={1} lg={1} />
                                    <Grid item xs={12} sm={5} md={5} lg={5}>
                                        <div className={classes.row}>
                                            <MUITextField className={classes.fullwidth} label={t("description.surfaceArea")} variant="filled" type="number" name="surfaceArea" id="area" value={values.surfaceArea} onChange={handleChange('surfaceArea')} InputProps={{ startAdornment: <InputAdornment position="start">sqft</InputAdornment>, }} required />
                                        </div>
                                        <div className={classes.row}>
                                            <MUITextField startAdornment={<InputAdornment position="start">$</InputAdornment>} className={classes.fullwidth} label={t("description.price")} variant="filled" type="number" name="price" id="price" value={values.price} onChange={handleChange('price')} InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, }} required />
                                        </div>
                                        <div className={classes.row}>
                                            <MUITextField
                                                id="outlined-select-construction-condition"
                                                // className={classes.row}
                                                style={{ width: "100%" }}
                                                select
                                                name='constructionCondition'
                                                label={t("description.constCond")}
                                                value={values.constructionCondition}
                                                renderValue={(value) => `${value}`}
                                                onChange={handleChange('constructionCondition')}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                // helperText="Please select your currency"
                                                variant="filled"
                                            >
                                                {constructionConditions.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </MUITextField>
                                        </div>
                                        <div className={classes.row}>
                                            <MUITextField className={classes.fullwidth} label={t("description.emailAdd")} variant="filled" type="email" name="email" id="email" value={values.email} onChange={handleChange('email')} required />
                                        </div>
                                        <div className={classes.row}>
                                            <MUITextField className={classes.fullwidth} label={t("description.phone")} variant="filled" type="number" name="phoneNumber" id="phone" value={values.phoneNumber} onChange={handleChange('phoneNumber')} required />
                                        </div>
                                        <div className={classes.row}>
                                            <MUITextField className={classes.fullwidth} label='altitude' variant="filled" type="number" name="Altitude" id="altitude" value={values.altitude} onChange={handleChange('altitude')} required />
                                        </div>
                                    </Grid>
                                    <Grid item xs={0} sm={1} md={1} lg={1} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid >
                    <React.Fragment>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="inherit"
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                <Trans i18nKey="description.back">
                                    Back
                                </Trans>
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <LoadingButton
                                size="large"
                                type="submit"
                                disabled={Object.keys(markers).length === 0 ? true : false}
                            >
                                <Trans i18nKey="description.next">
                                    Next
                                </Trans>
                            </LoadingButton>
                        </Box>
                    </React.Fragment>
                </Container>
            </div>
        </form>
    )
}
export default MainInformation
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { makeStyles } from '@material-ui/styles';
import CardInput from './CardInput';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import { Link, useNavigate } from 'react-router-dom';
import {
  Alert,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText, Grid,
  Snackbar,
  Typography
} from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@material-ui/lab";
import Dialog from "@material-ui/core/Dialog";
import { Trans, useTranslation } from 'react-i18next';
import { alpha, styled } from '@material-ui/core/styles';
import Cookies from 'js-cookie';

const MUITextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const useStyles = makeStyles({
  root: {
    maxWidth: 500,
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
  },

  div: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start',
    justifyContent: 'space-between',
  },

  button: {
    margin: '2em auto 1em',
  },
});

function Stripe({ setOpenStripe, openStripe, ERROR, Submit, price, Post, error, loading, succeed }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [phone, setPhone] = useState('');
  // const [loading, setLoading] = useState(false);
  // const [succeed, setSuccess] = useState(false);
  const [openSnack, setSnackOpen] = useState(false);
  // const [error, setError] = useState('')


  const stripe = useStripe();
  const elements = useElements();
  const { t, i18n } = useTranslation();
  // const handleSubmit = async (values) => {
  // setLoading(true)
  //   const LoggedUser = JSON.parse(localStorage.getItem('profile'))
  //   const form=
  //       {
  //         id: LoggedUser.result._id,
  //         email: values.email,
  //         firstName:values.firstName,
  //         lastName: values.lastName,
  //         phoneNumber: phone,
  //         amount:price
  //       }
  //   if (!stripe || !elements) {
  //
  //     setLoading(false)
  //     return;
  //   }
  //
  //   const res = await axios.post('http://localhost:5000/stripe/pay', form);
  //
  //   const clientSecret = res.data['client_secret'];
  //
  //   const result = await stripe.confirmCardPayment(clientSecret, {
  //     payment_method: {
  //       card: elements.getElement(CardElement),
  //       billing_details: {
  //         email: form.email,
  //         phone: form.phoneNumber,
  //         name: form.firstName + ' ' + form.lastName
  //       },
  //     },
  //   });
  //
  //   if (result.error) {
  //     setLoading(false)
  //     setError(result.error.message)
  //     return;
  //   } else {
  //
  //     if (result.paymentIntent.status === 'succeeded') {
  //       setSuccess(true)
  //       setLoading(false)
  //       Submit(values)
  //     }
  //   }
  // };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email format')
      .required(t("description.requir")),
    firstName: Yup.string()
      .min(2, t("description.tooShort"))
      .max(50, t("description.tooLong"))
      .required(t("description.requir")),
    lastName: Yup.string()
      .min(2, t("description.tooShort"))
      .max(50, t("description.tooLong"))
      .required(t("description.requir"))
  })
  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: ''
    },
    validationSchema,
    onSubmit: values => {
      const LoggedUser = JSON.parse(localStorage.getItem('profile'))
      const form = {
        id: LoggedUser.result._id,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: phone,
        amount: price
      }
      Submit(form, stripe, elements)
    },
  }
  )
  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenStripe(false)
  };

  const [arLang, setArLang] = useState(false);
  let cookieLanguage = Cookies.get('i18next');


  useEffect(() => {
    if (cookieLanguage == "ar") {
      setArLang(true);
    }
  }, [])

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Card className={classes.root}>
          <CardContent className={classes.content}>
            <Grid container justifyContent='center'>
              <img src="/static/stripe.png" alt="stripe logo" style={{ width: 300 }} />
            </Grid>

            <div sx={{ marginBottom: 20 }}>
              <MUITextField
                label={t("description.fn")}
                name='firstName'
                id='outlined-firstName-input'
                margin='normal'
                variant='filled'
                type='text'
                required
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
              />
              {formik.touched.firstName && formik.errors.firstName ? <div style={{ color: 'red' }}>{formik.errors.firstName}</div> : null}
            </div>


            <div sx={{ marginBottom: 20 }}>
              <MUITextField
                label={t("description.ln")}
                id='outlined-lastName-input'
                margin='normal'
                variant='filled'
                type='text'
                name='lastName'
                required
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
              />
              {formik.touched.lastName && formik.errors.lastName ? <div style={{ color: 'red' }}>{formik.errors.lastName}</div> : null}
            </div>
            <br />

            {
              arLang ? (
                <PhoneInput masks={{ lb: '.. ... ...' }} inputStyle={{ direction: "ltr", width: "100%", paddingRight: "30px" }} inputProps={{ required: true }} label={t("description.phone")} country={'lb'} value={phone} onChange={setPhone} />
              ) : (
                <PhoneInput masks={{ lb: '.. ... ...' }} inputProps={{ required: true }} label={t("description.phone")} country={'lb'} value={phone} onChange={setPhone} />
              )
            }


            <br />
            <div sx={{ marginBottom: 20 }}>
              <MUITextField
                label={t("description.emailAdd")}
                id='outlined-email-input'
                helperText={`Email you'll receive updates and receipts on`}
                margin='normal'
                variant='filled'
                type='email'
                name='email'
                required
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
              />
              {formik.touched.email && formik.errors.email ? <div style={{ color: 'red' }}>{formik.errors.email}</div> : null}
            </div>
            <br />
            <Typography variant='body1'>
              <Trans i18nKey="description.pleaseFill">Please fill the card in order to proceed
              </Trans></Typography>
            <br />
            <CardInput />
            <br />
            <Typography>
              <Trans i18nKey="description.wanttopay">Are you sure you want to pay</Trans> <span style={{ fontWeight: 'bold' }}>LBP {price}</span><Trans i18nKey="description.asthereisno"> as there is no </Trans><span style={{ color: 'red', fontWeight: 'bold' }}><Trans i18nKey="description.refund">REFUND</Trans></span>.
              <Trans i18nKey="description.bycliking"> By clicking on pay, your property will be saved upon payment success and will be available
                in the featured property
              </Trans></Typography>
            <div className={classes.div}>
              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                loading={loading}
                className={classes.button}
                disabled={formik.errors.email || formik.errors.firstName || formik.errors.lastName || succeed}
              >
                Pay LBP {price}
              </LoadingButton>
            </div>
          </CardContent>
        </Card>
      </form>
      {
        ERROR ? (
          <Snackbar open={openStripe} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert onClose={handleSnackClose} severity="error">
              {error}
            </Alert>
          </Snackbar>
        ) : null
      }
    </>
  );
}

export default Stripe;
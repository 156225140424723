import React, { useState } from 'react';
import AdCard from "./AdCard";

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllAds } from '../../actions/adsSection';
import { Grid } from '@material-ui/core';
const Ads = ({ setCurrentId }) => {
    //const [currentId, setCurrentId] = useState(0);
    const { ads, isLoading } = useSelector((state) => state.ads);  //callback funcion .posts because in the reducers we called posts

    const dispatch = useDispatch();

return (
    ads.map((ad) => (
      <Grid key={ad._id} item xs={12} sm={6} md={4} lg={3}>
        <AdCard ad={ad} setCurrentId={setCurrentId} />
      </Grid>
    ))
);
}

export default Ads;
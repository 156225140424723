import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import useStyles from '../CMSAds/style';

import { useDispatch } from 'react-redux';
import { getAd, editAd } from '../../../actions/adsSection';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { LoadingButton } from '@material-ui/lab';
import { useFormik } from "formik";
import TextField from '@material-ui/core/TextField';

const CMS_Ad_Details = ({ id }) => {

    // const { ad, ads, isLoading } = useSelector((state) => state.ads);
    const dispatch = useDispatch();
    // const { id } = useParams();


    const [adInfo, setAd] = useState([]);

    useEffect(async () => {
        const ad = await dispatch(getAd(id));
        setAd(ad);
    }, [id, dispatch]);

    const [edit, setEdit] = useState(false);

    const editAnAd = () => {
        setEdit(true);
    }


    const [success, setSuccess] = useState(undefined);


    const formValues = {
        companyName: adInfo.companyName,
        title: adInfo.title,
        description: adInfo.description,
        link: adInfo.link
    }

    const formik = useFormik({
        initialValues: {
            companyName: adInfo.companyName,
            title: adInfo.title,
            description: adInfo.description,
            link: adInfo.link
        },
        onSubmit: values => {
            handleSubmit(values);
        }
    });


    const [adValues, setAdValues] = useState({
        companyName: formik.companyName,
        title: formik.title,
        description: formik.description,
        link: formik.link
    });

    const handleChange = input => e => {
        setAdValues({ ...adValues, [input]: e.target.value });
    }

    const handleSubmit = async (values) => {
        const form =
        {
            companyName: values.companyName,
            title: values.title,
            description: values.description,
            link: values.link
        }
        const success = await dispatch(editAd(id, form));
        if (success) {
        } else { };

        setSuccess(success)
    };

    return (
        edit ? (
            <>
                <Grid container>
                    <Grid item md={12} align="center">
                        <Typography variant="h3">Ad Information</Typography>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container>
                                <Grid item md={6} sm={6} sx={{ marginTop: "40px" }}>
                                    <FormControl sx={{ width: "80%", marginBottom: "30px" }}>
                                        <InputLabel htmlFor="component_outlined">Company Name</InputLabel>
                                        <OutlinedInput id="component_outlined" name="companyName" onChange={formik.handleChange} value={formik.companyName} />
                                    </FormControl>

                                    <FormControl sx={{ width: "80%", marginBottom: "30px" }}>
                                        <InputLabel htmlFor="component_outlined_2">Title</InputLabel>
                                        <OutlinedInput id="component_outlined_2" name="title" onChange={formik.handleChange} value={formik.title} />
                                    </FormControl>
                                </Grid>

                                <Grid item md={6} sm={6} sx={{ marginTop: "40px" }}>
                                    <FormControl sx={{ width: "80%", marginBottom: "30px" }}>
                                        <InputLabel htmlFor="component_outlined_3">Description</InputLabel>
                                        <OutlinedInput id="component_outlined_3" name="description" onChange={formik.handleChange} value={formik.description} />
                                    </FormControl>

                                    <FormControl sx={{ width: "80%", marginBottom: "30px" }}>
                                        <InputLabel htmlFor="component_outlined_4">Link</InputLabel>
                                        <OutlinedInput id="component_outlined_4" name="link" onChange={formik.handleChange} value={formik.link} />
                                    </FormControl>
                                </Grid>

                            </Grid>
                            <LoadingButton
                                size="large"
                                type="submit"
                                variant="outlined"
                                sx={{ borderColor: "black", color: "black", float: "right", marginRight: "5%" }}
                            >
                                Update Ad
                            </LoadingButton>
                        </form>
                    </Grid>
                </Grid>
            </>
        ) : (
            <>
                <Grid container>
                    <Grid item md={12} align="center">
                        <Typography variant="h3">Ad Information</Typography>
                        <Grid container>
                            <Grid item md={6} sm={6} sx={{ marginTop: "40px" }}>
                                <FormControl disabled sx={{ width: "80%", marginBottom: "30px" }}>
                                    <TextField
                                        disabled
                                        id="outlined-disabled"
                                        value={adInfo.companyName}
                                    />
                                </FormControl>

                                <FormControl disabled sx={{ width: "80%", marginBottom: "30px" }}>
                                    <TextField
                                        disabled
                                        id="outlined-disabled"
                                        value={adInfo.title}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item md={6} sm={6} sx={{ marginTop: "40px" }}>
                                <FormControl disabled sx={{ width: "80%", marginBottom: "30px" }}>
                                    <TextField
                                        disabled
                                        id="outlined-disabled"
                                        value={adInfo.description}
                                    />
                                </FormControl>

                                <FormControl sx={{ width: "80%", marginBottom: "30px" }}>
                                    <TextField
                                        disabled
                                        id="outlined-disabled"
                                        value={adInfo.link}
                                    />
                                </FormControl>
                            </Grid>

                        </Grid>
                        <LoadingButton
                            size="large"
                            type="submit"
                            variant="outlined"
                            sx={{ borderColor: "black", color: "black", float: "right", marginRight: "5%" }}
                            onClick={() => editAnAd()}
                        >
                            Edit Ad
                        </LoadingButton>
                    </Grid>
                </Grid>
            </>
        )

    )
};

export default CMS_Ad_Details;
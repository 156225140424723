import React, { useState, useEffect } from 'react';
import UseStyles from "./style";
import { Container, Box, Grid, Typography, Stack } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import FeaturedPostCard from '../../components/featuredPostsCard/featuredPostCard';
import ServicesCards from '../../components/ServicesCards/servicesCards';
import { useFormik } from 'formik';
import FilterSidebar from '../../components/Filter/filter';
import { MHidden } from '../../components/@material-extend';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import FeaturedPosts from '../../components/featuredPostsCard/featuredPosts';
import Pagination from '../../components/Pagination/PaginationFeatured';
import { getPostsBySearchh, getFeaturedPosts } from '../../actions/posts';
import { Trans, useTranslation } from 'react-i18next';
import Iframe from 'react-iframe';
import Link from '@material-ui/core/Link';
import Page from '../../components/Page';
import HomePageCarousel from '../../components/HomePageCarousel/HomePageCarousel'
import usePlacesAutocomplete from "use-places-autocomplete";
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from "@reach/combobox";
import "@reach/combobox/styles.css";
import Request360 from '../Request360/Request360';
import { alpha, styled } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/core/Autocomplete';
import Map from '../../components/MapHome/Map'
import {
  useLoadScript,
} from "@react-google-maps/api";
import Cookies from 'js-cookie';
import NewServicesCards from '../../components/ServicesCards/NewServicesCards';
import './style.css';
const libraries = ["places"];
const mapContainerStyle = {
  height: "100vh",
  width: "100%",
};
const options = {
  zoomControl: true,
  fullscreenControl: false,
  mapTypeControl: true,
  streetViewControl: false
};
const center = {
  lat: 33.82543142169751,
  lng: 35.583106540518465,
};
const MyTextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    border: 'none',
    overflow: 'hidden',
    backgroundColor: "white",
    transition: theme.transitions.create([
      'background-color',
    ]),
    '&:hover': {
      backgroundColor: 'white',
    },
    '&.Mui-focused': {
      backgroundColor: 'white',
    },
  },
}));
const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});
const RootStyle = styled(Page)(({ theme }) => ({
  marginTop: "-25px",
  paddingBottom: theme.spacing(10)
}));
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const HomePage = () => {
  const classes = UseStyles();
  const user = JSON.parse(localStorage.getItem('profile'));
  const query = useQuery();
  const page = query.get('page') || 1;
  const [currentId, setCurrentId] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [arLang, setArLang] = useState(false);
  let cookieLanguage = Cookies.get('i18next');
  useEffect(() => {
    if (cookieLanguage == "ar") {
      setArLang(true);
    }
  }, [])
  const { ready, value, suggestions: { status, data }, setValue, clearSuggestions } = usePlacesAutocomplete();
  const handleInput = (e) => {
    setValue(e.target.value);
  };
  const handleSelect = (val) => {
    setValue(val, false);
  };
  useEffect(() => {
    dispatch(getFeaturedPosts());
  }, [currentId, dispatch]);
  /////////////////////////////////////////////////
  const [propertyType, setPropertyType] = useState('');
  const [coolingType, setCoolingType] = useState('');
  const [heatingType, setHeatingType] = useState('');
  const [constructionCondition, setConstructionCondition] = useState('');
  const [price, setPrice] = useState({ minPrice: 0, maxPrice: 50000000 });
  const [Area, setArea] = useState({ minArea: 0, maxArea: 5000 });
  const [listingType, setListingType] = useState('');
  const [numberOfBathrooms, setNumberOfBathrooms] = useState('');
  const [numberOfBedrooms, setNumberOfBedrooms] = useState('');
  // const [garden, setGarden] = useState(false);
  const [features, setFeatures] = useState({
    garden: false,
    parkingSpace: false,
    dwelling: false,
    brickRoof: false,
    outBuildings: false,
    caretakerConcierge: false,
    swimmingPool: false,
    terrace: false,
    elevator: false,
    facingStreet: false,
    inTheWood: false,
    flatLand: false,
    furnished: false,
    internetAccess: false,
    videoPhone: false,
    specialNeedsAccessibility: false,
    secureAccess: false,
    showRoom: false,
    receptionDesk: false,
    pets: false,
    smoking: false,
    bigParties: false,
    loudMusic: false
  })
  const [value2, setValue2] = React.useState([0, 5000000]);
  const [value3, setValue3] = React.useState([0, 5000]);
  const minPrice = value2[0];
  const maxPrice = value2[1];
  const [valueMap, setValueMap] = useState('')
  const minArea = value3[0];
  const maxArea = value3[1];
  const searchPost = () => {
    const garden = features.garden;
    const parkingSpace = features.parkingSpace;
    const dwelling = features.dwelling
    const brickRoof = features.brickRoof
    const outBuildings = features.outBuildings
    const caretakerConcierge = features.caretakerConcierge
    const swimmingPool = features.swimmingPool
    const terrace = features.terrace
    const elevator = features.elevator
    const facingStreet = features.facingStreet
    const inTheWood = features.inTheWood
    const flatLand = features.flatLand
    const furnished = features.furnished
    const internetAccess = features.internetAccess
    const videoPhone = features.videoPhone
    const specialNeedsAccessibility = features.specialNeedsAccessibility
    const secureAccess = features.secureAccess
    const showRoom = features.showRoom
    const receptionDesk = features.receptionDesk
    const pets = features.pets
    const smoking = features.smoking
    const bigParties = features.bigParties
    const loudMusic = features.loudMusic
    if (valueMap.trim() || propertyType.trim() || listingType.trim() || coolingType.trim() || heatingType.trim() || constructionCondition.trim() || valueMap ||
      propertyType ||
      listingType ||
      coolingType ||
      heatingType ||
      constructionCondition ||
      garden ||
      minPrice ||
      maxPrice ||
      maxArea ||
      minArea ||
      numberOfBedrooms ||
      numberOfBathrooms ||
      parkingSpace ||
      dwelling ||
      brickRoof ||
      outBuildings ||
      caretakerConcierge ||
      swimmingPool ||
      terrace ||
      elevator ||
      facingStreet ||
      inTheWood ||
      flatLand ||
      furnished ||
      internetAccess ||
      videoPhone ||
      specialNeedsAccessibility ||
      secureAccess ||
      showRoom ||
      receptionDesk ||
      pets ||
      smoking ||
      bigParties ||
      loudMusic ||
      numberOfBathrooms ||
      numberOfBedrooms ||
      minPrice ||
      maxPrice
    ) {
      dispatch(getPostsBySearchh({
        valueMap,
        propertyType,
        listingType,
        heatingType,
        coolingType,
        constructionCondition,
        garden,
        minPrice,
        maxPrice,
        maxArea,
        minArea,
        numberOfBedrooms,
        numberOfBathrooms,
        parkingSpace,
        dwelling,
        brickRoof,
        outBuildings,
        caretakerConcierge,
        swimmingPool,
        terrace,
        elevator,
        facingStreet,
        inTheWood,
        flatLand,
        furnished,
        internetAccess,
        videoPhone,
        specialNeedsAccessibility,
        secureAccess,
        showRoom,
        receptionDesk,
        pets,
        smoking,
        bigParties,
        loudMusic,
      }));
      // navigate(`/posts/search?searchQuery=${search}&propertyTypeQuery=${propertyType}&listingTypeQuery=${listingType}&minPriceQuery=${minPrice}&maxPriceQuery=${maxPrice}`);
      navigate(`/posts/searchh?searchQuery=${valueMap}&propertyTypeQuery=${propertyType}&coolingTypeQuery=${coolingType}&heatingTypeQuery=${heatingType}&constructionConditionQuery=${constructionCondition}&listingTypeQuery=${listingType}&parkingSpaceQuery=${parkingSpace}&gardenQuery=${garden}&dwellingQuery=${dwelling}&brickRoofQuery=${brickRoof}&outBuildingsQuery=${outBuildings}&caretakerConciergeQuery=${caretakerConcierge}&swimmingPoolQuery=${swimmingPool}&terraceQuery=${terrace}&elevatorQuery=${elevator}&facingStreetQuery=${facingStreet}&inTheWoodQuery=${inTheWood}&flatLandQuery=${flatLand}&furnishedQuery=${furnished}&internetAccessQuery=${internetAccess}&videoPhoneQuery=${videoPhone}&specialNeedsAccessibilityQuery=${specialNeedsAccessibility}&secureAccessQuery=${secureAccess}&showRoomQuery=${showRoom}&receptionDeskQuery=${receptionDesk}&petsQuery=${pets}&smokingQuery=${smoking}&bigPartiesQuery=${bigParties}&loudMusicQuery=${loudMusic}&minPriceQuery=${minPrice}&maxPriceQuery=${maxPrice}&numberOfBedroomsQuery=${numberOfBedrooms}&numberOfBathroomsQuery=${numberOfBathrooms}&minAreaQuery=${minArea}&maxAreaQuery=${maxArea}`);
    } else {
      navigate('/');
    }
  };
  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      searchPost();
    }
  };
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyC-cRq-I-LZI5RcMt00vnXyhfz5Mchuihc',
    libraries, //Avoid many rerenders
  });
  ///////////////////////////////////////////////////////
  const { t, i18n } = useTranslation();
  if (loadError) return "Error loading maps";
  if (!isLoaded) return "loading maps"
  return (
    <RootStyle title="eAkary">
      <HomePageCarousel />
      <Container>
        <Grid container justifyContent="center">
          <Grid>
            <Typography variant="h3" mt={6} mb={4}>
              <Trans i18nKey="description.featuredProp">
                Featured Properties
              </Trans>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} >
          <Grid item xs={12}>
            <FeaturedPosts setCurrentId={setCurrentId} />
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid>
            <Typography variant="h3" mt={6} mb={4}>
              <Trans i18nKey="description.weCanhelp">
                We Can Help You
              </Trans>
            </Typography>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} justifyContent="center">
          <Grid item xs={12}>
            {/* <ServicesCards /> */}
            <NewServicesCards />
          </Grid>
        </Grid>
        <Grid
          item
          marginTop={{ sm: "3%", xs: "8%", md: "0%", lg: "0%", xl: "0%" }}
          xs={12}
          sm={12}
          md={12}
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ border: "0px solid green" }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            className="background_image"
            alignItems="center"
            sx={{ mt: 4, mb: 3 }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{ border: "0px solid red" }}
              height="90px"
              textAlign="center"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h3" color="white">
                <Trans i18nKey="description.exceptionalVE">
                  An Exceptional Virtual Experience
                </Trans>
              </Typography>
            </Grid>
            <Grid
              marginTop={{
                sm: "0px",
                xs: "0px",
                md: "3px",
                lg: "3px",
                xl: "3px",
              }}
              item
              xs={12}
              sm={12}
              md={12}
              style={{ border: "0px solid green" }}
              textAlign="center"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                sx={{
                  mt: 2,
                  mb: 3,
                  height: 50,
                  width: 250,
                  border: "2px solid white",
                  fontSize: "17px",
                  color: "white",
                }}
                onClick={() => navigate("/request360")}
                disableElevation
              >
                <Trans i18nKey="description.reqVT">Request Virtual Tour</Trans>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{ mb: 5 }} >
          <Grid container alignItems="center" xs={12} sm={12} md={12}>
            <Iframe
              src="https://orbix360.com/t/7Lo1Kzu6M2Y4qcR2ShMFT0GRD6u2/5034404254580736/eakary?embed=yes"
              allowfullscreen="true"
              frameborder="0"
              scrolling="no"
              allow="vr;gyroscope;accelerometer"
              width="100%"
              height="300vh"
              style="border:none">
            </Iframe>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  )
}
export default HomePage;
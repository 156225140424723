import React, { useState } from 'react';
import PostCard from "../../../components/postCards/postCard";
import Grid from "@material-ui/core/Grid";
import UseStyles from "./style";
import { useTheme } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useEffect } from "react";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import { useSelector, useDispatch } from "react-redux";
import { getBuyPosts } from '../../../actions/posts';
import Posts from '../../../components/postCards/Posts';
import { useFormik } from 'formik';
import { Container, Stack, Typography } from '@material-ui/core';
import Pagination from '../../../components/Pagination/PaginationBuy';
import { useHistory, useLocation, useNavigate } from 'react-router-dom';
import FilterSidebar from '../../../components/Filter/filter';
import Map from "../../../components/MapRent/Map";
import { getAllUsers } from '../../../actions/users';
import Users from '../../../components/UsersCard/Users';
import { getUsersBySearch } from '../../../actions/users';



function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const UsersPage = () => {


  const classes = UseStyles();
  const theme = useTheme();
  const query = useQuery();
  const page = query.get('page') || 1;
  const dispatch = useDispatch();
  const [currentId, setCurrentId] = useState(0);


  useEffect(() => {
    dispatch(getAllUsers());
  }, [currentId, dispatch]);



  ////////////////////////////////////////////////////////////////////////   

  // const [search, setSearch] = useState('');
  // const [propertyType, setPropertyType] = useState('');
  // const [listingType, setListingType] = useState('');
  const [user, setUser] = useState('');
  const navigate = useNavigate();

  const searchUser = () => {
    if (user.trim()) {
      dispatch(getUsersBySearch({ user }));
      navigate(`/user/search?userQuery=${user}`);
    }
    else {
      navigate('/CMS/landingAdmin');
    }
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      searchUser();
    }
  };


  return (
    <>
      <Container>
        <Grid container justifyContent="center">
          <Paper className={classes.search}
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'left', width: 400, border: 1 }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search by Name"
              inputProps={{ 'aria-label': 'search users' }}
              value={user}
              onChange={(e) => { setUser(e.target.value) }}
              onKeyDown={handleKeyPress}
            />
            <IconButton onClick={searchUser} type="submit" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

          </Paper>
          
      </Grid>
      <Grid container justifyContent="center" mt={13}>
            <Typography variant="h3">All Users</Typography>
          </Grid>
      <Grid container justifyContent="center" mt={4} spacing={2}>
          <Users setCurrentId={currentId}> </Users>
        </Grid>
      </Container>

    </>
  )




}
export default UsersPage;
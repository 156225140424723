import React from 'react'
import Button from '@material-ui/core/Button';

import './Popup360Style.css'

function Popup360(props) {
    return (props.trigger) ? (
        <div className="popup">
            <div className="popup-inner">
                <Button variant="contained"
                    color="primary"
                    className="close-btn" onClick={() => props.setTrigger(false)}>close</Button>
                {props.children}
            </div>
        </div>
    ) : "";
}

export default Popup360
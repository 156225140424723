import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Footer from '../../components/Footer/footer'
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import './backtotop.css';
import ReactTooltip from "react-tooltip";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 72;
const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  // overflow: 'hidden'
});
const MainStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  // overflow: 'auto',
  width: '100%',
  minHeight: '100vh',
  paddingTop: APP_BAR_MOBILE + 24,
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
  }
}));
// ----------------------------------------------------------------------
export default function DashboardLayout() {
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    let tooltip = document.querySelectorAll(`[data-tip][data-for=registerTip]`)[0];
    ReactTooltip.show(tooltip);
    setTimeout(() => { ReactTooltip.hide(tooltip) }, 5000);
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 200) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    }, true);
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  const [open, setOpen] = useState(false);
  return (
    <RootStyle>
      <a
        href="https://api.whatsapp.com/send?phone=9613034767&text=Hello%21%20I%20would%20like%20to%20get%20more%20information%20please."
        alt="Whatsapp button"
        target="_blank"
        rel="noreferrer"
        data-tip
        data-for="registerTip"
        className='whatsappBtn'
      >
        <WhatsAppIcon sx={{ color: 'white', fontSize: '2.8rem' }} data-tip
          data-for="registerTip" />
      </a>
      <ReactTooltip id="registerTip" place="left" effect="float" type="success">
        Contact administrator.
      </ReactTooltip>
      {showButton && (
        <div className='backtotop'>
          <KeyboardArrowUpIcon sx={{ color: 'white', fontSize: '2rem' }} onClick={e => scrollToTop()} />
        </div>
      )}
      <Navbar onOpenSidebar={() => setOpen(true)} />
      <Sidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>
        <Outlet />
        <Footer />
      </MainStyle>
    </RootStyle>
  );
}

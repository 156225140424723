import React, { useMemo, useEffect, useState } from 'react'
import { Alert, Button, Grid, InputLabel, LinearProgress, Select, Snackbar } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import UseStyles from './style'
import { Link } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import StripeComponent from "../../components/stripe/stripecomponent";
import './style.css';
import ButtonContained from "../../components/Buttons/ButtonContained";
import DialogDelete from "../../components/Dialog/Dialog";
import FormControl from "@material-ui/core/FormControl";
import { useFormik } from "formik";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import PayPalBtn from "../../components/PayPal/PaypalButton";
import { BorderColor } from '@material-ui/icons'
import CustomizedDividers from "../../components/BundlesToggle/bundlesToggle";

import { Trans, useTranslation } from 'react-i18next';

const DialogContent = withStyles((theme) => ({
    root: {
        padding: 15,
        overflow: 'hidden',
        height: 'auto',
    },


}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: 10,
    },
}))(MuiDialogActions);


const Bundle = ({ handleNext, bundle, setBundle, setPrice, price, post, setPost }) => {
    const classes = UseStyles();

    const [open, setOpen] = useState(false);



    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const paypalSubscribe = (data, actions) => {
        return actions.subscription.create({
            'plan_id': "P-9V619590R9082872EMEVIXOY", //Plan ID PayPal Gold
        });
    };

    const paypalOnError = (err) => {
    }

    const paypalOnApprove = (data, detail) => {
    };
    const { t, i18n } = useTranslation();

    const formik = useFormik({
        initialValues: {
            Duration: price,
        },
    }
    )

    setPrice(String(formik.values.Duration));

    const handleClickNext = () => {
        if (bundle === 'Basic') {
            setPost({ ...post, bundleChosen: 'Basic', duration: '3 months' })
        } else if (bundle === 'Gold') {
            setPost({ ...post, bundleChosen: 'Gold', duration: '5 months' })
        }
        else {
            setPost({ ...post, bundleChosen: 'Platinum', duration: '6 months' })
        }

        handleNext();
    }

    {
        const expireDiscountDate = new Date('2021-09-20T03:24:00');
        expireDiscountDate.setMonth(expireDiscountDate.getMonth() + 4);
        const dateNow = new Date();
        if (dateNow > expireDiscountDate) {
            return (
                <>

                    <Container>

                        <Grid container xs={12} justifyContent="center" alignItems="center" direction="column"
                            sx={{ mt: 6, mb: 6 }} >
                            {bundle === 'Basic' ? (
                                setPrice('100000'),
                                <>
                                    <Grid item>
                                        <Typography variant="h6" mt={1} mb={1}>
                                            <Trans i18nKey="description.duration">
                                                Duration
                                            </Trans>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h7" mt={4} mb={4}>
                                            <Trans i18nKey="description.yourpostWill">
                                                Your post will be listed for
                                            </Trans> <b>LBP 100 000</b><Trans i18nKey="description.3months"> for 3 months</Trans>
                                        </Typography>
                                    </Grid>
                                </>
                            ) : bundle === 'Gold' ? (
                                setPrice('150000'),
                                <>
                                    <Grid item>
                                        <Typography variant="h6" mt={1} mb={1}>
                                            <Trans i18nKey="description.duration">
                                                Duration
                                            </Trans>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h7" mt={4} mb={4}>
                                            <Trans i18nKey="description.yourpostWill">
                                                Your post will be listed for </Trans><b>LBP 150 000</b><Trans i18nKey="description.5months"> for 5 months</Trans>
                                        </Typography>
                                    </Grid>

                                </>
                            ) : bundle === 'Platinum' ? (
                                setPrice('200000'),
                                <>
                                    <Grid item>
                                        <Typography variant="h6" mt={1} mb={1}>
                                            <Trans i18nKey="description.duration">
                                                Duration
                                            </Trans>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h7" mt={4} mb={4}>
                                            <Trans i18nKey="description.yourpostWill">
                                                Your post will be listed for </Trans><b>LBP 200 000</b> <Trans i18nKey="description.6months">for 6 months</Trans>
                                        </Typography>
                                    </Grid>

                                </>
                            ) : null
                            }
                        </Grid>
                        <Divider sx={{ marginBottom: '3em' }} />
                        <CustomizedDividers post={post} setPost={setPost} priceDuration={formik.values.Duration}
                            bundle={bundle} setBundle={setBundle} dateNow={dateNow} expireDate={expireDiscountDate} />
                        <React.Fragment>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Box sx={{ flex: '1 1 auto' }} />

                                <Button onClick={() => handleClickNext()}>
                                    <Trans i18nKey="description.next">
                                        Next
                                    </Trans>
                                </Button>
                            </Box>
                        </React.Fragment>
                    </Container>

                </>
            )
        } else {
            return (
                <>

                    <Container>

                        <Grid container xs={12} justifyContent="center" alignItems="center" direction="column"
                            sx={{ mt: 6, mb: 6 }}>
                            {bundle === 'Basic' ? (
                                setPrice('Free'),
                                <>
                                    <Grid item>
                                        <Typography variant="h6" mt={1} mb={1}>
                                            <Trans i18nKey="description.duration">
                                                Duration
                                            </Trans>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h7" mt={4} mb={4}>
                                            <Trans i18nKey="description.yourpostWill">
                                                Your post will be listed for </Trans><b>Free</b> <Trans i18nKey="description.3months">for 3 months</Trans>
                                        </Typography>
                                    </Grid>
                                </>
                            ) : bundle === 'Gold' ? (
                                setPrice('75000'),
                                <>
                                    <Grid item>
                                        <Typography variant="h6" mt={1} mb={1}>
                                            <Trans i18nKey="description.duration">
                                                Duration
                                            </Trans>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h7" mt={4} mb={4}>
                                            <Trans i18nKey="description.yourpostWill">
                                                Your post will be listed for </Trans><b>LBP 75 000</b> <Trans i18nKey="description.5months">for 5 months</Trans>
                                        </Typography>
                                    </Grid>

                                </>
                            ) : bundle === 'Platinum' ? (
                                setPrice('100000'),
                                <>
                                    <Grid item>
                                        <Typography variant="h6" mt={1} mb={1}>
                                            <Trans i18nKey="description.duration">
                                                Duration
                                            </Trans>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h7" mt={4} mb={4}>
                                            <Trans i18nKey="description.yourpostWill">
                                                Your post will be listed for </Trans><b>LBP 100 000</b> <Trans i18nKey="description.6months">for 6 months</Trans>
                                        </Typography>
                                    </Grid>

                                </>
                            ) : null
                            }
                        </Grid>
                        <Divider sx={{ marginBottom: '3em' }} />
                        <CustomizedDividers post={post} setPost={setPost} priceDuration={formik.values.Duration}
                            bundle={bundle} setBundle={setBundle} />
                        <React.Fragment>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Box sx={{ flex: '1 1 auto' }} />

                                <Button onClick={() => handleClickNext()}>
                                    <Trans i18nKey="description.next">
                                        Next
                                    </Trans>
                                </Button>
                            </Box>
                        </React.Fragment>
                    </Container>

                </>
            )
        }
    }
}

export default Bundle
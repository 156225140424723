import { Height } from "@material-ui/icons";
import {makeStyles} from "@material-ui/styles";

const UseStyles = makeStyles(() => ({
  search: {
      position: "absolute"
  },
  paper: {
    fontSize: "16px !important"
  }
}));
export default UseStyles
import { makeStyles } from '@material-ui/styles';

const UseStyles = makeStyles((theme) => ({
    root: {  
      '&:hover': {
        boxShadow: " 4px 7px 17px -11px rgba(66,66,66,0.75)",    
      }
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    avatar: {
      backgroundColor: "red",
    },
    typoPrice: {
      marginBottom: 5
       
    },
    typoSize: {
        
        marginBottom: 5
    },
    typoLocation: {
        
    
    },
    Favorite: {
        float: 'right',
        cursor: "pointer",
    },
    Featured: {
      float: 'left',
      position: 'absolute',
  }
  }));

export default UseStyles
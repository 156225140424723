
import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import useStyles from './style';
import { useState } from "react";

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { getPost } from '../../../actions/posts';



const CMS_Post_Detail = ({ postId }) => {
    const classes = useStyles();

    // const { postbyId, posts } = useSelector((state) => state.posts);

    const [postbyId, setByPost] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(async () => {
        const data = await dispatch(getPost(postId));
        setByPost(data);

    }, [dispatch]);




    return (
        <>
            <Typography variant="h3">Post Detail</Typography>
            <Grid container>
                <Grid item md={6}>
                    <div>
                        <Typography variant="h5" sx={{ marginTop: "20px", marginBottom: "10px" }}>General Information</Typography>
                        <div>
                            <Grid item xs={12} lg={6}>
                                <Typography className={classes.paper}><b>Email:</b> {postbyId.email}</Typography>
                                <Typography className={classes.paper}><b>Phone:</b> {postbyId.phoneNumber}</Typography>
                            </Grid>
                        </div>
                    </div>
                    <br />

                    <Typography variant="h5" sx={{ marginTop: "20px", marginBottom: "10px" }}>Overview</Typography>
                    <div className={classes.root_grid}>

                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={6}>
                                <Typography className={classes.paper}><b>Bedrooms:</b>  {postbyId.numberOfBedrooms}</Typography>
                                <Typography className={classes.paper}><b>Bathrooms:</b>  {postbyId.numberOfBathrooms}</Typography>
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <Typography className={classes.paper}><b>Area:</b>  {postbyId.surfaceArea}</Typography>
                                <Typography className={classes.paper}><b>Floor:</b>  {postbyId.floor}</Typography>
                            </Grid>

                        </Grid>
                        <br /><br />
                    </div>

                    <Typography variant="h5" sx={{ marginTop: "20px", marginBottom: "10px" }}>Permits</Typography>
                    <div className={classes.root_grid}>

                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={6}>
                                <Typography className={classes.paper}><b>Pets:</b>  {postbyId.pets}</Typography>
                                <Typography className={classes.paper}><b>Smoking:</b>  {postbyId.smoking}</Typography>
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <Typography className={classes.paper}><b>Big Parties:</b>  {postbyId.bigParties}</Typography>
                                <Typography className={classes.paper}><b>Loud Music:</b>  {postbyId.loudMusic}</Typography>
                            </Grid>

                        </Grid>
                        <br /><br />
                    </div>

                    <Typography variant="h5" sx={{ marginBottom: "10px" }}>Description</Typography>
                    <Typography className={classes.description}>{postbyId.description}</Typography>
                </Grid>

                <Grid item md={6}>
                    <Typography variant="h5" sx={{ marginTop: "20px", marginBottom: "10px" }}>Interior</Typography>
                    <div className={classes.root_grid}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={6}>
                                <Typography className={classes.paper}><b>Kitchen Area:</b>  {postbyId.kitchenArea}</Typography>
                            </Grid>
                        </Grid>
                    </div>

                    <Typography variant="h5" sx={{ marginTop: "50px", marginBottom: "10px" }}>Exterior</Typography>
                    <div className={classes.root_grid}>
                        <Grid container spacing={2}>
                            <Grid item xs={7} lg={2}>
                                <Typography className={classes.paper}><b>Parking Space:</b>  {postbyId.parkingSpace}</Typography>
                                <Typography className={classes.paper}><b>Dwelling:</b>{postbyId.dwelling}</Typography>
                            </Grid>
                            <Grid item xs={5} lg={4}>
                                <Typography className={classes.paper}><b>Brick Roof:</b> {postbyId.brickRoof}</Typography>
                                <Typography className={classes.paper}><b>OutBuildings:</b> {postbyId.outBuildings}</Typography>
                            </Grid>
                            <Grid item xs={7} lg={2}>
                                <Typography className={classes.paper}><b>Concierge:</b>  {postbyId.caretakerConcierge}</Typography>
                                <Typography className={classes.paper}><b>Garden:</b> {postbyId.garden}</Typography>
                            </Grid>
                            <Grid item xs={5} lg={4}>
                                <Typography className={classes.paper}><b>Swimming Pool:</b> {postbyId.swimmingPool}</Typography>
                                <Typography className={classes.paper}><b>Terrace:</b> {postbyId.terrace}</Typography>
                            </Grid>
                        </Grid>
                        <br /><br />
                    </div>

                    <Typography variant="h5" sx={{ marginTop: "20px", marginBottom: "10px" }}>Features</Typography>
                    <div className={classes.root_grid}>
                        <Grid container spacing={2}>
                            <Grid item xs={5} lg={4}>
                                <Typography className={classes.paper}><b>Heating Type:</b>  {postbyId.heatingType}</Typography>
                                <Typography className={classes.paper}><b>Cooling Type:</b>  {postbyId.coolingType}</Typography>
                            </Grid>
                            <Grid item xs={7} lg={2}>
                                <Typography className={classes.paper}><b>Elevator:</b> {postbyId.elevator}</Typography>
                                <Typography className={classes.paper}><b>Furnished:</b> {postbyId.furnished}</Typography>
                            </Grid>
                            <Grid item xs={5} lg={4}>
                                <Typography className={classes.paper}><b>Internet Access:</b>{postbyId.internetAccess}</Typography>
                                <Typography className={classes.paper}><b>Video Phone:</b> {postbyId.videoPhone}</Typography>
                            </Grid>
                            <Grid item xs={7} lg={2}>
                                <Typography className={classes.paper}><b>Special Needs Accessability:</b> {postbyId.specialNeedsAccessibility}</Typography>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>


            </Grid>

            <div className={classes.moveLeft}>
                {/* <Typography variant="h5" sx={{ marginBottom: "10px" }}>Overview</Typography>
                <div className={classes.root_grid}>

                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <Typography className={classes.paper}><b>Bedrooms:</b>  {postbyId.numberOfBedrooms}</Typography>
                            <Typography className={classes.paper}><b>Bathrooms:</b>  {postbyId.numberOfBathrooms}</Typography>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <Typography className={classes.paper}><b>Area:</b>  {postbyId.surfaceArea}</Typography>
                            <Typography className={classes.paper}><b>Floor:</b>  {postbyId.floor}</Typography>
                        </Grid>

                    </Grid>
                    <br /><br />
                </div> 
                <Typography variant="h5" sx={{ marginBottom: "10px" }}>Description</Typography>
                <Typography className={classes.description}>{postbyId.description}</Typography>
                <br />*/}
                {/* <Typography variant="h5" sx={{ marginBottom: "10px" }}>Interior</Typography>
                <div className={classes.root_grid}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <Typography className={classes.paper}><b>Kitchen Area:</b>  {postbyId.kitchenArea}</Typography>
                        </Grid>
                    </Grid>
                </div> */}
            </div>
            <br /><br />
            <br /><br />
            <div className={classes.moveLeft}>
                {/* <Typography variant="h5" sx={{ marginBottom: "10px" }}>Exterior</Typography>
                <div className={classes.root_grid}>
                    <Grid container spacing={2}>
                        <Grid item xs={7} lg={2}>
                            <Typography className={classes.paper}><b>Parking Space:</b>  {postbyId.parkingSpace}</Typography>
                            <Typography className={classes.paper}><b>Dwelling:</b>{postbyId.dwelling}</Typography>
                        </Grid>
                        <Grid item xs={5} lg={4}>
                            <Typography className={classes.paper}><b>Brick Roof:</b> {postbyId.brickRoof}</Typography>
                            <Typography className={classes.paper}><b>OutBuildings:</b> {postbyId.outBuildings}</Typography>
                        </Grid>
                        <Grid item xs={7} lg={2}>
                            <Typography className={classes.paper}><b>Concierge:</b>  {postbyId.caretakerConcierge}</Typography>
                            <Typography className={classes.paper}><b>Garden:</b> {postbyId.garden}</Typography>
                        </Grid>
                        <Grid item xs={5} lg={4}>
                            <Typography className={classes.paper}><b>Swimming Pool:</b> {postbyId.swimmingPool}</Typography>
                            <Typography className={classes.paper}><b>Terrace:</b> {postbyId.terrace}</Typography>
                        </Grid>
                    </Grid>
                    <br /><br />
                </div> */}
                {/* <Typography variant="h5" sx={{ marginBottom: "10px" }}>Features</Typography>
                <div className={classes.root_grid}>
                    <Grid container spacing={2}>
                        <Grid item xs={5} lg={4}>
                            <Typography className={classes.paper}><b>Heating Type:</b>  {postbyId.heatingType}</Typography>
                            <Typography className={classes.paper}><b>Cooling Type:</b>  {postbyId.coolingType}</Typography>
                        </Grid>
                        <Grid item xs={7} lg={2}>
                            <Typography className={classes.paper}><b>Elevator:</b> {postbyId.elevator}</Typography>
                            <Typography className={classes.paper}><b>Furnished:</b> {postbyId.furnished}</Typography>
                        </Grid>
                        <Grid item xs={5} lg={4}>
                            <Typography className={classes.paper}><b>Internet Access:</b>{postbyId.internetAccess}</Typography>
                            <Typography className={classes.paper}><b>Video Phone:</b> {postbyId.videoPhone}</Typography>
                        </Grid>
                        <Grid item xs={7} lg={2}>
                            <Typography className={classes.paper}><b>Special Needs Accessability:</b> {postbyId.specialNeedsAccessibility}</Typography>
                        </Grid>
                    </Grid>
                </div> */}
                <div className={classes.moveLeft}>
                    {/* <Typography variant="h5" sx={{ marginBottom: "10px" }}>Permits</Typography>
                    <div className={classes.root_grid}>

                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={6}>
                                <Typography className={classes.paper}><b>Pets:</b>  {postbyId.pets}</Typography>
                                <Typography className={classes.paper}><b>Smoking:</b>  {postbyId.smoking}</Typography>
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <Typography className={classes.paper}><b>Big Parties:</b>  {postbyId.bigParties}</Typography>
                                <Typography className={classes.paper}><b>Loud Music:</b>  {postbyId.loudMusic}</Typography>
                            </Grid>

                        </Grid>
                        <br /><br />
                    </div> */}
                </div>
            </div>
        </>
    )

}
export default CMS_Post_Detail
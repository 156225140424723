import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import StarsIcon from '@material-ui/icons/Stars';
import HotelIcon from '@material-ui/icons/Hotel';
import BathtubIcon from '@material-ui/icons/Bathtub';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import Chip from '@material-ui/core/Chip';
import Stack from '@material-ui/core/Stack';
import { fCurrency } from '../../utils/formatNumber';
import UseStyles from './styles';
import apartment from "../../images/apartment.jpg";
import 'boxicons';
import { ADD_TO_WISHLIST, REMOVE_FROM_WISHLIST } from '../../constants/actionTypes';
import { addToWishlist, getWishlist } from '../../actions/posts';
import { deleteWish } from '../../actions/posts';
import { Navigation } from '@material-ui/icons';
import { Trans, useTranslation } from 'react-i18next';
import DialogLogin from '../Dialog/DialogLogin';
import Cookies from 'js-cookie';

const PostCard = ({ post, setCurrentId }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [arLang, setArLang] = useState(false);
  let cookieLanguage = Cookies.get('i18next');


  useEffect(() => {
    if (cookieLanguage == "ar") {
      setArLang(true);
    }
  }, [])

  const openDialog = () => {
    setOpen(true);
  }

  const closeDialog = () => {
    setOpen(false);
  }

  // const role =LoggedUser.result.role ;
  const openPost = (e) => {

    navigate(`/posts/${post._id}`);

  };
  const classes = UseStyles();


  return (

    <Card variant="outlined"  className={classes.root} elevation={0}>
      <Grid container>
        {(post?.bundleChosen === 'Gold') && (
          <Grid container >
            <Chip label={t("description.gold")} sx={{ mt: 1, ml: 1, backgroundColor: "#FFD700", color: "white" }} className={classes.Featured} size="small" />
          </Grid>

        )}

        {(post?.bundleChosen === 'Platinum') && (
          <Grid container >
            <Chip label={t("description.platinum")} sx={{ mt: 1, ml: 1, backgroundColor: "#E5E4E2", color: "white" }} className={classes.Featured} size="small" />
          </Grid>

        )}
      </Grid>

      {
        arLang ? (
          <IconButton aria-label="add to favorites" className={classes.Favorite} style={{float: "left"}} onClick={() => openDialog()}>
            <FavoriteIcon />
          </IconButton>
        ) : (
          <IconButton aria-label="add to favorites" className={classes.Favorite} onClick={() => openDialog()}>
            <FavoriteIcon />
          </IconButton>
        )
      }


      <DialogLogin open={open} close={closeDialog} />
<div onClick={openPost} style={{cursor: 'pointer'}}>
      <CardMedia
        className={classes.media}
        image={post.mainImage ? post.mainImage.url : null}
        title={apartment}
        alt={apartment}
      />

      <CardContent  style={{ textAlign: "Left" }}>
        <Typography variant="subtitle2" component="p" >
          {post.propertyType}
        </Typography>
        <Typography variant="h4" style={{ marginBottom: "5px", marginTop: "5px" }} component="p" >
          {fCurrency(post.price)}
        </Typography>
        <Typography variant="body2" component="p" noWrap>
          {post.mapPin ? post.mapPin.address : post.city}
        </Typography>
        <hr className={classes.solid} style={{ marginBottom: "10px", marginTop: "10px" }} />
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {(post?.numberOfBedrooms !== null) && (

            <Chip icon={<HotelIcon style={{ color: "#001329" }} />} label={post.numberOfBedrooms} size="small" style={{ backgroundColor: "white" }} />

          )}
          {(post?.numberOfBathrooms !== null) && (

            <Chip icon={<BathtubIcon style={{ color: "#001329" }} />} label={post.numberOfBathrooms} size="small" style={{ backgroundColor: "white" }} />

          )}
          {(post?.surfaceArea !== null) && (

            <Chip icon={<ZoomOutMapIcon style={{ color: "#001329" }} />} label={post.surfaceArea} size="small" style={{ backgroundColor: "white" }} />

          )}

        </Stack>

      </CardContent>
      </div>
    </Card>
  );
}


export default PostCard;
import { AUTH } from '../constants/actionTypes';
import * as api from '../api/index.js';



export const contactUs = (formData) => async () => {
    try {
       await api.contactUs(formData);
       return true
    } catch (error) {
        return false
    }
};
import React, { useState } from 'react';
import UseStyles from "./style";
import { useTheme } from '@material-ui/styles';

import Button from '@material-ui/core/Button';

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Stack} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { getAllAds } from '../../../actions/adsSection';
import { useNavigate} from 'react-router-dom'
import Ads from '../../../components/AdsSectionCard/Ads';



function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CMSAds = () => {

    const query = useQuery();
    const dispatch = useDispatch();
    const [currentId, setCurrentId] = useState(0);
    const navigate = useNavigate();
    useEffect(() => {

        dispatch( getAllAds() );

    }, [currentId, dispatch]);



  ////////////////////////////////////////////////////////////////////////   

  return (
    <>
<Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mb: 5 }}
        >
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }} style={{marginRight:10}}>
          <Button
                disableRipple
                variant="contained"
                color="secondary"
                onClick ={() => {navigate(`/CMS/ad/createAd`)} }
            >
                Create Ad&nbsp;
            </Button>
          </Stack>
        </Stack>

      <Container>
        
      <Ads setCurrentId={currentId} />
      
      </Container>

    </>
  )

               


}
export default CMSAds;
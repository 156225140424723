import * as React from 'react';
import { useState } from 'react';
import { styled, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import MuiDrawer from '@material-ui/core/Drawer';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import AccountPopover from '../../../layouts/CMSNavBarLayout/AccountPopover';
import { ClassNames } from '@emotion/react';
import Grid from '@material-ui/core/Grid';
import "./style.css";
import GroupIcon from '@material-ui/icons/Group';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import RadioIcon from '@material-ui/icons/Radio';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CardMedia from '@material-ui/core/CardMedia';
import CMS_Users from '../Users/cms_users';
import CMS_Posts from '../Posts/cms_posts';
import CMS_Ads from '../CMSAds/cms_ads';
import CMS_Notification from '../CMSNotification/cms_notification';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CMS_Agency from '../CMSAgency/cms_agency';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function CMS_Home() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const [users, setUserPage] = useState(false);
  const [posts, setPostPage] = useState(false);
  const [ads, setAdsPage] = useState(false);
  const [notification, setNotificationPage] = useState(false);
  const [agency, setAgencyPage] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const userPageClicked = () => {
    setUserPage(true);
    setPostPage(false);
    setAdsPage(false);
    setNotificationPage(false);
    setAgencyPage(false);
  }

  const postPageClicked = () => {
    setUserPage(false);
    setPostPage(true);
    setAdsPage(false);
    setNotificationPage(false);
    setAgencyPage(false);
  }

  const adsPageClicked = () => {
    setUserPage(false);
    setPostPage(false);
    setAdsPage(true);
    setNotificationPage(false);
    setAgencyPage(false);
  }

  const notificationPageClicked = () => {
    setUserPage(false);
    setPostPage(false);
    setAdsPage(false);
    setNotificationPage(true);
    setAgencyPage(false);
  }

  const agencyPageClicked = () => {
    setUserPage(false);
    setPostPage(false);
    setAdsPage(false);
    setNotificationPage(false);
    setAgencyPage(true);
  }

  const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  });
  

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ marginRight: "auto" }}>
            Welcome Admin!
          </Typography>
          <AccountPopover />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
          <Img src="/static/logo2.png" sx={{ width: 60, height: 50, cursor: 'pointer'  }} />
        </DrawerHeader>
        <Divider />
        <List>
          {['Users', 'Properties', 'Ads', 'Notifications, Agencies'].splice(0, 1).map((text, index) => (
            <>
              <ListItem button onClick={() => userPageClicked()}>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="Users" />
              </ListItem>
              <ListItem button onClick={() => postPageClicked()}>
                <ListItemIcon>
                  <HomeWorkIcon />
                </ListItemIcon>
                <ListItemText primary="Properties" />
              </ListItem>
              <ListItem button onClick={() => adsPageClicked()}>
                <ListItemIcon>
                  <RadioIcon />
                </ListItemIcon>
                <ListItemText primary="Ads" />
              </ListItem>
              <ListItem button onClick={() => notificationPageClicked()}>
                <ListItemIcon>
                  <NotificationsIcon />
                </ListItemIcon>
                <ListItemText primary="Notifications" />
              </ListItem>
              <ListItem button onClick={() => agencyPageClicked()}>
                <ListItemIcon>
                  <PersonAddIcon />
                </ListItemIcon>
                <ListItemText primary="Agencies" />
              </ListItem>
            </>
          ))}
        </List>
      </Drawer>
      {/* <Box component="main" sx={{width: "60%", height: "60%", margin: "0 auto"}}>
        <div style={{width: "50%", height: "70%", margin: "0 auto"}}>
          <img src="https://img.freepik.com/free-vector/settings-concept-illustration_114360-4157.jpg?size=338&ext=jpg"
                style={{width: "100%", height: "100%", marginRight: "auto", marginLeft: "auto"}} />
        </div>
      </Box> */}
      {
        users ? (
          <CMS_Users />
        ) : (
          posts ? (
            <CMS_Posts />
          ) : (
            ads ? (
              <CMS_Ads />
            ) : (
              notification ? (
                <CMS_Notification />
              ) : (
                agency ? (
                  <CMS_Agency />
                ) : (
                  <Box component="main" sx={{ width: "60%", height: "60%", margin: "0 auto" }}>
                    <div style={{ width: "50%", height: "70%", margin: "0 auto" }}>
                      <img src="https://img.freepik.com/free-vector/settings-concept-illustration_114360-4157.jpg?size=338&ext=jpg"
                        style={{ width: "100%", height: "100%", marginRight: "auto", marginLeft: "auto" }} />
                    </div>
                  </Box>
                )
              )
            )
          )
        )
      }
    </Box>
  );
}

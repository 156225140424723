
import React, { memo } from 'react';
import { Grid, IconButton, Tooltip, Hidden } from '@material-ui/core'
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import HotelIcon from '@material-ui/icons/Hotel';
import BathtubIcon from '@material-ui/icons/Bathtub';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import WeekendIcon from '@material-ui/icons/Weekend';
import UseStyles from './styles';

import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
function ApartmentVillaPropertyItem({ _id, numberOfBedrooms, numberOfBathrooms, numberOfRooms, surfaceArea }) {
    const isMobileScreen = useMediaQuery('(max-width:600px)');
    const navigate = useNavigate();

    const isTabletScreen = useMediaQuery('(max-width:1070px)');
    const classes = UseStyles('ApartmentVilla', isMobileScreen, isTabletScreen);
    return (
        <Grid xs={12} item container sx={{ ...classes.footer }} direction="row" alignItems="flex-end" justifyContent="center" >

            <Grid container item sx={{ ...classes.footerInfo, flex: '4' }} justifyContent="space-around" alignItems="center" >

                <Grid item sx={{ ...classes.footerText }}> <Tooltip title="Number Of Bedrooms"><span> <HotelIcon sx={{ fontSize: '1.3rem' }} /> {` ${numberOfBedrooms} `} <Hidden smDown>  Bedrooms</Hidden> </span></Tooltip></Grid>
                <Grid item sx={{ ...classes.footerText }}><Tooltip title="Number Of Bathrooms"><span> <BathtubIcon sx={{ fontSize: '1.3rem' }} /> {` ${numberOfBathrooms} `}<Hidden smDown>Bathrooms </Hidden></span></Tooltip></Grid>
                <Grid item sx={{ ...classes.footerText }}><Tooltip title="Number Of Rooms"><span> <WeekendIcon sx={{ fontSize: '1.3rem' }} /> {` ${numberOfRooms}`}<Hidden smDown> Rooms</Hidden></span></Tooltip></Grid>
                <Grid item sx={{ ...classes.footerText }}><Tooltip title="Surface Area"><span> <ZoomOutMapIcon sx={{ fontSize: '1.3rem' }} /> {` ${surfaceArea}sqft`}</span></Tooltip></Grid>
            </Grid>
            <Grid onClick={() => navigate(`/posts/${_id}`)} container sx={{
                ':hover': {
                    cursor: 'pointer !important',
                    background: 'rgba(4,135,186,0.5)'
                }, transition: 'background 200ms ease-in-out', height: '60%',
                backgroundColor: 'rgba(0,0,0,0.7)', flex: '1',
                borderLeft: '2px solid white'
            }} justifyContent="center" alignItems="center" item >
                < MoreHorizIcon sx={{ color: 'white' }} fontSize="large" />
                <IconButton size="small" sx={{ ...classes.footerText }}>
                </IconButton></Grid>

        </Grid >
    )
}

export default memo(ApartmentVillaPropertyItem)
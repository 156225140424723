import { Height } from "@material-ui/icons";
import {makeStyles} from "@material-ui/styles";

const UseStyles = makeStyles((theme) => ({
  search: {
      position: "absolute"
  },
  root: {
    '& > *': {
      width: '50ch',
    },
  },
}));
export default UseStyles
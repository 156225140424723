import React, { useState, useRef } from 'react';
import { Stack } from "@material-ui/core";
import "./toggle.css";

export default function Inputt({ setIsBuy, isBuy }) {
    // if (isBuy) {

    //     document.getElementById("slider").classList.remove("rent");
    // } else {
    //     document.getElementById("slider").classList.add("rent");
    // }

    const toggle = () => {
        setIsBuy(!isBuy)
    }
    // const toggleOld = () => {
    //     if (val === "Buy") {
    //         setVal("Rent");
    //         isBuyOrRent(false);
    //     } else if (val === "Rent") {
    //         setVal("Buy");
    //         isBuyOrRent(true);
    //     }
    // }
    return (
        <Stack id="postPage-toggleBuyRent" direction="row" justifyContent="center" alignItems="center" sx={{ alignSelf: 'center', marginBottom: '0.25rem', marginTop: "0.25rem", width: 'fit-content' }} border="0px solid red" onClick={() => { toggle(); }}>
            <div id="outerContainer">
                <div id="toggleContainer">
                    <div id="slider" className={isBuy ? '' : 'rent'}></div>
                    <span class="label">Buy</span><span class="label">Rent</span>
                </div>
            </div>
        </Stack>
    );
}
import React, { useState } from "react";
import usePlacesAutocomplete, { getGeocode, getLatLng, } from "use-places-autocomplete";
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from "@reach/combobox";
import { GoogleMap, useLoadScript, Marker, InfoWindow, } from "@react-google-maps/api";
import "@reach/combobox/styles.css";
import "./searchStyle.css";
import { Trans, useTranslation } from 'react-i18next';
// import useStyles from './searchStyle'


// const search = {
//     position: absolute,
//     top: '1rem',
//     left: '50%',
//     // transform: translateX(-50 %),
//     width: '100 %'',
//     maxWidth: 400px,
//         z - index: 10,
//   }

let Resadress = []
export default function Search({ panTo, markers, setMarkers }) {

    const { t, i18n } = useTranslation();

    // const classes = useStyles();

    // const [markers, setMarkers] = useState([]);

    const { ready, value, suggestions: { status, data }, setValue, clearSuggestions } = usePlacesAutocomplete({
        requestOptions: {
            location: {
                lat: () => 43.6532,
                lng: () => -79.3832,
            },
            radius: 200 * 1000,
        },
    });

    return (


        // <div style={{
        //     position: "absolute",
        //     top: '1rem',
        //     left: '50%',
        //     zIndex: "10",
        //     width: "100%",
        //     maxWidth: "400px"
        // }}>
        <div className="search" >

            <Combobox
                onSelect={async (address) => {

                    setValue(address, false)
                    clearSuggestions();
                    try {
                        const results = await getGeocode({ address });
                        Resadress = (results[0].formatted_address)
                        const { lat, lng } = await getLatLng(results[0]);
                        const newMarker = {
                            lat: lat,
                            lng: lng,
                            address: Resadress,
                            time: new Date(),
                        };
                        const res = newMarker;
                        setMarkers(res);
                        panTo({ lat, lng });
                        // setMarkers([...markers, { lat, lng }]);


                    } catch (error) {
                    }
                }}

            >
                <ComboboxInput value={value} onChange={(e) => {
                    setValue(e.target.value);
                }}
                    disabled={!ready}
                    placeholder={t("description.enterAdd")}


                />
                <ComboboxPopover>
                    <ComboboxList>
                        {status === "OK" && data.map(({ id, description }) => (
                            <ComboboxOption key={id} value={description} />
                        ))}
                    </ComboboxList>
                </ComboboxPopover>
            </Combobox>
        </div >
    );
}
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Grid from "@material-ui/core/Grid";
import { fCurrency } from '../../utils/formatNumber';
import Typography from '@material-ui/core/Typography';
import { deletePost } from '../../api';

import HotelIcon from '@material-ui/icons/Hotel';
import BathtubIcon from '@material-ui/icons/Bathtub';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import Chip from '@material-ui/core/Chip';
import Stack from '@material-ui/core/Stack';

import UseStyles from './styles';
import Button from '@material-ui/core/Button'
import apartment from "../../images/apartment.jpg";
import 'boxicons';
import { Trans, useTranslation } from 'react-i18next';
import axios from 'axios'

const BlockedPost = ({ post, setCurrentId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem('profile'));

  const deletePost = (id) => {
    axios.delete(`https://backend.eakary.com/posts/delete/` + id).then(() => {
      window.location.reload(false);
    })
  }

  const classes = UseStyles();

  //////////////////////////////////////
  const { t, i18n } = useTranslation();

  return (
    <>

      <section style={{
        position: "absolute",
        minHeight: 360,
        maxHeight: 360,
        backgroundColor: "black",
        opacity: 0.3,
        maxWidth: 289,
        minWidth: 289,
        borderRadius: 3
      }} />
      <Typography variant="h6" sx={{ position: 'absolute', transform: 'translate(2em,5em)', zIndex: 1111, color: "white" }}>
        <Trans i18nKey="description.propexp">
          Your property has expired
        </Trans>
      </Typography>

      <Card variant="contained" className={classes.blockedRoot} elevation={0}>
        <CardMedia
          className={classes.media}
          image={post.mainImage ? post.mainImage.url : null}
          title={apartment}
          alt={apartment}
        />

        <CardContent style={{ textAlign: "Left" }}>
          <Typography variant="subtitle2" component="p" >
            {post.propertyType}
          </Typography>
          <Typography variant="h4" style={{ marginBottom: "5px", marginTop: "5px" }} component="p" >
            {fCurrency(post.price)}
          </Typography>
          <Typography variant="body2" component="p" noWrap>
            {post.mapPin ? post.mapPin.address : post.city}
          </Typography>
          <hr className={classes.solid} style={{ marginBottom: "10px", marginTop: "10px" }} />
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            {(post?.numberOfBedrooms !== null) && (

              <Chip icon={<HotelIcon style={{ color: "#001329" }} />} label={post.numberOfBedrooms} size="small" style={{ backgroundColor: "white" }} />

            )}
            {(post?.numberOfBathrooms !== null) && (

              <Chip icon={<BathtubIcon style={{ color: "#001329" }} />} label={post.numberOfBathrooms} size="small" style={{ backgroundColor: "white" }} />

            )}
            {(post?.surfaceArea !== null) && (

              <Chip icon={<ZoomOutMapIcon style={{ color: "#001329" }} />} label={post.surfaceArea} size="small" style={{ backgroundColor: "white" }} />

            )}

          </Stack>

        </CardContent>
        <CardActions sx={{ mt: "-15px" }} >
          <Button onClick={() => navigate(`/posts/unlock/${post._id}`)} size="medium" sx={{ zIndex: 1111 }} color="primary">
            <Trans i18nKey="description.unlock">
              Unlock
            </Trans>
          </Button>
          <Button onClick={() => deletePost(post._id)} sx={{ zIndex: 1111 }} size="medium" color="error">
            <Trans i18nKey="description.delete">
              Delete
            </Trans>
          </Button>
        </CardActions>
      </Card>
    </>


  );
}


export default BlockedPost;
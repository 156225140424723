import React, { useState } from 'react'
import { Container, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ToggleButtonGroup from "@material-ui/core/ToggleButtonGroup";
import ToggleButton from "@material-ui/core/ToggleButton";
import TextField from "@material-ui/core/TextField";
import { Link as RouterLink } from "react-router-dom";
import UseStyles from "./style";
import Box from "@material-ui/core/Box";
import { useForm, FormProvider } from 'react-hook-form';
import { LoadingButton } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import { Trans, useTranslation } from 'react-i18next';
import { alpha, styled } from '@material-ui/core/styles';
const MUITextField = styled((props) => (
    <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
    '& .MuiFilledInput-root': {
        border: '1px solid #e2e2e1',
        overflow: 'hidden',
        borderRadius: 4,
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&.Mui-focused': {
            backgroundColor: 'transparent',
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },
    },
}));


const PropertyTypes = [

    {
        value: 'Apartment',
        label: 'Apartment',
    },
    {
        value: 'Garden',
        label: 'Garden',
    },
    {
        value: 'Garage',
        label: 'Garage',
    },
    {
        value: 'Office',
        label: 'Office',
    },
    {
        value: 'Villa',
        label: 'Villa',
    },
    {
        value: 'Land',
        label: 'Land',
    },

];

const GetStartedEdit = ({ posts, handleNext, post, handleChange, setPost }) => {
    const handleToggle = (event, newToggle) => {
        setPost({ ...post, listingType: newToggle });
    };


    const classes = UseStyles();

    const { t, i18n } = useTranslation();

    return (

        <div>
            <Container>
                <Grid container justifyContent="center">
                    <Grid>
                        <Typography variant="h4" mt={4} mb={2}>
                            <Trans i18nKey="description.addProp">
                                Edit Your Property
                            </Trans>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid>
                        <Typography variant="body1" mb={4}>
                            <Trans i18nKey="description.startBy">
                                Start by choosing you listing type and property type to continue to the next steps
                            </Trans>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid containerjustifyContent="center">
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <Grid item >
                            <ToggleButtonGroup
                                color="primary"
                                value={post ? post.listingType: null}
                                exclusive
                                onChange={handleToggle}
                            // aria-label="text alignment"
                            >
                                <ToggleButton style={{ width: "100px" }} value="buy" aria-label="left aligned" className={classes.row}>
                                    <Typography>
                                        <Trans i18nKey="description.buyPage">
                                            Buy
                                        </Trans>
                                    </Typography>
                                </ToggleButton>
                                <ToggleButton style={{ width: "100px" }} value="rent" aria-label="centered" className={classes.row}>
                                    <Typography>
                                        <Trans i18nKey="description.rentPage">
                                            Rent
                                        </Trans>
                                    </Typography>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>


                        <Grid item style={{ marginBottom: 20 }}>
                            <MUITextField
                                id="outlined-select-property-type"
                                // className={classes.row}
                                style={{ width: "200px", marginLeft: 10 }}
                                select
                                label={t("description.propType")}
                                value={post ? post.propertyType: null}
                                renderValue={(value) => `${value}`} onChange={handleChange('propertyType')}
                                SelectProps={{
                                    native: true,
                                }}
                                // helperText="Please select your currency"
                                variant="filled"
                            >
                                {PropertyTypes.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </MUITextField>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid>
                        <Typography variant="body1" color="secondary" component={RouterLink} to="/" mt={4} mb={2}>
                            <Trans i18nKey="description.know">
                                Know more about the benefits of listing your property on eAkary
                            </Trans>
                        </Typography>
                    </Grid>
                </Grid>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={() => handleNext()}>
                        <Trans i18nKey="description.next">
                            Next
                        </Trans>
                    </Button>
                </Box>
            </Container>
        </div>
    )
}
export default GetStartedEdit
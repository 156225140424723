import React, { useEffect } from 'react';
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import useState from "react-hook-use-state";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import UseStyles from './style';
import { addLink360, getPost, updatePost } from '../../../actions/posts';
import { useFormik } from "formik";
import { Container, Box, Grid, Typography, Alert, Snackbar, Slide } from "@material-ui/core";

const DialogUpdatePost = ({ open, id, close }) => {
    const dispatch = useDispatch();
    const [openSnack, setSnackOpen] = useState(false);
    const [success, setSuccess] = useState(false);

    const classes = UseStyles();

    const [post, setPost] = useState([]);

    const UpdatePost = async (id) => {
        const success = dispatch(updatePost(id));
    };

    // useEffect(async() => {
    //     const post = await dispatch(getPost(id));
    //     setPost(post);

    // })

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const { i18n } = useTranslation();
    // const [success, setSuccess] = useState(undefined);

    const handleSubmit = async (values) => {

        const post = await dispatch(getPost(id));
        setPost(post);

        const form =
        {
            link360: values.link360
        }
        const success = await dispatch(addLink360(id, form));
        if (success) {
            setSuccess(true);

            setSnackOpen(true);
            setTimeout(function () {
                window.location.reload();
            }, 1000);
        }
        else {
            setSnackOpen(false);
        }

        setSuccess(success)
    };


    const formik = useFormik({
        initialValues: {
            link360: "" || post.link360
        },
        onSubmit: values => {
            handleSubmit(values);
        }
    }
    )

    return (
        <>
            <Dialog
                open={open}
                keepMounted
                onClose={close}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title" sx={{ height: "60px" }}>{"Add 360 Link To This Post"}</DialogTitle>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent className={classes.root}>

                        <DialogContentText id="alert-dialog-slide-description" >

                            <TextField placeholder="360 Link" name="link360" className={classes.root} onChange={formik.handleChange} value={formik.link360} />

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={close} color="primary">
                            <Trans i18nKey="description.cancel">
                                Cancel
                            </Trans>
                        </Button>
                        <Button type="submit" color="primary">
                            {/* <Trans i18nKey="description.delete"> */}
                            Update
                            {/* </Trans> */}
                        </Button>

                    </DialogActions>
                </form>
            </Dialog>
            {
                success ? (
                    <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                        <Alert onClose={handleSnackClose} severity="success">
                            <Trans>
                                360 Link Added Successfully
                            </Trans>
                        </Alert>
                    </Snackbar>
                ) : (
                    <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                        <Alert onClose={handleSnackClose} severity="error">
                            <Trans i18nKey="description.wefound">
                                Couldn't Add 360 Link
                            </Trans>
                        </Alert>
                    </Snackbar>
                )
            }
        </>
    )
}
export default DialogUpdatePost;
import 'typeface-roboto'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from  '@material-ui/core/Grid'
import Button from  '@material-ui/core/Button'
import Box from  '@material-ui/core/Box'
import { Component } from 'react';
import {Link as RouterLink} from 'react-router-dom'
import { experimentalStyled as styled } from '@material-ui/core/styles';

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'fill',
  position: 'absolute'
});

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.down("xs")]: {
        maxWidth: 274,
        marginLeft:0,
    },[theme.breakpoints.up("lg")]: {
        maxWidth: 280,
        boxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
        marginLeft:-40
    }
  }));


class ServiceCard extends Component {
    render(){
    return (
        <>
            <Card>  
            <Box sx={{ pt: '100%', position: 'relative' }}>
                    <ProductImgStyle
                        alt="Service"
                        src={this.props.img}
                        title="Service"
                    />
                    </Box>
                    <CardContent>
                        <Typography gutterBottom align="center" variant="h5" component="h2">
                            {this.props.title}
                        </Typography>
                        <Typography align="center" variant="body1" color="textSecondary" component="p">
                            {this.props.desc}
                        </Typography>
                        <br/>
                        <CardActionArea component={RouterLink} to={this.props.link} >
                        <Typography align="center" variant="button" color="primary" component="p">
                            {this.props.action}
                        </Typography>
                        </CardActionArea>
                    </CardContent>   
            </Card>
            
        </>
    )
}
}
export default ServiceCard
import * as React from 'react';
import { Container, Box, Grid, Typography, Alert, Snackbar, Slide } from "@material-ui/core";
import ProfileField from "../../components/TextFields/TextField";
import ButtonContained from "../../components/Buttons/ButtonContained";
import ContactIm from '../../images/contact us.svg'
import useState from "react-hook-use-state";
import Button from "@material-ui/core/Button";
import { LoadingButton } from "@material-ui/lab";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { contactUs } from "../../actions/contact";
import { Trans, useTranslation } from 'react-i18next';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import Page from '../../components/Page';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import en from 'react-phone-number-input/locale/en.json'
import fr from 'react-phone-number-input/locale/fr.json'
import Cookies from 'js-cookie';
import '../Request360/style.css';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

const RootStyle = styled(Page)(({ theme }) => ({
    paddingBottom: theme.spacing(10)
}));
const ContactUs = () => {
    const { t, i18n } = useTranslation();
    let cookieLanguage = Cookies.get('i18next');
    const [open, setOpen] = useState(false);
    const [openPE, setOpenPE] = useState(false);
    const [LoggedUser, setLoggedUser] = useState(JSON.parse(localStorage.getItem('profile')))
    const [success, setSuccess] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false)
    };
    const handleSnackPEClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenPE(false)
    };
    const handleSubmit = async (values) => {
        setLoading(true)
        const form =
        {
            name: values.name,
            email: values.email,
            description: values.description,
            phoneNumber: value,
        }
        console.log(form);
        const success = await contactUs(form);
        if (success) {
            setLoading(false)
        } else setLoading(false)
        setSuccess(success)
        setOpen(true)
    };
    const validationSchema = Yup.object({
        name: Yup.string()
            .required('Required!'),
        email: Yup.string()
            .email('Invalid email format')
            .required('Required!'),
        description: Yup.string()
            .required('Required!'),
    })
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            description: ''
        },
        onSubmit: values => {
            if (isValid) {
                handleSubmit(values)
            } else {
                setOpenPE(true)
            }
        },
        validationSchema,
    })
    const handlePhoneChange = (status, phoneNumber, country) => {
        setValue('+' + country.dialCode + phoneNumber)
        setIsValid(status);
    };
    const [value, setValue] = useState({})
    const [isValid, setIsValid] = useState(false)
    return (
        <RootStyle title="Contact Us | eAkary">
            <Container>
                <Grid container justifyContent='center'>
                    <Grid>
                        <Typography variant="h3" mt={4} mb={4}>
                            <Trans i18nKey="description.getintouch">
                                Get In Touch With Us
                            </Trans>
                        </Typography>
                    </Grid>
                </Grid>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container rowSpacing={1} spacing={5} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={6} md={5} lg={5} direction='column' >
                            <Box
                                component="img"
                                src={ContactIm}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} direction='column' columnSpacing={5}>
                            <div style={{ marginBottom: 20 }}>
                                <ProfileField onBlur={formik.handleBlur} handleChange={formik.handleChange} value={formik.values.name} name='name' placeholder={t("description.name")} label={t("description.name")} />
                                {formik.touched.name && formik.errors.name ? <span style={{ fontSize: '15px' }}>  <div style={{ color: 'red' }}>{formik.errors.name}</div></span> : null}
                            </div>
                            <div style={{ marginBottom: 20 }}>
                                <ProfileField onBlur={formik.handleBlur} handleChange={formik.handleChange} value={formik.values.email} name='email' placeholder="example@me.com" type='email' label={t("description.emailAdd")} />
                                {formik.touched.email && formik.errors.email ? <span style={{ fontSize: '15px' }}>  <div style={{ color: 'red' }}>{formik.errors.email}</div> </span> : null}
                            </div>
                            <div style={{ marginBottom: 20 }}>
                                <IntlTelInput
                                    containerClassName="intl-tel-input"
                                    inputClassName="form-control"
                                    variant="contained"
                                    type="tel"
                                    value={value.phoneNumber}
                                    onPhoneNumberChange={handlePhoneChange}
                                    preferredCountries={['lb']}
                                />
                                {/* {cookieLanguage === 'en' ?
                                    (
                                        <PhoneInput
                                            sx={{ height: '56px' }}
                                            variant="contained"
                                            value={value}
                                            onChange={setValue}
                                            labels={en}
                                            rules={{ required: true }}
                                        />
                                    ) : (
                                        <PhoneInput
                                            sx={{ height: '56px' }}
                                            variant="contained"
                                            value={value}
                                            onChange={setValue}
                                            labels={fr}
                                            rules={{ required: true }}
                                        />
                                    )} */}
                            </div>
                            {/* <div style={{ marginBottom: 20 }}>
                                <ProfileField onBlur={formik.handleBlur} handleChange={formik.handleChange} value={formik.values.phoneNumber} name='phoneNumber' label={t("description.phone")} />
                                {formik.touched.phoneNumber && formik.errors.phoneNumber ? <span style={{ fontSize: '15px' }}>  <div style={{ color: 'red' }}>{formik.errors.phoneNumber}</div> </span> : null}
                            </div> */}
                            <div style={{ marginBottom: 20 }}>
                                <ProfileField onBlur={formik.handleBlur} handleChange={formik.handleChange} value={formik.values.description} name='description' placeholder={t("description.leaveCmt")} label={t("description.desc")} multi={true} rows={5} />
                                {formik.touched.description && formik.errors.description ? <span style={{ fontSize: '15px' }}>  <div style={{ color: 'red' }}>{formik.errors.description}</div> </span> : null}
                            </div>
                            <LoadingButton
                                size="large"
                                type="submit"
                                style={{ width: "100%" }}
                                variant="contained"
                                loading={loading}
                            >
                                <Trans i18nKey="description.send">
                                    Send
                                </Trans>
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
                {
                    success !== undefined ? (
                        success ? (
                            <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                                <Alert onClose={handleSnackClose} severity="success">
                                    <Trans i18nKey="description.dear">Dear</Trans> <Trans i18nKey="description.wewill">we will reach you back as fast as possible</Trans>
                                </Alert>
                            </Snackbar>
                        ) : (
                            <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                                <Alert onClose={handleSnackClose} severity="error">
                                    <Trans i18nKey="description.thereis">
                                        There is something wrong , your request is lost please try again
                                    </Trans>
                                </Alert>
                            </Snackbar>
                        )) : null
                }
                <Snackbar open={openPE} autoHideDuration={6000} onClose={handleSnackPEClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                    <Alert onClose={handleSnackPEClose} severity="error">
                        Wrong phone number
                    </Alert>
                </Snackbar>
            </Container>
        </RootStyle>
    )
}
export default ContactUs;
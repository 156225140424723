import React from 'react'
import {Container} from '@material-ui/core'
import Map from "../../components/MapBuy/Map";

const BuyMap = () => {
    return (
        
            <Map />
      
    )
}

export default BuyMap
import React, { useState, useEffect } from 'react'
import House from './House'
import Land from './Land'
import OfficeAndGarage from './OfficeAndGarage'
import { useSelector, useDispatch } from 'react-redux';
import { Grid, CircularProgress } from '@material-ui/core';
import { getWishlist } from '../../actions/posts';
import { useNavigate } from 'react-router-dom';
const Posts = ({ page, setIsSnackBarOpen }) => {
    const navigate = useNavigate();
    const { posts, isLoading, numberOfPages } = useSelector((state) => state.posts);  //callback funcion .posts because in the reducers we called posts
    const dispatch = useDispatch();
    const [LoggedUser, setLoggedUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const [wishlist, setWishlist] = useState([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        // const id = LoggedUser.result._id;
        if (LoggedUser !== null) {
            const id = LoggedUser.result._id;
            const wishlist = await dispatch(getWishlist(id));
            setWishlist(wishlist);
        }
    }, [dispatch]);
    const numberOfProperties = posts.length;
    const startIndex = 6 * (page - 1);
    const endIndex = startIndex + 6;
    let sortedPosts = posts;
    // if (toSort && sortByIncreasingOrder) {
    //     sortedPosts = posts.sort((post1, post2) => { return post2.price - post1.price })
    // } else if (toSort) {
    //     sortedPosts = posts.sort((post1, post2) => { return post1.price - post2.price })
    // }
    if (posts.length > 0) {
        return isLoading ?
            (<div class="d-flex justify-content-center">
                <CircularProgress />
            </div>
            ) : (
                <div>
                    {posts.map(post => {
                        if (post.propertyType === 'Apartment' || post.propertyType === "Villa") {
                            return <House setIsSnackBarOpen={setIsSnackBarOpen} liked={JSON.stringify(wishlist).includes(post._id)}
                                onClick={() => navigate(`/posts/${post._id}`)} numberOfRooms={post.numberOfRooms} bundleChosen={post.bundleChosen}
                                building={post.building} address={post.mapPin ? post.mapPin.address : post.city} _id={post._id} key={post._id}
                                price={post.price} isForSale={post.listingType === 'buy'} mainImage={post.mainImage} numberOfBedrooms={post.numberOfBedrooms}
                                numberOfBathrooms={post.numberOfBathrooms} surfaceArea={post.surfaceArea} phoneNumber={post.phoneNumber} email={post.email} />
                        } else if (post.propertyType === "Land" || post.propertyType === "Garden") {
                            return <Land setIsSnackBarOpen={setIsSnackBarOpen} liked={JSON.stringify(wishlist).includes(post._id)}
                                onClick={() => navigate(`/posts/${post._id}`)} bundleChosen={post.bundleChosen} address={post.mapPin ? post.mapPin.address : post.city}
                                _id={post._id} key={post._id} price={post.price} isForSale={post.listingType === 'buy'} mainImage={post.mainImage}
                                altitude={post.altitude ? post.altitude : '1000'} surfaceArea={post.surfaceArea} phoneNumber={post.phoneNumber} email={post.email} />
                        } else if (post.propertyType === "Garage" || post.propertyType === "Office") {
                            return <OfficeAndGarage setIsSnackBarOpen={setIsSnackBarOpen} liked={JSON.stringify(wishlist).includes(post._id)}
                                onClick={() => navigate(`/posts/${post._id}`)} bundleChosen={post.bundleChosen} address={post.mapPin ? post.mapPin.address : post.city}
                                _id={post._id} key={post._id} price={post.price} isForSale={post.listingType === 'buy'} mainImage={post.mainImage}
                                surfaceArea={post.surfaceArea} phoneNumber={post.phoneNumber} email={post.email} />
                        }
                    })
                    }
                </div>
            )
    } else if (!isLoading) { return <span>No results found</span> }
    return null;
};
export default Posts;


import Router from './routes';
import ThemeConfig from './theme';


const App = () => {
  return (
    <ThemeConfig>
      <Router />
    </ThemeConfig>

  )
};

export default App;

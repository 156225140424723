
import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { Icon } from '@iconify/react';
import clockFill from '@iconify-icons/eva/clock-fill';
// material
import {
    Box,
    List,
    Avatar,
    Divider,
    ListItem,
    Typography,
    ListItemText,
    ListItemAvatar,
    ListSubheader,
    Grid
} from '@material-ui/core';

import { useDispatch } from "react-redux";
import { getUser } from '../../../actions/users';
import { getPost, updateNotif } from '../../../actions/posts';

const NotificationItem = ({ notifs }) => {



    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [user, setUser] = useState({});
    const [post, setPost] = useState({});

    useEffect(async () => {
        // const id = LoggedUser.result._id;

        if (notifs.user != null && notifs.post != null) {
            const users = await dispatch(getUser(notifs.user));

            setUser(users);

            const posts = await dispatch(getPost(notifs.post));

            setPost(posts);
        }

        else if (notifs.email != undefined) {
        }
    }, [dispatch]);


    const handleNotificationPost = async (id) => {
        if (notifs.post !== undefined) {
            window.location.href = `/posts/${notifs.post}`;
        }

        notifs.isRead = true;

        await dispatch(updateNotif(id, { isRead: true }));
    };

    const [week, setWeek] = useState(false);

    const dateNow = new Date();

    if (notifs.post != undefined) {
        const dateCreated = new Date(post.createdAt);

        if (dateCreated != "Invalid Date") {
            const newCreatedDate = dateCreated.toISOString().substring(0, 10);

            var str = newCreatedDate;
        }
        else {
        }
    }

    else {
        const dateCreatedNotif = new Date(notifs.createdAt);

        if (dateCreatedNotif != "Invalid Date") {
            const newCreatedDate = dateCreatedNotif.toISOString().substring(0, 10);

            var notifStr = newCreatedDate;
        }
        else {
        }
    }



    return (
        <>
            {
                notifs.isRead !== true ? (
                    <List sx={{ marginLeft: "15%" }}>
                        {
                            notifs.request360 != true ? (
                                <ListItem
                                    button
                                    to="#"
                                    disableGutters
                                    component={RouterLink}
                                    sx={{
                                        py: 1.5,
                                        px: 2.5,
                                        mt: '1px',
                                        backgroundColor: "#D3D3D3"
                                    }}
                                    onClick={() => handleNotificationPost(notifs._id)}
                                >
                                    <ListItemAvatar>
                                        <Avatar sx={{ bgcolor: 'background.neutral' }}><img alt="hi" src="/static/icons/ic_notification_mail.svg" /></Avatar>
                                    </ListItemAvatar>
                                    {/* <ListItemText
                                primary={
                                    <Typography variant="subtitle2">
                                        {notifs.email} / {notifs.phoneNumber}
                                    </Typography>
                                }
                                secondary={
                                    <div>
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                mt: 0.5,
                                                display: 'flex',
                                                alignItems: 'center',
                                                color: 'text.disabled'
                                            }}
                                        >
                                            <Typography component="span" variant="body2" sx={{ color: 'text.primary' }}>
                                                requested 360 tour for their property. <br />
                                                Message: {notifs.message}
                                            </Typography>
                                        </Typography>
                                        {/* <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} /> {str} */}
                                    {/* </div> */}
                                    {/* } */}
                                    {/* /> */}
                                    {
                                        notifs.user != null && notifs.post != null ? (
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle2">
                                                        {user.email}
                                                    </Typography>
                                                }
                                                secondary={
                                                    <div>
                                                        <Typography
                                                            variant="caption"
                                                            sx={{
                                                                mt: 0.5,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                color: 'text.disabled'
                                                            }}
                                                        >
                                                            {/* {
                                        post === undefined ? (
                                            <><Typography component="span" variant="body2" sx={{ color: 'text.primary' }}>
                                                requested 360 tour for their property. <br />
                                                Message: {notifs.message}
                                            </Typography></>
                                        ) : ( */}
                                                            <Typography component="span" variant="body2" sx={{ color: 'text.primary' }}>
                                                                got the <b><i>{post.bundleChosen}</i></b> bundle for <b>{post.title}</b>
                                                            </Typography>
                                                            {/* )
                                    } */}
                                                        </Typography>
                                                        <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} /> {str}
                                                    </div>
                                                }
                                            />
                                        ) : (
                                            <>User or Post Not Found</>
                                        )
                                    }
                                </ListItem>

                            ) : (

                                <ListItem
                                    button
                                    to="#"
                                    disableGutters
                                    component={RouterLink}
                                    sx={{
                                        py: 1.5,
                                        px: 2.5,
                                        mt: '1px',
                                        backgroundColor: "#D3D3D3"
                                    }}
                                    onClick={() => handleNotificationPost(notifs._id)}
                                >
                                    <ListItemAvatar>
                                        <Avatar sx={{ bgcolor: 'background.neutral' }}><img alt="hi" src="/static/icons/ic_notification_mail.svg" /></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography variant="subtitle2">
                                                {notifs.email} / phone: {notifs.phoneNumber}
                                            </Typography>
                                        }
                                        secondary={
                                            <div>
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        mt: 0.5,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        color: 'text.disabled'
                                                    }}
                                                >
                                                    <Typography component="span" variant="body2" sx={{ color: 'text.primary' }}>
                                                        requested 360 tour for their property. <br />
                                                        Message: {notifs.message}
                                                    </Typography>
                                                </Typography>
                                                <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} /> {notifStr}
                                            </div>
                                        }
                                    />

                                </ListItem>
                            )
                        }
                        <Divider component="li" />
                    </List>
                ) : (
                    <List sx={{ marginLeft: "15%" }}>
                        {
                            notifs.request360 != true ? (
                                <ListItem
                                    button
                                    to="#"
                                    disableGutters
                                    component={RouterLink}
                                    sx={{
                                        py: 1.5,
                                        px: 2.5,
                                        mt: '1px',
                                        backgroundColor: "white"
                                    }}
                                // onClick={() => handleNotificationPost(notifs._id)}
                                >
                                    <ListItemAvatar>
                                        <Avatar sx={{ bgcolor: 'background.neutral' }}><img alt="hi" src="/static/icons/ic_notification_mail.svg" /></Avatar>
                                    </ListItemAvatar>
                                    {/* <ListItemText
                                primary={
                                    <Typography variant="subtitle2">
                                        {notifs.email} / {notifs.phoneNumber}
                                    </Typography>
                                }
                                secondary={
                                    <div>
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                mt: 0.5,
                                                display: 'flex',
                                                alignItems: 'center',
                                                color: 'text.disabled'
                                            }}
                                        >
                                            <Typography component="span" variant="body2" sx={{ color: 'text.primary' }}>
                                                requested 360 tour for their property. <br />
                                                Message: {notifs.message}
                                            </Typography>
                                        </Typography>
                                        {/* <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} /> {str} */}
                                    {/* </div> */}
                                    {/* } */}
                                    {/* /> */}
                                    {
                                        notifs.user != null && notifs.post != null ? (
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle2">
                                                        {user.email}
                                                    </Typography>
                                                }
                                                secondary={
                                                    <div>
                                                        <Typography
                                                            variant="caption"
                                                            sx={{
                                                                mt: 0.5,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                color: 'text.disabled'
                                                            }}
                                                        >
                                                            {/* {
                                        post === undefined ? (
                                            <><Typography component="span" variant="body2" sx={{ color: 'text.primary' }}>
                                                requested 360 tour for their property. <br />
                                                Message: {notifs.message}
                                            </Typography></>
                                        ) : ( */}
                                                            <Typography component="span" variant="body2" sx={{ color: 'text.primary' }}>
                                                                got the <b><i>{post.bundleChosen}</i></b> bundle for <b>{post.title}</b>
                                                            </Typography>
                                                            {/* )
                                    } */}
                                                        </Typography>
                                                        <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} /> {str}
                                                    </div>
                                                }
                                            />
                                        ) : (
                                            <>User or Post Not Found</>
                                        )
                                    }
                                </ListItem>

                            ) : (

                                <ListItem
                                    button
                                    to="#"
                                    disableGutters
                                    component={RouterLink}
                                    sx={{
                                        py: 1.5,
                                        px: 2.5,
                                        mt: '1px',
                                        backgroundColor: "white"
                                    }}
                                    onClick={() => handleNotificationPost(notifs._id)}
                                >
                                    <ListItemAvatar>
                                        <Avatar sx={{ bgcolor: 'background.neutral' }}><img alt="hi" src="/static/icons/ic_notification_mail.svg" /></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography variant="subtitle2">
                                                {notifs.email} / phone: {notifs.phoneNumber}
                                            </Typography>
                                        }
                                        secondary={
                                            <div>
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        mt: 0.5,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        color: 'text.disabled'
                                                    }}
                                                >
                                                    <Typography component="span" variant="body2" sx={{ color: 'text.primary' }}>
                                                        requested 360 tour for their property. <br />
                                                        Message: {notifs.message}
                                                    </Typography>
                                                </Typography>
                                                <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} /> {notifStr}
                                            </div>
                                        }
                                    />

                                </ListItem>
                            )
                        }
                        <Divider component="li" />
                    </List>
                )
            }

        </>
    )
}

export default NotificationItem;
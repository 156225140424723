import React, { useMemo, useState } from 'react'
import { Container, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import UseStyles from "./style";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import InputAdornment from '@material-ui/core/InputAdornment';

import Button from "@material-ui/core/Button";
import { LoadingButton } from "@material-ui/lab";
import { Trans, useTranslation } from 'react-i18next';
import { alpha, styled } from '@material-ui/core/styles';
const MUITextField = styled((props) => (
    <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
    '& .MuiFilledInput-root': {
        border: '1px solid #e2e2e1',
        overflow: 'hidden',
        borderRadius: 4,
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&.Mui-focused': {
            backgroundColor: 'transparent',
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },

    },
}));

const heatingTypes = [
    {
        value: '',
        label: '',
    },
    {
        value: 'Boilers',
        label: 'Boilers',
    },
    {
        value: 'Furnaces',
        label: 'Furnaces',
    },
    {
        value: 'Heat-Pumps',
        label: 'Heat Pumps',
    },
    {
        value: 'Gas-Fired-Space-Heaters',
        label: 'Gas-Fired Space Heaters',
    },
    {
        value: 'Wood-Burning-and-Pellet-Stoves',
        label: 'Wood-Burning and Pellet Stoves',
    },
    {
        value: 'Fireplaces',
        label: 'Fireplaces',
    },
    {
        value: 'Radiant-Floor-Heat',
        label: 'Radiant Floor Feat',
    },
];

const coolingTypes = [
    {
        value: '',
        label: '',
    },
    {
        value: 'Central-Air',
        label: 'Central Air',
    },
    {
        value: 'Room-Air-Conitioners',
        label: 'Room Air Conditioners',
    },
    {
        value: 'Evaporative-Coolers',
        label: 'Evaporative Coolers',
    },
    {
        value: 'State-of-the-Art-Cooling',
        label: 'State of the Art Cooling',
    },
];


const AdditionalFeatures = ({ handleBack, handleNext, values, handleChange, setPost, currentId }) => {
    const classes = UseStyles();
    const handleCheckChange = (event) => {
        setPost({ ...values, [event.target.name]: event.target.checked });
    };

    //////////////////////////////////////
    const { t, i18n } = useTranslation();

    return (
        <div>
            <Container>
                <form onSubmit={() => handleNext()}>
                    <Grid sx={{ mt: 5 }} container direction="row">
                        <Grid item xs={12} lg={6} align="center">
                            {values.propertyType === 'Apartment' ||
                                values.propertyType === 'Villa' ||
                                values.propertyType === 'Office' ? (
                                <Grid container direction="column" item xs={12} lg={6}>
                                    <Typography justify="flex-start" className={classes.dislpayflex} variant="h4"
                                        component="h2" gutterBottom>
                                        <Trans i18nKey="description.interior">
                                            Interior
                                        </Trans>
                                    </Typography>
                                    <MUITextField
                                        value={values.numberOfRooms} onChange={handleChange('numberOfRooms')}
                                        id="numberofRooms"
                                        className={classes.row}
                                        label={t("description.nbrOfRooms")}
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        required
                                        variant="filled"
                                    />
                                    {values.propertyType !== 'Office' ? (
                                        <MUITextField
                                            value={values.numberOfBedrooms} onChange={handleChange('numberOfBedrooms')}
                                            id="numberofBedrooms"
                                            className={classes.row}
                                            label={t("description.nbrOfBedrooms")}
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            rerquired
                                            variant="filled"
                                        />) : null
                                    }
                                    <MUITextField
                                        value={values.numberOfBathrooms} onChange={handleChange('numberOfBathrooms')}
                                        id="numberofBathrooms"
                                        className={classes.row}
                                        label={t("description.nbrOfBathrooms")}
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        required
                                        variant="filled"
                                    />
                                    <MUITextField
                                        value={values.kitchenArea} onChange={handleChange('kitchenArea')}
                                        label={t("description.kitchenArea")}
                                        className={classes.row}
                                        variant="filled"
                                        type="number"
                                        name="kitchenArea"
                                        id="kitchenArea"
                                        InputProps={{ startAdornment: <InputAdornment position="start">sqft</InputAdornment>, }}
                                        required />
                                </Grid>
                            ) : null
                            }
                            {values.propertyType === 'Apartment' ||
                                values.propertyType === 'Villa' ||
                                values.propertyType === 'Office' ? (
                                <Grid container direction="column" item xs={12} lg={6} sx={{ mt: 5, mb: 5 }}>
                                    <Typography className={classes.dislpayflex} variant="h4" component="h2" gutterBottom>
                                        <Trans i18nKey="description.exterior">
                                            Exterior
                                        </Trans>
                                    </Typography>
                                    <FormControl component="fieldset">
                                        <FormGroup aria-label="position" column>
                                            <FormControlLabel

                                                control={<Checkbox
                                                    checked={values.parkingSpace}
                                                    onChange={handleCheckChange}
                                                    color="primary" />}
                                                label={t("description.parkingSpace")}
                                                name="parkingSpace"
                                                labelPlacement="end"
                                            />
                                            {values.propertyType !== 'Office' ? (
                                                <FormControlLabel

                                                    control={<Checkbox
                                                        checked={values.dwelling}
                                                        onChange={handleCheckChange} color="primary" />}
                                                    label={t("description.dwelling")}
                                                    name="dwelling"
                                                    labelPlacement="end"
                                                />) : null
                                            }
                                            <FormControlLabel

                                                control={<Checkbox
                                                    checked={values.brickRoof}
                                                    onChange={handleCheckChange} color="primary" />}
                                                label={t("description.brickRoof")}
                                                name="brickRoof"
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel


                                                control={<Checkbox
                                                    checked={values.outBuildings}
                                                    onChange={handleCheckChange} color="primary" />}
                                                label={t("description.Outbuildings")}
                                                name="outBuildings"
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel

                                                control={<Checkbox
                                                    checked={values.caretakerConcierge}
                                                    onChange={handleCheckChange} color="primary" />}
                                                label={t("description.caretaker")}
                                                name="caretakerConcierge"
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel

                                                control={<Checkbox
                                                    checked={values.garden}
                                                    onChange={handleCheckChange} color="primary" />}
                                                label={t("description.garden")}
                                                name="garden"
                                                labelPlacement="end"
                                            />
                                            {values.propertyType !== 'Office' ? (
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        checked={values.swimmingPool}
                                                        onChange={handleCheckChange} color="primary" />}
                                                    label={t("description.pool")}
                                                    name="swimmingPool"
                                                    labelPlacement="end"
                                                />) : null
                                            }
                                            <FormControlLabel
                                                control={<Checkbox
                                                    checked={values.terrace}
                                                    onChange={handleCheckChange} color="primary" />}
                                                label={t("description.terrace")}
                                                name="terrace"
                                                labelPlacement="end"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>) : values.propertyType === 'Land' ? (
                                    <Grid container direction="column" item xs={12} lg={6} sx={{ mt: 5, mb: 5 }}>
                                        <Typography className={classes.dislpayflex} variant="h4" component="h2" gutterBottom>
                                            <Trans i18nKey="description.land">
                                                Land
                                            </Trans>
                                        </Typography>
                                        <FormControl component="fieldset">
                                            <FormGroup aria-label="position" column>
                                                <FormControlLabel

                                                    control={<Checkbox
                                                        checked={values.facingStreet}
                                                        onChange={handleCheckChange}
                                                        color="primary" />}
                                                    label={t("description.facingStreet")}
                                                    name="facingStreet"
                                                    labelPlacement="end"
                                                />
                                                <FormControlLabel

                                                    control={<Checkbox
                                                        checked={values.inTheWood}
                                                        onChange={handleCheckChange} color="primary" />}
                                                    label={t("description.intheWoods")}
                                                    name="inTheWood"
                                                    labelPlacement="end"
                                                />
                                                <FormControlLabel

                                                    control={<Checkbox
                                                        checked={values.flatLand}
                                                        onChange={handleCheckChange} color="primary" />}
                                                    label={t("description.flatLand")}
                                                    name="flatLand"
                                                    labelPlacement="end"
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                ) : null
                            }
                        </Grid>

                        <Grid item xs={12} lg={6} align="center">
                            {values.propertyType !== 'Land' ? (
                                <Grid container direction="column" item xs={12} lg={6}>
                                    <Typography className={classes.dislpayflex} variant="h4" component="h2" gutterBottom>
                                        <Trans i18nKey="description.additionalFeatures">
                                            Additional Features
                                        </Trans>
                                    </Typography>
                                    {values.propertyType === 'Apartment' ||
                                        values.propertyType === 'Villa' ||
                                        values.propertyType === 'Office' ? (
                                        <>
                                            <MUITextField
                                                id="heatingType"
                                                className={classes.row}
                                                select
                                                label={t("description.heating")}
                                                renderValue={(value) => `${value}`}
                                                value={values.heatingType}
                                                onChange={handleChange('heatingType')}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                helperText={t("description.heatingH")}
                                                variant="filled"
                                            >
                                                {heatingTypes.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </MUITextField>
                                            <MUITextField
                                                id="coolingType"
                                                className={classes.row}
                                                select
                                                label={t("description.cooling")}
                                                renderValue={(value) => `${value}`}
                                                value={values.coolingType}
                                                onChange={handleChange('coolingType')}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                helperText={t("description.coolingH")}
                                                variant="filled"
                                            >
                                                {coolingTypes.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </MUITextField>
                                        </>
                                    ) : null
                                    }
                                    <FormControl component="fieldset">
                                        <FormGroup aria-label="position" column>
                                            {values.propertyType !== 'Garden' ? (
                                                <FormControlLabel

                                                    control={<Checkbox
                                                        checked={values.elevator}
                                                        onChange={handleCheckChange} color="primary" />}
                                                    label={t("description.elevator")}
                                                    name="elevator"
                                                    labelPlacement="end"
                                                />
                                            ) : null
                                            }
                                            {values.propertyType !== 'Garage' ? (
                                                <>
                                                    <FormControlLabel

                                                        control={<Checkbox
                                                            checked={values.furnished}
                                                            onChange={handleCheckChange} color="primary" />}
                                                        label={t("description.furnished")}
                                                        name="furnished"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel

                                                        control={<Checkbox
                                                            checked={values.internetAccess}
                                                            onChange={handleCheckChange} color="primary" />}
                                                        label={t("description.internet")}
                                                        name="internetAccess"
                                                        labelPlacement="end"
                                                    />
                                                </>
                                            ) : null
                                            }
                                            {values.propertyType !== 'Garage' || values.propertyType !== 'Garden' ? (
                                                <FormControlLabel

                                                    control={<Checkbox
                                                        checked={values.videoPhone}
                                                        onChange={handleCheckChange} color="primary" />}
                                                    label={t("description.videoP")}
                                                    name="videoPhone"
                                                    labelPlacement="end"
                                                />
                                            ) : null
                                            }
                                            <FormControlLabel

                                                control={<Checkbox
                                                    checked={values.specialNeedsAccessibility}
                                                    onChange={handleCheckChange} color="primary" />}
                                                label={t("description.sna")}
                                                name="specialNeedsAccessibility"
                                                labelPlacement="end"
                                            />
                                            {values.propertyType === 'Office' ? (
                                                <>
                                                    <FormControlLabel

                                                        control={<Checkbox
                                                            checked={values.secureAccess}
                                                            onChange={handleCheckChange} color="primary" />}
                                                        label={t("description.secureAccess")}
                                                        name="secureAccess"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel

                                                        control={<Checkbox
                                                            checked={values.showRoom}
                                                            onChange={handleCheckChange} color="primary" />}
                                                        label={t("description.showRoom")}
                                                        name="showRoom"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel

                                                        control={<Checkbox
                                                            checked={values.receptionDesk}
                                                            onChange={handleCheckChange} color="primary" />}
                                                        label={t("description.receptionDesk")}
                                                        name="receptionDesk"
                                                        labelPlacement="end"
                                                    />
                                                </>
                                            ) : null
                                            }
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                            ) : null
                            }
                            {/* </Grid>
              <Grid item xs={12} lg={6}> */}
                            <Grid container direction="column" item xs={12} lg={6}>
                                <Typography className={classes.dislpayflex} sx={{ mt: 5 }} variant="h4" component="h2" gutterBottom>
                                    <Trans i18nKey="description.permits">
                                        Permits
                                    </Trans>
                                </Typography>
                                <FormControl component="fieldset">
                                    <FormGroup aria-label="position" column>
                                        <FormControlLabel

                                            control={<Checkbox
                                                checked={values.pets}
                                                onChange={handleCheckChange} color="primary" />}
                                            label={t("description.pets")}
                                            name="pets"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel

                                            control={<Checkbox
                                                checked={values.smoking}
                                                onChange={handleCheckChange} color="primary" />}
                                            label={t("description.smoking")}
                                            name="smoking"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel

                                            control={<Checkbox color="primary" />}
                                            checked={values.bigParties}
                                            onChange={handleCheckChange}
                                            label={t("description.bigP")}
                                            name="bigParties"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel

                                            control={<Checkbox
                                                checked={values.loudMusic}
                                                onChange={handleCheckChange} color="primary" />}
                                            label={t("description.loudM")}
                                            name="loudMusic"
                                            labelPlacement="end"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>

                        </Grid >
                    </Grid>
                    <React.Fragment>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="inherit"
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                <Trans i18nKey="description.back">
                                    Back
                                </Trans>
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <LoadingButton
                                size="large"
                                type="submit"
                            >

                                <Trans i18nKey="description.next">
                                    Next
                                </Trans>
                            </LoadingButton>
                        </Box>
                    </React.Fragment>
                </form>
            </Container>
        </div>
    )
}
export default AdditionalFeatures
import { START_LOADING, END_LOADING, FETCH_ALL, UPDATE_IMGAD, FETCH_IMGAD, FETCH_AD, FETCH_BY_SEARCH, CREATE, UPDATE, DELETE, LIKE, ADD_TO_WISHLIST, GET_WISHLIST, REMOVE_FROM_WISHLIST, FETCH_POST_ADS } from '../constants/actionTypes';
import * as api from '../api/index.js';


export const createImgAd = (id, imgad) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.createImgAd(imgad, id);
    dispatch({ type: CREATE, payload: data });
    dispatch({ type: END_LOADING });
    return true;
  } catch (error) {
    return false;
  }
};

export const getAd = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const { data } = await api.fetchImgAd(id);
    dispatch({ type: FETCH_IMGAD, payload: { imgad: data } });
    return data;
  } catch (error) {
  }
};

export const getAllPostImgAds = (id) => async (dispatch) => {
  try {
    //dispatch({ type: START_LOADING });

    const { data } = await api.getPostImgAds(id);
    //dispatch({ type: FETCH_IMGAD, payload: { imgad: data } });
    //dispatch({ type: END_LOADING });
    return data;
  } catch (error) {
  }
};


export const deleteImgAd = (id) => async (dispatch) => {
  try {
    await await api.deleteImgAd(id);

    dispatch({ type: DELETE, payload: id });
  } catch (error) {
  }
};
export const editAd = (id, ad) => async (dispatch) => {
  try {
    const { data } = await api.editImgAd(id, ad);
    dispatch({ type: UPDATE_IMGAD, payload: data });
    return true;
  } catch (error) {
    return false;
  }
};
import React from 'react';
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import useState from "react-hook-use-state";
import { deleteAccount, updateUser } from "../../actions/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next';
import { deleteCMSPost, getMyPosts } from '../../actions/posts';
import axios from 'axios';

const DialogLogin = ({ open, id, close }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const deletePost = async (id) => {
    //     dispatch(deleteCMSPost(id));

    //     window.location.reload();

    // };

    const { i18n } = useTranslation();

    return (
        <Dialog
            open={open}
            keepMounted
            onClose={close}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{"Add To Favorites?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <Trans i18nKey="description.plzlogin">
                        Please Login to your account to enable add to favorites button
                    </Trans>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color="primary">
                    <Trans i18nKey="description.cancel">
                        Cancel
                    </Trans>
                </Button>
                <Button onClick={() => navigate("/auth/login")} color="primary">
                    <Trans i18nKey="description.loginPage">
                        Login
                    </Trans>
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default DialogLogin;
import React from 'react';
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import useState from "react-hook-use-state";
import { deleteAccount, updateUser } from "../../actions/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next';

const DialogDelete = ({ open, Transition, handleClose }) => {
    const [LoggedUser, setLoggedUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const DeleteAccount = async () => {
        setLoading(true)
        const id = LoggedUser.result._id
        const succ = await dispatch(deleteAccount(id));
        if (succ) {
            setLoading(false)
            navigate('/auth/login');
        } else setLoading(false)
    };

    //////////////////////////////////////
    const { t, i18n } = useTranslation();

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{"Delete Account?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <Trans i18nKey="description.deleteAcc">
                        Are you sure you want to delete your account as there is no going back
                    </Trans>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    <Trans i18nKey="description.cancel">
                        Cancel
                    </Trans>
                </Button>
                {loading ? (
                    <CircularProgress sx={{ marginBottom: 1 }} />
                ) : (
                    <Button onClick={DeleteAccount} color="primary">
                        <Trans i18nKey="description.delete">
                            Delete
                        </Trans>
                    </Button>
                )
                }
            </DialogActions>
        </Dialog>
    )
}
export default DialogDelete;
import * as React from 'react';
import { styled } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/core/ToggleButton';
import ToggleButtonGroup from '@material-ui/core/ToggleButtonGroup';
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button';
import Stack from '@material-ui/core/Stack';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Trans, useTranslation } from 'react-i18next';
import { MHidden } from '../@material-extend';


const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  width: '100%',
  justifyContent: 'space-around',
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    color: 'black',
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
    '&.Mui-selected': {
      borderRadius: '10px',
      background: 'white',
      border: '1px solid rgba(26, 157, 230)',
      color: 'black',
    },
    '&.MuiToggleButton-root': {
      '&:hover': {
        borderRadius: '10px',
        boxShadow: '0px 0px 10px 1px rgba(0,0,0,1)',
        transition: 'box-shadow 200ms ease-in-out',
        background: 'white',
        color: 'black',

      },
    },
  },
}));



export default function CustomizedDividers({ bundle, setBundle, post, setPost, dateNow, expireDate }) {
  const [alignment, setAlignment] = React.useState('Basic');

  const handleToggle = (event, newToggle) => {
    setBundle(newToggle);
    setAlignment(newToggle);
    if (newToggle === 'Gold' || newToggle === 'Platinum') {
      setPost({ ...post, featured: true });



    } else setPost({ ...post, featured: false })
  };

  //////////////////////////////////////
  const { t, i18n } = useTranslation();
  {
    if (dateNow > expireDate) {
      return (

        <Container>

          <MHidden width="mdUp">
            <Grid container justifyContent="center">
              <StyledToggleButtonGroup
                size="small"
                value={bundle}
                exclusive
                orientation="vertical"
                onChange={handleToggle}
                aria-label="text alignment"
              >

                <ToggleButton value="Basic">
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                  >
                    <CardContent>
                      <Typography variant="h5" color="secondary" gutterBottom>
                        <Trans i18nKey="description.basic">
                          Basic
                        </Trans>
                      </Typography>
                      <Typography variant="body2" color="text.secondary" component="h2">
                        <Trans i18nKey="description.lessReach">
                          Less Reach to Potential Tenants
                        </Trans>
                      </Typography>

                    </CardContent>
                    <List>
                      <ListItem>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="List property"
                        />
                      </ListItem>


                      <ListItem divider>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="15 pictures"
                        />
                      </ListItem>
                    </List>
                    <Typography variant="h5" sx={{
                      mb: 2, fontWeight: "bold"
                    }}>LBP 100 000</Typography>
                    <Button variant="contained" sx={{ width: "100%", mt: 4 }} color="primary" disableElevation>
                      {alignment === "Basic" ? `Selected` : 'Choose'}
                    </Button>
                  </Stack>
                </ToggleButton>
                <Divider />
                <ToggleButton value="Gold">
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                  >
                    <CardContent>
                      <Typography variant="h5" color="secondary" gutterBottom>
                        Gold
                      </Typography>
                      <Typography variant="body2" color="text.secondary" component="h2">
                        <Trans i18nKey="description.moreReach">
                          More Reach to Potential Tenants
                        </Trans>
                      </Typography>

                    </CardContent>
                    <List>
                      <ListItem>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="List property"
                        />
                      </ListItem>


                      <ListItem>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Featured Property"
                        />
                      </ListItem>

                      <ListItem divider>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="20 photos"
                        />
                      </ListItem>
                    </List>
                    <Typography variant="h5" sx={{
                      mb: 2, fontWeight: "bold"
                    }}>LBP 150 000</Typography>
                    <Button variant="contained" sx={{ width: "100%", mb: 2 }} color="primary" disableElevation>
                      {alignment === "Gold" ? `Selected` : 'Choose'}
                    </Button>


                  </Stack>
                </ToggleButton>
                <Divider />
                <ToggleButton value="Platinum">
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                  >
                    <CardContent>
                      <Typography variant="h5" color="secondary" gutterBottom>
                        Platinum
                      </Typography>
                      <Typography variant="body2" color="text.secondary" component="h2">
                        <Trans i18nKey="description.moreReach">
                          More Reach to Potential Tenants
                        </Trans>
                      </Typography>

                    </CardContent>
                    <List>
                      <ListItem>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="List property"
                        />
                      </ListItem>


                      <ListItem>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Featured Property ++"
                        />
                      </ListItem>

                      <ListItem divider>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="25 photos"
                        />
                      </ListItem>
                    </List>
                    <Typography variant="h5" sx={{
                      mb: 2, fontWeight: "bold"
                    }}>LBP 200 000</Typography>
                    <Button variant="contained" sx={{ width: "100%", mb: 2 }} color="primary" disableElevation>
                      {alignment === "Platinum" ? `Selected` : 'Choose'}
                    </Button>


                  </Stack>
                </ToggleButton>
              </StyledToggleButtonGroup>
            </Grid>
          </MHidden>




          <MHidden width="mdDown">
            <Grid container justifyContent="center">
              <StyledToggleButtonGroup
                size="small"
                value={bundle}
                exclusive
                onChange={handleToggle}
                aria-label="text alignment"
              >

                <ToggleButton value="Basic">
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                  >
                    <CardContent>
                      <Typography variant="h5" color="secondary" gutterBottom>
                        <Trans i18nKey="description.basic">
                          Basic
                        </Trans>
                      </Typography>
                      <Typography variant="body2" color="text.secondary" component="h2">
                        <Trans i18nKey="description.lessReach">
                          Less Reach to Potential Tenants
                        </Trans>
                      </Typography>

                    </CardContent>
                    <List>
                      <ListItem>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="List property"
                        />
                      </ListItem>


                      <ListItem divider>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="15 pictures"
                        />
                      </ListItem>
                    </List>
                    <Typography variant="h5" sx={{
                      mb: 2, fontWeight: "bold"
                    }}>LBP 100 000</Typography>
                    <Button variant="contained" sx={{ width: "100%", mt: 4 }} color="primary" disableElevation>
                      {alignment === "Basic" ? `Selected` : 'Choose'}
                    </Button>
                  </Stack>
                </ToggleButton>
                <ToggleButton value="Gold">
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                  >
                    <CardContent>
                      <Typography variant="h5" color="secondary" gutterBottom>
                        Gold
                      </Typography>
                      <Typography variant="body2" color="text.secondary" component="h2">
                        <Trans i18nKey="description.moreReach">
                          More Reach to Potential Tenants
                        </Trans>
                      </Typography>

                    </CardContent>
                    <List>
                      <ListItem>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="List property"
                        />
                      </ListItem>


                      <ListItem>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Featured Property"
                        />
                      </ListItem>

                      <ListItem divider>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="20 photos"
                        />
                      </ListItem>
                    </List>
                    <Typography variant="h5" sx={{
                      mb: 2, fontWeight: "bold"
                    }}>LBP 150 000</Typography>
                    <Button variant="contained" sx={{ width: "100%", mb: 2 }} color="primary" disableElevation>
                      {alignment === "Gold" ? `Selected` : 'Choose'}
                    </Button>


                  </Stack>
                </ToggleButton>
                <ToggleButton value="Platinum">
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                  >
                    <CardContent>
                      <Typography variant="h5" color="secondary" gutterBottom>
                        Platinum
                      </Typography>
                      <Typography variant="body2" color="text.secondary" component="h2">
                        <Trans i18nKey="description.moreReach">
                          More Reach to Potential Tenants
                        </Trans>
                      </Typography>

                    </CardContent>
                    <List>
                      <ListItem>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="List property"
                        />
                      </ListItem>


                      <ListItem>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Featured Property ++"
                        />
                      </ListItem>

                      <ListItem divider>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="25 photos"
                        />
                      </ListItem>
                    </List>
                    <Typography variant="h5" sx={{
                      mb: 2, fontWeight: "bold"
                    }}>LBP 200 000</Typography>
                    <Button variant="contained" sx={{ width: "100%", mb: 2 }} color="primary" disableElevation>
                      {alignment === "Platinum" ? `Selected` : 'Choose'}
                    </Button>


                  </Stack>
                </ToggleButton>
              </StyledToggleButtonGroup>
            </Grid>
          </MHidden>
        </Container>
      );
    } else {
      return (
        <Container>
          <MHidden width="mdUp">
            <Grid container justifyContent="center">
              <StyledToggleButtonGroup
                size="small"
                value={bundle}
                exclusive
                orientation="vertical"
                onChange={handleToggle}
                aria-label="text alignment"
              >

                <ToggleButton value="Basic">
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                  >
                    <CardContent>
                      <Typography variant="h5" color="secondary" gutterBottom>
                        <Trans i18nKey="description.basic">
                          Basic
                        </Trans>
                      </Typography>
                      <Typography variant="body2" color="text.secondary" component="h2">
                        <Trans i18nKey="description.lessReach">
                          Less Reach to Potential Tenants
                        </Trans>
                      </Typography>

                    </CardContent>
                    <List>
                      <ListItem>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="List property"
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="3 months"
                        />
                      </ListItem>

                      <ListItem divider>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="15 pictures"
                        />
                      </ListItem>
                    </List>
                    <Typography variant="h5" sx={{
                      mb: 2,
                      fontWeight: 'bold',
                      color: "black",
                      opacity: 0.8,
                      textDecoration: 'line-through'
                    }}>LBP 100 000</Typography>
                    <Typography variant="h4" color="black" sx={{ mb: 2, fontWeight: "bold" }}>FREE</Typography>
                    <Button variant="contained" sx={{ width: "100%", mt: 4 }} color="primary" disableElevation>
                      {alignment === "Basic" ? `Selected` : 'Choose'}
                    </Button>
                  </Stack>
                </ToggleButton>
                <Divider />
                <ToggleButton value="Gold">
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                  >
                    <CardContent>
                      <Typography variant="h5" color="secondary" gutterBottom>
                        Gold
                      </Typography>
                      <Typography variant="body2" color="text.secondary" component="h2">
                        <Trans i18nKey="description.moreReach">
                          More Reach to Potential Tenants
                        </Trans>
                      </Typography>

                    </CardContent>
                    <List>
                      <ListItem>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="List property"
                        />
                      </ListItem>


                      <ListItem>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Featured Property"
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="5 months"
                        />
                      </ListItem>

                      <ListItem divider>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="20 photos"
                        />
                      </ListItem>
                    </List>
                    <Typography variant="h5" sx={{
                      mb: 2,
                      fontWeight: 'bold',
                      color: "black",
                      opacity: 0.8,
                      textDecoration: 'line-through'
                    }}>LBP 150 000</Typography>
                    <Typography variant="h4" color="black" sx={{ mb: 2, fontWeight: "bold" }}>LBP 75 000</Typography>
                    <Button variant="contained" sx={{ width: "100%", mb: 2 }} color="primary" disableElevation>
                      {alignment === "Gold" ? `Selected` : 'Choose'}
                    </Button>


                  </Stack>
                </ToggleButton>
                <Divider />
                <ToggleButton value="Platinum">
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                  >
                    <CardContent>
                      <Typography variant="h5" color="secondary" gutterBottom>
                        Platinum
                      </Typography>
                      <Typography variant="body2" color="text.secondary" component="h2">
                        <Trans i18nKey="description.mostReach">
                          Most Reach to Potential Tenants
                        </Trans>
                      </Typography>

                    </CardContent>
                    <List>
                      <ListItem>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="List property"
                        />
                      </ListItem>


                      <ListItem>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Featured Property ++"
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="6 months"
                        />
                      </ListItem>

                      <ListItem divider>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="25 photos"
                        />
                      </ListItem>
                    </List>
                    <Typography variant="h5" sx={{
                      mb: 2,
                      fontWeight: 'bold',
                      color: "black",
                      opacity: 0.8,
                      textDecoration: 'line-through'
                    }}>LBP 200 000</Typography>
                    <Typography variant="h4" color="black" sx={{ mb: 2, fontWeight: "bold" }}>LBP 100 000</Typography>
                    <Button variant="contained" sx={{ width: "100%", mb: 2 }} color="primary" disableElevation>
                      {alignment === "Platinum" ? `Selected` : 'Choose'}
                    </Button>


                  </Stack>
                </ToggleButton>
              </StyledToggleButtonGroup>
            </Grid>
          </MHidden>





          <MHidden width="mdDown">
            <Grid container justifyContent="center">
              <StyledToggleButtonGroup
                size="small"
                value={bundle}
                exclusive
                onChange={handleToggle}
                aria-label="text alignment"
              >

                <ToggleButton value="Basic">
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                  >
                    <CardContent>
                      <Typography variant="h5" color="secondary" gutterBottom>
                        <Trans i18nKey="description.basic">
                          Basic
                        </Trans>
                      </Typography>
                      <Typography variant="body2" color="text.secondary" component="h2">
                        <Trans i18nKey="description.lessReach">
                          Less Reach to Potential Tenants
                        </Trans>
                      </Typography>

                    </CardContent>
                    <List>
                      <ListItem>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="List property"
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="3 months"
                        />
                      </ListItem>

                      <ListItem divider>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="15 pictures"
                        />
                      </ListItem>
                    </List>
                    <Typography variant="h5" sx={{
                      mb: 2,
                      fontWeight: 'bold',
                      color: "black",
                      opacity: 0.8,
                      textDecoration: 'line-through'
                    }}>LBP 100 000</Typography>
                    <Typography variant="h4" color="black" sx={{ mb: 2, fontWeight: "bold" }}>FREE</Typography>
                    <Button variant="contained" sx={{ width: "100%", mt: 4 }} color="primary" disableElevation>
                      {alignment === "Basic" ? `Selected` : 'Choose'}
                    </Button>
                  </Stack>
                </ToggleButton>
                <ToggleButton value="Gold">
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                  >
                    <CardContent>
                      <Typography variant="h5" color="secondary" gutterBottom>
                        Gold
                      </Typography>
                      <Typography variant="body2" color="text.secondary" component="h2">
                        <Trans i18nKey="description.moreReach">
                          More Reach to Potential Tenants
                        </Trans>
                      </Typography>

                    </CardContent>
                    <List>
                      <ListItem>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="List property"
                        />
                      </ListItem>


                      <ListItem>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Featured Property"
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="5 months"
                        />
                      </ListItem>

                      <ListItem divider>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="20 photos"
                        />
                      </ListItem>
                    </List>
                    <Typography variant="h5" sx={{
                      mb: 2,
                      fontWeight: 'bold',
                      color: "black",
                      opacity: 0.8,
                      textDecoration: 'line-through'
                    }}>LBP 150 000</Typography>
                    <Typography variant="h4" color="black" sx={{ mb: 2, fontWeight: "bold" }}>LBP 75 000</Typography>
                    <Button variant="contained" sx={{ width: "100%", mb: 2 }} color="primary" disableElevation>
                      {alignment === "Gold" ? `Selected` : 'Choose'}
                    </Button>


                  </Stack>
                </ToggleButton>
                <ToggleButton value="Platinum">
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                  >
                    <CardContent>
                      <Typography variant="h5" color="secondary" gutterBottom>
                        Platinum
                      </Typography>
                      <Typography variant="body2" color="text.secondary" component="h2">
                        <Trans i18nKey="description.mostReach">
                          Most Reach to Potential Tenants
                        </Trans>
                      </Typography>

                    </CardContent>
                    <List>
                      <ListItem>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="List property"
                        />
                      </ListItem>


                      <ListItem>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Featured Property ++"
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="6 months"
                        />
                      </ListItem>

                      <ListItem divider>
                        <ListItemIcon sx={{ mr: -2 }}>
                          <CheckCircleIcon color='secondary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary="25 photos"
                        />
                      </ListItem>
                    </List>
                    <Typography variant="h5" sx={{
                      mb: 2,
                      fontWeight: 'bold',
                      color: "black",
                      opacity: 0.8,
                      textDecoration: 'line-through'
                    }}>LBP 200 000</Typography>
                    <Typography variant="h4" color="black" sx={{ mb: 2, fontWeight: "bold" }}>LBP 100 000</Typography>
                    <Button variant="contained" sx={{ width: "100%", mb: 2 }} color="primary" disableElevation>
                      {alignment === "Platinum" ? `Selected` : 'Choose'}
                    </Button>


                  </Stack>
                </ToggleButton>
              </StyledToggleButtonGroup>
            </Grid>
          </MHidden>


        </Container>

      );
    }
  }
}
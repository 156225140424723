import * as React from 'react';
import {Container, Box, Grid, Typography, Alert, Snackbar, Stack, CircularProgress} from "@material-ui/core";
import { useParams } from "react-router-dom";
import ProfileField from "../components/TextFields/TextField";
import emailConf from '../images/emailConf.svg'
import useState from "react-hook-use-state";
import { LoadingButton } from "@material-ui/lab";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import AuthLayout from '../layouts/AuthLayout';

import { Trans, useTranslation } from 'react-i18next';
import Button from "@material-ui/core/Button";
import { emailConfirmation, signup } from "../actions/auth";

import { experimentalStyled as styled } from '@material-ui/core/styles';
import Page from '../components/Page';
const RootStyle = styled(Page)(({ theme }) => ({
    paddingBottom: theme.spacing(10),
    paddingTop: theme.spacing(10)
  }));

const ActivateAccount = () => {
    const dispatch = useDispatch();
    const search = useLocation();
    const { token } = useParams();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [success, setSuccess] = useState(true);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false)
    };

    const { t, i18n } = useTranslation();

    return (
        <RootStyle title="Activate Account | eAkary">
             <AuthLayout />
            <Container style={{marginTop:'10%'}}>
                <Grid container rowSpacing={1} spacing={5} justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={6} md={5} lg={5} direction='column'   >
                        <Box
                            component="img"
                            src={emailConf}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} direction='column' columnSpacing={5}>
                        <Typography variant="h3" mt={4} mb={4}>
                            <Trans i18nKey="description.activateAcc">
                                Activate Account
                            </Trans>
                        </Typography>
                        {success ?
                            <Button
                                size="medium"
                                variant="contained"
                                style={{width: "fit-content"}}
                                onClick={async () => {
                                    setSuccess(false)
                                    const form =
                                    {
                                        token: token
                                    }
                                    const success = await dispatch(emailConfirmation(form));
                                    if (success) {
                                        navigate('/');
                                        setSuccess(false)
                                    }
                                }
                                }
                            >
                                <Trans i18nKey="description.activateAcc">
                                    Activate Account
                                </Trans>

                            </Button> :
                            <CircularProgress />
                        }
                    </Grid>
                </Grid>
                {
                    success !== undefined ? (
                        success ? null
                            : (
                                <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                                    <Alert onClose={handleSnackClose} severity="error">
                                        <Trans i18nKey="description.sessionExp">
                                            Session Has Expired please resend your email
                                        </Trans>
                                    </Alert>
                                </Snackbar>
                            )) : null
                }
            </Container>
        </RootStyle>
    )
}
export default ActivateAccount;
//////////////////////////////////////////////////////////////

import React, { useState } from 'react';
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import { ProgressBar } from 'react-bootstrap'
//Image upload modules
import { Upload, Modal, Progress, Button } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import axios from 'axios'
import "antd/dist/antd.css";
import Container from "@material-ui/core/Container";
import { Grid } from "@material-ui/core";
import { Alert, Snackbar } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import './imageUpload.css'

class PicturesWall extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    uploadPercentage: 0,
    snackbarOpen: false,
    snackbarMsg: '',
  };
  snackbarClose = (event) => {
    this.setState({ snackbarOpen: false });
  };

  handleCancel = () => this.setState({ previewVisible: false });
  //Image Preview
  handlePreview = async file => {

    this.setState({
      previewImage: file.thumbUrl,
      previewVisible: true,
    });
  };

  handleChange = ({ fileList }) => this.props.setFile({ fileList });
  // this.setState({ fileList });


  handleUpload = (imageFile) => {

    // you store them in state, so that you can make a http req with them later
    // this.setState({ fileList });
    if (imageFile.file.size < 1000000) {
      if (imageFile.file.type === "image/jpeg" || imageFile.file.type === "image/png") {
        this.props.setFile(imageFile);
        // this.setState(fileList)
      } else {
        this.setState({ snackbarOpen: true, snackbarMsg: "Wrong Format" })
      }
    } else {
      this.setState({ snackbarOpen: true, snackbarMsg: "Image must be less than 1 MB" })
    }
  };

  handleUploadMainImage = (imageMain) => {

    // you store them in state, so that you can make a http req with them later
    // this.setState({ fileList });
    if (imageMain.file.size < 1000000) {
      if (imageMain.file.type === "image/jpeg" || imageMain.file.type === "image/png") {
        this.props.setMainImage(imageMain);
        // this.setState(fileList)
      } else {
        this.setState({ snackbarOpen: true, snackbarMsg: "Wrong Format" })
      }
    } else {
      this.setState({ snackbarOpen: true, snackbarMsg: "Image must be less than 1 MB" })
    }
  };

  render() {

    const { previewVisible, previewImage, previewTitle, uploadPercentage } = this.state;



    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload <br />(max {this.props.bundle === 'Basic' ? 14 : this.props.bundle === 'Gold' ? 19 : 24})</div>
      </div>
    );
    const uploadButton2 = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload <br />(max 1)</div>
      </div>
    );
    return (
      <>
        <Container>
          <Grid container justifyContent="center" xs={12}>
            <div className="jumbotron text-center" style={{ marginTop: 30, marginBottom: 30 }}>
              {/* <h3>Upload Images</h3> */}
              {this.props.bundle === 'Basic' ? (
                <div>
                  <h4>Please upload your main image</h4>
                  <h6>This image will be diplayed first on your post</h6>
                </div>
              ) : this.props.bundle === 'Gold' ? (
                <div>
                  <h4>Please upload your main image</h4>
                  <h6>This image will be diplayed first on your post</h6>
                </div>
              ) : this.props.bundle === 'Platinum' ? (
                <div>
                  <h4>Please upload your main image</h4>
                  <h6>This image will be diplayed first on your post</h6>
                </div>
              ) : null
              }
            </div>

            <Upload
              listType="picture-card"
              maxCount={1}
              onPreview={this.handlePreview}
              onChange={this.handleUploadMainImage}
              fileList={this.props.mainImage.length === 0 ? null : this.props.mainImage.fileList}
              beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
              accept="image/*"
            >

              {uploadButton2}

            </Upload>
          </Grid>
        </Container>
        <Container>
          <Grid container justifyContent="center" alignItems="center" xs={12}>
            <div className="jumbotron text-center" style={{ marginTop: 30, marginBottom: 30 }}>
              {/* <h3>Upload Images</h3> */}
              {this.props.bundle === 'Basic' ? (
                <h4>Please upload a minimum of 4 images </h4>
              ) : this.props.bundle === 'Gold' ? (
                <h4>Please upload a minimum of 4 images</h4>
              ) : this.props.bundle === 'Platinum' ? (
                <h4>Please upload a minimum of 4 images</h4>
              ) : null
              }
            </div>
            <Upload
              listType="picture-card"
              fileList={this.props.file.length === 0 ? null : this.props.file.fileList}
              onPreview={this.handlePreview}
              onChange={this.handleUpload}
              maxCount={this.props.bundle === 'Basic' ? 14 : this.props.bundle === 'Gold' ? 19 : 24}
              beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
              accept="image/*"

            >
              {uploadButton}
            </Upload>
          </Grid>
        </Container>
        <Modal
          visible={previewVisible}
          // title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>


        {uploadPercentage > 0 && <ProgressBar style={{ maxWidth: "600px", margin: "0 auto", marginTop: "20px" }} now={uploadPercentage} active label={`${uploadPercentage}%`} />}
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={this.state.snackbarOpen}
          autoHideDuration={3000}
        >
          <Alert severity="error" action={[
            <IconButton key="close" aria-label="Close" color="inherit" onClick={this.snackbarClose}>x</IconButton>
          ]} style={{ maxHeight: "50px", height: "100%" }}>
            {<span id="message-id"><div style={{ margin: "0 auto" }}>{this.state.snackbarMsg}</div></span>}
          </Alert>
        </Snackbar>

      </>
    );
  }
}

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);

  }

  render() {
    return (
      <div className="MainDiv">
        {/* <div className="jumbotron text-center">
          <h3>Upload Images</h3>
          {this.props.bundle === 'Basic' ? (
            <h4>Please upload your main image and 1 to 14 images </h4>
          ) : this.props.bundle === 'Gold' ? (
            <h4>Please upload your main image and 1 to 19 images</h4>
          ) : this.props.bundle === 'Platinum' ? (
            <h4>Please upload your main image and 1 to 24 images</h4>
          ) : null
          }
        </div> */}
        <div className="container">
          <PicturesWall bundle={this.props.bundle} mainImage={this.props.mainImage} setMainImage={this.props.setMainImage} file={this.props.fileList} setFile={this.props.setFileList} post={this.props.post} setPost={this.props.setPost} />
        </div>
      </div>
    );
  }
}
export default ImageUpload;

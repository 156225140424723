import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import googleFill from '@iconify-icons/eva/google-fill';
import facebookFill from '@iconify-icons/eva/facebook-fill';
// material
import { Stack, Button, Divider, Typography } from '@material-ui/core';

// ----------------------------------------------------------------------
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import axios from 'axios';
import { Trans, useTranslation } from 'react-i18next';
import decode from "jwt-decode";
import Cookies from 'js-cookie';

const componentClicked = (data) => {

  console.warn(data);

}

export default function AuthSocial() {

  const [LoggedUser, setLoggedUser] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();



  const googleSuccess = response => {
    axios({
      method: "POST",
      url: "https://backend.eakary.com/user/googlelogin",
      data: { idToken: response.tokenId },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + response.accessToken
      }
    })
      .then(response => {
        localStorage.setItem('profile', JSON.stringify(response.data));
        window.location.href = "/";
      })
      .catch(error => {
      });
  };


  const googleFailure = () => {
  };


  const responseFacebook = response => {
    axios.post('https://backend.eakary.com/user/facebooklogin', {
      userID: response.userID,
      accessToken: response.accessToken,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + response.accessToken
      }
    })
      .then(res => {
        localStorage.setItem('profile', JSON.stringify(res.data));
        window.location.href = "/";
      });
  };

  const [arLang, setArLang] = useState(false);
  let cookieLanguage = Cookies.get('i18next');


  useEffect(() => {
    if (cookieLanguage == "ar") {
      setArLang(true);
      // window.onload = function() {
      //   if(!window.location.hash) {
      //     window.location = window.location + '#loaded';
      //     window.location.reload();
      //   }
      // }
      (function () {
        if (window.localStorage) {
          //check if reloaded once already 
          if (!localStorage.getItem('firstLoad')) {
            //if not reloaded once, then set firstload to true
            localStorage['firstLoad'] = true;
            //reload the webpage using reload() method
            window.location.reload();
          }
          else
            localStorage.removeItem('firstLoad');
        }
      })();
    }
  }, [])

  //////////////////////////////////////
  const { i18n } = useTranslation();

  return (
    <>
      <Stack direction="row" spacing={2}>
        <GoogleLogin
          clientId="1054204296317-d1m4m6qc1b0dc0jpa1a6an6io42caon4.apps.googleusercontent.com"
          render={(renderProps) => (
            <Button
              fullWidth
              color="inherit"
              size="large"
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              variant="outlined"
            >
              <Icon icon={googleFill} color="#DF3E30" height={24} />
            </Button>

          )}
          onSuccess={googleSuccess}
          onFailure={googleFailure}
          cookiePolicy="single_host_origin"


        />
        {
          arLang ? (
            <FacebookLogin

              appId="789110441777574"

              autoLoad={false}

              fields="email"
              onClick={componentClicked}
              callback={responseFacebook}

              render={(renderProps) => (
                <Button
                  fullWidth
                  color="inherit"
                  size="large"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  variant="outlined"
                  sx={{ marginLeft: "-20px" }}
                >
                  <Icon icon={facebookFill} color="#1877F2" height={24} />
                </Button>

              )}

            />
          ) : (
            <FacebookLogin

              appId="789110441777574"

              autoLoad={false}

              fields="email"
              onClick={componentClicked}
              callback={responseFacebook}

              render={(renderProps) => (
                <Button
                  fullWidth
                  color="inherit"
                  size="large"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  variant="outlined"
                >
                  <Icon icon={facebookFill} color="#1877F2" height={24} />
                </Button>

              )}

            />
          )
        }



      </Stack>

      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          <Trans i18nKey="description.or">
            OR
          </Trans>
        </Typography>
      </Divider>
    </>
  );
}

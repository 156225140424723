import React, { useState, useCallback, useRef, useEffect } from "react";
import Button from '@material-ui/core/Button';
import {
    GoogleMap,
    useLoadScript,
    Marker,
} from "@react-google-maps/api";
import "@reach/combobox/styles.css";
import YouNeedToLogInSnackBar from './YouNeedToLogInSnackBar'
import { addToWishlist, getWishlist } from '../../actions/posts';
import { deleteWish } from '../../actions/posts';
import ThreeDRotationIcon from '@material-ui/icons/ThreeDRotation';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import { fCurrency } from '../../utils/formatNumber';
import { useTheme } from "@material-ui/core";
import HotelIcon from '@material-ui/icons/Hotel';
import BathtubIcon from '@material-ui/icons/Bathtub';
import ShareIcon from '@material-ui/icons/Share';
import Chip from '@material-ui/core/Chip';
import { Stack, Paper, CircularProgress, Divider, Container } from '@material-ui/core';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import RoomIcon from '@material-ui/icons/Room';
import Grid from '@material-ui/core/Grid';
import useStyles from './style';
import Carousel, { Modal, ModalGateway } from "react-images";
import Popup360 from "../../components/Popup360";
import Iframe from 'react-iframe';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, Link as RouterLink, } from 'react-router-dom'
import { getPost } from '../../actions/posts';
import { MHidden } from '../../components/@material-extend';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import { FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon, TwitterShareButton, TwitterIcon } from "react-share";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Hidden from '@material-ui/core/Hidden'
import mapStyles from "./mapStyles";
////////////// SWIPER ///////////////////////
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
    Pagination, Navigation
} from 'swiper/core';
/////////////////////////////////////////////
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import Page from '../../components/Page';
import axios from "axios";
import { Trans, useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import FavoriteTwoToneIcon from '@material-ui/icons/FavoriteTwoTone'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import FavoriteIcon from '@material-ui/icons/Favorite'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone, faBed, faBath, faVectorSquare } from '@fortawesome/free-solid-svg-icons'
import Filter4TwoToneIcon from '@material-ui/icons/Filter4TwoTone';
import Filter5TwoToneIcon from '@material-ui/icons/Filter5TwoTone';
import Filter6TwoToneIcon from '@material-ui/icons/Filter6TwoTone';
import Filter7TwoToneIcon from '@material-ui/icons/Filter7TwoTone';
import Filter8TwoToneIcon from '@material-ui/icons/Filter8TwoTone';
import Filter9TwoToneIcon from '@material-ui/icons/Filter9TwoTone';
import Filter9PlusTwoToneIcon from '@material-ui/icons/Filter9PlusTwoTone';
SwiperCore.use([Pagination, Navigation]);
const RootStyle = styled(Page)(({ theme }) => ({
    paddingBottom: theme.spacing(10)
}));
const ProductImgStyle = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'fill',
    position: 'absolute'
});
const libraries = ["places"]
const mapContainerStyle = {
    height: "45vh",
    width: "100%",
};
const options = {
    styles: mapStyles,
    //zoomControl: true,
    fullscreenControl: false,
    mapTypeControl: true,
    streetViewControl: false
};
const center = {
    lat: 33.82543142169751,
    lng: 35.583106540518465,
};
const PostDetail = () => {
    const geticon = (n) => {
        if (n + 1 === 5) { return <Filter5TwoToneIcon sx={{ color: '#0487BA' }} />; }
        else if (n + 1 === 6) { return <Filter6TwoToneIcon sx={{ color: '#0487BA' }} />; }
        else if (n + 1 === 7) { return <Filter7TwoToneIcon sx={{ color: '#0487BA' }} />; }
        else if (n + 1 === 8) { return <Filter8TwoToneIcon sx={{ color: '#0487BA' }} />; }
        else if (n + 1 === 9) { return <Filter9TwoToneIcon sx={{ color: '#0487BA' }} />; }
        else { return <Filter9PlusTwoToneIcon />; }
    }
    //const { t, i18n } = useTranslation();
    const isMobileScreen = useMediaQuery('max-width:600px')
    const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
    const handleSnackBarClose = () => {
        setIsSnackBarOpen(false);
    }
    const isTabletScreen = useMediaQuery('(max-width:1070px)');
    const is560pxScreen = useMediaQuery('min-width:560px');
    const [openDiag, setOpenDiag] = React.useState(false);
    const navigate = useNavigate();
    const classes = useStyles();
    const theme = useTheme();
    const [buttonPopup, setButtonPopup] = useState(false)
    const { post } = useSelector((state) => state.posts);
    const dispatch = useDispatch();
    const { id } = useParams();
    const [similarPosts, setSimilarPosts] = useState('');
    const propertyType = { type: post ? post.propertyType : null }
    const [ads, setAds] = useState('');
    const [imageAds, setImageAds] = useState('');
    const [images, setImages] = useState([]);
    const [currentId, setCurrentId] = useState(0);
    const user = JSON.parse(localStorage.getItem('profile'));
    const [LoggedUser, setLoggedUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const [wishlist, setWishlist] = useState([]);
    useEffect(async () => {
        // const id = LoggedUser.result._id;
        if (LoggedUser !== null) {
            const id = LoggedUser.result._id;
            const wishlist = await dispatch(getWishlist(id));
            setWishlist(wishlist);
        }
    }, [dispatch]);
    const [isLiked, setIsLiked] = useState(JSON.stringify(wishlist).includes(post?._id))
    useEffect(() => { setIsLiked(JSON.stringify(wishlist).includes(post?._id)) }, [post])
    const clickedFavoriteBtn = (idPost) => {
        const id = LoggedUser?.result._id;
        if (!id) { setIsSnackBarOpen(true); return }
        const formData = {
            post: idPost
        };
        setIsLiked(true);
        if (id !== null) {
            dispatch(addToWishlist(id, formData));
        }
    }
    const unClickedFavoriteBtn = (idPost) => {
        const userId = LoggedUser?.result._id;
        if (!id) { alert('you need to login first'); return }
        // dispatch(getWishlist(userId));
        setIsLiked(false);
        if (userId !== null) {
            dispatch(deleteWish(userId, idPost));
        }
    }
    const handleLikePostClick = () => {
        if (isLiked) { unClickedFavoriteBtn(id); }
        else (clickedFavoriteBtn(id))
    }
    useEffect(() => {
        dispatch(getPost(id));
    }, [id]);
    useEffect(() => {
        fetchAds();
    }, []);
    const [arLang, setArLang] = useState(false);
    const { t, i18n } = useTranslation();
    let cookieLanguage = Cookies.get('i18next');
    useEffect(() => {
        if (cookieLanguage == "ar") {
            setArLang(true);
        }
    }, [])
    const fetchSimilar = () => {
        axios.get('https://backend.eakary.com/posts/recommended/' + propertyType.type)
            .catch((error) => console.error("Error: ", error))
            .then(response => {
                setSimilarPosts(response.data || []);
            });
    }
    const fetchAds = () => {
        axios.get('https://backend.eakary.com/ad/specificad/' + id)
            .catch((error) => console.error("Error:", error))
            .then(response => {
                setAds(response.data);
            });
    }
    const Images = [
        { source: post ? post.mainImage.url : null }
    ];
    post && post.images.map((images) => {
        let image = {}
        image.source = images.url;
        Images.push(image)
    })
    const mapRef = useRef(); //retain state when you dont want to rerender
    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);
    const displaySimilar = () => {
        if (similarPosts != '') {
            return (
                <>
                    <Swiper navigation={true} breakpoints={{
                        0: {
                            "slidesPerView": 1,
                            "spaceBetween": 0,
                            "slidesPerGroup": 1
                        },
                        386: {
                            "slidesPerView": 1.2,
                            "spaceBetween": 0,
                            "slidesPerGroup": 1
                        },
                        560: {
                            "slidesPerView": 2,
                            "spaceBetween": 14,
                            "slidesPerGroup": 1
                        },
                        1070: {
                            "slidesPerView": 3,
                            "spaceBetween": 20,
                            "slidesPerGroup": 2
                        }
                    }} className="mySwiper" style={{ padding: '0 2em' }}>
                        {similarPosts.data.map((item, index) => {
                            if (item._id != post._id) {
                                const openSimilarPost = (e) => {
                                    navigate(`/posts/${item._id}`);
                                    window.location.reload(false);
                                };
                                return (
                                    <>
                                        <SwiperSlide >
                                            <Card
                                                sx={{
                                                    minWidth: isMobileScreen ? '10rem' : isTabletScreen ? '14rem' : '18rem',
                                                    maxWidth: isMobileScreen ? '10rem' : isTabletScreen ? '14rem' : '18rem',
                                                }}
                                                style={{ justifyContent: is560pxScreen ? 'center !important' : 'flex-start !important' }} onClick={openSimilarPost} variant="outlined" className={classes.root} elevation={0} >
                                                <Grid container>
                                                    {(item?.bundleChosen === 'Gold') && (
                                                        <Grid container >
                                                            <Chip label="Gold" sx={{ mt: 1, ml: 1, backgroundColor: "#FFD700", color: "white" }} className={classes.Featured} size="small" />
                                                        </Grid>
                                                    )}
                                                    {(item?.bundleChosen === 'Platinum') && (
                                                        <Grid container >
                                                            <Chip label="Platinum" sx={{ mt: 1, ml: 1, backgroundColor: "#E5E4E2", color: "white" }} className={classes.Featured} size="small" />
                                                        </Grid>
                                                    )}
                                                </Grid>
                                                <CardMedia
                                                    component="img"
                                                    sx={{ maxHeight: 150 }}
                                                    image={item.mainImage.url}
                                                    title="property image"
                                                    alt="property image"
                                                />
                                                <CardContent style={{ textAlign: "Left" }}>
                                                    <Typography variant="subtitle2" component="p" >
                                                        {item.propertyType}
                                                    </Typography>
                                                    <Typography variant="h4" style={{ marginBottom: "5px", marginTop: "5px" }} component="p" >
                                                        {fCurrency(item.price)}
                                                    </Typography>
                                                    <Typography variant="body2" component="p" noWrap>
                                                        {item.mapPin ? item.mapPin.address : item.city}
                                                    </Typography>
                                                    <hr className={classes.solid} style={{ marginBottom: "10px", marginTop: "10px" }} />
                                                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                                                        {(item?.numberOfBedrooms !== null) && (
                                                            <Chip icon={<HotelIcon style={{ color: "#001329" }} />} label={item.numberOfBedrooms} size="small" style={{ backgroundColor: "white" }} />
                                                        )}
                                                        {(item?.numberOfBathrooms !== null) && (
                                                            <Chip icon={<BathtubIcon style={{ color: "#001329" }} />} label={item.numberOfBathrooms} size="small" style={{ backgroundColor: "white" }} />
                                                        )}
                                                        {(item?.surfaceArea !== null) && (
                                                            <Chip icon={<ZoomOutMapIcon style={{ color: "#001329" }} />} label={`${item.surfaceArea}sqft`} size="small" style={{ backgroundColor: "white" }} />
                                                        )}
                                                    </Stack>
                                                </CardContent>
                                            </Card>
                                        </SwiperSlide>
                                    </>
                                )
                            }
                        })
                        }
                    </Swiper>
                </>
            )
        }
    }
    const displayAds = () => {
        if (ads != '') {
            return (
                <>
                    <Grid container spacing={3} justifyContent="center" sx={{}} >
                        {ads.map((item, index) => {
                            return (
                                <>
                                    <Grid item xs={12} sm={4} md={12}>
                                        <a href={item.link} target="_blank" rel="noreferrer"><Card sx={{ display: 'flex' }}>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 130 }}
                                                image={item.image.url}
                                                alt="Company Logo"
                                            />
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <CardContent sx={{ flex: '1 0 auto' }}>
                                                    <Typography component="div" variant="h5">
                                                        {item.companyName}
                                                    </Typography>
                                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                                        {item.title}
                                                    </Typography>
                                                </CardContent>
                                                <Box sx={{ display: 'flex', alignItems: 'flex-end', pl: 1, pb: 1 }}>
                                                    <Chip label="Ad" sx={{ mt: 1, ml: 1, backgroundColor: "#808080", color: "white" }} size="small" />
                                                </Box>
                                            </Box>
                                        </Card>
                                        </a>
                                    </Grid>
                                </>
                            )
                        })
                        }
                    </Grid>
                </>
            )
        }
    }
    if (propertyType !== null) {
        fetchSimilar();
    }
    const { isLoaded, loadError } = useLoadScript({
        //googleMapsApiKey: 'AIzaSyC-cRq-I-LZI5RcMt00vnXyhfz5Mchuihc',
        libraries, //Avoid many rerenders
    });
    // let modalIsOpen = false;
    let open;
    let currentIndex = 0;
    const [modalIsOpen, isOpen] = useState(false);
    if (!post) return null;
    const startModal = () => {
        isOpen(true);
    };
    const closeModel = () => {
        isOpen(false);
    }
    const CustomModalFooter = ({ currentIndex, views }) => {
        const activeView = currentIndex + 1;
        const totalViews = views.length;
        if (!activeView || !totalViews) return null;
        return (
            <span class="react-images__footer__count css-w6xjhe css-1ycyyax">{activeView} / {totalViews}</span>
        );
    };
    const handleClickOpen = () => {
        setOpenDiag(true);
    };
    const handleClose = () => {
        setOpenDiag(false);
    };
    if (loadError) return "Error loading maps";
    if (!isLoaded) return "loading maps"
    return (
        <>
            <RootStyle title="Listing Details | eAkary">
                <Container>
                    <ModalGateway>
                        {modalIsOpen ? (
                            <Modal onClose={closeModel}>
                                <Carousel
                                    currentIndex={currentIndex}
                                    components={{ FooterCount: CustomModalFooter }}
                                    views={Images}
                                />
                            </Modal>
                        ) : null}
                    </ModalGateway>
                    <Popup360 trigger={buttonPopup} setTrigger={setButtonPopup}>
                        <Grid container justifyContent="center" alignItems="center" >
                            <Iframe
                                src={post.link360}
                                //src="https://orbix360.com/t/7Lo1Kzu6M2Y4qcR2ShMFT0GRD6u2/5034404254580736/eakary?embed=yes"
                                allowfullscreen="true"
                                frameborder="0"
                                scrolling="no"
                                allow="vr;gyroscope;accelerometer"
                                width="90%"
                                height="800vh"
                                style="border:none; top: 100px;">
                            </Iframe>
                        </Grid>
                    </Popup360>
                    {/* LAPTOP TITLE, PRICE, AND SHARE ICON */}
                    <MHidden width="mdDown">
                        <Grid container sx={{ marginTop: '20px', padding: '0' }} style={{ background: "#F7F9FB" }}>
                            <Grid xs={8} container direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2, padding: '0.5em' }} >
                                <Grid item xs={6} container direction="column" justifyContents="center">
                                    <Grid item  >
                                        <Typography component="h5" variant="h5">
                                            {post.title} {isLiked ?
                                                <FavoriteIcon sx={{
                                                    fontSize: '2rem',
                                                    color: 'red', ':hover': { cursor: 'pointer' }, boxSizing: 'border-box',
                                                    border: `2px solid rgba(255,255,255,0.6)`,
                                                    background: 'rgba(19,32,49,.25)', borderRadius: '50%',
                                                    padding: '3px'
                                                }} onClick={handleLikePostClick} /> :
                                                // FavoriteBorderIcon
                                                <FavoriteTwoToneIcon sx={{
                                                    fontSize: '2rem',
                                                    color: 'white', ':hover': { cursor: 'pointer', color: 'red', transition: '0.5s ease' },
                                                    boxSizing: 'border-box', border: `2px solid rgba(255,255,255,0.6)`, background: 'rgba(19,32,49,.25)',
                                                    borderRadius: '50%',
                                                    padding: '3px'
                                                }} onClick={handleLikePostClick} />
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item >
                                        <RoomIcon style={{ color: "#001329" }} sx={{ marginLeft: '0' }} />
                                        <Box component="span" sx={{ fontSize: "15px" }} > {post.mapPin ? post.mapPin.address : post.city}</Box>
                                    </Grid>
                                    <Grid item container justifyContent="flex-start" alignItems="center">
                                        <Grid itemjustifyContent="flex-start" alignItems="center">
                                            {(post?.numberOfBedrooms !== null) && (
                                                <Box component="span" sx={{ fontSize: "15px", marginRight: '1em' }}>{post.numberOfBedrooms}
                                                    {t("description.bedrooms")} |
                                                </Box>
                                            )}
                                            {(post?.numberOfBathrooms !== null) && (
                                                <Box component="span" sx={{ fontSize: "15px", marginRight: '1em', display: 'none' }}>{post.numberOfBathrooms}
                                                    {t("description.bathrooms")}
                                                </Box>
                                            )}
                                            {(post?.surfaceArea !== null) && (
                                                <Box component="span" sx={{ fontSize: "15px" }}>{post.surfaceArea} sqft
                                                </Box>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} container direction="column" justifyContent="space-between" alignItems="flex-end">
                                    <Grid item>
                                        <Typography component="h3" variant="h3" >
                                            $ {post.price}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* SHARE AND SAVE ICONS */}
                            <Grid item xs={3} container justifyContent="space-around" alignItems="center" sx={{ mr: 2 }}>
                                <Button className={classes.phoneEmailButtons1} variant="outlined" sx={{ ':hover': { transition: '500ms ease-in-out', } }} onClick={handleClickOpen}
                                    startIcon={<ShareIcon />}>
                                    {t("description.share")}
                                </Button>
                            </Grid>
                        </Grid>
                        <Dialog open={openDiag} onClose={handleClose}>
                            <DialogTitle>Share This Property</DialogTitle>
                            <DialogContent>
                                <FacebookShareButton
                                    style={{ marginRight: 10 }}
                                    url={window.location.href}
                                    quote={post.description}
                                    className={classes.socialMediaButton}>
                                    <FacebookIcon size={60} />
                                </FacebookShareButton>
                                <WhatsappShareButton
                                    style={{ marginRight: 10 }}
                                    url={window.location.href}
                                    title={post.title}
                                    separator=":: "
                                    className={classes.socialMediaButton}>
                                    <WhatsappIcon size={60} />
                                </WhatsappShareButton>
                                <TwitterShareButton
                                    style={{ marginRight: 10 }}
                                    url={window.location.href}
                                    title={post.title}
                                    className={classes.socialMediaButton}>
                                    <TwitterIcon size={60} />
                                </TwitterShareButton>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>{t("description.close")}</Button>
                            </DialogActions>
                        </Dialog>
                    </MHidden>
                    <MHidden width="mdUp">
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={{ marginTop: '20px', background: "#F7F9FB" }}>
                            <Grid item xs={12} md={3} >
                                <Typography component="h5" variant="h5">
                                    {post.title} {isLiked ?
                                        <FavoriteIcon sx={{
                                            fontSize: '2rem',
                                            color: 'red', ':hover': { cursor: 'pointer' }, boxSizing: 'border-box', border: `2px solid rgba(255,255,255,0.6)`,
                                            background: 'rgba(19,32,49,.25)', borderRadius: '50%',
                                            padding: '3px'
                                        }} onClick={handleLikePostClick} /> :
                                        // FavoriteBorderIcon
                                        <FavoriteTwoToneIcon sx={{
                                            fontSize: '2rem',
                                            color: 'white', ':hover': { cursor: 'pointer', color: 'red', transition: '0.5s ease' },
                                            boxSizing: 'border-box', border: `2px solid rgba(255,255,255,0.6)`, background: 'rgba(19,32,49,.25)',
                                            borderRadius: '50%',
                                            padding: '3px'
                                        }} onClick={handleLikePostClick} />
                                    }
                                </Typography>
                            </Grid>
                            {/* <Grid item xs={12} md={3}>
                <Typography component="h3" variant="h3" >
                ${post.price}
             </Typography>
             </Grid> */}
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mb: 2 }} >
                            <Grid item xs={12} sm={12} md={4} >
                                <Chip icon={<RoomIcon style={{ color: "#001329" }} />} label="Bayrut, Beirut Governorate, Lebanon" style={{ backgroundColor: "white", fontSize: "15px" }} />
                            </Grid>
                            {(post?.numberOfBedrooms !== null) && (
                                <Grid item xs={3} sm={3} md={1} >
                                    <Chip icon={<HotelIcon style={{ color: "#001329" }} />} label={post.numberOfBedrooms} style={{ backgroundColor: "white", fontSize: "15px" }} />
                                </Grid>
                            )}
                            {(post?.numberOfBathrooms !== null) && (
                                <Grid item xs={3} sm={3} md={1} >
                                    <Chip icon={<BathtubIcon style={{ color: "#001329" }} />} label={post.numberOfBathrooms} style={{ backgroundColor: "white", fontSize: "15px" }} />
                                </Grid>
                            )}
                            {(post?.surfaceArea !== null) && (
                                <Grid item xs={3} sm={3} md={3} >
                                    <Chip icon={<ZoomOutMapIcon style={{ color: "#001329" }} />} label={`${post.surfaceArea}sqft`} style={{ backgroundColor: "white", fontSize: "15px" }} />
                                </Grid>
                            )}
                        </Grid>
                    </MHidden>
                    <Grid container>
                        <MHidden width="lgDown">
                            <Grid container justifyContent="flex-start" xs={12} sm={12} md={6} sx={{ width: "599px" }}>
                                <div>
                                    <Grid item xs={12} sx={{ position: 'relative', width: "599px" }}>
                                        {post?.link360 !== undefined && post?.link360 !== null ? (
                                            <Button onClick={() => setButtonPopup(true)}
                                                variant="contained"
                                                color="secondary"
                                                sx={{ mt: 1, mr: 2, position: 'absolute !important', bottom: 4, right: 58 }}
                                                className={classes.button3D1}
                                            >
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                    <ThreeDRotationIcon sx={{ color: '#0487BA' }} />
                                                </div>
                                            </Button>
                                        ) : null}
                                        <Button onClick={startModal}
                                            variant="contained"
                                            color="secondary"
                                            sx={{ mt: 1, position: 'absolute !important', bottom: 4, right: 4 }}
                                            className={classes.button3D1}
                                        >
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                {geticon(post.images.length)}
                                            </div>
                                        </Button>
                                        <img src={post.mainImage ? post.mainImage.url : post.images[0]} alt="eakary building details"
                                            width="599px" height={400} onClick={startModal} />
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid container xs={12} sm={5} md={3} alignItems="center"  >
                                <Grid item mb={1}>
                                    <img src={post.images[0].url ? post.images[0].url : post.images[0]}
                                        width="305px" height="190px" onClick={startModal} />
                                </Grid>
                                <Grid item>
                                    <img src={post.images[1].url ? post.images[1].url : post.images[1]}
                                        width="305px" height="190px" onClick={startModal} />
                                </Grid>
                            </Grid>
                            <Grid container xs={12} sm={5} md={3} alignItems="center"  >
                                <Grid item mb={1}>
                                    <img src={post.images[2].url ? post.images[2].url : post.mainImage.url}
                                        width="305px" height="190px" onClick={startModal} />
                                </Grid>
                                <Grid item>
                                    <img src={post.images[3].url ? post.images[3].url : post.mainImage.url}
                                        width="305px" height="190px" onClick={startModal} />
                                </Grid>
                            </Grid>
                        </MHidden>
                        <MHidden width="lgUp">
                            <MHidden width="mdDown">
                                <Grid container justifyContent="flex-start" xs={12} sm={12} md={6} sx={{ width: "500px" }}>
                                    <div>
                                        <Grid item xs={12} sx={{ position: 'relative', width: "500px" }}>
                                            {post?.link360 !== undefined && post?.link360 !== null ? (
                                                <Button onClick={() => setButtonPopup(true)}
                                                    variant="contained"
                                                    color="secondary"
                                                    sx={{ mt: 1, mr: 2, position: 'absolute !important', bottom: 4, right: 58 }}
                                                    className={classes.button3D1}
                                                >
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                        <ThreeDRotationIcon sx={{ color: '#0487BA' }} />
                                                    </div>
                                                </Button>
                                            ) : null}
                                            <Button onClick={startModal}
                                                variant="contained"
                                                color="secondary"
                                                sx={{ mt: 1, position: 'absolute !important', bottom: 4, right: 4 }}
                                                className={classes.button3D1}
                                            >
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                    {geticon(post.images.length)}
                                                </div>
                                            </Button>
                                            <img src={post.mainImage ? post.mainImage.url : post.images[0]} alt="eakary building details"
                                                width="500px" height={400} onClick={startModal} />
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid container xs={12} sm={5} md={3} alignItems="center"  >
                                    <Grid item mb={1}>
                                        <img src={post.images[0].url ? post.images[0].url : post.images[0]}
                                            width="240px" height="165px" onClick={startModal} />
                                    </Grid>
                                    <Grid item>
                                        <img src={post.images[1].url ? post.images[1].url : post.images[1]}
                                            width="240px" height="165px" onClick={startModal} />
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} sm={5} md={3} alignItems="center"  >
                                    <Grid item mb={1}>
                                        <img src={post.images[2].url ? post.images[2].url : post.mainImage.url}
                                            width="240.5px" height="165px" onClick={startModal} />
                                    </Grid>
                                    <Grid item>
                                        <img src={post.images[3].url ? post.images[3].url : post.mainImage.url}
                                            width="240.5px" height="165px" onClick={startModal} />
                                    </Grid>
                                </Grid>
                            </MHidden>
                        </ MHidden>
                        <MHidden width="mdUp">
                            <Grid container justifyContent="flex-start" xs={12} sx={{ width: "100%" }}>
                                <div>
                                    <Grid item xs={12} sx={{ position: 'relative', width: "100%" }}>
                                        {post?.link360 !== undefined && post?.link360 !== null ? (
                                            <Button onClick={() => setButtonPopup(true)}
                                                variant="contained"
                                                color="secondary"
                                                sx={{ mt: 1, mr: 2, position: 'absolute !important', bottom: 4, right: 58 }}
                                                className={classes.button3D1}
                                            >
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                    <ThreeDRotationIcon sx={{ color: '#0487BA' }} />
                                                </div>
                                            </Button>
                                        ) : null}
                                        <Button onClick={startModal}
                                            variant="contained"
                                            color="secondary"
                                            sx={{ mt: 1, position: 'absolute !important', bottom: 4, right: 4 }}
                                            className={classes.button3D1}
                                        >
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                {geticon(post.images.length)}
                                            </div>
                                        </Button>
                                        <img src={post.mainImage ? post.mainImage.url : post.images[0]} alt="eakary building details"
                                            width="100%" height="auto" onClick={startModal} />
                                    </Grid>
                                </div>
                            </Grid>


                        </MHidden>
                        <Grid container justifyContent="center" alignItems="center" xs={12} md={12} sx={{ mt: 2 }}
                            style={{ boxShadow: " 1px 1px 14px -11px rgba(66,66,66,0.75)", padding: 10 }}>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography component="h6" variant="h6" style={{ textAlign: 'center' }}>
                                    {t("description.contactOwner")}
                                </Typography>
                            </Grid>
                            <Grid item container xs={12} sm={6} md={6} sx={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                {/* SAVE POST AND SHARE */}
                                {/* MOBILE PHONEAND EMAIL */}
                                <Grid item xs={4} sm={4} md={4}>
                                    <Button variant="outlined" className={classes.phoneEmailButtons} href={"tel:" + post.phoneNumber}
                                        sx={{ width: { xs: '70px', md: '94px' }, padding: '0.2rem 0.5rem', ':hover': { transition: '500ms ease-in-out' } }}>
                                        <FontAwesomeIcon icon={faPhone} className='text-white-500' style={{ marginRight: '0.5rem' }}></FontAwesomeIcon>
                                        {t("description.phonee")}
                                    </Button>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}>
                                    <Button className={classes.phoneEmailButtons1}
                                        sx={{ width: { xs: '70px', md: '94px' }, padding: '0.2rem 0.5rem', ':hover': { transition: '500ms ease-in-out', } }}
                                        variant="outlined" href={"mailto:" + post.email}>
                                        <FontAwesomeIcon icon={faEnvelope} className='text-white-500' style={{ marginRight: '0.5rem' }}></FontAwesomeIcon>
                                        {t("description.email")}
                                    </Button>
                                </Grid>
                                <MHidden width="mdUp">
                                    <Grid item xs={4} sm={4} md={4} sx={{ width: '94px' }}>
                                        <Button className={classes.phoneEmailButtons1} sx={{
                                            width: { xs: '70px', md: '94px' }, padding: '0.2rem 0.5rem',
                                            ':hover': { transition: '500ms ease-in-out', }
                                        }}
                                            variant="outlined" onClick={handleClickOpen} startIcon={<ShareIcon sx={{ fontSize: '1px' }} />}>
                                            {t("description.share")}
                                        </Button>
                                    </Grid>
                                </MHidden>
                                <Dialog open={openDiag} onClose={handleClose}>
                                    <DialogTitle>Share This Property</DialogTitle>
                                    <DialogContent>
                                        <FacebookShareButton
                                            style={{ marginRight: 10 }}
                                            url={window.location.href}
                                            quote={post.description}
                                            className={classes.socialMediaButton}>
                                            <FacebookIcon size={60} />
                                        </FacebookShareButton>
                                        <WhatsappShareButton
                                            style={{ marginRight: 10 }}
                                            url={window.location.href}
                                            title={post.title}
                                            separator=":: "
                                            className={classes.socialMediaButton}>
                                            <WhatsappIcon size={60} />
                                        </WhatsappShareButton>
                                        <TwitterShareButton
                                            style={{ marginRight: 10 }}
                                            url={window.location.href}
                                            title={post.title}
                                            className={classes.socialMediaButton}>
                                            <TwitterIcon size={60} />
                                        </TwitterShareButton>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleClose}>Close</Button>
                                    </DialogActions>
                                </Dialog>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} >

                                <Typography component="h5" variant="h5" align="left" sx={{ mb: 3, mt: 2 }}>
                                    {t("description.overview")}
                                </Typography>

                            </Grid>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                <Grid item xs={5} md={4} >
                                    <Typography component="h6" variant="h6">
                                        {t("description.pricing")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} md={3}>
                                    <Typography component="body1" variant="body1" >
                                        ${post.price}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                <Grid item xs={12} md={4} >
                                    <Typography component="h6" variant="h6">
                                        {t("description.constDate")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Typography component="body1" variant="body1" >
                                        {post.constructionDate}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {(post?.propertyType === "Villa") && (
                                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                    <Grid item xs={12} md={4} >
                                        <Typography component="h6" variant="h6">
                                            {t("description.nbrOfFloors")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Typography component="body1" variant="body1" >
                                            {post.numberOfFloors}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <Typography component="h5" variant="h5" align="left" sx={{ mb: 3, mt: 5 }}>
                                    {t("description.desc")}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} md={6} >
                                {post?.description == null ? (
                                    <Typography component="body1" variant="body1" >
                                        {t("description.unavailable")}
                                    </Typography>
                                ) : (
                                    <Typography component="body1" variant="body1" >
                                        {post.description}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                        {/* Property Info */}
                        <Grid container justifyContent="space-between">
                            <Grid item xs={12} md={6} container>
                                {(post?.propertyType === "Apartment") && (
                                    <>
                                        <Grid container>
                                            <Grid item xs={12} >
                                                <Typography component="h5" variant="h5" align="left" sx={{ mb: 3, mt: 2 }}>
                                                    {t("description.interior")}
                                                </Typography>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.surfaceArea")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    <Typography component="body1" variant="body1" >
                                                        {post.surfaceArea} sqft
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.rooms")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.numberOfRooms == null ? (
                                                        <Typography component="body1" variant="body1" >
                                                            {t("description.none")}
                                                        </Typography>
                                                    ) : (
                                                        <Typography component="body1" variant="body1" >
                                                            {post.numberOfRooms}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.bedrooms")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.numberOfBedrooms == null ? (
                                                        <Typography component="body1" variant="body1" >
                                                            {t("description.none")}
                                                        </Typography>
                                                    ) : (
                                                        <Typography component="body1" variant="body1" >
                                                            {post.numberOfBedrooms}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.bathrooms")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.numberOfBathrooms == null ? (
                                                        <Typography component="body1" variant="body1" >
                                                            {t("description.none")}
                                                        </Typography>
                                                    ) : (
                                                        <Typography component="body1" variant="body1" >
                                                            {post.numberOfBathrooms}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.kitchen")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    <Typography component="body1" variant="body1" >
                                                        {post.kitchenArea} sqft
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.furnished")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.furnished == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} >
                                                <Typography component="h5" variant="h5" align="left" sx={{ mb: 3, mt: 5 }}>
                                                    {t("description.exterior")}
                                                </Typography>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.surfaceArea")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    <Typography component="body1" variant="body1" >
                                                        {post.surfaceArea} sqft
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.condition")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    <Typography component="body1" variant="body1" >
                                                        {post.constructionCondition}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.parkingSpace")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.parkingSpace == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.dwelling")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.dwelling == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.brickRoof")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.brickRoof == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.Outbuildings")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.outBuildings == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.terrace")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.terrace == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.garden")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.garden == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.pool")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.swimmingPool == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} >
                                                <Typography component="h5" variant="h5" align="left" sx={{ mb: 3, mt: 5 }}>
                                                    {t("description.heatingg")} &amp; {t("description.coolingg")}
                                                </Typography>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.coolingsystem")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.coolingType == null ? (
                                                        <Typography component="body1" variant="body1" >
                                                            {t("description.none")}
                                                        </Typography>
                                                    ) : (
                                                        <Typography component="body1" variant="body1" >
                                                            {post.coolingType}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.heatingsystem")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.heatingType == null ? (
                                                        <Typography component="body1" variant="body1" >
                                                            {t("description.none")}
                                                        </Typography>
                                                    ) : (
                                                        <Typography component="body1" variant="body1" >
                                                            {post.heatingType}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} >
                                                <Typography component="h5" variant="h5" align="left" sx={{ mb: 3, mt: 5 }}>
                                                    {t("description.facilities")}
                                                </Typography>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.caretaker")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.caretakerConcierge == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.elevator")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.elevator == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.videoP")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.videoPhone == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        Wifi
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.internetAccess == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.sna")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.SpecialNeedsAccessibility == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} >
                                                <Typography component="h5" variant="h5" align="left" sx={{ mb: 3, mt: 5 }}>
                                                    {t("description.permits")}
                                                </Typography>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.pets")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.pets == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.smoking")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.smoking == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.bigP")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.bigParties == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.loudM")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.loudMusic == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                {(post?.propertyType === "Villa") && (
                                    <>
                                        <Grid container>
                                            <Grid item xs={12} >
                                                <Typography component="h5" variant="h5" align="left" sx={{ mb: 3, mt: 5 }}>
                                                    {t("description.interior")}
                                                </Typography>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.surfaceArea")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    <Typography component="body1" variant="body1" >
                                                        {post.surfaceArea}sqft
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.rooms")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.numberOfRooms == null ? (
                                                        <Typography component="body1" variant="body1" >
                                                            {t("description.none")}
                                                        </Typography>
                                                    ) : (
                                                        <Typography component="body1" variant="body1" >
                                                            {post.numberOfRooms}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.bedrooms")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.numberOfBedrooms == null ? (
                                                        <Typography component="body1" variant="body1" >
                                                            {t("description.none")}
                                                        </Typography>
                                                    ) : (
                                                        <Typography component="body1" variant="body1" >
                                                            {post.numberOfBedrooms}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.bathrooms")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.numberOfBathrooms == null ? (
                                                        <Typography component="body1" variant="body1" >
                                                            {t("description.none")}
                                                        </Typography>
                                                    ) : (
                                                        <Typography component="body1" variant="body1" >
                                                            {post.numberOfBathrooms}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.kitchen")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    <Typography component="body1" variant="body1" >
                                                        {post.kitchenArea} ㎡
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.furnished")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.furnished == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} >
                                                <Typography component="h5" variant="h5" align="left" sx={{ mb: 3, mt: 5 }}>
                                                    {t("description.exterior")}
                                                </Typography>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.surfaceArea")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    <Typography component="body1" variant="body1" >
                                                        {post.surfaceArea}sqft
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.condition")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    <Typography component="body1" variant="body1" >
                                                        {post.constructionCondition}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.parkingSpace")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.parkingSpace == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.dwelling")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.dwelling == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.brickRoof")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.brickRoof == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.Outbuildings")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.outBuildings == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.terrace")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.terrace == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.garden")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.garden == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.pool")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.swimmingPool == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} >
                                                <Typography component="h5" variant="h5" align="left" sx={{ mb: 3, mt: 5 }}>
                                                    {t("description.heatingg")} &amp; {t("description.coolingg")}
                                                </Typography>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.coolingsystem")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.coolingType == null ? (
                                                        <Typography component="body1" variant="body1" >
                                                            {t("description.none")}
                                                        </Typography>
                                                    ) : (
                                                        <Typography component="body1" variant="body1" >
                                                            {post.coolingType}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.heatingsystem")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.heatingType == null ? (
                                                        <Typography component="body1" variant="body1" >
                                                            {t("description.none")}
                                                        </Typography>
                                                    ) : (
                                                        <Typography component="body1" variant="body1" >
                                                            {post.heatingType}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} >
                                                <Typography component="h5" variant="h5" align="left" sx={{ mb: 3, mt: 5 }}>
                                                    {t("description.facilities")}
                                                </Typography>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.caretaker")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.caretakerConcierge == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.elevator")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.elevator == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.videoP")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.videoPhone == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        Wifi
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.internetAccess == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.sna")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.SpecialNeedsAccessibility == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} >
                                                <Typography component="h5" variant="h5" align="left" sx={{ mb: 3, mt: 5 }}>
                                                    {t("description.permits")}
                                                </Typography>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.pets")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.pets == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.smoking")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.smoking == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.bigP")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.bigParties == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.loudM")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.loudMusic == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                {(post?.propertyType === "Office") && (
                                    <>
                                        <Grid container>
                                            <Grid item xs={12} >
                                                <Typography component="h5" variant="h5" align="left" sx={{ mb: 3, mt: 5 }}>
                                                    {t("description.interior")}
                                                </Typography>
                                                <Typography component="h5" variant="h5" align="left" sx={{ mb: 3, mt: 5 }}>
                                                    {t("description.interior")}
                                                </Typography>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.surfaceArea")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    <Typography component="body1" variant="body1" >
                                                        {post.surfaceArea}sqft
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.rooms")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.numberOfRooms == null ? (
                                                        <Typography component="body1" variant="body1" >
                                                            {t("description.none")}
                                                        </Typography>
                                                    ) : (
                                                        <Typography component="body1" variant="body1" >
                                                            {post.numberOfRooms}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.bathrooms")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.numberOfBathrooms == null ? (
                                                        <Typography component="body1" variant="body1" >
                                                            {t("description.none")}
                                                        </Typography>
                                                    ) : (
                                                        <Typography component="body1" variant="body1" >
                                                            {post.numberOfBathrooms}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.kitchen")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    <Typography component="body1" variant="body1" >
                                                        {post.kitchenArea} ㎡
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.furnished")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.furnished == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} >
                                                <Typography component="h5" variant="h5" align="left" sx={{ mb: 3, mt: 5 }}>
                                                    {t("description.exterior")}
                                                </Typography>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.surfaceArea")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    <Typography component="body1" variant="body1" >
                                                        {post.surfaceArea}sqft
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.condition")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    <Typography component="body1" variant="body1" >
                                                        {post.constructionCondition}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.parkingSpace")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.parkingSpace == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.brickRoof")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.brickRoof == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.Outbuildings")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.outBuildings == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.terrace")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.terrace == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.garden")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.garden == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} >
                                                <Typography component="h5" variant="h5" align="left" sx={{ mb: 3, mt: 5 }}>
                                                    {t("description.heatingg")} &amp; {t("description.coolingg")}
                                                </Typography>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.coolingsystem")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.coolingType == null ? (
                                                        <Typography component="body1" variant="body1" >
                                                            {t("description.none")}
                                                        </Typography>
                                                    ) : (
                                                        <Typography component="body1" variant="body1" >
                                                            {post.coolingType}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.heatingsystem")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.heatingType == null ? (
                                                        <Typography component="body1" variant="body1" >
                                                            {t("description.none")}
                                                        </Typography>
                                                    ) : (
                                                        <Typography component="body1" variant="body1" >
                                                            {post.heatingType}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} >
                                                <Typography component="h5" variant="h5" align="left" sx={{ mb: 3, mt: 5 }}>
                                                    {t("description.facilities")}
                                                </Typography>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.caretaker")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.caretakerConcierge == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.elevator")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.elevator == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.videoP")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.videoPhone == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        Wifi
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.internetAccess == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.sna")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.SpecialNeedsAccessibility == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.secureAccess")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.secureAccess == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.showRoom")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.showRoom == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.receptionDesk")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.receptionDesk == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} >
                                                <Typography component="h5" variant="h5" align="left" sx={{ mb: 3, mt: 5 }}>
                                                    {t("description.permits")}
                                                </Typography>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.pets")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.pets == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.smoking")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.smoking == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.bigP")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.bigParties == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.loudM")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.loudMusic == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                {(post?.propertyType === "Garden") && (
                                    <>
                                        <Grid container>
                                            <Grid item xs={12} >
                                                <Typography component="h5" variant="h5" align="left" sx={{ mb: 3, mt: 5 }}>
                                                    {t("description.facilities")}
                                                </Typography>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.furnished")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.furnished == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.caretaker")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.caretakerConcierge == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.videoP")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.videoPhone == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        Wifi
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.internetAccess == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.sna")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.SpecialNeedsAccessibility == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} >
                                                <Typography component="h5" variant="h5" align="left" sx={{ mb: 3, mt: 5 }}>
                                                    {t("description.permits")}
                                                </Typography>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.pets")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.pets == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.smoking")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.smoking == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.bigP")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.bigParties == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.loudM")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.loudMusic == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                {(post?.propertyType === "Land") && (
                                    <>
                                        <Grid container>
                                            <Grid item xs={12} >
                                                <Typography component="h5" variant="h5" align="left" sx={{ mb: 3, mt: 5 }}>
                                                    {t("description.positioning")}
                                                </Typography>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.facingStreet")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.facingWoods == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.intheWoods")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.inTheWoods == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.flatLand")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.flatLand == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} >
                                                <Typography component="h5" variant="h5" align="left" sx={{ mb: 3, mt: 5 }}>
                                                    {t("description.permits")}
                                                </Typography>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.pets")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.pets == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.smoking")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.smoking == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.bigP")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.bigParties == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.loudM")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.loudMusic == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                {(post?.propertyType === "Garage") && (
                                    <>
                                        <Grid container>
                                            <Grid item xs={12} >
                                                <Typography component="h5" variant="h5" align="left" sx={{ mb: 3, mt: 5 }}>
                                                    {t("description.facilities")}
                                                </Typography>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.videoP")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.videoPhone == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        Wifi
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.internetAccess == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.sna")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.SpecialNeedsAccessibility == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} >
                                                <Typography component="h5" variant="h5" align="left" sx={{ mb: 3, mt: 5 }}>
                                                    {t("description.permits")}
                                                </Typography>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.pets")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.pets == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.smoking")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.smoking == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.bigP")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.bigParties == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={5} md={4} >
                                                    <Typography component="h6" variant="h6">
                                                        {t("description.loudM")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    {post?.loudMusic == true ? (
                                                        <CheckCircleOutlineOutlinedIcon color="success" />
                                                    ) : (
                                                        <CancelOutlinedIcon color="error" />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                            <Hidden mdDown>
                                <Grid item md={4} sx={{ justifySelf: 'flex-end' }}>
                                    {displayAds()}
                                </Grid>
                            </Hidden>
                        </Grid>
                        {
                            post.mapPin ? (
                                <Grid container sx={{ mb: 3 }}>
                                    <Grid item xs={12} >
                                        <Typography component="h5" variant="h5" align="left" sx={{ mb: 3, mt: 5 }}>
                                            {t("description.location")}
                                        </Typography>
                                    </Grid>
                                    <GoogleMap
                                        mapContainerStyle={mapContainerStyle}
                                        zoom={8}
                                        center={center}
                                        options={options}
                                        // onClick={onMapClick}
                                        onLoad={onMapLoad}
                                    >
                                        <Marker
                                            // key={post.mapPin.lat}
                                            position={{ lat: post.mapPin.lat, lng: post.mapPin.lng }}
                                            icon={{
                                                url: "/house.svg",
                                                scaledSize: new window.google.maps.Size(30, 30),
                                                origin: new window.google.maps.Point(0, 0),
                                                anchor: new window.google.maps.Point(15, 15),
                                            }}
                                        />
                                    </GoogleMap>
                                </Grid>
                            ) : null
                        }
                        <Hidden mdUp>
                            <Grid container sx={{ mb: 2, mt: 2 }}>
                                <Grid item xs={12} >
                                    {displayAds()}
                                </Grid>
                            </Grid>
                        </Hidden>
                        {similarPosts === null ? (
                            null
                        ) : (<Grid container>
                            <Grid item xs={12} >
                                <Typography component="h5" variant="h5" align="left" sx={{ mb: 3, mt: 5 }}>
                                    {t("description.youmightlike")}
                                </Typography>
                            </Grid>
                            <Grid container spacing={3} >
                                <Grid item xs={12}>
                                    {displaySimilar()}
                                </Grid>
                            </Grid>
                        </Grid>
                        )}
                    </Grid>
                    <YouNeedToLogInSnackBar isOpen={isSnackBarOpen} handleClose={handleSnackBarClose} />
                </Container >
            </RootStyle >
        </>
    )
}
export default PostDetail

import axios from 'axios';

const API = axios.create({ baseURL: 'https://backend.eakary.com' });

API.interceptors.request.use((req) => {
  if (localStorage.getItem('profile')) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
  }

  return req;
});
export const publishPostCMS = (postId) => API.get(`/posts/publishPost/${postId}`)
export const unPublishPostCMS = (postId) => API.get(`/posts/unPublishPost/${postId}`)
export const fetchAllPosts = (page) => API.get(`/posts/all?page=${page}`);
export const fetchAllPostsCMS = () => API.get(`/posts/allPosts`);
export const fetchBuyPosts = (page) => API.get(`/posts/buy?page=${page}`);
export const fetchMapBuyPosts = () => API.get(`/posts/buy/map`);
export const fetchMapRentPosts = () => API.get(`/posts/rent/map`);
export const fetchRentPosts = (page) => API.get(`/posts/rent?page=${page}`);
export const fetchMyPosts = (id) => API.get(`/posts/myposts/${id}`);
export const fetchRecommendedPosts = (id) => API.get(`/posts/recommended/${id}`);
export const fetchFeaturedPosts = () => API.get(`/posts/featured`);
export const fetchPost = (id) => API.get(`/posts/${id}`);
export const fetchUsersBySearch = (searchQuery) => API.get(`/user/search?userQuery=${searchQuery.user}`);
export const fetchPostsBySearchb = (searchQuery) => API.get(`/posts/searchb?searchQuery=${searchQuery.valueMap}&cityQuery=${searchQuery.city}&propertyTypeQuery=${searchQuery.propertyType}&coolingTypeQuery=${searchQuery.coolingType}&heatingTypeQuery=${searchQuery.heatingType}&constructionConditionQuery=${searchQuery.constructionCondition}&listingTypeQuery=${searchQuery.listingType}&parkingSpaceQuery=${searchQuery.parkingSpace}&gardenQuery=${searchQuery.garden}&dwellingQuery=${searchQuery.dwelling}&brickRoofQuery=${searchQuery.brickRoof}&outBuildingsQuery=${searchQuery.outBuildings}&caretakerConciergeQuery=${searchQuery.caretakerConcierge}&swimmingPoolQuery=${searchQuery.swimmingPool}&terraceQuery=${searchQuery.terrace}&elevatorQuery=${searchQuery.elevator}&facingStreetQuery=${searchQuery.facingStreet}&inTheWoodQuery=${searchQuery.inTheWood}&flatLandQuery=${searchQuery.flatLand}&furnishedQuery=${searchQuery.furnished}&internetAccessQuery=${searchQuery.internetAccess}&videoPhoneQuery=${searchQuery.videoPhone}&specialNeedsAccessibilityQuery=${searchQuery.specialNeedsAccessibility}&secureAccessQuery=${searchQuery.secureAccess}&showRoomQuery=${searchQuery.showRoom}&receptionDeskQuery=${searchQuery.receptionDesk}&petsQuery=${searchQuery.pets}&smokingQuery=${searchQuery.smoking}&bigPartiesQuery=${searchQuery.bigParties}&loudMusicQuery=${searchQuery.loudMusic}&minPriceQuery=${searchQuery.minPrice}&maxPriceQuery=${searchQuery.maxPrice}&numberOfBedroomsQuery=${searchQuery.numberOfBedrooms}&numberOfBathroomsQuery=${searchQuery.numberOfBathrooms}&minAreaQuery=${searchQuery.minArea}&maxAreaQuery=${searchQuery.maxArea}&page=${searchQuery.page}&toSort=${searchQuery.toSort}&sortByIncreasingOrder=${searchQuery.sortByIncreasingOrder}`);
export const fetchPostsBySearchh = (searchQuery) => API.get(`/posts/searchb?searchQuery=${searchQuery.valueMap}&propertyTypeQuery=${searchQuery.propertyType}&coolingTypeQuery=${searchQuery.coolingType}&heatingTypeQuery=${searchQuery.heatingType}&constructionConditionQuery=${searchQuery.constructionCondition}&listingTypeQuery=${searchQuery.listingType}&parkingSpaceQuery=${searchQuery.parkingSpace}&gardenQuery=${searchQuery.garden}&dwellingQuery=${searchQuery.dwelling}&brickRoofQuery=${searchQuery.brickRoof}&outBuildingsQuery=${searchQuery.outBuildings}&caretakerConciergeQuery=${searchQuery.caretakerConcierge}&swimmingPoolQuery=${searchQuery.swimmingPool}&terraceQuery=${searchQuery.terrace}&elevatorQuery=${searchQuery.elevator}&facingStreetQuery=${searchQuery.facingStreet}&inTheWoodQuery=${searchQuery.inTheWood}&flatLandQuery=${searchQuery.flatLand}&furnishedQuery=${searchQuery.furnished}&internetAccessQuery=${searchQuery.internetAccess}&videoPhoneQuery=${searchQuery.videoPhone}&specialNeedsAccessibilityQuery=${searchQuery.specialNeedsAccessibility}&secureAccessQuery=${searchQuery.secureAccess}&showRoomQuery=${searchQuery.showRoom}&receptionDeskQuery=${searchQuery.receptionDesk}&petsQuery=${searchQuery.pets}&smokingQuery=${searchQuery.smoking}&bigPartiesQuery=${searchQuery.bigParties}&loudMusicQuery=${searchQuery.loudMusic}&minPriceQuery=${searchQuery.minPrice}&maxPriceQuery=${searchQuery.maxPrice}&numberOfBedroomsQuery=${searchQuery.numberOfBedrooms}&numberOfBathroomsQuery=${searchQuery.numberOfBathrooms}&minAreaQuery=${searchQuery.minArea}&maxAreaQuery=${searchQuery.maxArea}`);
export const fetchPostsBySearch = (searchQuery) => API.get(`/posts/searchb?searchQuery=${searchQuery.valueMap}&propertyTypeQuery=${searchQuery.propertyType}&coolingTypeQuery=${searchQuery.coolingType}&heatingTypeQuery=${searchQuery.heatingType}&constructionConditionQuery=${searchQuery.constructionCondition}&listingTypeQuery=${searchQuery.listingType}&parkingSpaceQuery=${searchQuery.parkingSpace}&gardenQuery=${searchQuery.garden}&dwellingQuery=${searchQuery.dwelling}&brickRoofQuery=${searchQuery.brickRoof}&outBuildingsQuery=${searchQuery.outBuildings}&caretakerConciergeQuery=${searchQuery.caretakerConcierge}&swimmingPoolQuery=${searchQuery.swimmingPool}&terraceQuery=${searchQuery.terrace}&elevatorQuery=${searchQuery.elevator}&facingStreetQuery=${searchQuery.facingStreet}&inTheWoodQuery=${searchQuery.inTheWood}&flatLandQuery=${searchQuery.flatLand}&furnishedQuery=${searchQuery.furnished}&internetAccessQuery=${searchQuery.internetAccess}&videoPhoneQuery=${searchQuery.videoPhone}&specialNeedsAccessibilityQuery=${searchQuery.specialNeedsAccessibility}&secureAccessQuery=${searchQuery.secureAccess}&showRoomQuery=${searchQuery.showRoom}&receptionDeskQuery=${searchQuery.receptionDesk}&petsQuery=${searchQuery.pets}&smokingQuery=${searchQuery.smoking}&bigPartiesQuery=${searchQuery.bigParties}&loudMusicQuery=${searchQuery.loudMusic}&minPriceQuery=${searchQuery.minPrice}&maxPriceQuery=${searchQuery.maxPrice}&numberOfBedroomsQuery=${searchQuery.numberOfBedrooms}&numberOfBathroomsQuery=${searchQuery.numberOfBathrooms}&minAreaQuery=${searchQuery.minArea}&maxAreaQuery=${searchQuery.maxArea}`);
export const createPost = (newPost) => API.post('/posts', newPost);
export const likePost = (id) => API.patch(`/posts/${id}/likePost`);
export const updatePost = (id, updatedPost) => API.patch(`/posts/edit/${id}`, updatedPost);
export const unlockPost = (id, unlockedPost) => API.patch(`/posts/unlock/${id}`, unlockedPost);

export const addLink360 = (id, updatedPost) => API.patch(`/posts/addLink/${id}`, updatedPost);
export const deletePost = (id) => API.delete(`/posts/delete/${id}`);
export const signIn = (formData) => API.post('/user/signin', formData);
export const signUp = (formData) => API.post('/user/signup', formData);
export const emailConfirmation = (formData) => API.post('/user/email-activate', formData);
export const createAgency = (formData) => API.post('/user/createAgency', formData);
export const updateUser = (id, updatedUser) => API.patch(`/user/updateUser/${id}`, updatedUser);
export const updateUserPassword = (id, updatedUserPassword) => API.patch(`/user/updateUserPassword/${id}`, updatedUserPassword);
export const deleteAccount = (id) => API.delete(`/user/deleteAccount/${id}`);

export const facebooklogin = (formData) => API.post('/user/facebooklogin', formData);
export const googlelogin = (formData) => API.post('/user/googlelogin', formData);

export const contactUs = (formData) => API.post('/contact/contactUs', formData);
export const forgetPasswordEmail = (formData) => API.put('/user/forgetPassword', formData);
export const resetPassword = (formData) => API.put('/user/resetPassword', formData);

export const fetchWishlist = (id) => API.get(`/posts/wishlist/${id}`);
export const addWishlist = (id, formData) => API.post(`/posts/wishlist/${id}`, formData);
export const deleteItemWishlist = (id, postId) => API.delete(`/posts/wishlist/${id}/${postId}`);

export const fetchNotifications = () => API.get(`/posts/notifications`);
export const fetchNotif = (id) => API.get(`/posts/notifications/${id}`);
export const updateNotif = (id, updatedNotif) => API.patch(`/posts/notifications/${id}`, updatedNotif);
export const createNotificationReq360 = (formData) => API.post(`/posts/notifications/req360`, formData);

export const getPostImgAds = (id) => API.get(`/imageAd/${id}`);
export const deleteCMSPost = (id) => API.delete(`/posts/${id}`);

export const createImgAd = (newAd, id) => API.post(`/imageAd/${id}/createAd`, newAd);
export const fetchImgAd = (id) => API.get(`/imageAd/imageAd/${id}`);
export const deleteImgAd = (id) => API.delete(`/imageAd/${id}`);
export const editImgAd = (id, updatedAd) => API.patch(`/imageAd/${id}`, updatedAd);

export const fetchAllUsers = () => API.get(`/user/AllUsers`);
export const fetchAllAgencies = () => API.get(`/user/AllAgencies`);
export const fetchUser = (id) => API.get(`/user/getUser/${id}`);
export const fetchUserPosts = (id) => API.get(`/user/${id}/userPosts`);
export const deleteUser = (id) => API.delete(`/user/deleteUser/${id}`);
export const deleteUserAcc = (id) => API.delete(`/user/deleteAccount/${id}`);

export const fetchAllAds = () => API.get('ad/ad/all');
export const fetchAd = (id) => API.get(`/ad/${id}`);
export const fetchSpecificAd = (id) => API.get(`ad/specificad/${id}`);
export const createAd = (newAd) => API.post('/ad/createAd', newAd);
export const deleteAd = (id) => API.delete(`/ad/${id}`);
export const editAd = (id, updatedAd) => API.patch(`/ad/${id}`, updatedAd);
export const edit360Link = (id, updatedPost) => API.patch(`/posts/edit360Link/${id}`, updatedPost);


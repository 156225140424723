import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Grid from "@material-ui/core/Grid";
import { fCurrency } from '../../utils/formatNumber';
import Typography from '@material-ui/core/Typography';
import { deletePost } from '../../api';

import HotelIcon from '@material-ui/icons/Hotel';
import BathtubIcon from '@material-ui/icons/Bathtub';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import Chip from '@material-ui/core/Chip';
import Stack from '@material-ui/core/Stack';
import DialogDeletePost from '../Dialog/DialogPost'
import UseStyles from './styles';
import Button from '@material-ui/core/Button'
import apartment from "../../images/apartment.jpg";
import 'boxicons';
import axios from 'axios'
import { Trans, useTranslation } from 'react-i18next';

const OwnedPost = ({ post, setCurrentId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('profile'));

  const deletePost = (id) => {
    axios.delete(`https://backend.eakary.com/posts/delete/` + id).then(() => {

    })
  }


  const [open, setOpen] = useState(false);

  const [postId, setId] = useState();

  const openDialog = (id) => {
    setOpen(true);

    setId(id);
  }

  const closeDialog = () => {
    setOpen(false);
  }

  const openPost = (e) => {
    // dispatch(getPost(post._id, navigate));

    navigate(`/posts/${post._id}`);
  };
  const classes = UseStyles();

  //////////////////////////////////////
  const { t, i18n } = useTranslation();

  return (

    <>
      <Card variant="outlined" className={classes.root} elevation={0} >
        <Grid container>
          {(post?.bundleChosen === 'Gold') && (
            <Grid container >
              <Chip label={t("description.gold")} sx={{ mt: 1, ml: 1, backgroundColor: "#FFD700", color: "white" }} className={classes.Featured} size="small" />
            </Grid>

          )}

          {(post?.bundleChosen === 'Platinum') && (
            <Grid container >
              <Chip label={t("description.platinum")} sx={{ mt: 1, ml: 1, backgroundColor: "#E5E4E2", color: "white" }} className={classes.Featured} size="small" />
            </Grid>

          )}
        </Grid>
        <div onClick={openPost} style={{ cursor: 'pointer' }}>
          <CardMedia
            className={classes.media}
            image={post.mainImage ? post.mainImage.url : null}
            title={apartment}
            alt={apartment}
          />

          <CardContent style={{ textAlign: "Left" }}>
            <Typography variant="subtitle2" component="p" >
              {post.propertyType}
            </Typography>
            <Typography variant="h4" style={{ marginBottom: "5px", marginTop: "5px" }} component="p" >
              {fCurrency(post.price)}
            </Typography>
            <Typography variant="body2" component="p" noWrap>
              {post.mapPin ? post.mapPin.address : post.city}
            </Typography>
            <hr className={classes.solid} style={{ marginBottom: "10px", marginTop: "10px" }} />
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              {(post?.numberOfBedrooms !== null) && (

                <Chip icon={<HotelIcon style={{ color: "#001329" }} />} label={post.numberOfBedrooms} size="small" style={{ backgroundColor: "white" }} />

              )}
              {(post?.numberOfBathrooms !== null) && (

                <Chip icon={<BathtubIcon style={{ color: "#001329" }} />} label={post.numberOfBathrooms} size="small" style={{ backgroundColor: "white" }} />

              )}
              {(post?.surfaceArea !== null) && (

                <Chip icon={<ZoomOutMapIcon style={{ color: "#001329" }} />} label={post.surfaceArea} size="small" style={{ backgroundColor: "white" }} />

              )}

            </Stack>

          </CardContent>
        </div>
        <CardActions >
          <Button onClick={() => navigate(`/posts/edit/${post._id}`)} size="medium" color="primary">
            <Trans i18nKey="description.edit">
              Edit
            </Trans>
          </Button>
          <Button onClick={() => openDialog(post._id)} size="medium" color="error">
            <Trans i18nKey="description.delete">
              Delete
            </Trans>
          </Button>
          <DialogDeletePost id={postId} open={open} close={closeDialog} />
        </CardActions>

      </Card>

    </>

  );
}


export default OwnedPost;
import React, { useState, useEffect } from 'react';
import { Alert, Dialog, DialogTitle, Grid, List, ListItem, ListItemText, Snackbar, Typography } from "@material-ui/core";
import ProfileField from "../TextFields/TextField";
import { LoadingButton } from "@material-ui/lab";
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from "react-redux";
import { updateUserPassword } from "../../actions/auth";
import { Trans, useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

const ChangePasswordPopUp = ({ open, handleClose }) => {
    const { t, i18n } = useTranslation();

    
    const dispatch = useDispatch();
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [LoggedUser, setLoggedUser] = useState(JSON.parse(localStorage.getItem('profile')))
    const [success, setSuccess] = useState(undefined);
    const [characterLenght, setLength] = useState(false);
    const [openSnack, setSnackOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleShowOldPassword = () => setShowOldPassword(!showOldPassword);
    const handleShowNewPassword = () => setShowNewPassword(!showNewPassword);
    const minLenght = 8;
    const Upper = /^(?=.*[A-Z])/;
    const Numb = /^(?=.*[0-9])/;
    const SpecialChar = /^(?=.*[@#$%^&+=])/
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false)
    };
    const handlePasswordSubmit = async (values) => {
        const id = LoggedUser.result._id;
        setLoading(true)
        const form =
        {
            currentPassword: values.currentPassword,
            newPassword: values.newPassword,
            confirmPassword: values.confirmPassword
        }
        const success = await dispatch(updateUserPassword(id, form));
        if (success) {
            setLoading(false)
        } else setLoading(false)
        setSuccess(success)
        setSnackOpen(true)
    };

    const validationSchema = Yup.object({
        currentPassword: Yup.string()
            .required('Required!'),
        newPassword: Yup.string()
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                t("description.mustContain")
            )
            .required(t("description.requir")),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Password must match')
            .required(t("description.requir"))
    })
    const formik = useFormik({
        initialValues: {
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
        },
        onSubmit: values => {
            handlePasswordSubmit(values)
        },
        validationSchema,
    }
    )

    const [arLang, setArLang] = useState(false);
  let cookieLanguage = Cookies.get('i18next');


  useEffect(() => {
    if (cookieLanguage == "ar") {
      setArLang(true);
    }
  }, [])
    //////////////////////////////////////
    
    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle sx={{ padding: '2em 2em 1em 2em' }}>
                <Trans i18nKey="description.changePass">
                    Change Password
                </Trans>
            </DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <List sx={{ padding: '0em 2em 2em 2em'}}>
                    <ListItem >
                        {
                            arLang ? (
                                <ProfileField handleChange={formik.handleChange} placeholder={t("description.currpassword")} name="currentPassword" label={t("description.currpassword")} width='20em' type={showOldPassword ? 'text' : 'password'} handleShowPassword={handleShowOldPassword} />
                            ) : (
                                 <ProfileField handleChange={formik.handleChange} placeholder={t("description.currpassword")} name="currentPassword" label={t("description.currpassword")} width='20em' type={showOldPassword ? 'text' : 'password'} handleShowPassword={handleShowOldPassword} />
                            )
                        }
                       
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            secondary={t("description.passCurrAss")}
                            sx={{ marginTop: -1 }}
                        />
                    </ListItem>
                    <ListItem>
                        <ProfileField handleChange={formik.handleChange} placeholder={t("description.newpassword")} name="newPassword" label={t("description.newpassword")} width='20em' type={showNewPassword ? 'text' : 'password'} handleShowPassword={handleShowNewPassword} />
                    </ListItem>
                    {/*{formik.errors.newPassword ? <ListItem>{formik.errors.newPassword}</ListItem>:null}*/}
                    <ListItem>
                        {formik.values.newPassword.length >= minLenght ? (
                            <>
                                <CheckCircleIcon id='character_Check' sx={{ color: 'green' }} fontSize='s' />
                                <ListItemText
                                    id='characterLength'
                                    secondary={<Typography sx={{ color: 'green', fontSize: '15px' }} >
                                        <Trans i18nKey="description.8char">
                                            At least 8 characters
                                        </Trans>
                                    </Typography>}
                                />
                            </>
                        ) : (
                            <>
                                <CancelIcon id='character_Uncheck' sx={{ color: 'red' }} fontSize='s' />
                                <ListItemText
                                    id='characterLength'
                                    secondary={<Typography sx={{ color: 'red', fontSize: '15px' }}>
                                        <Trans i18nKey="description.8char">
                                            At least 8 characters
                                        </Trans>
                                    </Typography>}
                                />
                            </>
                        )
                        }
                    </ListItem>
                    {
                        success !== undefined ? (
                            success ? (
                                <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                                    <Alert onClose={handleSnackClose} severity="success">
                                        <Trans i18nKey="description.passUpd">
                                            Password updated successfully
                                        </Trans>
                                    </Alert>
                                </Snackbar>
                            ) : (
                                <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                                    <Alert onClose={handleSnackClose} severity="error">
                                        <Trans i18nKey="description.currentPass">
                                            Current password does not match
                                        </Trans>
                                    </Alert>
                                </Snackbar>
                            )) : null
                    }
                    <ListItem sx={{ marginTop: -2 }}>
                        {
                            Numb.test(formik.values.newPassword) ? (
                                <>
                                    <CheckCircleIcon id='number_Check' sx={{ color: 'green' }} fontSize='s' />
                                    <ListItemText
                                        id='number'
                                        secondary={<Typography sx={{ color: 'green', fontSize: '15px' }} >
                                            <Trans i18nKey="description.1Nbr">
                                                At least 1 number
                                            </Trans>
                                        </Typography>}
                                    />
                                </>
                            ) : (
                                <>
                                    <CancelIcon id='number_Uncheck' sx={{ color: 'red' }} fontSize='s' />
                                    <ListItemText
                                        id='number'
                                        sx={{ color: 'red' }}
                                        secondary={<Typography sx={{ color: 'red', fontSize: '15px' }} >
                                            <Trans i18nKey="description.1Nbr">
                                                At least 1 number
                                            </Trans>
                                        </Typography>}
                                    />
                                </>
                            )
                        }
                    </ListItem>
                    <ListItem sx={{ marginTop: -2 }}>
                        {
                            Upper.test(formik.values.newPassword) ? (
                                <>
                                    <CheckCircleIcon id='special_Check' sx={{ color: 'green' }} fontSize='s' />
                                    <ListItemText
                                        id='characterSpecial'
                                        secondary={<Typography sx={{ color: 'green', fontSize: '15px' }} >
                                            <Trans i18nKey="description.1Spec">
                                                At least 1 special character
                                            </Trans>
                                        </Typography>}
                                    />
                                </>
                            ) : (
                                <>
                                    <CancelIcon id='special_Uncheck' sx={{ color: 'red' }} fontSize='s' />
                                    <ListItemText
                                        id='characterSpecial'
                                        sx={{ color: 'red' }}
                                        secondary={<Typography sx={{ color: 'red', fontSize: '15px' }} >
                                            <Trans i18nKey="description.1Spec">
                                                At least 1 special character
                                            </Trans>
                                        </Typography>}
                                    />
                                </>
                            )
                        }
                    </ListItem>
                    <ListItem sx={{ marginTop: -2 }}>
                        {
                            SpecialChar.test(formik.values.newPassword) ? (
                                <>
                                    <CheckCircleIcon id='upper_Check' sx={{ color: 'green' }} fontSize='s' />
                                    <ListItemText
                                        id='upperCase'
                                        secondary={<Typography sx={{ color: 'green', fontSize: '15px' }} >
                                            <Trans i18nKey="description.1Uper">
                                                At least 1 uppercase letter
                                            </Trans>
                                        </Typography>}
                                    />
                                </>
                            ) : (
                                <>
                                    <CancelIcon id='upper_Uncheck' sx={{ color: 'red' }} fontSize='s' />
                                    <ListItemText
                                        id='upperCase'
                                        sx={{ color: 'red' }}
                                        secondary={<Typography sx={{ color: 'red', fontSize: '15px' }} >
                                            <Trans i18nKey="description.1Uper">
                                                At least 1 uppercase letter
                                            </Trans>
                                        </Typography>}
                                    />
                                </>
                            )
                        }
                    </ListItem>
                    <ListItem>
                        <ProfileField handleChange={formik.handleChange} placeholder={t("description.confurNP")} name="confirmPassword" label={t("description.confurNP")} width='20em' type='password' />
                    </ListItem>
                    {/*{formik.errors.confirmPassword ? <ListItem>{formik.errors.confirmPassword}</ListItem>:null}*/}
                    <ListItem>
                        {
                            formik.values.confirmPassword === formik.values.newPassword
                                &&
                                formik.values.confirmPassword !== '' ? (
                                <>
                                    <CheckCircleIcon id='upper_Check' sx={{ color: 'green' }} fontSize='s' />
                                    <ListItemText
                                        id='upperCase'
                                        secondary={<Typography sx={{ color: 'green', fontSize: '15px' }} >
                                            <Trans i18nKey="description.passMatch">
                                                Password matches
                                            </Trans>
                                        </Typography>}
                                    />
                                </>
                            ) : (
                                <>
                                    <CancelIcon id='special_Uncheck' sx={{ color: 'red' }} fontSize='s' />
                                    <ListItemText
                                        id='characterSpecial'
                                        sx={{ color: 'red' }}
                                        secondary={<Typography sx={{ color: 'red', fontSize: '15px' }} >
                                            <Trans i18nKey="description.passNotMatch">
                                                Password does not match
                                            </Trans>
                                        </Typography>}
                                    />
                                </>
                            )
                        }
                    </ListItem>
                    <ListItem>
                        <LoadingButton
                            size="large"
                            type="submit"
                            variant="contained"
                            style={{width:"100%", marginTop: '0.5em' }}
                            loading={loading}

                        >
                            {t("description.changePass")}
                        </LoadingButton>
                    </ListItem>
                </List>
            </form>
        </Dialog>
    )
}
export default ChangePasswordPopUp
export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const FETCH_ALL = 'FETCH_ALL';
export const FETCH_POST = 'FETCH_POST';
export const FETCH_MAPBUY_POST = 'FETCH_MAPBUY_POST';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_IMGAD = 'FETCH_IMGAD';
export const FETCH_USER_POSTS = 'FETCH_USER_POSTS';
export const FETCH_POST_ADS = 'FETCH_POST_ADS';
export const FETCH_ALL_POSTS = 'FETCH_ALL_POSTS';
export const FETCH_MY_POSTS = 'FETCH_MY_POSTS';
export const FETCH_AD = 'FETCH_AD';
export const FETCH_BY_SEARCH = 'FETCH_BY_SEARCH';
export const LIKE = 'LIKE';
export const END_LOADING = 'END_LOADING';
export const START_LOADING = 'START_LOADING';
export const FETCH_SPECIFIC_AD = "FETCH_SPECIFIC_AD";

export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';
export const GET_WISHLIST = 'GET_WISHLIST';
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST';

export const FETCH_NOTIFICATION = 'FETCH_NOTIFICATION';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const CREATE_NOTIFICATION_REQ_360 = 'CREATE_NOTIFICATION_REQ_360';

export const AUTH = 'AUTH';

export const LOGOUT = 'LOGOUT';

export const UPDATE_AD = 'UPDATE_AD';
export const UPDATE_IMGAD = 'UPDATE_IMGAD';
export const UPDATE_POST = 'UPDATE_IMGAD';

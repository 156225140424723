import React from 'react'
import { useSelector } from 'react-redux';
import { Grid, CircularProgress } from '@material-ui/core';
import OwnedPost from './ownedPost'
import BlockedPost from "./blockedPost";

const OwnedPosts = ({ setCurrentId }) => {
    const { posts, isLoading } = useSelector((state) => state.posts);  //callback funcion .posts because in the reducers we called posts

    
   if (!posts.length && !isLoading) return 'Add a property';

    return (
        isLoading ? <div class="d-flex justify-content-center">
        <CircularProgress />
   
      </div>  : (  
        <>
        
        <Grid container spacing={3} justifyContent="center">
        {posts.map((post) => {
            const dateNow = new  Date();
            const expiredAt = Date.parse(post.expireAt);
            if(expiredAt !== undefined) {
                if(dateNow > expiredAt) {
                    return(
                    <Grid key={post._id} item xs={12} sm={6} md={4} lg={3}>
                        <BlockedPost post={post} setCurrentId={setCurrentId}/>
                    </Grid>
                    )
                }else {
                    return (
                        <Grid key={post._id} item xs={12} sm={6} md={4} lg={3}>
                            <OwnedPost post={post} setCurrentId={setCurrentId}/>
                        </Grid>
                    )
                }
            }else return (
                <Grid key={post._id} item xs={12} sm={6} md={4} lg={3}>
                    <OwnedPost post={post} setCurrentId={setCurrentId}/>
                </Grid>
            )
        })}
        </Grid>
   </> 
      )
    );

    }

    
  export default OwnedPosts;
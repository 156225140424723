import { makeStyles } from "@material-ui/styles"

const UseStyles = makeStyles((theme) => ({
    row: {
        flex: 1,
        marginBottom: 20,
        marginLeft: 10,
    },
    propertyType: {
        margin: 10,
        minWidth: 200,
    },
    root: {
        '& > *': {
            flex: 1,
            // width: 225,
        },
    },
    marginContainer: {
        // width: 225,
        // marginLeft: 50,
        // marginRight: 0,
        // marginLeft: 0,
        maxWidth: '1500px'
        // marginRight:0,
        // mx: "auto"
    },
    description: {
        width: "100%",
        // display: "inline"
    },
    inline: {
        display: 'inline-flex'
    },
    dislpayflex: {
        display: 'flex'
    },
    fullwidth: {
        width: "100%",
        // maxWidth: "100%"
    },
    button: {
        marginTop: 20,
        display: 'flex'

    },
    upload: {
        maxWidth: 70
    },
    descField: {
        // paddingBottom: "70px"
    }

}));

export default UseStyles
import { Link as RouterLink } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Card, Stack, Link, Container, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import { MHidden } from '../../components/@material-extend';
import LoginForm from '../../components/Auth/LoginForm';
import AuthSocial from '../../components/Auth/AuthSocial';
import Page from '../../components/Page';
// ----------------------------------------------------------------------
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));
const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));
const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));
// ----------------------------------------------------------------------
export default function Login() {
  //////////////////////////////////////
  const { i18n } = useTranslation();
  return (
    <RootStyle title="Login | eAkary">
      <AuthLayout />
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 14, mt: 10, mb: 5 }}>
            <Trans i18nKey="description.welcomeBack">
              Welcome Back
            </Trans>
          </Typography>
          <img src="/static/illustrations/login.svg" alt="login" />
        </SectionStyle>
      </MHidden>
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              <Trans i18nKey="description.signin">
                Sign in to eAkary
              </Trans>
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              <Trans i18nKey="description.enterDetails">
                Enter your details below.
              </Trans>
            </Typography>
          </Stack>
          <AuthSocial />
          <LoginForm />
          <Typography variant="body2" align="center" sx={{ mt: 3 }}>
            <Trans i18nKey="description.noAcc">
              Don’t have an account?  &nbsp;&nbsp;
            </Trans>
            &nbsp; &nbsp;
            <Link variant="subtitle2" component={RouterLink} to="/auth/register">
              <Trans i18nKey="description.getStarted">
                &nbsp;&nbsp; Get started
              </Trans>
            </Link>
          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}

import React, { useState } from 'react';
import PostCard from "../../../components/postCards/postCard";
import Grid from "@material-ui/core/Grid";
import UseStyles from "./style";
import { useTheme } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useEffect } from "react";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import { useSelector, useDispatch } from "react-redux";
import { getAllPosts } from '../../../actions/posts';
import Posts from '../../../components/postCards/Posts';
import { useFormik } from 'formik';
import { Container, Stack, Typography } from '@material-ui/core';
import Pagination from '../../../components/Pagination/PaginationBuy';
import { useHistory, useLocation, useNavigate } from 'react-router-dom';
import FilterSidebar from '../../../components/Filter/filter';
import Map from "../../../components/MapRent/Map";
import { getPostsBySearch } from '../../../actions/posts';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CMSPostsPage = () => {


  const classes = UseStyles();
  const theme = useTheme();
  const query = useQuery();
  const page = query.get('page') || 1;
  const dispatch = useDispatch();
  const [currentId, setCurrentId] = useState(0);

  const user = JSON.parse(localStorage.getItem('profile'));

  useEffect(() => {
    dispatch(getAllPosts());
  }, [currentId, dispatch]);
  // const getBuyPosts = useSelector(state => state.getBuyPosts);
  // const { posts } = getBuyPosts;



  //////////////////////////////////////////////////

  const [Tabvalue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  const handleChangeIndex = (index) => {
    setTabValue(index);
  };

  //////////////////////////////////////////////////
  const [openFilter, setOpenFilter] = useState(false);

  const formik = useFormik({
    initialValues: {
      features: '',
    },
    onSubmit: () => {
      setOpenFilter(false);
    }
  });

  const { resetForm, handleSubmit } = formik;

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    handleSubmit();
    resetForm();
  };

  const [search, setSearch] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const [listingType, setListingType] = useState('');
  const navigate = useNavigate();

  const searchPost = () => {
    if (search.trim() || propertyType.trim()) {
      dispatch(getPostsBySearch({ search, propertyType, listingType }));
      navigate(`/posts/search?searchQuery=${search}&propertyTypeQuery=${propertyType}&listingTypeQuery=${listingType}`);
    }
    else {
      navigate('/CMS/landingAdmin');
    }
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      searchPost();
    }
  };

  ////////////////////////////////////////////////////////////////////////   


  return (
    <>


      <Container>
        <Grid container justifyContent="center">
          <Paper className={classes.search}
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'left', width: 400, border: 1 }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search by Property Type"
              inputProps={{ 'aria-label': 'search property Type' }}
              value={propertyType}
              onChange={(e) => { setPropertyType(e.target.value) }}
              onKeyDown={handleKeyPress}
            />
            <IconButton onClick={searchPost} type="submit" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

          </Paper>
          <Grid>
            <Typography variant="h3" mt={13} mb={2}>All Properties</Typography>
          </Grid>
        </Grid>
        {/* <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mb: 5 }}
        >
          {/* <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
          <Button
        disableRipple
        variant="contained"
        color="secondary"
      >
       Add Post&nbsp;
      </Button>
          </Stack> */}

        <SwipeableViews
          index={Tabvalue}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={Tabvalue} index={0}>

            <Grid container spacing={3} >
              <Grid item xs={12}>
                <Posts setCurrentId={setCurrentId} />


              </Grid>
            </Grid>
            <Pagination page={page} />
          </TabPanel>
        </SwipeableViews>
      </Container>

    </>
  )




}

export default CMSPostsPage;
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { signup } from "../../actions/auth";
import Input from '../../components/Input/Input';
import * as Yup from 'yup';
import { useFormik } from 'formik';
// material
import { Alert, Snackbar, Stack } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import * as React from "react";
import { Trans, useTranslation } from 'react-i18next';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
// ----------------------------------------------------------------------

function RegisterForm({ navigation }) {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openSnack, setSnackOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  const minLenght = 8;
  const Upper = /^(?=.*[A-Z])/;
  const Numb = /^(?=.*[0-9])/;
  const SpecialChar = /^(?=.*[@#$%^&+=])/

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false)
  };
  const handleRegisterSubmit = async (values) => {
    setLoading(true)
    let email = values.email;
    email = email.trim();
    email = email.charAt(0).toLowerCase() + email.slice(1);
    const form =
    {
      email: email,
      password: values.password,
      firstName: values.firstName,
      lastName: values.lastName,
      confirmPassword: values.confirmPassword
    }
    const success = await dispatch(signup(form));

    if (success) {
      setLoading(false)
      navigate("/auth/authentication/emailSent", {
        state: {
          email: values.email,
          password: values.password,
          firstName: values.firstName,
          lastName: values.lastName,
          confirmPassword: values.confirmPassword
        }
      });
    } else setLoading(false)

    setSuccess(success)
    setSnackOpen(true)
  };
  const validationSchema = Yup.object({
    firstName: Yup.string()
      .min(2, t("description.tooShort"))
      .max(50, t("description.tooLong"))
      .required(t("description.fnReq")),
    lastName: Yup.string()
      .min(2, t("description.tooShort"))
      .max(50, t("description.tooLong"))
      .required(t("description.lnReq")),
    email: Yup.string()
      .email(t("description.emailValid"))
      .required(t("description.emailMustbeValid")),
    password: Yup.string()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%_*#?&])[A-Za-z\d@$!%_*#?&]{8,}$/,
        t("description.mustContain")
      )
      .required(t("description.passRequired")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Password must match')
      .required('Required!')
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: ''
    },
    validationSchema,
    onSubmit: (values) => {
      handleRegisterSubmit(values);
    }
  });

  //////////////////////////////////////


  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }} spacing={2}>
          <div style={{ width: '100%' }}>
            <Input required name="firstName" label={t("description.fn")} handleChange={formik.handleChange} handleBlur={formik.handleBlur} autoFocus half />
            {formik.touched.firstName && formik.errors.firstName ? <span style={{ fontSize: '15px' }}>
              <div style={{ color: 'red' }}>{formik.errors.firstName}</div></span> : null}
          </div>
          <div style={{ width: '100%' }}>
            <Input required name="lastName" label={t("description.ln")} handleChange={formik.handleChange} handleBlur={formik.handleBlur} half />
            {formik.touched.lastName && formik.errors.lastName ? <span style={{ fontSize: '15px' }}>  <div style={{ color: 'red' }}>{formik.errors.lastName}</div></span> : null}
          </div>
        </Stack>

        {
          success !== undefined ? (
            success ? null : (
              <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                <Alert onClose={handleSnackClose} severity="error">
                  <Trans i18nKey="description.userExist">
                    User already exists, please try a different email
                  </Trans>
                </Alert>
              </Snackbar>
            )) : null
        }

        <div sx={{ marginBottom: 20 }}>
          <Input required name="email" label={t("description.emailAdd")} handleChange={formik.handleChange} handleBlur={formik.handleBlur} type="email" />
          {formik.touched.email && formik.errors.email ? <span style={{ fontSize: '15px' }}>  <div style={{ color: 'red' }}>{formik.errors.email}</div></span> : null}
        </div>
        <div sx={{ marginBottom: 20 }}>
          <Input required name="password" label={t("description.password")} handleChange={formik.handleChange} handleBlur={formik.handleBlur} type={showPassword ? 'text' : 'password'} handleShowPassword={handleShowPassword} />
          {formik.touched.password ? (formik.values.password.length >= minLenght ? (
            <>
              <CheckCircleIcon id='character_Check' sx={{ color: 'green' }} fontSize='s' />
              <span style={{ color: 'green', fontSize: '15px' }}>
                <Trans i18nKey="description.8char">
                  At least 8 characters
                </Trans>
                <br />
              </span>
            </>
          ) : (
            <>
              <CancelIcon id='character_Uncheck' sx={{ color: 'red' }} fontSize='s' />
              <span style={{ color: 'red', fontSize: '15px' }}>
                <Trans i18nKey="description.8char">
                  At least 8 characters
                </Trans>
                <br />
              </span>
            </>
          )) : null
          }

          {formik.touched.password ? (
            Numb.test(formik.values.password) ? (
              <>
                <CheckCircleIcon id='number_Check' sx={{ color: 'green', }} fontSize='s' />
                <span style={{ color: 'green', fontSize: '15px' }} >
                  <Trans i18nKey="description.1Nbr">
                    At least 1 number
                  </Trans>
                </span>
                <br />
              </>
            ) : (
              <>
                <CancelIcon id='number_Uncheck' sx={{ color: 'red' }} fontSize='s' />
                <span style={{ color: 'red', fontSize: '15px' }} >
                  <Trans i18nKey="description.1Nbr">
                    At least 1 number
                  </Trans>
                </span>
                <br />
              </>
            )) : null
          }
          {formik.touched.password ? (
            Upper.test(formik.values.password) ? (
              <>
                <CheckCircleIcon id='special_Check' sx={{ color: 'green' }} fontSize='s' />
                <span style={{ color: 'green', fontSize: '15px' }} >
                  <Trans i18nKey="description.1Uper">
                    At least 1 uppercase letter
                  </Trans>
                </span>
                <br />
              </>
            ) : (
              <>
                <CancelIcon id='special_Uncheck' sx={{ color: 'red' }} fontSize='s' />
                <span style={{ color: 'red', fontSize: '15px' }} >
                  <Trans i18nKey="description.1Uper">
                    At least 1 uppercase letter
                  </Trans>
                </span>
                <br />
              </>
            )
          ) : null
          }
          {
            formik.touched.password ? (
              SpecialChar.test(formik.values.password) ? (
                <>
                  <CheckCircleIcon id='upper_Check' sx={{ color: 'green' }} fontSize='s' />
                  <span style={{ color: 'green', fontSize: '15px' }} >
                    <Trans i18nKey="description.1Spec">
                      At least 1 special character
                    </Trans>
                  </span>
                </>
              ) : (
                <>
                  <CancelIcon id='upper_Uncheck' sx={{ color: 'red' }} fontSize='s' />
                  <span style={{ color: 'red', fontSize: '15px' }} >
                    <Trans i18nKey="description.1Spec">
                      At least 1 special character
                    </Trans>
                  </span>
                </>
              )) : null
          }
        </div>
        <div sx={{ marginBottom: 20 }}>
          <Input required name="confirmPassword" label={t("description.repeatPassword")} handleChange={formik.handleChange} handleBlur={formik.handleBlur} type="password" />
          {
            formik.touched.confirmPassword ? (
              formik.values.confirmPassword === formik.values.password
                &&
                formik.values.confirmPassword !== '' ? (
                <>
                  <CheckCircleIcon id='upper_Check' sx={{ color: 'green' }} fontSize='s' />
                  <span style={{ color: 'green', fontSize: '15px' }} >
                    <Trans i18nKey="description.passMatch">
                      Password matches
                    </Trans>
                  </span>
                </>
              ) : (
                <>
                  <CancelIcon id='special_Uncheck' sx={{ color: 'red' }} fontSize='s' />
                  <span style={{ color: 'red', fontSize: '15px' }} >
                    <Trans i18nKey="description.passMatch">
                      Password matches
                    </Trans>
                  </span>
                </>
              )
            ) : null
          }          </div>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={loading}
        >
          <Trans i18nKey="description.register">
            register
          </Trans>
        </LoadingButton>
      </Stack>

      {
        success !== undefined ? (
          success ? null : (
            <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
              <Alert onClose={handleSnackClose} severity="error">
                <Trans i18nKey="description.userExist">
                  User already exists, please try a different email
                </Trans>
              </Alert>
            </Snackbar>
          )) : null
      }
      {/* </Stack> */}
    </form >
  );
}

export default RegisterForm;
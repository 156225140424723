import React, { useEffect, useState } from "react";
import axios from 'axios'
import 'bootstrap/dist/css/bootstrap.css'
import './SearcchBar.css'
import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import Downshift from "downshift";
import './tagsScrollBar.css'
const useStyles = makeStyles(theme => ({
    chip: {
        margin: '0 0.7rem 0 0'
    }
}));
export default function TagsInput({ ...props }) {
    const { results, setResults } = props;
    const [properties, setProperties] = useState([]);
    // const [results, setResults] = useState([]);
    const [inputValue, setInputValue] = React.useState("");
    const [selectedItem, setSelectedItem] = React.useState([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const getProperties = async () => {
            try {
                const res = await axios.get(`https://backend.eakary.com/posts/allPosts`);
                setProperties(res.data.data);
            } catch (e) {
                setProperties([])
            }
        }
        getProperties();

    }, [])
    const getResults = (inputValue) => {
        if (inputValue !== '') {
            const input_Value = inputValue.trim().toLowerCase();
            const inputLength = input_Value.length;
            const results = properties.filter(prod => prod.city.toLowerCase().slice(0, inputLength) === input_Value);
            const cities = results.map(property => property.city)
            let uniqueCities = [...new Set(cities)];
            setResults(uniqueCities);
            //return inputLength === 0 ? [] : products.filter(prod => prod.city.toLowerCase().slice(0, inputLength) === inputValue);
        } else {
            setResults([]);
        }
    }
    const res = results.map((c, i) => {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        return <a key={`${c}${i}`} class="list-group-item list-group-item-action hoveredItem" aria-current="true" onClick={() => handleChange(c)}>
            <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{c}</h5>
            </div>
        </a>
    });

    const classes = useStyles();
    const { selectedTags, placeholder, tags, ...other } = props;
    useEffect(() => {
        setSelectedItem(tags);
    }, [tags]);
    useEffect(() => {
        selectedTags(selectedItem);
    }, [selectedItem, selectedTags]);

    function handleKeyDown(event) {
        if (event.key === "Enter") {
            const newSelectedItem = [...selectedItem];
            const duplicatedValues = newSelectedItem.indexOf(
                event.target.value.trim()
            );

            if (duplicatedValues !== -1) {
                setInputValue("");
                return;
            }
            if (!event.target.value.replace(/\s/g, "").length) return;

            newSelectedItem.push(event.target.value.trim());
            setSelectedItem(newSelectedItem);
            setInputValue("");
            setResults([]);
        }
        if (
            selectedItem.length &&
            !inputValue.length &&
            event.key === "Backspace"
        ) {
            setSelectedItem(selectedItem.slice(0, selectedItem.length - 1));
        }
    }
    function handleChange(item) {
        let newSelectedItem = [...selectedItem];
        if (newSelectedItem.indexOf(item) === -1) {
            newSelectedItem = [...newSelectedItem, item];
        }
        setInputValue("");
        setResults([])
        setSelectedItem(newSelectedItem);
    }

    const handleDelete = item => () => {
        const newSelectedItem = [...selectedItem];
        newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
        setSelectedItem(newSelectedItem);
    };

    function handleInputChange(event) {
        getResults(event.target.value)
        setInputValue(event.target.value);
    }
    return (
        <React.Fragment>
            <Downshift
                id="downshift-multiple"
                inputValue={inputValue}
                onChange={handleChange}
                selectedItem={selectedItem}
            >
                {({ getInputProps }) => {
                    const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                        onKeyDown: handleKeyDown,
                        placeholder
                    });
                    return (
                        <div id="tagsSearchScrollBar" className="searchBarSearchInput" style={{ overflowX: 'auto', flex: '2' }}>
                            <TextField
                                InputProps={{
                                    startAdornment: selectedItem.map(item => (
                                        <Chip
                                            key={item}
                                            tabIndex={-1}
                                            label={item}
                                            className={classes.chip}
                                            onDelete={handleDelete(item)}

                                        />
                                    )),
                                    onBlur,
                                    onChange: event => {

                                        handleInputChange(event);
                                        onChange(event);
                                    },
                                    onFocus,
                                    disableUnderline: true
                                }}
                                {...other}
                                {...inputProps}

                            /> <div class="panel-body" style={{ zIndex: "300", position: 'absolute' }}>
                                <div class="listGroup roundedd2" >
                                    {res}
                                </div>
                            </div>
                        </div>
                    );
                }}
            </Downshift>

        </React.Fragment >
    );
}
TagsInput.defaultProps = {
    tags: []
};
TagsInput.propTypes = {
    selectedTags: PropTypes.func.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string)
};

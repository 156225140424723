import React, { useEffect, useState } from 'react'
import { Container, DialogContent, Grid, LinearProgress, Radio } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import UseStyles from "./style";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import StripeComponent from "../stripe/stripecomponent";
import Dialog from "@material-ui/core/Dialog";
import PayPalBtn from "../PayPal/PaypalButton";
import { withStyles } from "@material-ui/styles";
import { blue } from "@material-ui/core/colors";
import Stripe from '../../images/stripe.svg';
import Paypal from '../../images/paypal.svg';
import editprofile from "../../images/editProfile.svg";
import { Trans, useTranslation } from 'react-i18next';

const Preview = ({ handleBack, handleNext, loading, values, bundle, price, paymentSelected, setPayment, handleSubmit }) => {
    const { t, i18n } = useTranslation();

    const [open, setOpen] = useState(false);

    const handleChange = (event) => {
        setPayment(event.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const paypalSubscribe = (data, actions) => {
        return actions.subscription.create({
            'plan_id': "P-5AJ86519Y6533850DMDSW7JI",
        });
    };

    const paypalOnError = (err) => {
    }

    const paypalOnApprove = (data, detail) => {
    };

    const GreenRadio = withStyles({
        root: {
            color: blue[400],
            '&$checked': {
                color: blue[600],
            },
        },
        checked: {},
    })((props) => <Radio color="default" {...props} />);
    return (

        <div>
            <Container>
                <Grid container justifyContent="center">
                    <Grid>
                        <Typography variant="h4" mt={4} mb={2}>
                            <Trans i18nKey="description.preview">
                                Preview
                            </Trans>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid>
                        <Typography variant="h6" mb={0.5}>
                            <Trans i18nKey="description.orderSummary">
                                Order Summary
                            </Trans>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item xs={4} lg={2} >
                        <Typography variant="body1" color="secondary" mt={4} mb={2}>
                            <Trans i18nKey="description.typeProp">Type of property
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={2} lg={1} >
                        <Typography variant="body1" color="primary" mt={4} mb={2}>{values.propertyType}</Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item xs={4} lg={2}>
                        <Typography variant="body1" color="secondary" mt={4} mb={2}>
                            <Trans i18nKey="description.duration">
                                Duration
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={2} lg={1} >
                        <Typography variant="body1" color="primary" mt={4} mb={2}>
                            {
                                values.duration
                            }
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item xs={4} lg={2}>
                        <Typography variant="body1" color="secondary" mt={4} mb={2}>
                            <Trans i18nKey="description.visibility">Visibility package
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={2} lg={1} >
                        <Typography variant="body1" color="primary" mt={4} mb={2}>{bundle === 'Basic' ? t("description.basicBundle") : bundle === 'Gold' ? t("description.goldBundle") : t("description.patinumBundle")}</Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item xs={4} lg={2} >
                        <Typography variant="body1" color="secondary" mt={4} mb={2}>
                            <Trans i18nKey="description.priceListing">Price of Listing
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={2} lg={1} >
                        <Typography variant="body1" color="primary" mt={4} mb={2}>{bundle === 'Basic' ? t("description.free") : 'LBP ' + price}</Typography>
                    </Grid>
                </Grid>

                <Divider />

                <Grid container justifyContent="center">
                    <Grid item xs={4} lg={2}>
                        <Typography variant="h6" color="secondary" mt={4} mb={2}>
                            <Trans i18nKey="description.totalCost">
                                Total cost
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={2} lg={1}>
                        <Typography variant="h6" color="primary" mt={4} mb={2}>{bundle === 'Basic' ? t("description.free") : 'LBP ' + price}</Typography>
                    </Grid>
                </Grid>
                {price !== 'Free' ? (
                    <>
                        <Divider />
                        <Grid container justifyContent="center" >
                            <Grid>
                                <Typography variant="h6" color="secondary" mt={4} mb={2}>
                                    <Trans i18nKey="description.paymentMethod">Payment Method
                                    </Trans>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center" mb={{ lg: 5 }}>
                            <Grid>
                                <Typography variant="h7" color="secondary" mt={4} mb={2}>
                                    <Trans i18nKey="description.choosePayment">Please choose a payment method in order to proceed</Trans>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" sx={{ mt: 2, mb: 2, }} ml={{ sm: 10, md: 10 }} alignItem="center" justifyContent="center">
                            <Grid item xs={6} lg={3} >
                                <GreenRadio

                                    checked={paymentSelected === 'stripe'}
                                    onChange={handleChange}
                                    value="stripe"
                                    name="Stripe"
                                    inputProps={{ 'aria-label': 'Stripe' }}
                                />
                                <Box
                                    width={100}
                                    component="img"
                                    src={Stripe}
                                    onClick={handleChange({ e: { target: { value: 'stripe' } } })}
                                />
                            </Grid>
                            <Grid item xs={6} lg={3}>
                                <GreenRadio

                                    checked={paymentSelected === 'paypal'}
                                    onChange={handleChange}
                                    value="paypal"
                                    name="Paypal"
                                    inputProps={{ 'aria-label': 'Paypal' }}
                                />
                                <Box
                                    width={160}
                                    component="img"
                                    src={Paypal}
                                    onClick={handleChange({ e: { target: { value: 'paypal' } } })}
                                />
                                {/*    <Button variant="outlined" color="primary" onClick={handleClickOpen}*/}
                                {/*            style={{*/}
                                {/*                marginLeft: 5,*/}
                                {/*                width: '100%',*/}
                                {/*                maxHeight: 40,*/}
                                {/*                height: "100%",*/}
                                {/*                color: "white",*/}
                                {/*                backgroundColor: "#0070BA",*/}
                                {/*                fontSize: 18*/}
                                {/*            }} >*/}
                                {/*        Stripe*/}
                                {/*    </Button>*/}
                                {/*    <div className="paypalContainer" style={{ width: "100%", borderWidth: "1px", marginRight: 5, }}>*/}
                                {/*        <PayPalBtn style={{ width: '100%' }}*/}
                                {/*                   amount="40"*/}
                                {/*                   currency="USD"*/}
                                {/*                   createSubscription={paypalSubscribe}*/}
                                {/*                   onApprove={paypalOnApprove}*/}
                                {/*                   catchError={paypalOnError}*/}
                                {/*                   onError={paypalOnError}*/}
                                {/*                   onCancel={paypalOnError}*/}
                                {/*        />*/}
                                {/*    </div>*/}
                            </Grid>
                            {/*<Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>*/}

                            {/*    <DialogContent >*/}
                            {/*        <StripeComponent />*/}
                            {/*    </DialogContent>*/}
                            {/*</Dialog>*/}
                        </Grid>
                    </>
                ) : null
                }
                {loading ? (
                    <>
                        <Typography variant='h4'>
                            <Trans i18nKey="description.waiting">Waiting for your property to be added
                            </Trans>
                        </Typography>
                        <Box sx={{ width: '100%', marginTop: '5em' }}>
                            <LinearProgress />
                        </Box>
                    </>
                ) : (
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            <Trans i18nKey="description.back">
                                Back
                            </Trans>
                        </Button>

                        <Box sx={{ flex: '1 1 auto' }} />

                        {price !== 'Free' ? (
                            <Button onClick={() => handleNext()} disabled={paymentSelected === ''}>
                                <Trans i18nKey="description.proceedPayment">
                                    Proceed to payment
                                </Trans>
                            </Button>
                        ) : (
                            <Button onClick={() => handleSubmit()}>
                                <Trans i18nKey="description.submit">
                                    Submit
                                </Trans>
                            </Button>
                        )
                        }
                    </Box>
                )
                }
            </Container>
        </div>
    )
}
export default Preview
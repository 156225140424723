import { useState ,useEffect} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createAd ,getAd , editAd } from "../../../actions/ImgAd";
import Input from '../../../components/Input/Input';
import * as Yup from 'yup';
import { useFormik} from 'formik';
// material
import {Alert, Snackbar, Stack, Typography} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import * as React from "react";

// ----------------------------------------------------------------------

function CMSImgAdEdit() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openSnack, setSnackOpen] = useState(false);
  const [success,setSuccess] = useState(undefined);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);
  const { ad, imgads, isLoading } = useSelector((state) => state.imgads);

  const {id} = useParams();
  useEffect( () => {
    dispatch(
        getAd(id));
}, [id,dispatch]);

  
  const handleRegisterSubmit = async (values) => {
    setLoading(true)
    const form=
        {
          ownerName: values.ownerName,
          ownerAddress :values.ownerAddress,
          ownerPhone : values.ownerPhone,
          title: values.title,
          price: values.price,
          currency: values.currency
        }
      const success = await dispatch(editAd(id,form));
      if(success)
      {
        setLoading(false)
        navigate(`/CMS/posts/${imgads.propertyId}`)
      }else setLoading(false)

    setSuccess(success)
    setSnackOpen(true)
  };
  const validationSchema = Yup.object({
    ownerName: Yup.string()
      .required('Owner Name required'),
    ownerAddress: Yup.string()
        .required('Owner Address required'),
    ownerPhone: Yup.string()
        .required('Owner Phone is required'),
    title: Yup.string()
        .required('Title is required'),
    price: Yup.string()
        .required('Price is required'),
    currency: Yup.string()
        .required('Currency is required')
  });

  const formik = useFormik({
    initialValues: {
      ownerName: imgads.ownerName,
      ownerAddress: imgads.ownerAddress,
      ownerPhone: imgads.ownerPhone,
      title: imgads.title,
      price: imgads.price,
      currency: imgads.currency
    },
    validationSchema,
    onSubmit: (values) => {
      handleRegisterSubmit(values);
    }
  });
  return (
    <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          <Typography variant="h4" component="h2" gutterBottom> Owner Information</Typography>
            <div style={{width:'50%'}}>
              
            <Input required name="ownerName" label="owner Name" handleChange={formik.handleChange} handleBlur={formik.handleBlur} autoFocus half defaultValue={imgads.ownerName} />
            {formik.touched.ownerName && formik.errors.ownerName ? <div style={{color:'red'}}>{formik.errors.ownerName}</div>:null}
            </div>
            
            <div style={{width:'50%'}}>
            <Input required name="ownerAddress" label="owner Address" handleChange={formik.handleChange} handleBlur={formik.handleBlur} autoFocus half defaultValue={imgads.ownerAddress} />
            {formik.touched.ownerAddress && formik.errors.ownerAddress ? <div style={{color:'red'}}>{formik.errors.ownerAddress}</div>:null}
            </div>
            <div style={{width:'50%'}}>
            <Input required name="ownerPhone" label="owner Phone" handleChange={formik.handleChange} handleBlur={formik.handleBlur} autoFocus half defaultValue={imgads.ownerPhone} />
            {formik.touched.ownerPhone && formik.errors.ownerPhone ? <div style={{color:'red'}}>{formik.errors.ownerPhone}</div>:null}
            </div>
            <Typography variant="h4" component="h2" gutterBottom> Advertisement Information</Typography>
          <div sx={{marginBottom:20}}  style={{width:'50%'}}>
          <Input required name="title" label="Title" handleChange={formik.handleChange} handleBlur={formik.handleBlur} type="text"  defaultValue={imgads.title} />
          {formik.touched.title && formik.errors.title ? <div style={{color:'red'}}>{formik.errors.title}</div>:null}
          </div>
          <div sx={{marginBottom:20}}  style={{width:'50%'}}>
          <Input required name="currency" label="currency" handleChange={formik.handleChange} handleBlur={formik.handleBlur} type="text" defaultValue={imgads.currency}  />
          {formik.touched.currency && formik.errors.currency ? <div style={{color:'red'}}>{formik.errors.currency}</div>:null}
          </div>
          <div sx={{marginBottom:20}}  style={{width:'50%'}}>
          <Input required name="price" label="price" handleChange={formik.handleChange} handleBlur={formik.handleBlur} type="text" defaultValue={imgads.price}  />
          {formik.touched.price && formik.errors.price ? <div style={{color:'red'}}>{formik.errors.price}</div>:null}
          </div >
          <div style={{alignContent:'center'}}>
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
          >
            Update
          </LoadingButton>
          </div>
        </Stack>
   </form>
  );
}

export default CMSImgAdEdit;

import { Link as RouterLink } from 'react-router-dom';
// material
import { Trans, useTranslation } from 'react-i18next';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Button, Typography, Container } from '@material-ui/core';
// components
import Page from '../../components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

export default function Page404() {
  const { i18n } = useTranslation();

  return (
    <RootStyle title="404 Page Not Found | eAkary">
      <Container>
        <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
          <div>
            <Typography variant="h3" paragraph>
              <Trans i18nKey="description.sorry">
                Sorry, page not found!
              </Trans>
            </Typography>
          </div>
          <Typography sx={{ color: 'text.secondary' }}>
            <Trans i18nKey="description.sorryWe">
              Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL?
              Be sure to check your spelling.
            </Trans>
          </Typography>
          <div>
            <Box
              component="img"
              src="/static/illustrations/404.svg"
              sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
              xs={{ height: 100 }}
            />
          </div>
          <Button to="/" size="large" variant="contained" component={RouterLink}>
            <Trans i18nKey="description.goHome">
              Go to Home
            </Trans>
          </Button>
        </Box>
      </Container>
    </RootStyle>
  );
}

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify-icons/eva/menu-2-fill';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Button } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';

// components
import { MHidden } from '../../components/@material-extend';
//

import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
import Logo from '../../components/Logo'


//
import { useNavigate, useLocation, Link as RouterLink, } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import decode from 'jwt-decode';
import * as actionType from '../../constants/actionTypes';



// ----------------------------------------------------------------------

const DRAWER_WIDTH = 0;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {

  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  useEffect(() => {
    const token = user?.token;
if(token){
const decodedToken = decode(token);
if(decodedToken.exp * 1000 < new Date().getTime()) logout();
}
    setUser(JSON.parse(localStorage.getItem('profile')));
}, [location])

const logout = () => {
  dispatch({ type: actionType.LOGOUT });

  navigate('/auth/login');

  setUser(null);
};

  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width="mdUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
        <MHidden width="mdDown">
          <Logo />
        </MHidden>
        <Box sx={{ flexGrow: 1 }} />
        <MHidden width="mdDown">
          <Stack direction="row" style={{ marginRight: "30%" }} spacing={{ xs: 3.5, sm: 3.5 }}>
            <Button component={RouterLink} to="/CMS/CMSposts" color="primary">Posts</Button>
            <Button component={RouterLink} to="/CMS/CMSusers">Users</Button>
            <Button component={RouterLink} to="/CMS/CMSads" >Ads</Button>
          </Stack>
        </MHidden>
        <Stack direction="row" spacing={{ xs: 1.5, sm: 2.5 }}>
          {user?.result ? (
            <>
            <NotificationsPopover/>
              <AccountPopover />
            </>
          ) : (
            <>
              <MHidden width="mdDown">
                <Button component={RouterLink} to="/auth/login" variant="contained" color="primary">Login</Button>
              </MHidden>
            </>
          )}
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}

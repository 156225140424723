import { makeStyles } from '@material-ui/styles';

const UseStyles = makeStyles((theme) => ({
  tabs:{
    marginBottom: 10
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  iconButton: {
    padding: 10,
  },
  search:{
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  }

  }));

export default UseStyles
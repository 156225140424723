import { UPDATE_IMGAD, FETCH_ALL, FETCH_BY_SEARCH, FETCH_IMGAD, CREATE, UPDATE, DELETE, LIKE, ADD_TO_WISHLIST, GET_WISHLIST, REMOVE_FROM_WISHLIST } from '../constants/actionTypes';

export default (state = { isLoading: true, imgads:[] , updatedAd:{} }, action) => {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isLoading: true };
    case 'END_LOADING':
      return { ...state, isLoading: false };
    case FETCH_ALL:
      return {
        ...state,
        imgads: action.payload.data
      };
    case FETCH_BY_SEARCH:
      return { ...state, imgads: action.payload.data };
    case FETCH_IMGAD:
      return { ...state, imgads: action.payload.imgad };
    case LIKE:
      return { ...state, imgads: state.imgads.map((imgad) => (imgad._id === action.payload._id ? action.payload : imgad)) };
    case CREATE:
      return { ...state, imgads: [...state.imgads, action.payload] };
    case UPDATE_IMGAD:
      return { ...state, updatedAd: action.payload.imgad };
    case UPDATE:
      return { ...state, imgads: state.imgads.map((imgad) => (imgad._id === action.payload._id ? action.payload : imgad)) };
    case DELETE:
      return { ...state, imgads: state.imgads.filter((imgad) => imgad._id !== action.payload) };
    case ADD_TO_WISHLIST:
      return { ...state, wish: action.payload };
    case GET_WISHLIST:
      return { ...state, wish: action.payload };
    case REMOVE_FROM_WISHLIST:
      return { ...state, wish: action.payload }



    default:
      return state;
  }
};
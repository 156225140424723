import React, { useMemo, useEffect, useState } from 'react'
import { Button, Grid } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import UseStyles from './style'
import { Link } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import { experimentalStyled as styled } from '@material-ui/core/styles';
import Page from '../../components/Page';
import { Trans, useTranslation } from 'react-i18next';

const RootStyle = styled(Page)(({ theme }) => ({
  paddingBottom: theme.spacing(10)
}));
const PrivacyPolicy = () => {
    const { t, i18n } = useTranslation();
    const classes = UseStyles();

    return (
        <>
        <RootStyle title="Privacy Policy | eAkary">
        <Container>

            <Grid container alignItems="center" justify="space-between">
            <Typography className={classes.typoTitle} component="h4" variant="h4">
            {t("description.privacyPol")}
                </Typography>
            </Grid>

            <Grid container alignItems="center" justify="space-between">
                <Typography variant="subtitle2" component="p" className={classes.typoDate}>
                    Last updated on May 31, 2021.
                </Typography>

            </Grid>
            <Grid container alignItems="center" justify="space-between">
                <Typography className={classes.typoTitle} component="h5" variant="h5">
                I. {t("description.yourprivacy")}
                </Typography>
            </Grid>

            <Grid container alignItems="center" justify="space-between">
                <Typography variant="body2" component="p" className={classes.typoText}>
                {t("description.yourprivacyanswer")}
                </Typography>
                <Typography variant="body2" component="p" className={classes.typoText}>
                {t("description.yourprivacyanswer2")}
                </Typography>
                <Typography variant="body2" component="p" className={classes.typoText}>
                <ul>
                    <li>{t("description.thegeneral")}</li>
                    <li>{t("description.thegeneral2")}</li>
                </ul>
                </Typography>
            </Grid>
            <Grid container alignItems="center" justify="space-between">
                <Typography className={classes.typoTitle} component="h5" variant="h5">
                II. {t("description.legalbasis")}
                </Typography>


            </Grid>

            <Grid container alignItems="center" justify="space-between">
                <Typography variant="body2" component="p" className={classes.typoText}>
                {t("description.legalbasisanswer")}
                </Typography>

            </Grid>
           

        </Container>
        </RootStyle>
</>
    )
}

export default PrivacyPolicy
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { reducers } from './reducers';
import App from './App';
import "../src/translation/i18n";
import { Suspense } from 'react';

const store = createStore(reducers, {}, compose(applyMiddleware(thunk)));

ReactDOM.render(

  <Provider store={store}>
    <HelmetProvider>
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <App />
        </Suspense>
      </BrowserRouter>
    </HelmetProvider>
  </Provider>
  ,
  document.getElementById('root'),
);
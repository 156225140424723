import React from 'react'
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";


import ImageUpload from '../ImagesComponent/imageUpload'
import ImageDisplayUpload from '../ImagesComponent/imageDisplayUpload';
import { Trans, useTranslation } from 'react-i18next';


const Images = ({ handleBack, handleNext, fileList, setFileList, setPost, post, bundle, setMainImage, mainImage}) => {
    const { i18n } = useTranslation();
    return (
        <>
       
       <Container>
           <Grid container xs={12}>
            <ImageUpload bundle={bundle} fileList={fileList} setFileList={setFileList} setPost={setPost} post={post} mainImage={mainImage} setMainImage={setMainImage}/>
            </Grid>
            </Container>
            <React.Fragment>
            <Container>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                >
                    <Trans i18nKey="description.back">
                        Back
                    </Trans>
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                    {   bundle === 'Platinum' ?
                        fileList.fileList !== undefined && mainImage.fileList !== undefined ? (
                        fileList.fileList.length + mainImage.fileList.length >=5 && fileList.fileList.length + mainImage.fileList.length<=25 && mainImage.fileList.length>0 ?(
                        <Button onClick={handleNext}>
                            <Trans i18nKey="description.next">
                                Next
                            </Trans>
                        </Button>
                                    ):(

                        <Button onClick={handleNext} disabled>
                        <Trans i18nKey="description.next">
                        Next
                        </Trans>
                        </Button>
                                )
                    ):<Button onClick={handleNext} disabled>
                        <Trans i18nKey="description.next">
                            Next
                        </Trans>
                    </Button>
                        : bundle === 'Gold'?
                            fileList.fileList !== undefined && mainImage.fileList !== undefined ? (
                                fileList.fileList.length +mainImage.fileList.length >=5 && fileList.fileList.length + mainImage.fileList.length <=20 && mainImage.fileList.length>0 ?(
                                    <Button onClick={handleNext}>
                                        <Trans i18nKey="description.next">
                                            Next
                                        </Trans>
                                    </Button>
                                ):(

                                    <Button onClick={handleNext} disabled>
                                        <Trans i18nKey="description.next">
                                            Next
                                        </Trans>
                                    </Button>
                                )
                            ):<Button onClick={handleNext} disabled>
                                <Trans i18nKey="description.next">
                                    Next
                                </Trans>
                            </Button>
                            :fileList.fileList !== undefined && mainImage.fileList !== undefined ? (
                                fileList.fileList.length + mainImage.fileList.length >=5 && fileList.fileList.length + mainImage.fileList.length <=15 && mainImage.fileList.length>0 ?(
                                    <Button onClick={handleNext}>
                                        <Trans i18nKey="description.next">
                                            Next
                                        </Trans>
                                    </Button>
                                ):(

                                    <Button onClick={handleNext} disabled>
                                        <Trans i18nKey="description.next">
                                            Next
                                        </Trans>
                                    </Button>
                                )
                            ):<Button onClick={handleNext} disabled>
                                <Trans i18nKey="description.next">
                                    Next
                                </Trans>
                            </Button>
                    }
                </Box>
                </Container>
            </React.Fragment>

        </>
    )
}
export default Images
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import { fCurrency } from '../../utils/formatNumber';
import HotelIcon from '@material-ui/icons/Hotel';
import BathtubIcon from '@material-ui/icons/Bathtub';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import Chip from '@material-ui/core/Chip';
import Stack from '@material-ui/core/Stack';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import UseStyles from './styles';
import apartment from "../../images/apartment.jpg";
import 'boxicons';
import posts from '../../reducers/posts';
import { getPost, deleteWish } from '../../actions/posts';

const WishlistCard = ({ wishlist, postId }) => {

    const classes = UseStyles();
    const dispatch = useDispatch();
    const navigate= useNavigate();


    const openPost = (e) => {
        // dispatch(getPost(post._id, navigate));
    
        navigate(`/posts/${post._id}`);
      };
      
    const [buttonFavoriteIsClicked, isFavoriteClicked] = useState(false);
    const [buttonFavoriteIsNotClicked, isFavoriteNotClicked] = useState(true);

    const [LoggedUser, setLoggedUser] = useState(JSON.parse(localStorage.getItem('profile')));

    const clickedFavoriteBtn = (idPost) => {
        const userId = LoggedUser.result._id;

        isFavoriteClicked(true);

        dispatch(deleteWish(userId, idPost));
        window.location.reload();
        
    }

    
    const unClickedFavoriteBtn = () => {

        isFavoriteClicked(false);

        
    }

    const [post, setWishlist] = useState([]);

    useEffect(async () => {
        // const id = LoggedUser.result._id;
        const posts = await dispatch(getPost(postId));
        setWishlist(posts);


    }, [dispatch]);



    return (

        <Card variant="outlined" className={classes.root} elevation={0}>
            {
                buttonFavoriteIsClicked ?
                    (
                        <IconButton aria-label="add to favorites" onClick={unClickedFavoriteBtn} className={classes.Favorite} >
                            <FavoriteIcon  />
                        </IconButton>
                    ) :
                    (
                        <IconButton aria-label="add to favorites" className={classes.Favorite} onClick={() => clickedFavoriteBtn(postId)}>
                            <FavoriteIcon sx={{ color: "red" }} />
                        </IconButton>
                    )
            }

<div onClick={openPost} style={{cursor: 'pointer'}}>
            <CardMedia  
        className={classes.media}
        image={post.mainImage? post.mainImage.url : null}
        title={apartment}
        alt={apartment}
      />

<CardContent  style={{textAlign: "Left"}}>
          <Typography variant="subtitle2" component="p" >
          {post.propertyType}
        </Typography>
        <Typography variant="h4" style={{marginBottom: "5px", marginTop: "5px"}} component="p" >
       {fCurrency(post.price)}
        </Typography>
        <Typography variant="body2" component="p"  noWrap>
          {post.mapPin? post.mapPin.address : post.city}
        </Typography>
        <hr className={classes.solid} style={{marginBottom: "10px", marginTop: "10px"}}/>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
        {(post?.numberOfBedrooms !== null)  && (
          
          <Chip icon={<HotelIcon style={{color:"#001329"}}/>} label={post.numberOfBedrooms} size="small" style={{backgroundColor:"white"}}  />
       
              )}
            {(post?.numberOfBathrooms !== null)  && (     
            
                <Chip icon={<BathtubIcon style={{color:"#001329"}}/>} label={post.numberOfBathrooms} size="small" style={{backgroundColor:"white"}}  />
           
              )}
               {(post?.surfaceArea !== null)  && (  
           
                <Chip icon={<ZoomOutMapIcon style={{color:"#001329"}}/>} label={post.surfaceArea} size="small" style={{backgroundColor:"white"}}  />
           
              )}
              
             </Stack>
            
          </CardContent>
          </div>
        </Card>
    );
}


export default WishlistCard;
import * as React from 'react';
import { Container, Box, Grid, Typography, Alert, Snackbar, Slide } from "@material-ui/core";
import { styled } from '@material-ui/core/styles';
import ProfileField from "../../components/TextFields/TextField";
import editprofile from '../../images/editProfile.svg'
import ProfileAvatar from "../../components/ProfileAvatar/ProfileAvatar";
import ButtonOutlined from "../../components/Buttons/ButtonOutlined";
import ButtonContained from "../../components/Buttons/ButtonContained";
import Pic from '../../images/real-estate-websites.jpg'
import Divider from "@material-ui/core/Divider";
import useState from "react-hook-use-state";
import Button from "@material-ui/core/Button";
import ChangePasswordPopUp from "../../components/ChangePasswordPopUp/ChangePasswordPopUp";
import { LoadingButton } from "@material-ui/lab";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateUser } from "../../actions/auth";
import DialogDelete from "../../components/Dialog/Dialog";
import decode from "jwt-decode";
import NotLoggedIn from "../../components/NotLoggedIn/notLoggedIn";

import forgetPassIm from "../../images/forgetPassword.svg";
import { MHidden } from '../../components/@material-extend';
import { Trans, useTranslation } from 'react-i18next';

const RootStyle = styled('section')({
    marginTop: "-25px",
    marginBottom: "20px",

    display: "flex",

});
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const Profile = () => {
    const { t, i18n } = useTranslation();

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [openSnack, setSnackOpen] = useState(false);
    const [openDialog, setDialogOpen] = useState(false);
    const [LoggedUser, setLoggedUser] = useState(JSON.parse(localStorage.getItem('profile')))
    const [disable, setDisable] = useState(true);
    const [success, setSuccess] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleDialogOpen = () => {
        setDialogOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false)
    };
    const handleCloseDialog = () => {
        setDialogOpen(false)
    }
    const handleSubmit = async (values) => {
        const id = LoggedUser.result._id
        setLoading(true)
        const form =
        {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
        }
        const success = await dispatch(updateUser(id, form));
        if (success) {
            setLoading(false)
            const token = LoggedUser?.token;
            if (token) {
                const decodedToken = decode(token);
                if (decodedToken.exp * 1000 < new Date().getTime()) { }
            }
            setLoggedUser(JSON.parse(localStorage.getItem('profile')));
        } else setLoading(false)

        setSuccess(success)
        setSnackOpen(true)
    };

    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Invalid email format')
            .required('Required!'),
        firstName: Yup.string()
            .required('Required!'),
        lastName: Yup.string()
            .required('Required!')
    })

    const formik = useFormik({
        initialValues: {
            email: LoggedUser.result.email,
            firstName: LoggedUser.result.firstName,
            lastName: LoggedUser.result.lastName
        },
        onSubmit: values => {
            handleSubmit(values)
        },
        validationSchema,
    }
    )
    return (
        <RootStyle title="Profile | eAkary">
            <Container>
                <Grid container>
                    <Grid container justifyContent='center'>
                        <Typography variant="h4" mt={4} mb={6}>
                            <Trans i18nKey="description.myprofile">
                                My Profile
                            </Trans>
                        </Typography>
                    </Grid>
                </Grid>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container rowSpacing={1} justifyContent="center">
                        <Grid item xs={12} sm={6} md={4} lg={5}  >
                            <Box
                                component="img"
                                src={editprofile}
                            />
                        </Grid>
                        <Grid container item xs={12} sm={6} md={4} lg={3} justifyContent='center' alignItems="center" direction='column' columnSpacing={5} sx={{ mx: 6, my: 6 }}>
                            <ProfileField handleChange={formik.handleChange} value={formik.values.email} name='email' placeholder="example@me.com" type='email' label={t("description.emailAdd")} bottom='1em' width='18em' />
                            {formik.errors.email ? <span style={{ color: 'red', transform: 'translateY(-10px)' }}>{formik.errors.email}</span> : null}
                            <ProfileField handleChange={formik.handleChange} value={formik.values.firstName} name='firstName' placeholder={t("description.fn")} label={t("description.fn")} bottom='1em' width='18em' />
                            {formik.errors.firstName ? <span style={{ color: 'red', transform: 'translateY(-10px)' }}>{formik.errors.firstName}</span> : null}
                            <ProfileField handleChange={formik.handleChange} value={formik.values.lastName} name='lastName' placeholder={t("description.ln")} label={t("description.ln")} bottom='1.5em' width='18em' />
                            {formik.errors.lastName ? <span style={{ color: 'red', transform: 'translateY(-10px)' }}>{formik.errors.lastName}</span> : null}
                            <Button
                                variant="outlined"
                                color="primary"
                                style={{ width: `13em`, margin: `0em 4em 0.4em 4em` }}
                                onClick={handleClickOpen}
                            >
                                <Trans i18nKey="description.changePass">
                                    Change Password
                                </Trans>

                            </Button>
                        </Grid>
                        <Grid container item xs={12} sm={6} md={4} lg={12} justifyContent='center' >
                            {
                                formik.values.email !== LoggedUser.result.email
                                    || formik.values.firstName !== LoggedUser.result.firstName
                                    || formik.values.lastName !== LoggedUser.result.lastName ? (
                                    <LoadingButton
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        style={{ width: `7em`, borderRadius: `10px` }}
                                        loading={loading}
                                    >
                                        <Trans i18nKey="description.save">
                                            Save
                                        </Trans>
                                    </LoadingButton>
                                ) : (
                                    <LoadingButton
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        style={{ width: `7em`, borderRadius: `10px` }}
                                        loading={loading}
                                        disabled
                                    >
                                        <Trans i18nKey="description.save">
                                            Save
                                        </Trans>

                                    </LoadingButton>
                                )
                            }
                        </Grid>
                    </Grid>
                </form>
                <ChangePasswordPopUp open={open} handleClose={handleClose} />
                <Divider sx={{ marginTop: '3em' }} />
                <Grid container justifyContent="center" alignItems="center" spacing={2} paddingTop={2}>
                    <Grid item xs={12}  >
                        <Typography variant="h6" mt={1} mb={1}>
                            <Trans i18nKey="description.deleteAccount">
                                Delete your account
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h7" mt={4} mb={4}>
                            <Trans i18nKey="description.isItPoss">
                                It is possible to delete your account but it is irreversible</Trans><br />
                            <Trans i18nKey="description.pleaseBesure">
                                Please be sure that you would like to do that
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonContained name={t("description.delete")} color="secondary" handleClickOpen={handleDialogOpen} />
                    </Grid>
                    <DialogDelete open={openDialog} handleClose={handleCloseDialog} Transition={Transition} />
                    {
                        success !== undefined ? (
                            success ? (
                                <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                                    <Alert onClose={handleSnackClose} severity="success">
                                        <Trans i18nKey="description.profUpd">
                                            Profile updated successfully
                                        </Trans>
                                    </Alert>
                                </Snackbar>
                            ) : (
                                <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                                    <Alert onClose={handleSnackClose} severity="error">
                                        <Trans i18nKey="description.wefound">
                                            We found a user with the same email, try another one
                                        </Trans>
                                    </Alert>
                                </Snackbar>
                            )) : null
                    }
                </Grid>
            </Container>
        </RootStyle>
    )
}
export default Profile;
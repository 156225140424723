import React, { useEffect, useState } from 'react'
import { Stepper, Step, StepLabel } from "@material-ui/core";
import Bundle from "../Bundle/bundle";
import GetStarted from "../../components/Steppers/GetStarted";
import Typography from "@material-ui/core/Typography";
import MainInformation from "../../components/Steppers/MainInformation";
import Images from "../../components/Steppers/Images";
import AdditionalFeatures from "../../components/Steppers/AdditionalFeatures";
import { createPost } from "../../actions/posts";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Finish from "../../components/Steppers/Finish";
import { useDispatch } from "react-redux";
import NotLoggedIn from '../../components/NotLoggedIn/notLoggedIn';
import Preview from "../../components/Steppers/Preview";
import { experimentalStyled as styled } from '@material-ui/core/styles';
import Page from '../../components/Page';
import Payment from "../../components/Steppers/Payment";
import axios from "axios";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

const steps = ['Bundle', 'Get Started', 'Main Information', 'Images', 'Additional Features', 'Preview', 'Payment'];

const RootStyle = styled(Page)(({ theme }) => ({
    paddingBottom: theme.spacing(10)

}));

const AddListingStepper = () => {

    const user = JSON.parse(localStorage.getItem('profile'));
    const dispatch = useDispatch()
    const [activeStep, setActiveStep] = useState(0);
    const [postData, setPostData] = useState({ altitude: 0, duration: '', featured: false, bundleChosen: '', listingType: 'buy', images: [], propertyType: 'Apartment', street: '', city: '', building: '', floor: '', additionalDetail: '', title: '', description: '', surfaceArea: '', email: '', phoneNumber: '', constructionDate: '', constructionCondition: 'Old', price: '', numberOfRooms: '', numberOfBedrooms: '', numberOfBathrooms: '', kitchenArea: '', parkingSpace: false, dwelling: false, brickRoof: false, outBuildings: false, caretakerConcierge: false, garden: false, swimmingPool: false, terrace: false, elevator: false, furnished: false, internetAccess: false, videoPhone: false, specialNeedsAccessibility: false, heatingType: 'Furnaces', coolingType: 'Central Air', pets: false, smoking: false, bigParties: false, loudMusic: false });
    const [bundleChosen, setBundle] = useState('Basic');
    const [bundlePrice, setPrice] = useState("");
    const [markers, setMarkers] = useState({});
    const [openStripe, setOpenStripe] = useState(false)
    const [selectedPayment, setPayment] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [loadingStripe, setLoadingStripe] = useState(false);
    const [succeed, setSuccess] = useState(false);
    const [error, setError] = useState('');
    const [ERROR, setERROR] = useState(true)
    const [imageFileList, setFileList] = useState([]);
    const [mainImage, setMainImage] = useState([]);//Ralph
    const [uploadPercentage, setUploadPercentage] = useState(0)
    const [mainImageEntered, setMainImageEntered] = useState(false)
    const [ImagesEntered, setImagesEntered] = useState(undefined)

    let UploadedImages = [];
    let UploadMainImage = {};


    const Stripe = async (values, marker, stripe, elements) => {

        // setLoadingStripe(true)


        if (!stripe || !elements) {

            setLoadingStripe(false)
            return;
        }

        const res = await axios.post('https://backend.eakary.com/stripe/pay', values);

        const clientSecret = res.data['client_secret'];

        const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    email: values.email,
                    phone: values.phoneNumber,
                    name: values.firstName + ' ' + values.lastName
                },
            },
        });

        if (result.error) {
            setLoadingStripe(false)
            setERROR(true)
            setOpenStripe(true)
            setError(result.error.message)
            return;
        } else {

            if (result.paymentIntent.status === 'succeeded') {
                setSuccess(true)
                setLoadingStripe(false)
                setLoading(true)
                handleImageSubmit(marker)
            }
        }
    }



    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleNextFree = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 2);
    };


    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleChange = input => e => {
        if (!isNaN(e.target.value) && e.target.value < 0) { setPostData({ ...postData, [input]: 0 }); return; }
        setPostData({ ...postData, [input]: e.target.value });
    }
    const handleReset = () => {
        setPostData({ altitude: 0, duration: '', featured: false, bundleChosen: '', listingType: 'buy', images: [], propertyType: 'Apartment', street: '', city: '', building: '', floor: '', numberOfFloors: '', additionalDetail: '', title: '', description: '', surfaceArea: '', email: '', phoneNumber: '', constructionDate: '', constructionCondition: 'Old', price: '', numberOfRooms: '', numberOfBedrooms: '', numberOfBathrooms: '', kitchenArea: '', parkingSpace: false, dwelling: false, brickRoof: false, outBuildings: false, caretakerConcierge: false, garden: false, swimmingPool: false, terrace: false, elevator: false, facingStreet: false, inTheWood: false, flatLand: false, furnished: false, internetAccess: false, videoPhone: false, specialNeedsAccessibility: false, heatingType: 'Furnaces', coolingType: 'Central Air', secureAccess: false, showRoom: false, receptionDesk: false, pets: false, smoking: false, bigParties: false, loudMusic: false });
        setBundle('Basic');
        setPrice('50');
        setPayment('')
        setActiveStep(0);
        UploadedImages = [];
        UploadMainImage = [];
        setFileList([])
        setMainImage([]); //Ralph
        setMarkers({});
        setSuccess(false)
    };

    const handleImageSubmit = async (marker) => {
        // setTimeout(function() {
        const form = {
            altitude: postData.altitude,
            duration: postData.duration,
            featured: postData.featured,
            bundleChosen: postData.bundleChosen,
            listingType: postData.listingType,
            mainImage: UploadMainImage,
            images: UploadedImages,
            propertyType: postData.propertyType,
            street: postData.street,
            city: postData.city,
            building: postData.building,
            floor: postData.floor,
            numberOfFloors: postData.numberOfFloors,
            additionalDetail: postData.additionalDetail,
            title: postData.title,
            description: postData.description,
            surfaceArea: postData.surfaceArea,
            email: postData.email,
            phoneNumber: postData.phoneNumber,
            constructionDate: postData.constructionDate,
            constructionCondition: postData.constructionCondition,
            price: postData.price,
            numberOfRooms: postData.numberOfRooms,
            numberOfBedrooms: postData.numberOfBedrooms,
            numberOfBathrooms: postData.numberOfBathrooms,
            kitchenArea: postData.kitchenArea,
            parkingSpace: postData.parkingSpace,
            dwelling: postData.dwelling,
            brickRoof: postData.brickRoof,
            outBuildings: postData.outBuildings,
            caretakerConcierge: postData.caretakerConcierge,
            garden: postData.garden,
            swimmingPool: postData.swimmingPool,
            terrace: postData.terrace,
            elevator: postData.elevator,
            facingStreet: postData.facingStreet,
            inTheWood: postData.inTheWood,
            flatLand: postData.flatLand,
            furnished: postData.furnished,
            internetAccess: postData.internetAccess,
            videoPhone: postData.videoPhone,
            specialNeedsAccessibility: postData.specialNeedsAccessibility,
            heatingType: postData.heatingType,
            coolingType: postData.coolingType,
            secureAccess: postData.secureAccess,
            showRoom: postData.showRoom,
            receptionDesk: postData.receptionDesk,
            pets: postData.pets,
            smoking: postData.smoking,
            bigParties: postData.bigParties,
            loudMusic: postData.loudMusic,
            mapPin: marker
        }
        const result = await dispatch(createPost(form));
        const delayInMilliseconds = 2000;
        setTimeout(function () {
            if (result) {
                setLoading(false);
                if (bundlePrice === 'Free') {
                    handleNextFree()
                } else handleNext();
            } else {
                setLoading(false)
                setLoadingStripe(false)
                setSuccess(false)
            }
        }, delayInMilliseconds);
        //
        // }

    };

    const handleSubmitMainImage = (values, stripe, elements) => {
        // event.preventDefault();
        // setLoading(true)



        setLoadingStripe(true)
        setLoading(true)

        if (!stripe || !elements) {

            setLoadingStripe(false)
            return;
        }
        // let filesUrl = [];
        let options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total)

                if (percent < 100) {
                    setUploadPercentage(percent)
                }
            }
        }


        if (window.navigator.onLine) {
            let uploaders = mainImage.fileList.map(file => {

                let formData = new FormData();
                // add one or more of your files in FormData
                // again, the original file is located at the `originFileObj` key
                // const formData = new FormData();
                formData.append("file", file.originFileObj);
                formData.append("tags", `codeinfuse, medium, gist`);
                formData.append("upload_preset", "testing"); // Replace the preset name with your own
                formData.append("api_key", "211986464428528"); // Replace API key with your own Cloudinary key
                formData.append("timestamp", (Date.now() / 1000) | 0);
                // formData.append("file", this.state.fileList[0].originFileObj);

                return axios.post("https://api.cloudinary.com/v1_1/ralphb21/image/upload", formData, options, {
                    headers: { "X-Requested-With": "XMLHttpRequest" },
                }).then(response => {
                    const data = response.data;
                    const public_id = data.public_id
                    const fileURL = data.secure_url // You should store this URL for future references in your app
                    UploadMainImage.url = fileURL
                    UploadMainImage.public_id = public_id
                })
            })
            //Once all the files are uploaded
            axios.all(uploaders).then(async () => {
                // ... perform after upload is successful operation
                // await axios.post("http://localhost:5000/api/pins", markers).then((res)=>{
                //     if(res){
                //         handleImageSubmit(res.data._id);
                //         setFileList([])
                //     }
                // });
                // handleImageSubmit(markers);

                setMainImageEntered(true)

                handleSubmit(values, stripe, elements);
                // setMainImage([]);
                // setFileList([])
            });
        }
        else {

            setLoadingStripe(false)
            setERROR(true)
            setOpenStripe(true)
            setError('please check your internet connection and retry again')

        }

    }


    const handleSubmit = (values, stripe, elements) => {
        // event.preventDefault();
        // setLoading(true)
        // let filesUrl = [];
        const images = []
        let options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total)

                if (percent < 100) {
                    setUploadPercentage(percent)
                }
            }
        }

        if (window.navigator.onLine) {

            let uploaders = imageFileList.fileList.map(file => {

                let formData = new FormData();
                // add one or more of your files in FormData
                // again, the original file is located at the `originFileObj` key
                // const formData = new FormData();
                formData.append("file", file.originFileObj);
                formData.append("tags", `codeinfuse, medium, gist`);
                formData.append("upload_preset", "testing"); // Replace the preset name with your own
                formData.append("api_key", "211986464428528"); // Replace API key with your own Cloudinary key
                formData.append("timestamp", (Date.now() / 1000) | 0);
                // formData.append("file", this.state.fileList[0].originFileObj);


                return axios.post("https://api.cloudinary.com/v1_1/ralphb21/image/upload", formData, options, {
                    headers: { "X-Requested-With": "XMLHttpRequest" },
                }).then(response => {
                    images.push(response.data)
                    const data = response.data;
                    const public_id = data.public_id;
                    const fileURL = data.secure_url; // You should store this URL for future references in your app
                    UploadedImages.push({ url: fileURL, public_id: public_id });
                    // UploadedImages.url = fileURL
                    // UploadedImages.public_id = public_id
                })

            })




            //Once all the files are uploaded
            axios.all(uploaders).then(async () => {
                // ... perform after upload is successful operation
                // await axios.post("http://localhost:5000/api/pins", markers).then((res)=>{
                //     if(res){
                //         handleImageSubmit(res.data._id);
                //         setFileList([])
                //     }
                // });
                setMainImageEntered(false)
                setImagesEntered(true)
                await Stripe(values, markers, stripe, elements)
                // setFileList([])
            });
        }
        else {
            setLoadingStripe(false)
            setERROR(true)
            setOpenStripe(true)
            setError('please check your internet connection and retry again')
        }
    };

    const handleSubmitMainImageFree = () => {
        // event.preventDefault();
        // setLoading(true)



        setLoading(true)


        // let filesUrl = [];
        let options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total)

                if (percent < 100) {
                    setUploadPercentage(percent)
                }
            }
        }
        let uploaders = mainImage.fileList.map(file => {

            let formData = new FormData();
            // add one or more of your files in FormData
            // again, the original file is located at the `originFileObj` key
            // const formData = new FormData();
            formData.append("file", file.originFileObj);
            formData.append("tags", `codeinfuse, medium, gist`);
            formData.append("upload_preset", "testing"); // Replace the preset name with your own
            formData.append("api_key", "211986464428528"); // Replace API key with your own Cloudinary key
            formData.append("timestamp", (Date.now() / 1000) | 0);
            // formData.append("file", this.state.fileList[0].originFileObj);

            return axios.post("https://api.cloudinary.com/v1_1/ralphb21/image/upload", formData, options, {
                headers: { "X-Requested-With": "XMLHttpRequest" },
            }).then(response => {
                const data = response.data;
                const public_id = data.public_id
                const fileURL = data.secure_url // You should store this URL for future references in your app
                UploadMainImage.url = fileURL
                UploadMainImage.public_id = public_id
            })
        })
        //Once all the files are uploaded
        axios.all(uploaders).then(async () => {
            // ... perform after upload is successful operation
            // await axios.post("http://localhost:5000/api/pins", markers).then((res)=>{
            //     if(res){
            //         handleImageSubmit(res.data._id);
            //         setFileList([])
            //     }
            // });
            // handleImageSubmit(markers);

            setMainImageEntered(true)

            handleSubmitFree();
            // setMainImage([]);
            // setFileList([])
        });
    }


    const handleSubmitFree = () => {
        // event.preventDefault();
        // setLoading(true)
        // let filesUrl = [];
        const images = []
        let options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total)

                if (percent < 100) {
                    setUploadPercentage(percent)
                }
            }
        }
        let uploaders = imageFileList.fileList.map(file => {

            let formData = new FormData();
            // add one or more of your files in FormData
            // again, the original file is located at the `originFileObj` key
            // const formData = new FormData();
            formData.append("file", file.originFileObj);
            formData.append("tags", `codeinfuse, medium, gist`);
            formData.append("upload_preset", "testing"); // Replace the preset name with your own
            formData.append("api_key", "211986464428528"); // Replace API key with your own Cloudinary key
            formData.append("timestamp", (Date.now() / 1000) | 0);
            // formData.append("file", this.state.fileList[0].originFileObj);


            return axios.post("https://api.cloudinary.com/v1_1/ralphb21/image/upload", formData, options, {
                headers: { "X-Requested-With": "XMLHttpRequest" },
            }).then(response => {
                images.push(response.data)
                const data = response.data;
                const public_id = data.public_id;
                const fileURL = data.secure_url; // You should store this URL for future references in your app
                UploadedImages.push({ url: fileURL, public_id: public_id });
                // UploadedImages.url = fileURL
                // UploadedImages.public_id = public_id
            })

        })




        //Once all the files are uploaded
        axios.all(uploaders).then(async () => {
            // ... perform after upload is successful operation
            // await axios.post("http://localhost:5000/api/pins", markers).then((res)=>{
            //     if(res){
            //         handleImageSubmit(res.data._id);
            //         setFileList([])
            //     }
            // });
            setMainImageEntered(false)
            setImagesEntered(true)
            handleImageSubmit(markers)
            // setFileList([])
        });
    };


    const handleSubmitMainImagePaypal = () => {
        setLoading(true)
        let options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total)

                if (percent < 100) {
                    setUploadPercentage(percent)
                }
            }
        }

        let uploaders = mainImage.fileList.map(file => {

            let formData = new FormData();
            // add one or more of your files in FormData
            // again, the original file is located at the `originFileObj` key
            // const formData = new FormData();
            formData.append("file", file.originFileObj);
            formData.append("tags", `codeinfuse, medium, gist`);
            formData.append("upload_preset", "testing"); // Replace the preset name with your own
            formData.append("api_key", "211986464428528"); // Replace API key with your own Cloudinary key
            formData.append("timestamp", (Date.now() / 1000) | 0);
            // formData.append("file", this.state.fileList[0].originFileObj);

            return axios.post("https://api.cloudinary.com/v1_1/ralphb21/image/upload", formData, options, {
                headers: { "X-Requested-With": "XMLHttpRequest" },
            }).then(response => {
                const data = response.data;
                const public_id = data.public_id
                const fileURL = data.secure_url // You should store this URL for future references in your app
                UploadMainImage.url = fileURL
                UploadMainImage.public_id = public_id
            })
        })

        axios.all(uploaders).then(async () => {

            handleSubmitPaypal();

        });
    };

    const handleSubmitPaypal = () => {
        // event.preventDefault();
        // let filesUrl = [];
        let options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total)

                if (percent < 100) {
                    setUploadPercentage(percent)
                }
            }
        }

        let uploaders = imageFileList.fileList.map(file => {

            let formData = new FormData();
            // add one or more of your files in FormData
            // again, the original file is located at the `originFileObj` key
            // const formData = new FormData();
            formData.append("file", file.originFileObj);
            formData.append("tags", `codeinfuse, medium, gist`);
            formData.append("upload_preset", "testing"); // Replace the preset name with your own
            formData.append("api_key", "211986464428528"); // Replace API key with your own Cloudinary key
            formData.append("timestamp", (Date.now() / 1000) | 0);
            // formData.append("file", this.state.fileList[0].originFileObj);

            return axios.post("https://api.cloudinary.com/v1_1/ralphb21/image/upload", formData, options, {
                headers: { "X-Requested-With": "XMLHttpRequest" },
            }).then(response => {
                const data = response.data;
                const public_id = data.public_id;
                const fileURL = data.secure_url; // You should store this URL for future references in your app
                UploadedImages.push({ url: fileURL, public_id: public_id });
                // UploadedImages.url = fileURL
                // UploadedImages.public_id = public_id
            })

        })

        //Once all the files are uploaded
        axios.all(uploaders).then(async () => {
            // ... perform after upload is successful operation
            // await axios.post("http://localhost:5000/api/pins", markers).then((res)=>{
            //     if(res){
            //         handleImageSubmit(res.data._id);
            //         setFileList([])
            //     }
            // });
            handleImageSubmit(markers)
            // setFileList([])
        });
    };

    const GetStepContent = (activeStep) => {
        switch (activeStep) {
            case 0: return (<Bundle handleNext={handleNext} bundle={bundleChosen} setBundle={setBundle} setPrice={setPrice} price={bundlePrice} post={postData} setPost={setPostData} />)
            case 1: return (<GetStarted handleBack={handleBack} setPost={setPostData} handleChange={handleChange} values={postData} handleNext={handleNext} />)
            case 2: return (<MainInformation markers={markers} setMarkers={setMarkers} setPost={setPostData} values={postData} handleChange={handleChange} handleNext={handleNext} handleBack={handleBack} />)
            case 3: return (<Images bundle={bundleChosen} setPost={setPostData} post={postData} fileList={imageFileList} setFileList={setFileList} mainImage={mainImage} setMainImage={setMainImage} handleNext={handleNext} handleBack={handleBack} />)
            case 4: return (<AdditionalFeatures setPost={setPostData} handleChange={handleChange} values={postData} handleNext={handleNext} handleBack={handleBack} />)
            case 5: return (<Preview handleSubmit={handleSubmitMainImageFree} handleBack={handleBack} handleNext={handleNext} loading={isLoading} bundle={bundleChosen} values={postData} price={bundlePrice} paymentSelected={selectedPayment} setPayment={setPayment} />)
            case 6: return (<Payment setOpenStripe={setOpenStripe} openStripe={openStripe} ERROR={ERROR} stripe={Stripe} handleBack={handleBack} values={postData} setPost={setPostData} handleSubmit={mainImageEntered ? handleSubmit : handleSubmitMainImage} handleSubmitPaypal={handleSubmitMainImagePaypal} bundle={bundleChosen} loading={isLoading} price={bundlePrice} paymentSelected={selectedPayment} succeed={succeed} loadingStripe={loadingStripe} error={error} />)
            default: return
        }
    }

    if (!user?.result?.email) {
        return (
            <NotLoggedIn />
        );
    }
    return (
        <>
            <RootStyle title="Create Listing | eAkary" >
                <Stepper activeStep={activeStep} sx={{ overflowX: 'auto', marginTop: 10 }} alternativeLabel>
                    {
                        steps.map((step) => (
                            <Step key={step}>
                                <StepLabel>{step}</StepLabel>
                            </Step>
                        ))
                    }
                </Stepper>
                {activeStep === steps.length ? <Finish handleReset={handleReset} values={postData} /> : (GetStepContent(activeStep))
                }
            </RootStyle>
        </>
    )

}
export default AddListingStepper
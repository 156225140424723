import { PayPalButton } from "react-paypal-button-v2";
import React from 'react';
// import './style.css'

export function PayPalBtn(props) {
    const { amount, currency, createSubscription, onApprove, catchError, onError, onCancel } = props;
   //testing AaFu7-uvGQc0V61aNPNAGLyCi7aityiLEUjeRG_BmfS4N5L6l2g_7FFlJkmvSNIkrZMUJvRx6u0EzTZ3
   //live    AYNxODlcwufyCVO4An0ULEK43ufkTyu1qWeFfzhuHO33PD9RTBC8S0VMAV0sP3QwBcyIlCbk6OC8QKOb 
    const paypalKey = "AYNxODlcwufyCVO4An0ULEK43ufkTyu1qWeFfzhuHO33PD9RTBC8S0VMAV0sP3QwBcyIlCbk6OC8QKOb"
    return (
        <PayPalButton
            amount={amount}
            currency={currency}
            createSubscription={(data, details) => createSubscription(data, details)}
            onApprove={(data, details) => onApprove(data, details)}
            onError={(err) => onError(err)}
            catchError={(err) => catchError(err)}
            onCancel={(err) => onCancel(err)}
            options={{
                clientId: paypalKey,
                vault: true
            }}
            style={{
                shape: 'rect',
                color: 'blue',
                layout: 'horizontal',
                label: 'pay',
                height: 40,
            }}
        />
    );
}
export default PayPalBtn;
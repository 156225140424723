import React, { useState, useEffect } from "react";
import usePlacesAutocomplete from "use-places-autocomplete";
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from "@reach/combobox";
import { Trans, useTranslation } from 'react-i18next';
import './style.css'
import { MHidden } from '../@material-extend';
import Cookies from 'js-cookie';

// import useStyles from './searchStyle'


// const search = {
//     position: absolute,
//     top: '1rem',
//     left: '50%',
//     // transform: translateX(-50 %),
//     width: '100 %'',
//     maxWidth: 400px,
//         z - index: 10,
//   }

export default function Search({setValueMap}) {
    const [arLang, setArLang] = useState(false);
  let cookieLanguage = Cookies.get('i18next');


  useEffect(() => {
    if (cookieLanguage == "ar") {
      setArLang(true);
    }
  }, []);

    // const classes = useStyles();
    const { t, i18n } = useTranslation();

    // const [markers, setMarkers] = useState([]);

    const { ready, value, suggestions: { status, data }, setValue, clearSuggestions } = usePlacesAutocomplete();


    setValueMap(value)

    return (

        <div>
         <MHidden width="smDown">
            <Combobox 
                onSelect={async (address) => {

                    setValue(address, false)
                    clearSuggestions();
                  
                }}

            >
                <ComboboxInput  value={value} onChange={(e) => {
                    setValue(e.target.value);
                
                }}
                    placeholder={t("description.enterAdd")}
                     style={{width: "250px",height:"44px", border:"none"}}

                />
                <ComboboxPopover>
                    <ComboboxList>
                        {status === "OK" && data.map(({ id, description }) => (
                            <ComboboxOption key={id} value={description} />
                        ))}
                    </ComboboxList>
                </ComboboxPopover>
            </Combobox>
            </MHidden>
            <MHidden width="smUp">
            <Combobox 
                onSelect={async (address) => {

                    setValue(address, false)
                    clearSuggestions();
                  
                }}

            >
                <ComboboxInput  value={value} onChange={(e) => {
                    setValue(e.target.value);
                
                }}
                    placeholder={t("description.enterAdd")}
                     style={{width: "200px",height:"44px", border:"none"}}

                />
                <ComboboxPopover>
                    <ComboboxList>
                        {status === "OK" && data.map(({ id, description }) => (
                            <ComboboxOption key={id} value={description} />
                        ))}
                    </ComboboxList>
                </ComboboxPopover>
            </Combobox>
            </MHidden>
        </div >
    );
}
import * as React from 'react';
import { useEffect, useState } from "react";
import Typography from '@material-ui/core/Typography';
import { Grid, Snackbar, Alert, Stack, Container } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { createNotificationReq360 } from '../../actions/posts';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, Form, FormikProvider } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { LoadingButton } from '@material-ui/lab';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Input from '../../../src/components/Input/Input';
import * as Yup from 'yup';
import Iframe from 'react-iframe';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import Page from '../../components/Page';
import Cookies from 'js-cookie';
import './style.css';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

const RootStyle = styled(Page)(({ theme }) => ({
    paddingBottom: theme.spacing(10)
}));
export default function Request360() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openSnack, setSnackOpen] = useState(false);
    const [openPE, setOpenPE] = useState(false);
    const [success, setSuccess] = useState(undefined);
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    let cookieLanguage = Cookies.get('i18next');
    const LoginSchema = Yup.object({
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        password: Yup.string().required('Password is required')
    });
    const handleSnackPEClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenPE(false)
    };
    const { t, i18n } = useTranslation();
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false)
    };
    const validationSchema = Yup.object({
        firstName: Yup.string()
            .required('Required!'),
        lastName: Yup.string()
            .required('Required!'),
        email: Yup.string()
            .email('Invalid email format')
            .required('Required!'),
        message: Yup.string()
            .required('Required!'),
    })
    const formik = useFormik({
        initialValues: {
            email: '',
            firstName: '',
            lastName: '',
            message: ''
        },
        onSubmit: values => {
            if (isValid) {
                handleNotificationSubmit(values)
            } else {
                setOpenPE(true)
            }
        },
        validationSchema,
    });
    const handleNotificationSubmit = async (values) => {
        try {
            setLoading(true);
            const form = {
                email: values.email,
                phoneNumber: value,
                lastName: values.lastName,
                firstName: values.firstName,
                message: values.message
            }
            const success = await dispatch(createNotificationReq360(form))
            if (success) {
                setLoading(false);
                navigate('/');

                setSnackOpen(true);
            }
            else setLoading(false);
            setSuccess(success);
        }
        catch (err) {
        }
    }
    const handlePhoneChange = (status, phoneNumber, country) => {
        setValue('+' + country.dialCode + phoneNumber)
        setIsValid(status);
    };
    const [value, setValue] = useState({})
    const [isValid, setIsValid] = useState(false)
    return (
        <>
            <RootStyle title="Virtual Tour | eAKary">
                <Grid container justifyContent="center" alignItems="center">
                    <Typography variant="h3">{t("description.get360Tour")}</Typography>
                </Grid>
                <Grid container sx={{ mt: 5 }} justifyContent="center" alignItems="center">
                    <Grid item md={12} align="center">
                        <Grid container justifyContent="center" alignItems="center" sx={{ marginBottom: "20px" }}>
                            <Grid item mb={{ md: 0, sm: 4, xs: 4 }} md={6} sm={12} xs={12}>
                                <Iframe
                                    src="https://orbix360.com/t/7Lo1Kzu6M2Y4qcR2ShMFT0GRD6u2/5034404254580736/eakary?embed=yes"
                                    allowfullscreen="true"
                                    frameborder="0"
                                    scrolling="no"
                                    allow="vr;gyroscope;accelerometer"
                                    width="90%"
                                    height="300vh"
                                    style="border:none;">
                                </Iframe>
                            </Grid>
                            <Grid item md={6} sm={12} xs={12} sx={{ width: "90%" }}>
                                <Typography sx={{ fontSize: "18px", marginBottom: "20px", width: "90%", textAlign: "left", align: "justify" }}>
                                    <Trans i18nKey="description.360content">
                                        360° content is an immersive media format that shows a full 360° spherical view in all directions.
                                        <br />360° photography is like regular photography in that you can take a picture nearly anywhere, with the added bonus of 360° viewing range and a welcoming interactive display.
                                        <br />What's better than a real overview of a property to immerse yourself in it and make the best possible choice?
                                        <br />It is now possible with Eakary who proposes this service and has a team at your disposal.
                                        <br />Contact our agency team for more information or follow this link to proceed to payment.
                                    </Trans>
                                </Typography>
                            </Grid>
                        </Grid>
                        <br />
                        <hr style={{ marginBottom: "30px", width: "80%" }} />
                        <Container>
                            <Stack spacing={3}>
                                <Typography sx={{ fontSize: "18px", marginBottom: "30px", margin: "auto" }}>{t("description.filltheform")}</Typography>
                                <form onSubmit={formik.handleSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item md={6} sm={6} xs={12} sx={{ marginTop: "10px" }}>
                                            <div style={{ marginBottom: "20px" }}>
                                                <Input name="email" label={t("description.emailAdd")} handleBlur={formik.handleBlur} handleChange={formik.handleChange} type="email" />
                                                {formik.touched.email && formik.errors.email ? <span style={{ fontSize: '15px' }}>  <div style={{ color: 'red' }}>{formik.errors.email}</div></span> : null}
                                            </div>
                                            <div style={{ marginBottom: 20 }}>
                                                <IntlTelInput
                                                    containerClassName="intl-tel-input"
                                                    inputClassName="form-control"
                                                    variant="contained"
                                                    type="tel"
                                                    value={value.phoneNumber}
                                                    onPhoneNumberChange={handlePhoneChange}
                                                    preferredCountries={['lb']}
                                                />
                                                {/* {cookieLanguage === 'en' ?
                                                    (
                                                        <PhoneInput
                                                            value={value}
                                                            onChange={setValue}
                                                            labels={en}
                                                            rules={{ required: true }}
                                                        />
                                                    ) : (
                                                        <PhoneInput
                                                            value={value}
                                                            onChange={setValue}
                                                            labels={fr}
                                                            rules={{ required: true }}
                                                        />
                                                    )} */}
                                            </div>
                                            {/* <div style={{ marginBottom: 20 }}>
                                                <Input name="phoneNumber" label={t("description.phone")} handleBlur={formik.handleBlur} handleChange={formik.handleChange} type="phoneNumber" />
                                                {formik.touched.phoneNumber && formik.errors.phoneNumber ? <div style={{ color: 'red' }}>{formik.errors.phoneNumber}</div> : null}
                                            </div> */}
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12} sx={{ marginTop: "10px" }}>
                                            <div style={{ marginBottom: 20 }}>
                                                <Input name="firstName" label={t("description.fn")} handleBlur={formik.handleBlur} handleChange={formik.handleChange} type="firstName" />
                                                {formik.touched.firstName && formik.errors.firstName ? <div style={{ color: 'red' }}>{formik.errors.firstName}</div> : null}
                                            </div>
                                            <div style={{ marginBottom: 20 }}>
                                                <Input name="lastName" label={t("description.ln")} handleBlur={formik.handleBlur} handleChange={formik.handleChange} type="lastName" />
                                                {formik.touched.lastName && formik.errors.lastName ? <div style={{ color: 'red' }}>{formik.errors.lastName}</div> : null}
                                            </div>
                                        </Grid>
                                        <Grid item md={12} sm={12} xs={12} margin="auto" sx={{ marginTop: "20px" }}>
                                            <div style={{ marginBottom: "20px", margin: "auto" }}>
                                                <Input name="message" handleBlur={formik.handleBlur} label={t("description.message")} handleChange={formik.handleChange} type="message" multiline={true} rows={4} sx={{ width: "100%" }} />
                                                {formik.touched.message && formik.errors.message ? <div style={{ color: 'red' }}>{formik.errors.message}</div> : null}
                                            </div>
                                        </Grid>
                                        <Grid item md={6} margin="auto" sx={{ marginTop: "20px" }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                            >{t("description.request360")}</Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Stack>
                        </Container>
                        <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                            <Alert onClose={handleSnackClose} severity="success">
                                {t("description.successfuly360")}
                            </Alert>
                        </Snackbar>
                        <Snackbar open={openPE} autoHideDuration={6000} onClose={handleSnackPEClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                            <Alert onClose={handleSnackPEClose} severity="error">
                                Wrong phone number
                            </Alert>
                        </Snackbar>
                    </Grid>
                </Grid>
            </RootStyle>
        </>
    )
}

import {makeStyles} from "@material-ui/styles";
const UseStyles = makeStyles((theme) => ({
    rootAds:{
        [theme.breakpoints.down("xs")]: {
            marginLeft: '-175px',
            marginRight: '-50px',
        },[theme.breakpoints.up("lg")]: {
            maxWidth: 300,
        }
    },
    Favorite: {
        position:"absolute",
        top:10,
        right:63,
        background:"lightgray",
        color:"white",
        pointerEvents: "none"
    },
    Share:{
        position:"absolute",
        top:10,
        right:10,
        background:"lightgray",
        color:"white",
        pointerEvents: "none"
    },
    Title:{
        fontFamily:'Roboto'
    },

}));
export default UseStyles
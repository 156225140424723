import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { useSelector, useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Grid, CircularProgress } from '@material-ui/core'
import PropertyItem from '../PropertyItem/PropertyItem'
import "swiper/swiper.min.css";
import './swiperStyles.css'
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"
// import "./styles.css";
import LandL from '../images/carouselImages/land1.jpg'
import LandT from '../images/carouselImages/land2.jpg'
import LandM from '../images/carouselImages/land3.jpg'
import ApartmentL from '../images/carouselImages/apartment1.jpg'
import ApartmentT from '../images/carouselImages/apartment2.jpg'
import ApartmentM from '../images/carouselImages/apartment3.jpg'
import OfficeL from '../images/carouselImages/office1.jpg'
import OfficeT from '../images/carouselImages/office2.jpg'
import OfficeM from '../images/carouselImages/office3.jpg'
import VillaL from '../images/carouselImages/villa1.jpg'
import VillaT from '../images/carouselImages/villa2.jpg'
import VillaM from '../images/carouselImages/villa3.jpg'

import SwiperCore, {
    Pagination, Navigation
} from 'swiper/core';
SwiperCore.use([Pagination, Navigation]);

const Carousel = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [showProperties, setShowProperties] = useState([]);

    const properties = [
        { mainImage: { Laptop: LandL, Tablet: LandT, Mobile: LandM }, address: "Awkar,Maten- Mount Lebanon", price: '4,500,000', altitude: 1000, surfaceArea: '10,640', _id: 1, propertyType: "Land", isForSale: true },
        { mainImage: { Laptop: ApartmentL, Tablet: ApartmentT, Mobile: ApartmentM }, address: "Mar Moussa - Broummana", price: '1,000', numberOfBedrooms: 2, numberOfBathrooms: 1, numberOfRooms: 4, surfaceArea: '1,250', _id: 1, propertyType: "Apartment", isForSale: false },
        { mainImage: { Laptop: OfficeL, Tablet: OfficeT, Mobile: OfficeM }, address: "Saifi - Beirut", price: '2,000', surfaceArea: '7,212', _id: 1, propertyType: "Office", isForSale: false },
        { mainImage: { Laptop: VillaL, Tablet: VillaT, Mobile: VillaM }, address: "Jbeil Community- Mount Lebanon", price: '2,500,000', numberOfBedrooms: 5, numberOfBathrooms: 3, numberOfRooms: 10, surfaceArea: '2,500', _id: 1, propertyType: "Villa", isForSale: true },

    ]
    useEffect(() => {
        let LIMIT = 20;
        const getProperties = async (limit) => {
            setShowProperties([]);
            try {
                const res = await axios.get(`https://backend.eakary.com/posts/getRecentPostsCarousel/${limit}`);
                const posts = res.data.data;
                if (posts.length > 0) {
                    let needApartmentProperty = true;
                    let needVillaProperty = true;
                    let needLandProperty = true;
                    let needOfficeProperty = true;
                    let success = true;
                    let i = 0;
                    const gettingShowProperties = [];
                    while (needApartmentProperty || needVillaProperty || needLandProperty || needOfficeProperty) {
                        if (i >= posts.length) { success = false; break; }
                        switch (posts[i].propertyType) {
                            case 'Villa': needVillaProperty && gettingShowProperties.push({ propertyType: posts[i].propertyType, price: posts[i].price, address: posts[i].mapPin ? posts[i].mapPin.address : posts[i].city, numberOfBedrooms: posts[i].numberOfBedrooms, numberOfBathrooms: posts[i].numberOfBathrooms, numberOfRooms: posts[i].numberOfRooms, isForSale: posts[i].listingType === 'buy', surfaceArea: posts[i].surfaceArea, _id: posts[i]._id, mainImage: { Laptop: VillaL, Tablet: VillaT, Mobile: VillaM } }); needVillaProperty = false; break;
                            case 'Apartment': needApartmentProperty && gettingShowProperties.push({ propertyType: posts[i].propertyType, price: posts[i].price, address: posts[i].mapPin ? posts[i].mapPin.address : posts[i].city, numberOfBedrooms: posts[i].numberOfBedrooms, numberOfBathrooms: posts[i].numberOfBathrooms, numberOfRooms: posts[i].numberOfRooms, isForSale: posts[i].listingType === 'buy', surfaceArea: posts[i].surfaceArea, _id: posts[i]._id, mainImage: { Laptop: ApartmentL, Tablet: ApartmentT, Mobile: ApartmentM } }); needApartmentProperty = false; break;
                            case 'Land': needLandProperty && gettingShowProperties.push({ propertyType: posts[i].propertyType, price: posts[i].price, address: posts[i].mapPin ? posts[i].mapPin.address : posts[i].city, altitude: 1, isForSale: posts[i].listingType === 'buy', surfaceArea: posts[i].surfaceArea, _id: posts[i]._id, mainImage: { Laptop: LandL, Tablet: LandT, Mobile: LandM }, }); needLandProperty = false; break;
                            case 'Office': needOfficeProperty && gettingShowProperties.push({ propertyType: posts[i].propertyType, price: posts[i].price, address: posts[i].mapPin ? posts[i].mapPin.address : posts[i].city, isForSale: posts[i].listingType === 'buy', surfaceArea: posts[i].surfaceArea, _id: posts[i]._id, mainImage: { Laptop: OfficeL, Tablet: OfficeT, Mobile: OfficeM } }); needOfficeProperty = false; break;
                        }
                        i++;
                    }
                    if (!success) { LIMIT += 10; getProperties(LIMIT); }
                    setIsLoading(false)
                    const sortedGettingShowProperties = [];
                    sortedGettingShowProperties.push(gettingShowProperties.filter(property => property.propertyType === 'Land'))
                    sortedGettingShowProperties.push(gettingShowProperties.filter(property => property.propertyType === 'Apartment'))
                    sortedGettingShowProperties.push(gettingShowProperties.filter(property => property.propertyType === 'Office'))
                    sortedGettingShowProperties.push(gettingShowProperties.filter(property => property.propertyType === 'Villa'))
                    setShowProperties(gettingShowProperties);
                } else {
                    setIsLoading(false)
                    setShowProperties(properties);
                    throw new Error('failed to fetch properties')
                }
            } catch (e) {
                setIsLoading(false)
                setShowProperties(properties);
            }
        }

        getProperties(LIMIT);
    }, [])
    return (
        isLoading ? <CircularProgress /> :
            <Swiper lazy={true} navigation={true} slidesPerView={1} loop={true} loopFillGroupWithBlank={true} className="carouselSwiper" sx={{ height: 'auto' }} >

                {showProperties.length === 4 && showProperties.map((property, i) =>
                    <SwiperSlide key={i}>
                        <PropertyItem property={property} key={property._id} />
                    </SwiperSlide>)}
            </Swiper>

    )
};
export default Carousel;

import React, { useMemo, useEffect, useState } from 'react'
import { Button, Grid } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import UseStyles from './style'
import { Link } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import Page from '../../components/Page';
import { Trans, useTranslation } from 'react-i18next';

const RootStyle = styled(Page)(({ theme }) => ({
  paddingBottom: theme.spacing(10)
}));

const FAQ = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const classes = UseStyles();
  return (
    <>
      <RootStyle title="FAQ | eAkary">
        <Container>

          <Grid container alignItems="center" justify="space-between">
            <Typography className={classes.typoTitle} component="h4" variant="h4">
              FAQ
            </Typography>
          </Grid>


          <Grid container alignItems="center" justify="space-between">
            <Typography className={classes.typoTitle} component="h5" variant="h5">
              {t("description.hello")}
            </Typography>

          </Grid>

          <Grid container alignItems="center" justify="center" >

            <Grid container  >


              <Grid md={6} alignItems="center" justify="center" >
                <Accordion className={classes.Accordion}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>{t("description.iseakary")}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {t("description.eakaryisnot")}
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion className={classes.Accordion} >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>{t("description.howcani")}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {t("description.toincrease")}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion className={classes.Accordion}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>{t("description.idontremember")}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {t("description.wecaneasily")}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              {/* <Grid md={6} alignItems="center" justify="center" >
                <Accordion className={classes.Accordion} >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>Accordion 1</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                      sit amet blandit leo lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion className={classes.Accordion}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>Accordion 1</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                      sit amet blandit leo lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion className={classes.Accordion}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>Accordion 1</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                      sit amet blandit leo lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion className={classes.Accordion}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>Accordion 1</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                      sit amet blandit leo lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

              </Grid> */}
            </Grid>
          </Grid>


          <Grid container alignItems="center" justify="space-between">
            <Typography variant="body2" component="p" className={classes.typoText} style={{ paddingTop: '20px', paddingBottom: '20px' }} >
              {t("description.stillstuck")}
            </Typography>
          </Grid>

          <Grid container alignItems="center" justifyContent="center">

            <Button variant="contained" color="primary" disableElevation onClick={() => navigate("/contact")}>Contact Us</Button>
          </Grid>

        </Container>
      </RootStyle>
    </>
  )
}

export default FAQ
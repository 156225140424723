import React, { useState } from 'react'
import { addToWishlist, getWishlist } from '../../actions/posts';
import { deleteWish } from '../../actions/posts';
import { useDispatch, useSelector } from 'react-redux';
import './House.css'
import LandscapeIcon from '@material-ui/icons/Landscape';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone, faVectorSquare } from '@fortawesome/free-solid-svg-icons'
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container'
import FavoriteTwoToneIcon from '@material-ui/icons/FavoriteTwoTone'
import FavoriteIcon from '@material-ui/icons/Favorite'
import { Chip } from '@material-ui/core'
import { useTranslation } from 'react-i18next';

const OfficeAndGarage = ({ setIsSnackBarOpen, address, liked, surfaceArea, _id, isForSale, price, phoneNumber, email, mainImage, bundleChosen }) => {
    const dispatch = useDispatch();
    const [LoggedUser, setLoggedUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const [isLiked, setIsLiked] = useState(liked)
    const { t, i18n } = useTranslation();
    const clickedFavoriteBtn = (idPost) => {
        const id = LoggedUser?.result._id;
        if (!id) { setIsSnackBarOpen(true); return }
        const formData = {
            post: idPost
        };
        setIsLiked(true);
        if (id !== null) {
            dispatch(addToWishlist(id, formData));
        }
    }
    const unClickedFavoriteBtn = (idPost) => {
        const userId = LoggedUser?.result._id;
        if (!userId) { setIsSnackBarOpen(true); return }
        // dispatch(getWishlist(userId));
        setIsLiked(false);
        if (userId !== null) {
            dispatch(deleteWish(userId, idPost));
        }
    }
    const handleLikePostClick = () => {
        if (isLiked) { unClickedFavoriteBtn(_id); }
        else (clickedFavoriteBtn(_id))
    }
    const getFavIconStyle = () => {
        if (bundleChosen === 'Basic') { return { marginRight: '0', marginLeft: 'auto' } }
        return {}
    }
    const getChipStyle = () => {
        if (bundleChosen === 'Gold') {
            return { background: 'gold', color: 'black' }
        } else if (bundleChosen === 'Platinum') {
            return { background: 'gray', color: 'white' }
        }
        return {}
    }
    return (
        <Container>
            <Hidden only={['sm', 'xs']}>
                <div className='container' style={{ justifyContent: 'flex-end' }}>
                    <div className='property-box'>
                        <div className='image' style={{ position: 'relative' }}>
                            <div style={{ position: 'absolute', width: '100%', display: 'flex', justifyContent: 'space-between', padding: '0.25rem' }}>
                                {bundleChosen !== 'Basic' && <div><Chip sx={getChipStyle()} label={bundleChosen} />  </div>}
                                <div onClick={handleLikePostClick} style={getFavIconStyle()} > {isLiked ?
                                    <FavoriteIcon sx={{
                                        fontSize: '2rem',
                                        color: 'red', ':hover': { cursor: 'pointer' }, boxSizing: 'border-box', border: `2px solid rgba(255,255,255,0.6)`,
                                        background: 'rgba(19,32,49,.65)', borderRadius: '50%',
                                        padding: '3px'
                                    }} /> :
                                    // FavoriteBorderIcon
                                    <FavoriteTwoToneIcon sx={{
                                        fontSize: '2rem',
                                        color: 'white', ':hover': { cursor: 'pointer', color: 'red', transition: '0.5s ease' },
                                        boxSizing: 'border-box', border: `2px solid rgba(255,255,255,0.6)`, background: 'rgba(19,32,49,.65)', borderRadius: '50%',
                                        padding: '3px'
                                    }} />
                                } </div>
                            </div>
                            <img src={mainImage.url} alt='apartment' />
                        </div>
                        <div className='content' style={{ marginTop: '0.5rem' }}>
                            <h3>{address}<br />  </h3>
                            <h4>$ {price} {isForSale ? '' : '/mo'}</h4>
                            <div style={{ display: 'flex', justifyContent: 'space-around', margin: '0' }} className='details'>
                                <div style={{ display: 'flex', alignItems: 'center', fontSize: '1rem' }}><FontAwesomeIcon icon={faVectorSquare} className='bed'></FontAwesomeIcon>{surfaceArea}sqft</div>
                            </div>
                            <hr />
                            <div style={{ display: 'flex' }} className='buttons'>
                                <a href={`/posts/${_id}`}><button class="button-2" style={{ fontSize: '1rem' }} type="submit">
                                    {t("description.viewdet")}
                                </button></a>
                                <a href={`mailto:${email}`}> <button class="button-3" type="submit" ><FontAwesomeIcon icon={faEnvelope} className='text-w te-500'></FontAwesomeIcon></button></a>
                                <a href={`tel:${phoneNumber}`}> <button class="button-4" type="submit" ><FontAwesomeIcon icon={faPhone} className='text-w te-500'></FontAwesomeIcon></button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </Hidden>
            <Hidden smUp> {/*xs*/}
                <div className='container-xs'>
                    <div className='property-box-xs' style={{ height: 'fit-content' }}>
                        <div className='image-xs' style={{ position: 'relative' }}>
                            <div style={{ position: 'absolute', width: '100%', display: 'flex', justifyContent: 'space-between', padding: '0.25rem' }}>
                                {bundleChosen !== 'Basic' && <div><Chip sx={getChipStyle()} label={bundleChosen} />  </div>}
                                <div onClick={handleLikePostClick} style={getFavIconStyle()} > {isLiked ?
                                    <FavoriteIcon sx={{
                                        fontSize: '2rem',
                                        color: 'red', ':hover': { cursor: 'pointer' }, boxSizing: 'border-box', border: `2px solid rgba(255,255,255,0.6)`,
                                        background: 'rgba(19,32,49,.65)', borderRadius: '50%',
                                        padding: '3px'
                                    }} /> :
                                    // FavoriteBorderIcon
                                    <FavoriteTwoToneIcon sx={{
                                        fontSize: '2rem',
                                        color: 'white', ':hover': { cursor: 'pointer', color: 'red', transition: '0.5s ease' },
                                        boxSizing: 'border-box', border: `2px solid rgba(255,255,255,0.6)`, background: 'rgba(19,32,49,.65)', borderRadius: '50%',
                                        padding: '3px'
                                    }} />
                                } </div>
                            </div>
                            <img src={mainImage.url} alt='apartment' />
                        </div>
                        <div className='content-xs' style={{ padding: '0.5rem', display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'space-evenly' }}>
                            <div className='posts-house-houseInfo' style={{ display: 'flex', flexDirection: 'column', alignSelf: 'flex-start', justifyContent: 'center', alignItems: 'flex-start' }}>
                                <h3 style={{ margin: '0' }}>{address}</h3>
                                <h3 style={{ margin: '0' }}>  </h3>
                                <h4 style={{ margin: '0' }}>$ {price} {isForSale ? '' : '/mo'}</h4>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%', alignSelf: 'center', marginTop: '0.5rem' }} className='details'>
                                <div style={{ display: 'flex', alignItems: 'center' }}><FontAwesomeIcon icon={faVectorSquare} className='bed'></FontAwesomeIcon>{surfaceArea}sqft</div>
                            </div>
                            <hr />
                            <div style={{ display: 'flex', marginBottom: '0.5rem', alignSelf: 'center' }} className='buttons-xs'>
                                <a href={`/posts/${_id}`}> <button class="button-2" style={{ fontSize: '0.85rem' }} type="submit">
                                    {t("description.viewdet")}
                                </button></a>
                                <a href={`mailto:${email}`}><button class="button-3" type="submit" ><FontAwesomeIcon icon={faEnvelope} className='text-w te-500'></FontAwesomeIcon></button></a>
                                <a href={`tel:${phoneNumber}`}><button class="button-4" type="submit" ><FontAwesomeIcon icon={faPhone} className='text-w te-500'></FontAwesomeIcon></button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </Hidden>
            <Hidden only={['xl', 'lg', 'md', 'xs']}> {/*sm*/}
                <div className='container-sm'>
                    <div className='property-box-sm'>
                        <div className='image' style={{ position: 'relative' }}>
                            <div style={{ position: 'absolute', width: '100%', display: 'flex', justifyContent: 'space-between', padding: '0.25rem' }}>
                                {bundleChosen !== 'Basic' && <div><Chip sx={getChipStyle()} label={bundleChosen} />  </div>}
                                <div onClick={handleLikePostClick} style={getFavIconStyle()} > {isLiked ?
                                    <FavoriteIcon sx={{
                                        fontSize: '2rem',
                                        color: 'red', ':hover': { cursor: 'pointer' }, boxSizing: 'border-box', border: `2px solid rgba(255,255,255,0.6)`,
                                        background: 'rgba(19,32,49,.65)', borderRadius: '50%',
                                        padding: '3px'
                                    }} /> :
                                    // FavoriteBorderIcon
                                    <FavoriteTwoToneIcon sx={{
                                        fontSize: '2rem',
                                        color: 'white', ':hover': { cursor: 'pointer', color: 'red', transition: '0.5s ease' },
                                        boxSizing: 'border-box', border: `2px solid rgba(255,255,255,0.6)`, background: 'rgba(19,32,49,.65)', borderRadius: '50%',
                                        padding: '3px'
                                    }} />
                                } </div>
                            </div>
                            <img src={mainImage.url} alt='apartment' />
                        </div>
                        <div className='content' style={{ marginTop: '0.5rem' }}>
                            <h3>{address}<br />  </h3>
                            <h4>$ {price} {isForSale ? '' : '/mo'}</h4>
                            <div style={{ display: 'flex', justifyContent: 'space-around', margin: '0' }} className='details'>
                                <div style={{ display: 'flex', alignItems: 'center', fontSize: '1rem' }}><FontAwesomeIcon icon={faVectorSquare} className='bed'></FontAwesomeIcon>{surfaceArea}sqft</div>
                            </div>
                            <hr />
                            <div style={{ display: 'flex' }} className='buttons'>
                                <a href={`/posts/${_id}`}> <button class="button-2" style={{ fontSize: '1rem' }} type="submit">
                                    {t("description.viewdet")}
                                </button></a>
                                <a href={`mailto:${email}`}> <button class="button-3" type="submit" > <FontAwesomeIcon icon={faEnvelope} className='text-w te-500'></FontAwesomeIcon></button></a>
                                <a href={`tel:${phoneNumber}`}><button class="button-4" type="submit" ><FontAwesomeIcon icon={faPhone} className='text-w te-500'></FontAwesomeIcon></button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </Hidden>
        </Container>
    )
}
export default OfficeAndGarage

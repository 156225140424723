
import { Link as RouterLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
// material
import { Box, Button, Typography, Container } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
// components

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

export default function NotLoggedIn() {

  //////////////////////////////////////
  const { i18n } = useTranslation();

  const [arLang, setArLang] = useState(false);
  let cookieLanguage = Cookies.get('i18next');


  useEffect(() => {
    if (cookieLanguage == "ar") {
      setArLang(true);
    }
  }, [])

  return (
    <Container>
      <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center', mb: 5 }}>
        <div>
          <Typography variant="h3" paragraph>
            <Trans i18nKey="description.pleaseLogin">
              Please Login
            </Trans>
          </Typography>
        </div>
        <Typography sx={{ color: 'text.secondary' }}>
          <Trans i18nKey="description.sorryBut">
            Sorry, but you have to login or register to add a property
          </Trans>
        </Typography>
        <div>
          <Box
            component="img"
            src="/static/illustrations/warning.svg"
            sx={{ height: 160, mx: 'auto', my: { xs: 5, sm: 10 } }}
            sm={{ height: 50 }}
          />
        </div>
        {
          arLang ? (
            <Button to="/auth/register" size="large" variant="outlined" sx={{ mr: 3, marginLeft: "15px" }} component={RouterLink}>
              <Trans i18nKey="description.register">
                Register
              </Trans>
            </Button>
          ) : (
            <Button to="/auth/register" size="large" variant="outlined" sx={{ mr: 3 }} component={RouterLink}>
              <Trans i18nKey="description.register">
                Register
              </Trans>
            </Button>
          )
        }

        <Button to="/auth/login" size="large" variant="contained" component={RouterLink}>
          <Trans i18nKey="description.loginPage">
            Login
          </Trans>
        </Button>
      </Box>
    </Container>
  );
}

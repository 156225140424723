import React, { useState, useCallback, useRef, useEffect } from "react";
import {
    GoogleMap,
    useLoadScript,
    Marker,
    InfoWindow,
} from "@react-google-maps/api";
import { formatRelative } from "date-fns";
import Geocode from "react-geocode";
import "@reach/combobox/styles.css";
import mapStyles from "./mapStyles";
import Search from "./Search";
import Locate from './Locate';
import axios from "axios";
import './searchStyle.css';
import Typography from '@material-ui/core/Typography';

// import GetMarkers from "./GetMarkers";


const libraries = ["places"];

const mapContainerStyle = {
    height: "50vh",
    width: "80vw",
};
const options = {
    styles: mapStyles,
    zoomControl: true,
    fullscreenControl: false,
    mapTypeControl: true,
    streetViewControl: false
};
const center = {
    lat: 33.82543142169751,
    lng: 35.583106540518465,
};

export default function Map({ markers, setMarkers }) {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyC-cRq-I-LZI5RcMt00vnXyhfz5Mchuihc',
        libraries, //Avoid many rerenders
    });
    // const [markers, setMarkers] = useState([]);
    const [selected, setSelected] = useState(null);
    const [newPlace, setNewPlace] = useState(null);
    const [added, setAdded] = useState(null);

    let markerAddress = []

    const getAddress = async (event) => {
        Geocode.setApiKey("AIzaSyC-cRq-I-LZI5RcMt00vnXyhfz5Mchuihc");
        Geocode.fromLatLng(event.latLng.lat(), event.latLng.lng()).then(
            async (response) => {
                const address = response.results[0].formatted_address;
                markerAddress[0] = address;

                const newMarker = {
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng(),
                    address: address,
                    time: new Date(),
                };

                try {
                    const res = newMarker;
                    setMarkers(res);
                } catch (err) {
                }

            },
            (error) => {
            }
        );

    }


    const onMapClick = useCallback(async (event) => {
        getAddress(event)

        // setMarkers(current => {
        //     if (current.length < 1) {
        //         return [
        //             ...current,
        //             {
        //                 lat: event.latLng.lat(),
        //                 lng: event.latLng.lng(),
        //                 address: markerAddress,
        //                 time: new Date(),
        //             },
        //         ]

        //     }
        // })

    }, []);



    const handleMapClick = async (event) => {
        getAddress(event)
        const newMarker = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
            address: markerAddress,
            // time: new Date(),
        };
        const res = newMarker;

        setMarkers([...markers, res]);

    }

    const mapRef = useRef(); //retain state when you dont want to rerender
    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);

    const panTo = useCallback(({ lat, lng }) => {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(14);
    }, [])

    if (loadError) return "Error loading maps";
    if (!isLoaded) return "loading maps"

    return (
        <div>
            <div className="titleContainer">
                <Typography className="title">Cick on map to add you property {" "}
                    <span role="img" aria-label="tent">
                        📌
                    </span>
                </Typography>
            </div>
            <Search panTo={panTo} markers={markers} setMarkers={setMarkers} />
            <Locate panTo={panTo} />

            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={8}
                center={center}
                options={options}
                onClick={onMapClick}
                onLoad={onMapLoad}
            >

                <Marker
                    key={markers.lat, markers.lng}
                    position={{ lat: markers.lat, lng: markers.lng }}
                    icon={{
                        url: "./house.svg",
                        scaledSize: new window.google.maps.Size(30, 30),
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(15, 15),

                    }}
                    onClick={() => {
                        setSelected(markers);

                    }}
                    draggable={true}
                    onDragEnd={onMapClick}

                />

                {selected ? (
                    <InfoWindow position={
                        { lat: selected.lat, lng: selected.lng }
                    }
                        onCloseClick={() => {
                            setSelected(null);
                        }} >
                        <div>
                            {/* <p>Time:{formatRelative(selected.time, new Date())}</p> */}
                            <p>{selected.lat}</p>
                            <p>{selected.lng}</p>
                            <p>{selected.address}</p>
                        </div>
                    </InfoWindow>) : null}
            </GoogleMap>
        </div >
    );

}




import * as React from 'react';
import { Container, Box, Grid, Typography, Alert, Snackbar, Stack } from "@material-ui/core";
import { useParams } from "react-router-dom";
import ProfileField from "../components/TextFields/TextField";
import emailConf from '../images/emailConf.svg'
import useState from "react-hook-use-state";
import { LoadingButton } from "@material-ui/lab";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import AuthLayout from '../layouts/AuthLayout';

import { Trans, useTranslation } from 'react-i18next';
import Button from "@material-ui/core/Button";
import {signup} from "../actions/auth";
import { experimentalStyled as styled } from '@material-ui/core/styles';
import Page from '../components/Page';
const RootStyle = styled(Page)(({ theme }) => ({
    paddingBottom: theme.spacing(10),
    paddingTop: theme.spacing(10)
  }));

const EmailConfirmation = () => {
    const dispatch = useDispatch();
    const search = useLocation();
    const { token } = useParams();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [success, setSuccess] = useState(undefined);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleShowNewPassword = () => setShowNewPassword(!showNewPassword);

    const value = search

    const email = search.state.email
    const password = search.state.password
    const firstName = search.state.firstName
    const lastName = search.state.lastName
    const confirmPassword = search.state.confirmPassword

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false)
    };

    const { t, i18n } = useTranslation();

    return (
        <RootStyle title="Confirm Email | eAkary">
            <AuthLayout />
            <Container style={{marginTop:'10%'}}>
                <Grid container rowSpacing={1} spacing={5} justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={6} md={5} lg={5} direction='column'  >
                        <Box
                            component="img"
                            src={emailConf}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} direction='column' columnSpacing={5}>
                        <Typography variant="h3" mt={4} mb={4}>
                            <Trans i18nKey="description.pleaseCheck">
                                Please check you mailbox to validate your account
                            </Trans>
                        </Typography>
                        <Button
                            size="medium"
                            variant="contained"
                            style={{width:"fit-content" }}
                            onClick={() => {
                                const form =
                                {
                                    email: email,
                                    password: password,
                                    firstName: firstName,
                                    lastName: lastName,
                                    confirmPassword: confirmPassword
                                }
                                dispatch(signup(form));
                            }
                            }
                        >
                            <Trans i18nKey="description.resend">
                                Resend confirmation e-mail
                            </Trans>

                        </Button>
                    </Grid>
                </Grid>
                {
                    success !== undefined ? (
                        success ? null
                            : (
                                <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                                    <Alert onClose={handleSnackClose} severity="error">
                                        <Trans i18nKey="description.sessionExp">
                                            Session Has Expired please resend your email
                                        </Trans>
                                    </Alert>
                                </Snackbar>
                            )) : null
                }
            </Container>
        </RootStyle>
    )
}
export default EmailConfirmation;
import React, { useState, useCallback, useRef } from "react";
import "@reach/combobox/styles.css";
import './locateStyle.css';
import compassimage from './compass.svg';


export default function Locate({ panTo }) {
    return (
        <button className="locateRent" onClick={() => {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    panTo({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,

                    })
                },
                () => null
            );
        }}
        >
            <img src={compassimage} alt="compass - locate me" />
        </button>
    );
}


import { Card, CardContent, Typography } from "@material-ui/core";
import React from "react";
import UseStyles from './style';
import { useNavigate, useParams } from 'react-router-dom';

const AdImgCard = ({ imgad , setcurrentId }) => {
    const navigate = useNavigate();
    const classes = UseStyles();
    const {id} =useParams();
    const openAd = (e) => {
        navigate(`/CMS/posts/${imgad._id}/ImgAdDetails`);
      };

    return (
    <div>
        <div>
            <Card variant="outlined" className={classes.root} elevation={0} onClick={() => openAd()} >
            <CardContent style={{ textAlign: "Left" }}>

                <Typography variant="subtitle2" component="p" className={classes.typoName}>
                {imgad.title}hi
                </Typography>
                <Typography variant="h6" color="primary" component="p" className={classes.typoPrice}>
                {imgad.ownerName}
                </Typography>

            </CardContent>
            </Card>
    </div>
</div>
);};
export default AdImgCard;
import React, { useState } from 'react'
import { Link as RouterLink } from "react-router-dom";
import UseStyles from "./style";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import HotelIcon from '@material-ui/icons/Hotel';
import BathtubIcon from '@material-ui/icons/Bathtub';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import apartment from "../../images/apartment.jpg";
import 'boxicons';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';

import Stack from '@material-ui/core/Stack';
import { fCurrency } from '../../utils/formatNumber';


const Finish = ({ handleReset, values }) => {

  const { t, i18n } = useTranslation();

  return (

    <React.Fragment>
      <Container>
        <Grid sx={{ mb: 2, mt: 5 }} container justifyContent="center">
          <Card style={{ maxWidth: 300 }} variant="outlined" elevation={0} >
            <Grid container>
              {(values?.bundleChosen === 'Gold') && (
                <Grid container >
                  <Chip label={t("description.gold")} sx={{ mt: 1, ml: 1, backgroundColor: "#FFD700", color: "white", position: "absolute" }} size="small" />
                </Grid>

              )}

              {(values?.bundleChosen === 'Platinum') && (
                <Grid container >
                  <Chip label={t("description.platinum")} sx={{ mt: 1, ml: 1, backgroundColor: "#E5E4E2", color: "white", position: "absolute" }} size="small" />
                </Grid>

              )}
            </Grid>

            <CardMedia
              component="img"
              image={values.mainImage ? values.mainImage.url : '/static/logo2.png'}
              title={apartment}
              alt={apartment}
            />

            <CardContent style={{ textAlign: "Left" }}>
              <Typography variant="subtitle2" component="p" >
                {values.propertyType}
              </Typography>
              <Typography variant="h4" style={{ marginBottom: "5px", marginTop: "5px" }} component="p" >
                {fCurrency(values.price)}
              </Typography>
              <Typography variant="body2" component="p" noWrap>
                {values.mapPin ? values.mapPin.address : values.city}
              </Typography>
              <hr style={{ marginBottom: "10px", marginTop: "10px" }} />
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                {values.numberOfBedrooms === null ||
                  values.numberOfBedrooms === '' ||
                  values.numberOfBedrooms === undefined ? (
                  null
                ) : (
                  <Chip icon={<HotelIcon style={{ color: "#001329" }} />} label={values.numberOfBedrooms} size="small" style={{ backgroundColor: "white" }} />

                )}
                {values.numberOfBathrooms === null ||
                  values.numberOfBathrooms === '' ||
                  values.numberOfBathrooms === undefined ? (
                  null
                ) : (<Chip icon={<BathtubIcon style={{ color: "#001329" }} />} label={values.numberOfBathrooms} size="small" style={{ backgroundColor: "white" }} />

                )}

                {(values?.surfaceArea !== null) && (

                  <Chip icon={<ZoomOutMapIcon style={{ color: "#001329" }} />} label={values.surfaceArea} size="small" style={{ backgroundColor: "white" }} />

                )}

              </Stack>

            </CardContent>
          </Card>
        </Grid>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button onClick={handleReset}>
            <Trans i18nKey="description.addAnother">
              Add Another Listing
            </Trans>
          </Button>
          <Button variant="contained" component={RouterLink} to="/">
            <Trans i18nKey="description.finish">
              Finish
            </Trans>
          </Button>
        </Box>
      </Container>
    </React.Fragment>
  )
}
export default Finish
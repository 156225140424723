import React from 'react';
import Button from '@material-ui/core/Button';

const ButtonContained = ({name,color,left,width,radius,handleClickOpen}) => {
    return(
        <Button
            variant="contained"
            color={color}
            onClick={handleClickOpen}
            style={{marginLeft:`${left}`,width:`${width}`,borderRadius:`${radius}`}}
        >
            {name}
        </Button>
    )
}
export default ButtonContained;
import React, { useState, useEffect } from 'react';
import PostCard from "../../components/postCards/postCard";
import Grid from "@material-ui/core/Grid";
import UseStyles from "./styles";
import { useTheme } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Container from '@material-ui/core/Container'
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import { useSelector, useDispatch } from "react-redux";
import { getBuyPosts } from '../../actions/posts';
import Posts from '../../components/postCards/Posts';
import { Stack } from "@material-ui/core";
import { useNavigate, useLocation, Link as RouterLink, } from 'react-router-dom';
import { getPostsBySearchb } from '../../actions/posts';
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from '@material-ui/core/Autocomplete';
import { MHidden } from '../../components/@material-extend';
import Map from '../../components/MapSearchBar/Map'


import Slider from '@material-ui/core/Slider';
import Pagination from '../../components/Pagination/PaginationBuy';

import { Trans, useTranslation } from 'react-i18next';
import Page from '../../components/Page';
import { alpha, styled } from '@material-ui/core/styles';
import Cookies from 'js-cookie';

const MUITextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const MyTextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    border: 'none',
    overflow: 'hidden',
    backgroundColor: "white",
    transition: theme.transitions.create([
      'background-color',
    ]),
    '&:hover': {
      backgroundColor: 'white',
    },
    '&.Mui-focused': {
      backgroundColor: 'white',
    },
  },
}));



const RootStyle = styled(Page)(({ theme }) => ({
  paddingBottom: theme.spacing(10),
}));


const PropertyTypes = [
  {
    value: '',
    label: '',
  },
  {
    value: 'Apartment',
    label: 'Apartment',
  },
  {
    value: 'Garden',
    label: 'Garden',
  },
  {
    value: 'Garage',
    label: 'Garage',
  },
  {
    value: 'Office',
    label: 'Office',
  },
  {
    value: 'Villa',
    label: 'Villa',
  },
  {
    value: 'Land',
    label: 'Land',
  },

];
const constructionConditions = [
  {
    value: '',
    label: '',
  },
  {
    value: 'Old',
    label: 'Old',
  },
  {
    value: 'New',
    label: 'New',
  },
  {
    value: 'Needs-Renovation',
    label: 'Needs Renovation',
  },
];

const ListingTypes = [

  {
    value: '',
    label: '',
  },
  {
    value: 'buy',
    label: 'Buy',
  },
  {
    value: 'rent',
    label: 'Rent',
  },

];


const heatingTypes = [
  {
    value: '',
    label: '',
  },
  {
    value: 'Furnaces',
    label: 'Furnaces',
  },
  {
    value: 'Boilers',
    label: 'Boilers',
  },
  {
    value: 'Heat-Pumps',
    label: 'Heat Pumps',
  },
  {
    value: 'Gas-Fired-Space-Heaters',
    label: 'Gas-Fired Space Heaters',
  },
  {
    value: 'Wood-Burning-and-Pellet-Stoves',
    label: 'Wood-Burning and Pellet Stoves',
  },
  {
    value: 'Fireplaces',
    label: 'Fireplaces',
  },
  {
    value: 'Radiant-Floor-Heat',
    label: 'Radiant Floor Feat',
  },
];

const coolingTypes = [
  {
    value: '',
    label: '',
  },
  {
    value: 'Central-Air',
    label: 'Central Air',
  },
  {
    value: 'Room-Air-Conitioners',
    label: 'Room Air Conditioners',
  },
  {
    value: 'Evaporative-Coolers',
    label: 'Evaporative Coolers',
  },
  {
    value: 'State-of-the-Art-Cooling',
    label: 'State of the Art Cooling',
  },
];


function valuetext(value) {
  return `$${value}`;
}

const minDistance = 10;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const BuyPage = () => {


  const handleReset = () => {
    setFiltered(false)
    setValueMap('')
    setPropertyType('')
    setCoolingType('')
    setHeatingType('')
    setArea({ minArea: 0, maxArea: 5000 })
    setPrice({ minPrice: 0, maxPrice: 50000000 })
    setNumberOfBathrooms('')
    setNumberOfBedrooms('')
    setListingType('')
    setConstructionCondition('')
    setFeatures({
      garden: false, parkingSpace: false, dwelling: false,
      brickRoof: false,
      outBuildings: false,
      caretakerConcierge: false,
      swimmingPool: false,
      terrace: false,
      elevator: false,
      facingStreet: false,
      inTheWood: false,
      flatLand: false,
      furnished: false,
      internetAccess: false,
      videoPhone: false,
      specialNeedsAccessibility: false,
      secureAccess: false,
      showRoom: false,
      receptionDesk: false,
      pets: false,
      smoking: false,
      bigParties: false,
      loudMusic: false
    })
    navigate('/buy');
    window.location.reload(false);
  };

  const [propertyType, setPropertyType] = useState('');
  const [coolingType, setCoolingType] = useState('');
  const [heatingType, setHeatingType] = useState('');
  const [constructionCondition, setConstructionCondition] = useState('');
  const [filtered, setFiltered] = useState(false);
  const [valueMap, setValueMap] = useState('')

  const [price, setPrice] = useState({ minPrice: 0, maxPrice: 50000000 });
  const [Area, setArea] = useState({ minArea: 0, maxArea: 5000 });
  const [listingType, setListingType] = useState('');
  const [numberOfBathrooms, setNumberOfBathrooms] = useState('');
  const [numberOfBedrooms, setNumberOfBedrooms] = useState('');

  const dialogClick = () => {
    searchPost();
    setFiltered(true);
    handleClose();
  }


  // const [garden, setGarden] = useState(false);
  const [features, setFeatures] = useState({
    garden: false,
    parkingSpace: false,
    dwelling: false,
    brickRoof: false,
    outBuildings: false,
    caretakerConcierge: false,
    swimmingPool: false,
    terrace: false,
    elevator: false,
    facingStreet: false,
    inTheWood: false,
    flatLand: false,
    furnished: false,
    internetAccess: false,
    videoPhone: false,
    specialNeedsAccessibility: false,
    secureAccess: false,
    showRoom: false,
    receptionDesk: false,
    pets: false,
    smoking: false,
    bigParties: false,
    loudMusic: false
  })


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value2, setValue2] = React.useState([0, 5000000]);
  const [value3, setValue3] = React.useState([0, 5000]);
  const minPrice = value2[0];
  const maxPrice = value2[1];

  const minArea = value3[0];
  const maxArea = value3[1];

  const searchPost = () => {
    const garden = features.garden;
    const parkingSpace = features.parkingSpace;
    const dwelling = features.dwelling
    const brickRoof = features.brickRoof
    const outBuildings = features.outBuildings
    const caretakerConcierge = features.caretakerConcierge
    const swimmingPool = features.swimmingPool
    const terrace = features.terrace
    const elevator = features.elevator
    const facingStreet = features.facingStreet
    const inTheWood = features.inTheWood
    const flatLand = features.flatLand
    const furnished = features.furnished
    const internetAccess = features.internetAccess
    const videoPhone = features.videoPhone
    const specialNeedsAccessibility = features.specialNeedsAccessibility
    const secureAccess = features.secureAccess
    const showRoom = features.showRoom
    const receptionDesk = features.receptionDesk
    const pets = features.pets
    const smoking = features.smoking
    const bigParties = features.bigParties
    const loudMusic = features.loudMusic
    if (valueMap.trim() || propertyType.trim() || listingType.trim() || coolingType.trim() || heatingType.trim() || constructionCondition.trim() || valueMap ||
      propertyType ||
      listingType ||
      coolingType ||
      heatingType ||
      constructionCondition ||
      garden ||
      minPrice ||
      maxPrice ||
      maxArea ||
      minArea ||
      numberOfBedrooms ||
      numberOfBathrooms ||
      parkingSpace ||
      dwelling ||
      brickRoof ||
      outBuildings ||
      caretakerConcierge ||
      swimmingPool ||
      terrace ||
      elevator ||
      facingStreet ||
      inTheWood ||
      flatLand ||
      furnished ||
      internetAccess ||
      videoPhone ||
      specialNeedsAccessibility ||
      secureAccess ||
      showRoom ||
      receptionDesk ||
      pets ||
      smoking ||
      bigParties ||
      loudMusic ||
      numberOfBathrooms ||
      numberOfBedrooms ||
      minPrice ||
      maxPrice
    ) {
      dispatch(getPostsBySearchb({
        valueMap,
        propertyType,
        listingType,
        heatingType,
        coolingType,
        constructionCondition,
        garden,
        minPrice,
        maxPrice,
        maxArea,
        minArea,
        numberOfBedrooms,
        numberOfBathrooms,
        parkingSpace,
        dwelling,
        brickRoof,
        outBuildings,
        caretakerConcierge,
        swimmingPool,
        terrace,
        elevator,
        facingStreet,
        inTheWood,
        flatLand,
        furnished,
        internetAccess,
        videoPhone,
        specialNeedsAccessibility,
        secureAccess,
        showRoom,
        receptionDesk,
        pets,
        smoking,
        bigParties,
        loudMusic,
      }));
      // navigate(`/posts/search?searchQuery=${search}&propertyTypeQuery=${propertyType}&listingTypeQuery=${listingType}&minPriceQuery=${minPrice}&maxPriceQuery=${maxPrice}`);
      navigate(`/posts/searchb?searchQuery=${valueMap}&propertyTypeQuery=${propertyType}&coolingTypeQuery=${coolingType}&heatingTypeQuery=${heatingType}&constructionConditionQuery=${constructionCondition}&listingTypeQuery=${listingType}&parkingSpaceQuery=${parkingSpace}&gardenQuery=${garden}&dwellingQuery=${dwelling}&brickRoofQuery=${brickRoof}&outBuildingsQuery=${outBuildings}&caretakerConciergeQuery=${caretakerConcierge}&swimmingPoolQuery=${swimmingPool}&terraceQuery=${terrace}&elevatorQuery=${elevator}&facingStreetQuery=${facingStreet}&inTheWoodQuery=${inTheWood}&flatLandQuery=${flatLand}&furnishedQuery=${furnished}&internetAccessQuery=${internetAccess}&videoPhoneQuery=${videoPhone}&specialNeedsAccessibilityQuery=${specialNeedsAccessibility}&secureAccessQuery=${secureAccess}&showRoomQuery=${showRoom}&receptionDeskQuery=${receptionDesk}&petsQuery=${pets}&smokingQuery=${smoking}&bigPartiesQuery=${bigParties}&loudMusicQuery=${loudMusic}&minPriceQuery=${minPrice}&maxPriceQuery=${maxPrice}&numberOfBedroomsQuery=${numberOfBedrooms}&numberOfBathroomsQuery=${numberOfBathrooms}&minAreaQuery=${minArea}&maxAreaQuery=${maxArea}`);
    } else {
      navigate('/');
    }
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      searchPost();
    }
  };

  ///////////////////////////////////////////////////////

  const classes = UseStyles();
  const theme = useTheme();
  const query = useQuery();
  const page = query.get('page') || 1;
  const [currentId, setCurrentId] = useState(0);

  const user = JSON.parse(localStorage.getItem('profile'));

  const [arLang, setArLang] = useState(false);
  let cookieLanguage = Cookies.get('i18next');


  useEffect(() => {
    dispatch(getBuyPosts());

    if (cookieLanguage == "ar") {
      setArLang(true);
    }
  }, [currentId, dispatch]);
  // const getBuyPosts = useSelector(state => state.getBuyPosts);
  // const { posts } = getBuyPosts;

  //////////////////////////////////////////////////


  const { t, i18n } = useTranslation();

  ////////////////////////////////////////////////////////////////////////   
  const [openDiag, setOpenDiag] = React.useState(false);

  const handleClickOpen = () => {
    setOpenDiag(true);
  };

  const handleClose = () => {
    setOpenDiag(false);
  };


  /////////////////////////////////////////////////////////////////////////
  const handleChange2 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setValue2([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setValue2([clamped - minDistance, clamped]);
      }
    } else {
      setValue2(newValue);
    }

    setPrice({ ...price, minPrice: value2[0], maxPrice: value2[1] })
  };



  const handleChangeArea = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setValue3([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setValue3([clamped - minDistance, clamped]);
      }
    } else {
      setValue3(newValue);
    }

    setArea({ ...Area, minArea: value3[0], maxArea: value3[1] })
  };

  ////////////////////////////////////////////////////////////////////////


  return (


    <>
      <RootStyle title="Properties on Sale | eAkary">


        <Container>
          <MHidden width="mdDown">
            <hr className={classes.solid} style={{ marginBottom: "20px" }} />
            <Stack direction="row" justifyContent="center" alignItems="flex-end" spacing={2}>

              <Paper variant="outlined"
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 300 }}
              >
                <Map setValueMap={setValueMap} />

                <SearchIcon sx={{ ml: 1 }} size="small" />

              </Paper>
              {/* {
                arLang ? (
                  <MUITextField
                    fullWidth
                    size="small"
                    id="outlined-select-listing-type"
                    // className={classes.row}
                    style={{ width: "150px", marginRight: "15px" }}
                    select
                    label={t("description.listingType")}
                    onKeyDown={handleKeyPress}
                    renderValue={(value) => `${listingType}`} onChange={(e) => setListingType(e.target.value)}
                    SelectProps={{
                      native: true,
                    }}
                    // helperText="Please select your currency"
                    variant="filled"
                  >
                    {ListingTypes.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </MUITextField>
                ) : (
                  <MUITextField
                    fullWidth
                    size="small"
                    id="outlined-select-listing-type"
                    // className={classes.row}
                    style={{ width: "150px", marginRight: "15px" }}
                    select
                    label={t("description.listingType")}
                    onKeyDown={handleKeyPress}
                    renderValue={(value) => `${listingType}`} onChange={(e) => setListingType(e.target.value)}
                    SelectProps={{
                      native: true,
                    }}
                    // helperText="Please select your currency"
                    variant="filled"
                  >
                    {ListingTypes.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </MUITextField>
                )
              } */}




              <MUITextField
                fullWidth
                size="small"
                id="outlined-select-property-type"
                // className={classes.row}
                style={{ width: "200px" }}
                select
                label={t("description.propType")}
                onKeyDown={handleKeyPress}
                renderValue={(value) => `${propertyType}`} onChange={(e) => setPropertyType(e.target.value)}
                SelectProps={{
                  native: true,
                }}
                // helperText="Please select your currency"
                variant="filled"
              >
                {PropertyTypes.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </MUITextField>

              <MUITextField
                size="small"
                id="outlined-select-contruction-condition"
                // className={classes.row}
                style={{ width: "200px" }}
                select
                label={t("description.constCond")}
                onKeyDown={handleKeyPress}
                renderValue={(value) => `${constructionCondition}`} onChange={(e) => setConstructionCondition(e.target.value)}
                SelectProps={{
                  native: true,
                }}
                // helperText="Please select your currency"
                variant="filled"
              >
                {constructionConditions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </MUITextField>



              {filtered !== false ? (
                <Button variant="outlined" style={{ height: "45px", backgroundColor: "rgba(135,206,250,0.3)" }} onClick={handleClickOpen} disableElevation>More Filters</Button>
              ) : <Button variant="outlined" style={{ height: "45px" }} onClick={handleClickOpen} disableElevation>More Filters</Button>
              }

              <Button variant="contained" style={{ height: "45px" }} onClick={searchPost} disableElevation>{t("description.search")}</Button>


            </Stack>
            <hr className={classes.solid} style={{ marginBottom: "20px", marginTop: "20px" }} />
          </MHidden>

          <MHidden width="mdUp">
            <hr className={classes.solid} style={{ marginBottom: "20px", marginTop: "-5px" }} />
            <Stack direction="row" justifyContent="center" alignItems="flex-end" spacing={2}>
              <Paper variant="outlined"
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 250 }}
              >
                <Map setValueMap={setValueMap} />

                <IconButton onClick={searchPost} sx={{ p: '8px' }} aria-label="search">
                  <SearchIcon size="small" />
                </IconButton>

              </Paper>
              <Button variant="outlined" style={{ height: "45px" }} onClick={handleClickOpen} disableElevation>More Filters</Button>
            </Stack>
            <hr className={classes.solid} style={{ marginBottom: "20px", marginTop: "20px" }} />
          </MHidden>

          <Dialog open={openDiag} onClose={handleClose}>
            <DialogTitle>More Filters</DialogTitle>
            <DialogContent>

              <Grid container justifyContent="center" alignItems="center">
                <MHidden width="mdUp">
                  <Grid container>
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <MUITextField
                        fullWidth
                        size="small"
                        id="outlined-select-listing-type"
                        // className={classes.row}
                        style={{ width: "150px", marginRight: "15px" }}
                        select
                        label={t("description.listingType")}
                        onKeyDown={handleKeyPress}
                        renderValue={(value) => `${listingType}`} onChange={(e) => setListingType(e.target.value)}
                        SelectProps={{
                          native: true,
                        }}
                        // helperText="Please select your currency"
                        variant="filled"
                      >
                        {ListingTypes.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </MUITextField>


                    </Grid>
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <MUITextField
                        fullWidth
                        size="small"
                        id="outlined-select-property-type"
                        // className={classes.row}
                        style={{ width: "200px" }}
                        select
                        label={t("description.propType")}
                        onKeyDown={handleKeyPress}
                        renderValue={(value) => `${propertyType}`} onChange={(e) => setPropertyType(e.target.value)}
                        SelectProps={{
                          native: true,
                        }}
                        // helperText="Please select your currency"
                        variant="filled"
                      >
                        {PropertyTypes.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </MUITextField>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <MUITextField
                        size="small"
                        id="outlined-select-contruction-condition"
                        // className={classes.row}
                        style={{ width: "200px" }}
                        select
                        label="Contruction Condition"
                        onKeyDown={handleKeyPress}
                        renderValue={(value) => `${constructionCondition}`} onChange={(e) => setConstructionCondition(e.target.value)}
                        SelectProps={{
                          native: true,
                        }}
                        // helperText="Please select your currency"
                        variant="filled"
                      >
                        {constructionConditions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </MUITextField>
                    </Grid>
                  </Grid>
                </MHidden>
                <Grid container>
                  <Grid item xs={12} >
                    <Typography component="h4" variant="h4" align="left" sx={{ mb: 2 }}>
                      {t("description.price")}
                    </Typography>
                    <Typography component="h6" variant="h6" align="left" sx={{ mb: 2, mt: 2 }}>
                      ${value2[0]} - ${value2[1]}
                    </Typography>
                    <Slider
                      getAriaLabel={() => 'Minimum distance shift'}
                      value={value2}
                      min={0}
                      max={1000000}
                      step={1000}
                      onChange={handleChange2}
                      valueLabelDisplay="auto"
                      getAriaValueText={valuetext}
                      disableSwap
                    />
                    <Divider light style={{ color: "#091A7A" }} sx={{ mb: 3, mt: 3 }} />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12} >
                    <Typography component="h4" variant="h4" c align="left" sx={{ mb: 2 }}>
                      {t("description.area")}
                    </Typography>
                    <Typography component="h6" variant="h6" align="left" sx={{ mb: 2, mt: 2 }}>
                      {value3[0]}sqft - {value3[1]}sqft
                    </Typography>
                    <Slider
                      getAriaLabel={() => 'Minimum distance shift'}
                      value={value3}
                      min={50}
                      max={5000}
                      step={50}
                      onChange={handleChangeArea}
                      valueLabelDisplay="auto"
                      getAriaValueText={valuetext}
                      disableSwap

                    />
                    <Divider light style={{ color: "#091A7A" }} sx={{ mb: 3, mt: 3 }} />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12} >
                    {propertyType === 'Apartment' ||
                      propertyType === 'Villa' ||
                      propertyType === 'Office' ? (
                      <Grid container direction="column" item xs={12}>
                        <Typography justify="flex-start" className={classes.dislpayflex} variant="h4"
                          component="h2" gutterBottom>
                          <Trans i18nKey="description.interior">
                            Interior
                          </Trans>
                        </Typography>

                        {propertyType !== 'Office' ? (
                          <MUITextField
                            sx={{ mb: 2 }}
                            onKeyDown={handleKeyPress}
                            renderValue={(value) => `${numberOfBedrooms}`} onChange={(e) => setNumberOfBedrooms(e.target.value)}
                            id="numberofBedrooms"
                            className={classes.row}
                            label={t("description.nbrOfBedrooms")}
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="filled"
                          />) : null
                        }
                        <MUITextField
                          renderValue={(value) => `${numberOfBathrooms}`} onChange={(e) => setNumberOfBathrooms(e.target.value)}

                          id="numberofBathrooms"
                          className={classes.row}
                          label={t("description.nbrOfBathrooms")}
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="filled"
                        />

                      </Grid>
                    ) : null
                    }
                    {propertyType === 'Apartment' ||
                      propertyType === 'Villa' ||
                      propertyType === 'Office' ? (
                      <Grid container direction="column" item xs={12} sx={{ mt: 5, mb: 5 }}>
                        <Typography className={classes.dislpayflex} variant="h4" component="h2" gutterBottom>
                          <Trans i18nKey="description.exterior">
                            Exterior
                          </Trans>
                        </Typography>
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" column>
                            <FormControlLabel

                              control={<Checkbox
                                checked={features.parkingSpace}
                                onChange={() => setFeatures({ ...features, parkingSpace: !features.parkingSpace })}
                                color="primary" />}
                              label={t("description.parkingSpace")}
                              name="parkingSpace"
                              labelPlacement="end"
                            />
                            {propertyType !== 'Office' ? (
                              <FormControlLabel

                                control={<Checkbox
                                  checked={features.dwelling}
                                  onChange={() => setFeatures({ ...features, dwelling: !features.dwelling })} color="primary" />}
                                label={t("description.dwelling")}
                                name="dwelling"
                                labelPlacement="end"
                              />) : null
                            }
                            <FormControlLabel

                              control={<Checkbox
                                checked={features.brickRoof}
                                onChange={() => setFeatures({ ...features, brickRoof: !features.brickRoof })} color="primary" />}
                              label={t("description.brickRoof")}
                              name="brickRoof"
                              labelPlacement="end"
                            />
                            <FormControlLabel


                              control={<Checkbox
                                checked={features.outBuildings}
                                onChange={() => setFeatures({ ...features, outBuildings: !features.outBuildings })} color="primary" />}
                              label={t("description.Outbuildings")}
                              name="outBuildings"
                              labelPlacement="end"
                            />
                            <FormControlLabel

                              control={<Checkbox
                                checked={features.caretakerConcierge}
                                onChange={() => setFeatures({ ...features, caretakerConcierge: !features.caretakerConcierge })} color="primary" />}
                              label={t("description.caretaker")}
                              name="caretakerConcierge"
                              labelPlacement="end"
                            />
                            <FormControlLabel

                              control={<Checkbox
                                checked={features.garden}
                                onChange={() => setFeatures({ ...features, garden: !features.garden })} color="primary" />}
                              label={t("description.garden")}
                              name="garden"
                              labelPlacement="end"
                            />
                            {propertyType !== 'Office' ? (
                              <FormControlLabel
                                control={<Checkbox
                                  checked={features.swimmingPool}
                                  onChange={() => setFeatures({ ...features, swimmingPool: !features.swimmingPool })} color="primary" />}
                                label={t("description.pool")}
                                name="swimmingPool"
                                labelPlacement="end"
                              />) : null
                            }
                            <FormControlLabel
                              control={<Checkbox
                                checked={features.terrace}
                                onChange={() => setFeatures({ ...features, terrace: !features.terrace })} color="primary" />}
                              label={t("description.terrace")}
                              name="terrace"
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>) : propertyType === 'Land' ? (
                        <Grid container direction="column" item xs={12} sx={{ mt: 5, mb: 5 }}>
                          <Typography className={classes.dislpayflex} variant="h4" component="h2" gutterBottom>
                            <Trans i18nKey="description.land">
                              Land
                            </Trans>
                          </Typography>
                          <FormControl component="fieldset">
                            <FormGroup aria-label="position" column>
                              <FormControlLabel

                                control={<Checkbox
                                  checked={features.facingStreet}
                                  onChange={() => setFeatures({ ...features, facingStreet: !features.facingStreet })}
                                  color="primary" />}
                                label={t("description.facingStreet")}
                                name="facingStreet"
                                labelPlacement="end"
                              />
                              <FormControlLabel

                                control={<Checkbox
                                  checked={features.inTheWood}
                                  onChange={() => setFeatures({ ...features, inTheWood: !features.inTheWood })} color="primary" />}
                                label={t("description.intheWoods")}
                                name="inTheWood"
                                labelPlacement="end"
                              />
                              <FormControlLabel

                                control={<Checkbox
                                  checked={features.flatLand}
                                  onChange={() => setFeatures({ ...features, flatLand: !features.flatLand })} color="primary" />}
                                label={t("description.flatLand")}
                                name="flatLand"
                                labelPlacement="end"
                              />
                            </FormGroup>
                          </FormControl>
                        </Grid>
                      ) : null
                    }
                  </Grid>
                </Grid>
                <Divider light style={{ color: "#091A7A" }} color="secondary" sx={{ mb: 3, mt: 3 }} />
                <Grid item xs={12}>
                  {propertyType !== 'Land' ? (
                    <Grid container direction="column" item xs={12}>
                      <Typography className={classes.dislpayflex} variant="h4" component="h2" gutterBottom>
                        <Trans i18nKey="description.additionalFeatures">
                          Additional Features
                        </Trans>
                      </Typography>
                      {propertyType === 'Apartment' ||
                        propertyType === 'Villa' ||
                        propertyType === 'Office' ? (
                        <>
                          <MUITextField
                            sx={{ mb: 2 }}
                            id="heatingType"
                            className={classes.row}
                            select
                            label={t("description.heating")}

                            onKeyDown={handleKeyPress}
                            renderValue={(value) => `${heatingType}`} onChange={(e) => setHeatingType(e.target.value)}
                            SelectProps={{
                              native: true,
                            }}
                            helperText={t("description.heatingH")}
                            variant="filled"
                          >
                            {heatingTypes.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </MUITextField>
                          <MUITextField
                            id="coolingType"
                            className={classes.row}
                            select
                            label={t("description.cooling")}
                            onKeyDown={handleKeyPress}
                            renderValue={(value) => `${coolingType}`} onChange={(e) => setCoolingType(e.target.value)}

                            SelectProps={{
                              native: true,
                            }}
                            helperText={t("description.coolingH")}
                            variant="filled"
                          >
                            {coolingTypes.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </MUITextField>
                        </>
                      ) : null
                      }
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position" column>
                          {propertyType !== 'Garden' ? (
                            <FormControlLabel

                              control={<Checkbox
                                checked={features.elevator}
                                onChange={() => setFeatures({ ...features, elevator: !features.elevator })} color="primary" />}
                              label={t("description.elevator")}
                              name="elevator"
                              labelPlacement="end"
                            />
                          ) : null
                          }
                          {propertyType !== 'Garage' ? (
                            <>
                              <FormControlLabel

                                control={<Checkbox
                                  checked={features.furnished}
                                  onChange={() => setFeatures({ ...features, furnished: !features.furnished })} color="primary" />}
                                label={t("description.furnished")}
                                name="furnished"
                                labelPlacement="end"
                              />
                              <FormControlLabel

                                control={<Checkbox
                                  checked={features.internetAccess}
                                  onChange={() => setFeatures({ ...features, internetAccess: !features.internetAccess })} color="primary" />}
                                label={t("description.internet")}
                                name="internetAccess"
                                labelPlacement="end"
                              />
                            </>
                          ) : null
                          }
                          {propertyType !== 'Garage' || propertyType !== 'Garden' ? (
                            <FormControlLabel

                              control={<Checkbox
                                checked={features.videoPhone}
                                onChange={() => setFeatures({ ...features, videoPhone: !features.videoPhone })} color="primary" />}
                              label={t("description.videoP")}
                              name="videoPhone"
                              labelPlacement="end"
                            />
                          ) : null
                          }
                          <FormControlLabel

                            control={<Checkbox
                              checked={features.specialNeedsAccessibility}
                              onChange={() => setFeatures({ ...features, specialNeedsAccessibility: !features.specialNeedsAccessibility })} color="primary" />}
                            label={t("description.sna")}
                            name="specialNeedsAccessibility"
                            labelPlacement="end"
                          />
                          {propertyType === 'Office' ? (
                            <>
                              <FormControlLabel

                                control={<Checkbox
                                  checked={features.secureAccess}
                                  onChange={() => setFeatures({ ...features, secureAccess: !features.secureAccess })} color="primary" />}
                                label={t("description.secureAccess")}
                                name="secureAccess"
                                labelPlacement="end"
                              />
                              <FormControlLabel

                                control={<Checkbox
                                  c checked={features.showRoom}
                                  onChange={() => setFeatures({ ...features, showRoom: !features.showRoom })} color="primary" />}
                                label={t("description.showRoom")}
                                name="showRoom"
                                labelPlacement="end"
                              />
                              <FormControlLabel

                                control={<Checkbox
                                  checked={features.receptionDesk}
                                  onChange={() => setFeatures({ ...features, receptionDesk: !features.receptionDesk })} color="primary" />}
                                label={t("description.receptionDesk")}
                                name="receptionDesk"
                                labelPlacement="end"
                              />
                            </>
                          ) : null
                          }
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  ) : null
                  }
                  {/* </Grid>
                <Grid item xs={12} lg={6}> */}
                  <Grid container direction="column" item xs={12} lg={6}>
                    <Typography className={classes.dislpayflex} sx={{ mt: 5 }} variant="h4" component="h2" gutterBottom>
                      <Trans i18nKey="description.permits">
                        Permits
                      </Trans>
                    </Typography>
                    <FormControl component="fieldset">
                      <FormGroup aria-label="position" column>
                        <FormControlLabel

                          control={<Checkbox
                            checked={features.pets}
                            onChange={() => setFeatures({ ...features, pets: !features.pets })} color="primary" />}
                          label={t("description.pets")}
                          name="pets"
                          labelPlacement="end"
                        />
                        <FormControlLabel

                          control={<Checkbox
                            checked={features.smoking}
                            onChange={() => setFeatures({ ...features, smoking: !features.smoking })} color="primary" />}
                          label={t("description.smoking")}
                          name="smoking"
                          labelPlacement="end"
                        />
                        <FormControlLabel

                          control={<Checkbox color="primary" />}
                          checked={features.bigParties}
                          onChange={() => setFeatures({ ...features, bigParties: !features.bigParties })}
                          label={t("description.bigP")}
                          name="bigParties"
                          labelPlacement="end"
                        />
                        <FormControlLabel

                          control={<Checkbox
                            checked={features.loudMusic}
                            onChange={() => setFeatures({ ...features, loudMusic: !features.loudMusic })} color="primary" />}
                          label={t("description.loudM")}
                          name="loudMusic"
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                </Grid >
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleReset}>{t("description.reset")}</Button>
              <Button variant="contained" onClick={dialogClick}>{t("description.search")}</Button>

            </DialogActions>
          </Dialog>

        </Container>



        <Container>
          <Grid container >
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Button component={RouterLink} to="/buy/map" variant="outlined" color="secondary" startIcon={<ExploreOutlinedIcon sx={{ marginLeft: "5px" }} />}> {t("description.mapView")} </Button>
            </Grid>
          </Grid>
          <Grid container spacing={3} >
            <Grid item xs={12}>
              <Posts setCurrentId={setCurrentId} />
            </Grid>
          </Grid>
          {(!valueMap && !propertyType && !listingType && !constructionCondition && !coolingType &&
            !heatingType &&
            features.garden !== true &&
            !numberOfBedrooms &&
            !numberOfBathrooms &&
            features.parkingSpace !== true &&
            features.dwelling !== true &&
            features.brickRoof !== true &&
            features.outBuildings !== true &&
            features.caretakerConcierge !== true &&
            features.swimmingPool !== true &&
            features.terrace !== true &&
            features.elevator !== true &&
            features.facingStreet !== true &&
            features.inTheWood !== true &&
            features.flatLand !== true &&
            features.furnished !== true &&
            features.internetAccess !== true &&
            features.videoPhone !== true &&
            features.specialNeedsAccessibility !== true &&
            features.secureAccess !== true &&
            features.showRoom !== true &&
            features.receptionDesk !== true &&
            features.pets !== true &&
            features.smoking !== true &&
            features.bigParties !== true &&
            features.loudMusic !== true
          ) && (
              <Pagination page={page} />
            )}

        </Container>
      </RootStyle>
    </>
  )


}


export default BuyPage;
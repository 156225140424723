import { Grid, Typography, Container } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import OwnedPosts from '../../components/OwnedPosts/ownedPosts';
import { getMyPosts } from '../../actions/posts';
import Pagination from '../../components/Pagination/PaginationMyPosts';
import { useDispatch } from "react-redux";
import { useParams, } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { experimentalStyled as styled } from '@material-ui/core/styles';

import Page from '../../components/Page';
const RootStyle = styled(Page)(({ theme }) => ({
  paddingBottom: theme.spacing(10)
  }));

const MyPosts = () => {
  const { i18n } = useTranslation();
  const { id } = useParams();
  const [currentId, setCurrentId] = useState(0);
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getMyPosts(id));
  }, [currentId, dispatch, id]);

  return (
    <>
<RootStyle title="My Listings | eAkary">
    <Container>
      <Typography component="h3" variant="h3" align="center" sx={{ mb: 10 }}>
        <Trans i18nKey="description.myprop">
          My Properties
        </Trans>
      </Typography>
    
      <Grid container spacing={3} >
        <Grid item xs={12}>
        
          <OwnedPosts setCurrentId={setCurrentId} />
          
        </Grid>
      </Grid>
  
    </Container>
</RootStyle>
</>
  )
}

export default MyPosts;

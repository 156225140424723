import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Stack from '@material-ui/core/Stack';
import { MHidden } from '../@material-extend';
import Container from '@material-ui/core/Container';
import LogoWhite from '../LogoWhite'
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import './style.css'
const Footer = () => {
    const { t, i18n } = useTranslation();
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    return (
        <Box mt={{ xs: 2, sm: 2 }} px={{ xs: 2, sm: 2 }} py={{ xs: 2, sm: 2 }} style={{ marginTop: "auto" }} bgcolor="text.primary" color="white">
            <Container>
                <MHidden width="smDown">
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} sm={12} md={2} >
                            <LogoWhite />
                        </Grid>
                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={3} sx={{ flexWrap: 'wrap' }}>
                            <Typography className='bodyHover' variant="body1">
                                <Link onClick={e => scrollToTop()} className='Link' to="/aboutUs">
                                    eAkary
                                </Link>
                            </Typography>
                            <Typography className='bodyHover' variant="body1">
                                <Link onClick={e => scrollToTop()} className='Link' to="/privacypolicy">
                                    {t("description.privacyPol")}
                                </Link>
                            </Typography>
                            <Typography className='bodyHover' variant="body1">
                                <Link onClick={e => scrollToTop()} className='Link' to="/termsofservice">
                                    {t("description.tos")}
                                </Link>
                            </Typography>
                            <Typography variant="body1">
                                <Link onClick={e => scrollToTop()} className='Link' to="/faq" >
                                    {t("description.faq")}
                                </Link>
                            </Typography>
                            <Typography variant="body1">
                                <Link onClick={e => scrollToTop()} className='Link' to="/contact">
                                    {t("description.contactUs")}
                                </Link>
                            </Typography>
                            <Box >
                                <Box
                                    component="img"
                                    src="/static/illustrations/googlecomingsoon.png"
                                    sx={{ height: 40, mx: 'auto', my: { xs: 2, sm: 2 }, marginRight: '10px' }}
                                    xs={{ height: 60 }}
                                />
                                <Box
                                    component="img"
                                    src="/static/illustrations/appstorecommingsoon.png"
                                    sx={{ height: 40, mx: 'auto', my: { xs: 2, sm: 2 } }}
                                    xs={{ height: 60 }}
                                />
                            </Box>
                        </Stack>
                    </Grid>
                </MHidden>
                <MHidden width="smUp">
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} sm={12} md={2} >
                            <LogoWhite />
                        </Grid>
                        <Stack direction="column" justifyContent="center" alignItems="center" spacing={3}>
                            <Typography className='bodyHover' variant="body1">
                                <Link onClick={e => scrollToTop()} className='Link' to="/aboutUs">
                                    eAkary
                                </Link>
                            </Typography>
                            <Typography className='bodyHover' variant="body1">
                                <Link onClick={e => scrollToTop()} className='Link' to="/privacypolicy">
                                    {t("description.privacyPol")}
                                </Link>
                            </Typography>
                            <Typography className='bodyHover' variant="body1">
                                <Link onClick={e => scrollToTop()} className='Link' to="/termsofservice">
                                    {t("description.tos")}
                                </Link>
                            </Typography>
                            <Typography variant="body1">
                                <Link onClick={e => scrollToTop()} className='Link' to="/faq" >
                                    {t("description.faq")}
                                </Link>
                            </Typography>
                            <Typography variant="body1">
                                <Link onClick={e => scrollToTop()} className='Link' to="/contact">
                                    {t("description.contactUs")}
                                </Link>
                            </Typography>
                        </Stack>
                        <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ mb: 2, mt: 2 }}>
                            <Grid item >
                                <Box
                                    component="img"
                                    src="/static/illustrations/googlecomingsoon.png"
                                    sx={{ height: 60, mx: 'auto', my: { xs: 2, sm: 2 } }}
                                    xs={{ height: 60 }}
                                />
                            </Grid>
                            <Grid item >
                                <Box
                                    component="img"
                                    src="/static/illustrations/appstorecommingsoon.png"
                                    sx={{ height: 60, mx: 'auto', my: { xs: 2, sm: 2 } }}
                                    xs={{ height: 60 }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </MHidden>
                <Box textAlign="center" style={{ fontSize: "15px" }} mt={{ xs: 2, sm: 2, md: 0 }}> eAkary &reg; {new Date().getFullYear()}</Box>
            </Container>
        </Box>
    );
};
export default Footer
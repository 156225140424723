import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Link, Button, Drawer, Typography, Grid, Avatar, Stack } from '@material-ui/core';
// components
import Logo from '../../components/Logo';
import Scrollbar from './Scrollbar';
import NavSection from './NavSection';
import { MHidden } from '../../components/@material-extend';
//
import decode from 'jwt-decode';
import * as actionType from '../../constants/actionTypes';
import { Trans, useTranslation } from 'react-i18next';
import sidebarConfig from './SidebarConfig';
// ----------------------------------------------------------------------
const DRAWER_WIDTH = 280;
const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200]
}));
// ----------------------------------------------------------------------
DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};
export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const renderContent = (
    <>
      {user?.result?.email ? (
        <>
          <Scrollbar
            sx={{
              height: '100%',
              '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
            }}
          >
            <Box sx={{ px: 2.5, py: 3 }} style={{ textAlign: 'center' }}>
              <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
                <p style={{ fontWeight: 'bold', fontSize: 20 }}>Menu</p>
              </Box>
            </Box>
            <NavSection navConfig={sidebarConfig} />
            <Box sx={{ flexGrow: 1 }} />
          </Scrollbar>
        </>
      ) : (
        <>
          <Scrollbar
            sx={{
              height: '100%',
              '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
            }}
          >
            <Box sx={{ px: 2.5, py: 3 }} style={{ textAlign: 'center' }}>
              <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
                <p style={{ fontWeight: 'bold', fontSize: 20 }}>Menu</p>
              </Box>
            </Box>
            <NavSection navConfig={sidebarConfig} />
            <Box sx={{ mt: 3, flexGrow: 1 }} />
            <Grid container justifyContent="center">
              <Button sx={{ width: "80%" }} component={RouterLink} to="/auth/login" variant="contained" color="primary" disableElevation>
                <Trans i18nKey="description.loginPage">
                  Login
                </Trans>
              </Button>
            </Grid>
          </Scrollbar>
        </>
      )}
    </>
  );
  return (
    <MHidden width="lgUp">
      <Drawer
        open={isOpenSidebar}
        onClose={onCloseSidebar}
        PaperProps={{
          sx: { width: DRAWER_WIDTH }
        }}
      >
        {renderContent}
      </Drawer>
    </MHidden>
  );
}

import React from 'react'
import {Container} from '@material-ui/core'
import Map from "../../components/MapRent/Map";

const RentMap = () => {
    return (
        
            <Map />
      
    )
}

export default RentMap
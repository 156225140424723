import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination, PaginationItem } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { getBuyPosts, searchPost } from '../../actions/posts';
import useStyles from './PaginationStyle';

const Paginate = ({ page, searchPost, query }) => {
  const { numberOfPages } = useSelector((state) => state.posts); //retrieve the reducer that has acces to the number of pages
  const dispatch = useDispatch();
  const classes = useStyles();

  // useEffect(() => {
  //   if (page) {
  //     dispatch(getPostsBySearchb(page));
  //   }
  // }, [dispatch, page]);
  useEffect(() => {
    if (page) { searchPost(true) }
  }, [page])
  return (
    <Pagination
      sx={{ mt: 10, width: '100%' }}
      classes={{ ul: classes.ul }}
      count={numberOfPages}
      page={Number(page) || 1}
      variant="outlined"
      color="primary"
      renderItem={(item) => (
        <PaginationItem {...item} component={Link} to={`${query}&page=${item.page}&frmPg=y`} />
        // <PaginationItem {...item} component={Link} onClick={()=>setPage(item.page)} to={`/posts?page=${item.page}`} />
      )}
    />
  );
};

export default Paginate;

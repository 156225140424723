import { makeStyles } from '@material-ui/styles';


const UseStyles = makeStyles((theme) => ({
    centertext: {
        display: 'flex',
    alignItems: 'center',
    
    },
 
    typoTitle:{

        marginTop:'15px',
        marginBottom:'15px',
    },
    typoText:{

        textAlign: 'justify',
    textJustify: 'inter-word', 
    },
    search:{
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width:'100%',
        marginTop:'10px'
      },
      input: {
        marginLeft: 10,
        flex: 1,
      },
      Accordion:{
        width:'99%',
      }
    
    



}));
export default UseStyles

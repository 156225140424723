import React, { useState, useEffect, memo } from 'react';
import { useNavigate } from 'react-router-dom';
// import UseStyles from './style';
import { Grid, Paper, Select, IconButton, Tooltip, InputBase, Hidden, MenuItem, Divider, TextField, Button } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import HouseOutlinedIcon from '@material-ui/icons/HouseOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EmptyQuerySnackBar from './EmptyQuerySnackBar';
import useMediaQuery from '@material-ui/core/useMediaQuery';

//select proerty type icons
import HouseIcon from '@material-ui/icons/House';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import ApartmentIcon from '@material-ui/icons/Apartment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import WorkIcon from '@material-ui/icons/Work';
import TagsInput from './TagsInput'
import './sloganText.css'
import { useTranslation } from 'react-i18next';

function SearchBar(props) {

    const navigate = useNavigate();
    // const classes = UseStyles();
    const [propertyType, setPropertyType] = useState('PropertyType');
    const { t, i18n } = useTranslation();
    const [searchQueryInput, setSearchQueryInput] = useState('');
    const [isEmptyQuerySnackBarOpen, setIsEmptyQuerySnackBarOpen] = useState(false);
    const [cities, setCities] = useState([])
    const handleChange = (event) => {
        setPropertyType(event.target.value);
        props.setPropertyType(event.target.value);
    }
    const handleSearchQueryInputChange = (event) => {
        setSearchQueryInput(event.target.value);
    }
    const handleSnackBarClose = () => {
        setIsEmptyQuerySnackBarOpen(false);
    }
    const handleSearchClick = () => {
        // if (searchQueryInput === '') {
        //     setIsEmptyQuerySnackBarOpen(true);
        //     return;
        // }
        const searchQueryPrefix = "/posts/searchb?searchQuery=";
        const searchQueryInput = `&cityQuery=${cities}&propertyTypeQuery=${propertyType === 'PropertyType' ? '' : propertyType}`;
        navigate(`${searchQueryPrefix}${searchQueryInput}`);
    }
    const handleSearchFormSubmit = (e) => {
        e.preventDefault();
        // handleSearchClick();
    }

    const isTabletScreen = useMediaQuery('(max-width:1070px)');
    const selectComponent =
        <Select style={{ fontWeight: '700' }} IconComponent={ExpandMoreIcon} variant="standard" disableUnderline sx={{ display: 'flex' }}
            displayEmpty value={propertyType} onChange={handleChange}>
            <MenuItem value="PropertyType"><Tooltip title="any">
                <HouseOutlinedIcon sx={{ color: 'black', fontSize: '1.5rem', marginRight: { lg: '0.2rem', xs: 0 } }} />
            </Tooltip>  <Hidden smDown>  Property Type</Hidden>
            </MenuItem>
            <MenuItem value="Land"><Tooltip title="Land"><InsertPhotoIcon sx={{ marginRight: { lg: '0.2rem', xs: 0 } }} /></Tooltip><Hidden smDown>Land</Hidden>
            </MenuItem>
            <MenuItem value="Apartment"><Tooltip title="Apartment"><ApartmentIcon sx={{ marginRight: { lg: '0.2rem', xs: 0 } }} /></Tooltip><Hidden smDown>Apartment</Hidden>
            </MenuItem>
            <MenuItem value="Office"><Tooltip title="Office"><WorkIcon sx={{ marginRight: { lg: '0.2rem', xs: 0 } }} /></Tooltip><Hidden smDown>Office</Hidden>
            </MenuItem>
            <MenuItem value="Garage"><Tooltip title="Garage"><DriveEtaIcon sx={{ marginRight: { lg: '0.2rem', xs: 0 } }} /></Tooltip><Hidden smDown>Garage</Hidden>
            </MenuItem>
            <MenuItem value="Villa"><Tooltip title="villa"><HouseIcon sx={{ marginRight: { lg: '0.2rem', xs: 0 } }} /></Tooltip><Hidden smDown>Villa</Hidden>
            </MenuItem>
        </Select>;
    return (
        <>
            <Grid item xs={10} sm={8} md={8} container
                sx={{
                    overflow: 'visible', padding: '0', zIndex: '1', backgroundColor: 'rgba(255,255,255,0.81)',
                    position: 'absolute', top: '40px', marginRight: 'auto', marginLeft: 'auto'
                }}
                justifyContent="space-between" alignItems="center">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        zIndex: 1, height: 'fit-content',
                        borderBottom: '3px solid #0487ba',
                        color: '#0487BA', fontWeight: 'bold',
                        fontSize: { md: 30, sm: 20 }
                    }}
                >
                    {t("description.slogF")}&nbsp;<strong>{t("description.ppropp")}</strong>
                </Grid>
                <Hidden mdDown>  <Grid item container xs={12} md={2}
                    sx={{
                        padding: '0 !important', marginLeft: isTabletScreen ? 'auto' : '1em', marginRight: isTabletScreen ? 'auto' : '0',
                        flexWrap: 'nowrap'
                    }} justifyContent="center" alignItems="center">
                    {selectComponent}

                </Grid>
                </Hidden>
                <Grid item container xs={12} md={6} sx={{ padding: '0 !important' }} justifyContent="center">
                    <>
                        <Paper
                            component="div"
                            onSubmit={handleSearchFormSubmit}
                            elevation={0}
                            sx={{
                                width: '100%', display: 'flex', flexShrink: '1', background: 'none !important', alignItems: 'center',
                                justifyContent: { md: 'flex-start', xs: 'space-around' }, flexDirection: { xs: 'row-reverse', md: 'row' },
                            }}
                        >
                            <IconButton sx={{ p: '10px', background: { xs: '#0487BA', sm: '#0487BA', md: "none" }, borderRadius: '0' }} aria-label="menu" onClick={handleSearchClick}>
                                <SearchIcon sx={{ color: { xs: 'white', sm: 'white', md: "gray" }, borderRadius: '0' }} />
                            </IconButton>
                            {/* OLD SEARCH INPUT
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Search by location or property id..."
                                inputProps={{ 'aria-label': 'Search by location or property id...' }}
                                value={searchQueryInput} onChange={handleSearchQueryInputChange}
                                variant="standard"
                            /> */}
                            <TagsInput
                                selectedTags={(items) => setCities(items)}
                                variant="standard"
                                id="tags"
                                name="tags"
                                fullWidth
                                placeholder="Search by location..."
                            />
                            <Hidden mdUp sx={{ marginLeft: '0.5em' }}>
                                {selectComponent}
                            </Hidden>
                        </Paper>
                    </>
                </Grid>
                <Grid item xs={2} sx={{ margin: '0', display: { xs: 'none', md: 'flex' }, padding: '0 !important' }}>
                    <Button
                        sx={
                            {
                                backgroundColor: '#0487BA', margin: '0', borderRadius: '0', color: 'white', padding: '0.7em 1.75em', textAlign: 'center',
                                textTransform: 'none !important', width: '100%', fontSize: '1em', fontWeight: '700',
                                ':hover': { cursor: 'pointer', backgroundColor: '#1bb1f7' }, transition: 'background-color 300ms ease-in-out'
                            }}
                        onClick={handleSearchClick}>
                        Search</Button>
                </Grid>
            </Grid >
            <EmptyQuerySnackBar isOpen={isEmptyQuerySnackBarOpen} handleClose={handleSnackBarClose} />
        </>

    )
}
export default memo(SearchBar);
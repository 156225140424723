import { useRef, useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
// material
import { alpha } from '@material-ui/core/styles';
import Select from "@material-ui/core/Select";
import { Box, MenuItem, ListItemIcon, ListItemText, IconButton } from '@material-ui/core';
// components
import MenuPopover from './MenuPopover';
import Cookies from 'js-cookie';
// ----------------------------------------------------------------------

const LANGS = [
  {
    id: 0,
    value: 'en',
    label: 'English',
    icon: '/static/icons/ic_flag_en.svg'
  },
  {
    id: 1,
    value: 'fr',
    label: 'Français',
    icon: '/static/icons/ic_flag_fr.svg'
  }
  // },
  // {
  //   id: 2,
  //   value: 'ar',
  //   label: 'العربية',
  //   icon: '/static/icons/ic_flag_ar.svg',
  //   dir: 'rtl'
  // }
];

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { t, i18n } = useTranslation();

  const [selectedLang, setSelectedLang] = useState(0);

  const changeLanguage = (language, index) => {
    i18n.changeLanguage(language);
    window.location.reload();
    setSelectedLang(index);

  };

  const currentLanguageCode = cookies.get('i18next') || 'en';
  const currentLanguage = LANGS.find(l => l.value === currentLanguageCode);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr';

    if (cookieLanguage == "ar") {
      setArLang(true);
    }
  }, [currentLanguage]);

  const [arLang, setArLang] = useState(false);
  let cookieLanguage = Cookies.get('i18next');




  return (
    arLang ? (
      <div style={{ marginLeft: "15px" }}>
        <IconButton
          ref={anchorRef}
          onClick={handleOpen}
          sx={{
            padding: 0,
            width: 44,
            height: 44,
            ...(open && {
              bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
            })
          }}
        >
          <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xMi4wMiAwYzYuNjE0LjAxMSAxMS45OCA1LjM4MyAxMS45OCAxMiAwIDYuNjIzLTUuMzc2IDEyLTEyIDEyLTYuNjIzIDAtMTItNS4zNzctMTItMTIgMC02LjYxNyA1LjM2Ny0xMS45ODkgMTEuOTgxLTEyaC4wMzl6bTMuNjk0IDE2aC03LjQyN2MuNjM5IDQuMjY2IDIuMjQyIDcgMy43MTMgNyAxLjQ3MiAwIDMuMDc1LTIuNzM0IDMuNzE0LTdtNi41MzUgMGgtNS41MjNjLS40MjYgMi45ODUtMS4zMjEgNS40MDItMi40ODUgNi43NzEgMy42NjktLjc2IDYuNjcxLTMuMzUgOC4wMDgtNi43NzFtLTE0Ljk3NCAwaC01LjUyNGMxLjMzOCAzLjQyMSA0LjM0IDYuMDExIDguMDA5IDYuNzcxLTEuMTY0LTEuMzY5LTIuMDU5LTMuNzg2LTIuNDg1LTYuNzcxbS0uMTIzLTdoLTUuNzM2Yy0uMzMxIDEuMTY2LS43NDEgMy4zODkgMCA2aDUuNzM2Yy0uMTg4LTEuODE0LS4yMTUtMy45MjUgMC02bTguNjkxIDBoLTcuNjg1Yy0uMTk1IDEuOC0uMjI1IDMuOTI3IDAgNmg3LjY4NWMuMTk2LTEuODExLjIyNC0zLjkzIDAtNm02Ljc0MiAwaC01LjczNmMuMDYyLjU5Mi4zMDggMy4wMTkgMCA2aDUuNzM2Yy43NDEtMi42MTIuMzMxLTQuODM1IDAtNm0tMTIuODI1LTcuNzcxYy0zLjY2OS43Ni02LjY3MSAzLjM1LTguMDA5IDYuNzcxaDUuNTI0Yy40MjYtMi45ODUgMS4zMjEtNS40MDMgMi40ODUtNi43NzFtNS45NTQgNi43NzFjLS42MzktNC4yNjYtMi4yNDItNy0zLjcxNC03LTEuNDcxIDAtMy4wNzQgMi43MzQtMy43MTMgN2g3LjQyN3ptLTEuNDczLTYuNzcxYzEuMTY0IDEuMzY4IDIuMDU5IDMuNzg2IDIuNDg1IDYuNzcxaDUuNTIzYy0xLjMzNy0zLjQyMS00LjMzOS02LjAxMS04LjAwOC02Ljc3MSIvPjwvc3ZnPg==" alt={LANGS[0].label} />
        </IconButton>

        <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current}>
          <Box sx={{ py: 1 }}>
            {LANGS.map((option, index) => (
              <MenuItem
                key={option.value}
                selected={option.value === LANGS[selectedLang].value}
                onClick={handleClose, () => changeLanguage(option.value, index)}
                sx={{ py: 1, px: 2.5 }}
              >
                <ListItemIcon>
                  <Box component="img" alt={option.label} src={option.icon} />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                  {option.label}
                </ListItemText>
              </MenuItem>
            ))}
          </Box>
        </MenuPopover>
      </div>
    ) : (
      <div>
        <IconButton
          ref={anchorRef}
          onClick={handleOpen}
          sx={{
            padding: 0,
            width: 44,
            height: 44,
            ...(open && {
              bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
            })
          }}
        >
          <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xMi4wMiAwYzYuNjE0LjAxMSAxMS45OCA1LjM4MyAxMS45OCAxMiAwIDYuNjIzLTUuMzc2IDEyLTEyIDEyLTYuNjIzIDAtMTItNS4zNzctMTItMTIgMC02LjYxNyA1LjM2Ny0xMS45ODkgMTEuOTgxLTEyaC4wMzl6bTMuNjk0IDE2aC03LjQyN2MuNjM5IDQuMjY2IDIuMjQyIDcgMy43MTMgNyAxLjQ3MiAwIDMuMDc1LTIuNzM0IDMuNzE0LTdtNi41MzUgMGgtNS41MjNjLS40MjYgMi45ODUtMS4zMjEgNS40MDItMi40ODUgNi43NzEgMy42NjktLjc2IDYuNjcxLTMuMzUgOC4wMDgtNi43NzFtLTE0Ljk3NCAwaC01LjUyNGMxLjMzOCAzLjQyMSA0LjM0IDYuMDExIDguMDA5IDYuNzcxLTEuMTY0LTEuMzY5LTIuMDU5LTMuNzg2LTIuNDg1LTYuNzcxbS0uMTIzLTdoLTUuNzM2Yy0uMzMxIDEuMTY2LS43NDEgMy4zODkgMCA2aDUuNzM2Yy0uMTg4LTEuODE0LS4yMTUtMy45MjUgMC02bTguNjkxIDBoLTcuNjg1Yy0uMTk1IDEuOC0uMjI1IDMuOTI3IDAgNmg3LjY4NWMuMTk2LTEuODExLjIyNC0zLjkzIDAtNm02Ljc0MiAwaC01LjczNmMuMDYyLjU5Mi4zMDggMy4wMTkgMCA2aDUuNzM2Yy43NDEtMi42MTIuMzMxLTQuODM1IDAtNm0tMTIuODI1LTcuNzcxYy0zLjY2OS43Ni02LjY3MSAzLjM1LTguMDA5IDYuNzcxaDUuNTI0Yy40MjYtMi45ODUgMS4zMjEtNS40MDMgMi40ODUtNi43NzFtNS45NTQgNi43NzFjLS42MzktNC4yNjYtMi4yNDItNy0zLjcxNC03LTEuNDcxIDAtMy4wNzQgMi43MzQtMy43MTMgN2g3LjQyN3ptLTEuNDczLTYuNzcxYzEuMTY0IDEuMzY4IDIuMDU5IDMuNzg2IDIuNDg1IDYuNzcxaDUuNTIzYy0xLjMzNy0zLjQyMS00LjMzOS02LjAxMS04LjAwOC02Ljc3MSIvPjwvc3ZnPg==" alt={LANGS[0].label} />
        </IconButton>

        <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current}>
          <Box sx={{ py: 1 }}>
            {LANGS.map((option, index) => (
              <MenuItem
                key={option.value}
                selected={option.value === LANGS[selectedLang].value}
                onClick={handleClose, () => changeLanguage(option.value, index)}
                sx={{ py: 1, px: 2.5 }}
              >
                <ListItemIcon>
                  <Box component="img" alt={option.label} src={option.icon} />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                  {option.label}
                </ListItemText>
              </MenuItem>
            ))}
          </Box>
        </MenuPopover>
      </div>
    )

  );
}

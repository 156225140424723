import React from 'react';

import Stripe from './stripe';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

// pk_test_51JAlvVHL2e42QB3osyIuTLjY1Phtx3Z0pOuFs6cpVBKNe6oao7Oq3XAX5p2FpK9RjS4UnRNpL0xe2bOJyM9A5SOn007wQdZWaB
// pk_live_51JL6cEKmRdNqCZVhIazYNRziBpjot7l6RshSSNVHsnUi7XeE8HpAMIDSWpXfijlY6NnAyUE8iQKhXAxVmrXVbBpt00ZAIqx97i
const stripePromise = loadStripe('pk_test_51JL6cEKmRdNqCZVhgCJsFwGHBWeEcBZvMJdySpK9XyOLfOgsyXozwP6M00oNLmbINGIvE6CEH7kixuxHaasZua7M00gw3taov9');

function StripeComponent({setOpenStripe, openStripe, ERROR, stripe, handleSubmit, price, setPost, values , loading, succeed, error}) {
  return (
    <Elements stripe={stripePromise}>
      <Stripe setOpenStripe={setOpenStripe} openStripe={openStripe} ERROR={ERROR} Stripe={stripe} Submit={handleSubmit} price={price} setPost={setPost} Post={values} succeed={succeed} loading={loading} error={error}/>
    </Elements>
  );
}

export default StripeComponent;
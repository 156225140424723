
const UseStyles = (propertyType, isMobileScreen = false, isTabletScreen = false) => {
    switch (propertyType) {
        case 'ApartmentVilla': {
            return ({
                footer: {

                    bottom: '0'
                    , height: isMobileScreen ? '30%' : '20%',
                    zIndex: '1',
                    position: 'absolute',

                },
                footerInfo: {
                    // padding: '0.5em 0.2em 1.5em',
                    background: 'rgba(4,135,186,0.5)',
                    height: '60%',
                },
                footerText: {
                    color: 'white',
                    fontSize: isMobileScreen ? '0.9rem' : isTabletScreen ? '0.9rem' : '1.2rem',
                },
                text: {
                    color: 'black',
                    fontSize: '0.7rem',
                    fontWeight: '900'
                }
            }); break;
        }

        case 'Land': {
            return ({
                footer: {

                    bottom: '0'
                    , height: isMobileScreen ? '30%' : '20%',
                    zIndex: '1',
                    position: 'absolute'
                },
                footerInfo: {
                    // padding: '0.5em 0.2em 1.5em',
                    background: 'rgba(0, 196, 46,0.5)',
                    height: '60%',
                },

                footerText: {
                    color: 'white',
                    fontSize: isTabletScreen ? '1rem' : '1.2rem'
                },
                text: {
                    color: 'white',
                    fontSize: '0.7rem',
                    fontWeight: '900'
                }
            });
        }
        case 'OfficeGarage': {
            return ({
                footer: {

                    bottom: '0'
                    , height: isMobileScreen ? '30%' : '20%',
                    zIndex: '1',
                    position: 'absolute'
                },
                footerInfo: {
                    // padding: '0.5em 0.2em 1.5em',
                    background: 'rgba(117, 117, 117,0.5)',
                    height: '60%',
                },

                footerText: {
                    color: 'white',
                    fontSize: isTabletScreen ? '1rem' : '1.2rem'
                },
                text: {
                    color: 'white',
                    fontSize: '0.7rem',
                    fontWeight: '900'
                }
            });
        }

    }
};
export default UseStyles
import React, { memo } from 'react';
import { Grid, IconButton, Box } from '@material-ui/core'
import ApartmentVillaPropertyItem from './ApartmentVillaPropertyItem';
import LandPropertyItem from './LandPropertyItem';
import OfficeGaragePropertyItem from './OfficeGaragePropertyItem';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import HotelIcon from '@material-ui/icons/Hotel';
import BathtubIcon from '@material-ui/icons/Bathtub';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LazyLoad from 'react-lazyload';
import UseStyles from './styles';

function PropertyItem({ property }) {
    const { mainImage, address, price, _id, propertyType, isForSale } = property;
    // const { mainImage, address, price, numberOfBedrooms, numberOfBathrooms, numberOfParkingSpaces, surfaceArea, _id, propertyType } = property;
    const isMobileScreen = useMediaQuery('(max-width:600px)');
    const isTabletScreen = useMediaQuery('(max-width:1070px)');
    const isLaptopScreen = useMediaQuery('(min-width:1071px');
    const image = isMobileScreen ? mainImage.Mobile : isTabletScreen ? mainImage.Tablet : mainImage.Laptop;
    let propertyItem;
    let propertyAddressPriceColor = 'black';
    switch (propertyType) {
        case 'Apartment': { propertyItem = <ApartmentVillaPropertyItem numberOfBedrooms={property.numberOfBedrooms} numberOfBathrooms={property.numberOfBathrooms} numberOfRooms={property.numberOfRooms} surfaceArea={property.surfaceArea} _id={property._id} />; propertyAddressPriceColor = "rgb(66,188,245)"; break; }
        case 'Villa': { propertyItem = <ApartmentVillaPropertyItem numberOfBedrooms={property.numberOfBedrooms} numberOfBathrooms={property.numberOfBathrooms} numberOfRooms={property.numberOfRooms} surfaceArea={property.surfaceArea} _id={property._id} />; break; }
        case 'Land': { propertyItem = <LandPropertyItem surfaceArea={property.surfaceArea} _id={property._id} altitude={property.altitude} />; propertyAddressPriceColor = 'white'; break; }
        case 'Office': { propertyItem = <OfficeGaragePropertyItem surfaceArea={property.surfaceArea} _id={property._id} />; propertyAddressPriceColor = "white"; break; }
        case 'Garage': { propertyItem = <OfficeGaragePropertyItem surfaceArea={property.surfaceArea} _id={property._id} />; propertyAddressPriceColor = "white"; break; }
        default: propertyItem = null;
    }
    return (
        <Grid container sx={{ position: 'relative' }}>
            <LazyLoad>
                <img src={image} alt="home" style={{ height: 'auto', width: '100%', zIndex: '0' }} />
            </LazyLoad>
            <Grid item xs={12} direction="column" container sx={{
                marginLeft: { sm: '3rem', xs: '1rem', md: '3rem', },
                color: propertyAddressPriceColor, fontWeight: '900', top: isMobileScreen ? '50%' : '50%', zIndex: '1', position: 'absolute'
            }}>

                <Grid item justifyContent="flex-start" alignItems="center" container
                    sx={{
                        color: 'black', padding: '0.2em 0.2em', fontSize: isMobileScreen ? '1rem' : isTabletScreen ? '1.2em' : '1.3em',
                        width: 'fit-content', backgroundColor: 'rgba(255,255,255,0.7)',
                        borderRadius: '20px'
                    }}>
                    <LocationOnIcon sx={{ fontSize: isMobileScreen ? '1rem' : '1.7em', color: 'black' }} />
                    {address}
                </Grid>

                <Grid item container justifyContent="space-between" alignItems="center" sx={{ width: 'fit-content', color: 'black', marginTop: isMobileScreen ? '0.7em' : '1em', fontSize: isTabletScreen ? '0.75em' : '1em', borderRadius: '20px', padding: '0.2em 0.7em', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                    <Grid item xs={1} sx={{ marginRight: '0.5em', fontSize: isMobileScreen ? '1rem' : '1.3em' }}>
                        <AttachMoneyIcon sx={{ boxSizing: 'border-box', border: `2px solid black`, borderRadius: '50%', marginBottom: isMobileScreen ? '2px' : '5px', fontSize: isMobileScreen ? '1rem' : '1em' }} />
                    </Grid>
                    <Grid item sx={{ fontSize: isMobileScreen ? '1rem' : '1em' }}>
                        ${price}
                    </Grid>
                </Grid>


            </Grid >
            {propertyItem}
        </Grid >
    )
}
export default memo(PropertyItem);
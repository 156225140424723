import * as React from 'react';
import { Container, Box, Grid, Typography, Alert, Snackbar, Stack } from "@material-ui/core";
import { Trans, useTranslation } from 'react-i18next';
import ProfileField from "../../components/TextFields/TextField";
import forgetPassIm from '../../images/forgetPassword.svg'
import useState from "react-hook-use-state";
import { LoadingButton } from "@material-ui/lab";
import * as Yup from "yup";
import AuthLayout from '../../layouts/AuthLayout';
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { forgetPasswordEmail } from "../../actions/auth";

import { experimentalStyled as styled } from '@material-ui/core/styles';
import Page from '../../components/Page';
const RootStyle = styled(Page)(({ theme }) => ({
    paddingBottom: theme.spacing(10),
    paddingTop: theme.spacing(10)
  }));

const ForgetPassword = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [LoggedUser, setLoggedUser] = useState(JSON.parse(localStorage.getItem('profile')))
    const [success, setSuccess] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false)
    };
    const handleSubmit = async (values) => {
        setLoading(true)
        let email = values.email;
        email=email.trim();
        email=email.charAt(0).toLowerCase() + email.slice(1);
        const form =
        {
            email: email,
        }
        const success = await dispatch(forgetPasswordEmail(form));
        if (success) {
            setLoading(false)
        } else setLoading(false)
        setSuccess(success)
        setOpen(true)
    };
    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Invalid email format')
            .required('Required!'),
    })
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        onSubmit: values => {
            handleSubmit(values)
        },
        validationSchema,
    }
    )

    //////////////////////////////////////
    const { t, i18n } = useTranslation();

    return (
        <RootStyle title="Forgot Password | eAkary">
            <AuthLayout />
            <Container>
            <form onSubmit={formik.handleSubmit}>
                <Grid container rowSpacing={1} spacing={5} justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={6} md={5} lg={5} direction='column' >
                        <Box
                            component="img"
                            src={forgetPassIm}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} direction='column' columnSpacing={5}>
                        <Typography variant="h3" mt={4}>
                            <Trans i18nKey="description.forgetPass">
                                Forgot your Password?
                            </Trans>
                        </Typography>
                        <Typography variant="h6" mt={4} mb={4}>
                            <Trans i18nKey="description.enterEmail">
                                Enter the e-mail address linked to your Eakary account
                            </Trans>
                        </Typography>
                    
                                <div style={{ marginBottom: 20 }}>
                                    <ProfileField onBlur={formik.handleBlur} handleChange={formik.handleChange} value={formik.values.email} name='email' placeholder="example@me.com" label={t("description.emailAdd")} />
                                    {formik.touched.email && formik.errors.email ?<span style={{ fontSize: '15px' }}>  <div style={{ color: 'red' }}>{formik.errors.email}</div> </span> : null}
                                </div>
                         
                            <LoadingButton
                                size="large"
                                type="submit"
                                variant="contained"
                                style={{width: "100%"}}
                                loading={loading}

                            >
                                <Trans i18nKey="description.send">
                                    Send
                                </Trans>
                            </LoadingButton>
                     
                    </Grid>
                </Grid>
                </form>
                {
                    success !== undefined ? (
                        success ? (
                            <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                                <Alert onClose={handleSnackClose} severity="success">
                                    <Trans i18nKey="description.emailSent">
                                        Email has been send, Kindly follow the instruction
                                    </Trans>
                                </Alert>
                            </Snackbar>
                        ) : (
                            <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                                <Alert onClose={handleSnackClose} severity="error">
                                    <Trans i18nKey="description.userEmail">
                                        User with this email does not exists
                                    </Trans>
                                </Alert>
                            </Snackbar>
                        )) : null
                }
            </Container>
        </RootStyle>
    )
}
export default ForgetPassword;
import React from "react";
import Search from "./Search";



export default function MapRent({setValueMap}) {

    return (
     
    <Search setValueMap={setValueMap} />
       
    );

}




import { UPDATE_AD, FETCH_ALL, FETCH_SPECIFIC_AD,FETCH_BY_SEARCH, FETCH_AD, CREATE, UPDATE, DELETE, LIKE, ADD_TO_WISHLIST, GET_WISHLIST, REMOVE_FROM_WISHLIST } from '../constants/actionTypes';

export default (state = { isLoading: true, ads:[] , updatedAd:{} }, action) => {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isLoading: true };
    case 'END_LOADING':
      return { ...state, isLoading: false };
    case FETCH_ALL:
      return {
        ...state,
        ads: action.payload.data
      };
      case FETCH_SPECIFIC_AD:
        return { ...state, ads: action.payload.data }
    case FETCH_BY_SEARCH:
      return { ...state, ads: action.payload.data };
    case FETCH_AD:
      return { ...state, ads: action.payload.ad };
    case LIKE:
      return { ...state, ads: state.ads.map((ad) => (ad._id === action.payload._id ? action.payload : ad)) };
    case CREATE:
      return { ...state, ads: [...state.ads, action.payload] };
    case UPDATE_AD:
      return { ...state, updatedAd: action.payload.ad };
    case UPDATE:
      return { ...state, ads: state.ads.map((ad) => (ad._id === action.payload._id ? action.payload : ad)) };
    case DELETE:
      return { ...state, ads: state.ads.filter((ad) => ad._id !== action.payload) };
    case ADD_TO_WISHLIST:
      return { ...state, wish: action.payload };
    case GET_WISHLIST:
      return { ...state, wish: action.payload };
    case REMOVE_FROM_WISHLIST:
      return { ...state, wish: action.payload }



    default:
      return state;
  }
};

import { START_LOADING, END_LOADING, UPDATE_POST, FETCH_ALL, FETCH_MY_POSTS, FETCH_USER_POSTS, FETCH_POST, FETCH_BY_SEARCH, CREATE, UPDATE, DELETE, LIKE, ADD_TO_WISHLIST, GET_WISHLIST, REMOVE_FROM_WISHLIST, FETCH_NOTIFICATION, FETCH_MAPBUY_POST, UPDATE_NOTIFICATION, CREATE_NOTIFICATION_REQ_360 } from '../constants/actionTypes';

import * as api from '../api/index.js';
import { da } from 'date-fns/locale';

export const getPost = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const { data } = await api.fetchPost(id);

    dispatch({ type: FETCH_POST, payload: { post: data } });
    return data;
  } catch (error) {
  }
};

export const getRecommendedPosts = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const { data } = await api.fetchRecommendedPosts(id);

    dispatch({ type: FETCH_ALL, payload: { post: data } });
    return data;
  } catch (error) {
  }
};

export const getBuyPosts = (page) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data: { data, currentPage, numberOfPages } } = await api.fetchBuyPosts(page);

    dispatch({ type: FETCH_ALL, payload: { data, currentPage, numberOfPages } });
    dispatch({ type: END_LOADING });
  } catch (error) {
  }
};

export const getMapBuyPosts = () => async (dispatch) => {
  try {
    const { data } = await api.fetchMapBuyPosts();
    return data;
  } catch (error) {
  }
};
export const getMapRentPosts = () => async (dispatch) => {
  try {
    const { data } = await api.fetchMapRentPosts();
    return data;
  } catch (error) {
  }
};

export const getRentPosts = (page) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data: { data, currentPage, numberOfPages } } = await api.fetchRentPosts(page);

    dispatch({ type: FETCH_ALL, payload: { data, currentPage, numberOfPages } });
    dispatch({ type: END_LOADING });
  } catch (error) {
  }
};

export const getAllPosts = (page) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data: { data, currentPage, numberOfPages } } = await api.fetchAllPosts(page);
    dispatch({ type: FETCH_ALL, payload: { data, currentPage, numberOfPages } });
    dispatch({ type: END_LOADING });
  } catch (error) {
  }
};

export const getAllPostsCMS = () => async (dispatch) => {
  try {
    // dispatch({ type: START_LOADING });
    const { data } = await api.fetchAllPostsCMS();
    // dispatch({ type: FETCH_ALL, payload: { data } });
    // dispatch({ type: END_LOADING });

    return data;
  } catch (error) {
  }
};

export const getMyPosts = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const { data: { data } } = await api.fetchMyPosts(id);

    dispatch({ type: FETCH_MY_POSTS, payload: { data } });

    dispatch({ type: END_LOADING });

    return data;
  } catch (error) {
  }
};

export const getSpecificAd = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const { data: { data } } = await api.fetchSpecificAd(id);

    dispatch({ type: FETCH_MY_POSTS, payload: { data } });

    dispatch({ type: END_LOADING });

    return data;
  } catch (error) {
  }
};

export const getFeaturedPosts = (page) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data: { data } } = await api.fetchFeaturedPosts();

    dispatch({ type: FETCH_MY_POSTS, payload: { data } });
    dispatch({ type: END_LOADING });
  } catch (error) {
  }
};

export const getPostsBySearch = (searchQuery) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data: { data } } = await api.fetchPostsBySearch(searchQuery);

    dispatch({ type: FETCH_BY_SEARCH, payload: { data } });
    dispatch({ type: END_LOADING });
  } catch (error) {
  }
};


export const getPostsBySearchb = (searchQuery) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data: { data, currentPage, numberOfPages } } = await api.fetchPostsBySearchb(searchQuery);
    dispatch({ type: FETCH_BY_SEARCH, payload: { data, currentPage, numberOfPages } });

    // dispatch({ type: FETCH_BY_SEARCH, payload: { data } });
    dispatch({ type: END_LOADING });
  } catch (error) {
  }
};

export const getPostsBySearchh = (searchQuery) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data: { data } } = await api.fetchPostsBySearchh(searchQuery);


    dispatch({ type: FETCH_BY_SEARCH, payload: { data } });
    dispatch({ type: END_LOADING });
  } catch (error) {
  }
};

export const createPost = (post, history) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.createPost(post);

    dispatch({ type: CREATE, payload: data });

    dispatch({ type: END_LOADING });

    return true
  } catch (error) {
    return false
  }
};

export const updatePost = (id, post) => async (dispatch) => {
  try {
    const { data } = await api.updatePost(id, post);

    dispatch({ type: UPDATE, payload: data });
    return data;
  } catch (error) {
  }
};

export const unlockPost = (id, post) => async (dispatch) => {
  try {
    const { data } = await api.unlockPost(id, post);

    dispatch({ type: UPDATE, payload: data });
    return data;
  } catch (error) {
  }
};

export const addLink360 = (id, post) => async () => {
  try {
    const { data } = await api.addLink360(id, post);

    // dispatch({ type: UPDATE, payload: data });
    return data;
  } catch (error) {
  }
};


export const likePost = (id) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem('profile'));

  try {
    const { data } = await api.likePost(id, user?.token);

    dispatch({ type: LIKE, payload: data });
  } catch (error) {
  }
};

export const deletePost = (id) => async (dispatch) => {
  try {
    await api.deletePost(id);

    dispatch({ type: DELETE, payload: id });
  } catch (error) {
  }
};

export const deleteCMSPost = (id) => async (dispatch) => {
  try {
    await api.deleteCMSPost(id);

    dispatch({ type: DELETE, payload: id });
  } catch (error) {
  }
};

export const addToWishlist = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.addWishlist(id, formData);
    dispatch({ type: ADD_TO_WISHLIST, payload: data });

  } catch (error) {
  }
};

export const getWishlist = (id) => async (dispatch) => {
  try {
    // dispatch({ type: START_LOADING });

    const { data } = await api.fetchWishlist(id);
    return data;
    // dispatch({ type: GET_WISHLIST, payload: { wish: data } });
  } catch (error) {
  }
}

export const deleteWish = (userId, postId) => async (dispatch) => {
  try {
    const { data } = await api.deleteItemWishlist(userId, postId);
    return data;
    // dispatch({ type: GET_WISHLIST, payload: { wish: data } });
  } catch (error) {
  }
}
export const publishPostCMS = async (postId, setPublished) => {
  try {
    if (setPublished) {
      const { data } = await api.publishPostCMS(postId);
    } else {
      const { data } = await api.unPublishPostCMS(postId);
    }
    // dispatch({ type: GET_WISHLIST, payload: { wish: data } });
  } catch (error) {
  }
}
export const getAllUserPosts = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const { data } = await api.fetchUserPosts(id);
    dispatch({ type: FETCH_USER_POSTS, payload: { posts: data.data } });
    dispatch({ type: END_LOADING });
    return data.data;
  } catch (error) {
  }
};
export const edit360Link = (id, link) => async (dispatch) => {
  try {
    const { data } = await api.edit360Link(id, link);
    dispatch({ type: UPDATE_POST, payload: data });
    return true;
  } catch (error) {
    return false;
  }
};

export const getAllNotifications = () => async (dispatch) => {
  try {
    // dispatch({ type: START_LOADING });

    const { data } = await api.fetchNotifications();

    dispatch({ type: FETCH_NOTIFICATION, payload: data });

    return data;
    // dispatch({ type: END_LOADING });
  } catch (error) {
  }
}

export const updateNotif = (id, notif) => async (dispatch) => {
  try {
    const { data } = await api.updateNotif(id, notif);


    // dispatch({ type: UPDATE_NOTIFICATION, payload: data });
    return data;
  } catch (error) {
  }
};

export const createNotificationReq360 = (notif, history) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.createNotificationReq360(notif);


    dispatch({ type: END_LOADING });

    return true
  } catch (error) {
    return false
  }
};
import * as React from 'react';
import { useEffect, useState } from "react";
import Typography from '@material-ui/core/Typography';
import { getAllPostsCMS, getMyPosts, publishPostCMS } from '../../../actions/posts';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import { styled, alpha } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { deleteCMSPost } from '../../../actions/posts';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import DialogDeletePost from '../../../components/Dialog/DialogPost';
import CMS_Post_Detail from '../CMSPostDetails/cms_post_detail';
import { useNavigate } from 'react-router';
import DialogUpdatePost from './cms_add360_link';
import CMS_Add_Ad from '../CMSCreateAd/cms_add_ad';

import CheckIcon from '@material-ui/icons/Check'
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '25ch',
        },
    },

}));

const columns = [
    { field: 'id', headerName: 'ID' },
    { field: 'title', headerName: 'Title' },
    { field: 'propertyType', headerName: 'Property Type' },
    { field: 'listingType', headerName: 'Listing Type' },
    { field: 'phoneNumber', headerName: 'Phone Number' },
]

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}



function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {columns.map((columns) => (
                    <TableCell
                        key={columns.field}
                        sortDirection={orderBy === columns.field ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === columns.field}
                            direction={orderBy === columns.field ? order : 'asc'}
                            onClick={createSortHandler(columns.field)}
                        >
                            {columns.headerName}
                            {orderBy === columns.field ? (
                                <Box component="span">
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function CMS_Posts() {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [addAd, setAddAd] = useState(false);
    const isSelected = (id) => selected.indexOf(id) !== -1;

    const [posts, setPosts] = useState([]);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(async () => {
        const posts = await dispatch(getAllPostsCMS());
        setPosts(posts.data);

    }, [dispatch]);


    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = posts.map((n) => n._id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const [searchTerm, setSearchTerm] = useState("");

    const deletePost = async (id) => {
        dispatch(deleteCMSPost(id));

        // const posts = await dispatch(getMyPosts());
        // setPosts(posts);

    };

    const [open, setOpen] = useState(false);

    const [postId, setId] = useState();

    const openDialog = (id) => {
        setOpen(true);

        setId(id);
    }

    const closeDialog = () => {
        setOpen(false);
    }

    const [postDet, setPostDetail] = useState(false);

    const [id, setPostId] = useState();

    const postDetail = (id) => {
        // setPostDetail(true);
        // setPostId(id);
        navigate(`/posts/${id}`);
    }

    const [addLink, setAddLink] = useState(false);

    const open360Popup = (id) => {
        setAddLink(true);
    }

    const [openUpdatePost, setOpenUpdatePost] = useState(false);

    const openDialogUpdatePost = (id) => {
        setOpenUpdatePost(true);

        setId(id);
    }

    const closeDialogUpdatePost = () => {
        setOpenUpdatePost(false);
    }

    const addAdForProperty = (id) => {
        setAddAd(true);

        setPostId(id);
    }

    return (
        <Grid container>
            <Grid item md={12} align="center">
                {
                    postDet ? (
                        <CMS_Post_Detail postId={id} />
                    ) : (
                        addAd ? (
                            <CMS_Add_Ad postId={id} />
                        ) : (
                            <>
                                <Typography variant="h3">Posts List</Typography>

                                <Box sx={{ width: '95%', marginTop: "15px" }} align="center">
                                    <Paper sx={{ width: '95%', mb: 2 }}>
                                        <Search align="left">
                                            <SearchIconWrapper>
                                                <SearchIcon />
                                            </SearchIconWrapper>
                                            <StyledInputBase
                                                placeholder="Search by title…"
                                                inputProps={{ 'aria-label': 'search' }}
                                                onChange={(event) => { setSearchTerm(event.target.value); }}
                                            />
                                        </Search>
                                        <TableContainer>
                                            <Table
                                                sx={{ minWidth: 750 }}
                                                aria-labelledby="tableTitle"
                                                size={dense ? 'small' : 'medium'}
                                            >
                                                <EnhancedTableHead
                                                    numSelected={selected.length}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onSelectAllClick={handleSelectAllClick}
                                                    onRequestSort={handleRequestSort}
                                                    rowCount={posts.length}
                                                />
                                                <TableBody>
                                                    {stableSort(posts, getComparator(order, orderBy))
                                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        .filter((row) => {
                                                            if (searchTerm == "") {
                                                                return row
                                                            }
                                                            else if (row.title.toLowerCase().includes(searchTerm.toLocaleLowerCase())) {
                                                                return row
                                                            }
                                                        })
                                                        .map((row, index) => {
                                                            const isItemSelected = isSelected(row._id);
                                                            const labelId = `enhanced-table-checkbox-${index}`;

                                                            return (
                                                                <>
                                                                    <TableRow
                                                                        hover
                                                                        onClick={(event) => handleClick(event, row._id)}
                                                                        role="checkbox"
                                                                        aria-checked={isItemSelected}
                                                                        tabIndex={-1}
                                                                        key={row._id}
                                                                        selected={isItemSelected}
                                                                    >
                                                                        <TableCell padding="checkbox">
                                                                            <Checkbox
                                                                                color="primary"
                                                                                checked={isItemSelected}
                                                                                inputProps={{
                                                                                    'aria-labelledby': labelId,
                                                                                }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell id={labelId} component="th" scope="row" padding="none" onClick={() => postDetail(row._id)}>{row._id}</TableCell>
                                                                        <TableCell align="left" onClick={() => postDetail(row._id)}>{row.title}</TableCell>
                                                                        <TableCell align="left" onClick={() => postDetail(row._id)}>{row.propertyType}</TableCell>
                                                                        <TableCell align="left" onClick={() => postDetail(row._id)}>{row.listingType}</TableCell>
                                                                        <TableCell align="left" onClick={() => postDetail(row._id)}>{row.phoneNumber}</TableCell>
                                                                        <TableCell align="left"  > <CheckIcon onClick={() => { publishPostCMS(row._id, !row.published); row.published = !row.published; }} sx={{ color: row.published ? 'green' : 'gray' }} /></TableCell>
                                                                        <TableCell><DeleteIcon onClick={() => openDialog(row._id)} style={{ cursor: "pointer" }} /></TableCell>
                                                                        <TableCell><EditIcon onClick={() => openDialogUpdatePost(row._id)} style={{ cursor: "pointer" }} /></TableCell>
                                                                        <TableCell><AddToQueueIcon onClick={() => addAdForProperty(row._id)} style={{ cursor: "pointer" }} /></TableCell>
                                                                    </TableRow>
                                                                    <DialogDeletePost id={postId} open={open} close={closeDialog} />
                                                                    <DialogUpdatePost id={postId} open={openUpdatePost} close={closeDialogUpdatePost} />
                                                                </>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 40, 55, 70, 100]}
                                            component="div"
                                            count={posts.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Paper>
                                </Box>
                            </>
                        )

                    )
                }

            </Grid>
        </Grid>
    )
}

import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { createImgAd } from "../../../actions/ImgAd";
import Input from '../../../components/Input/Input';
import * as Yup from 'yup';
import { useFormik} from 'formik';
// material
import {Alert, Snackbar, Stack, Typography} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import * as React from "react";

// ----------------------------------------------------------------------

function CMSCreateImgAd() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openSnack, setSnackOpen] = useState(false);
  const [success,setSuccess] = useState(undefined);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);
  const {id} = useParams();
  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false)
  };
  const handleRegisterSubmit = async (values) => {
    setLoading(true)
    const form=
        {
          title: values.title,
          price: values.price,
          currency: values.currency,
          ownerName : values.ownerName,
          ownerPhone:values.ownerPhone,
          ownerAddress :values.ownerAddress
        }
      const success = await dispatch(createImgAd(id,form));
      if(success)
      {
        setLoading(false)
        navigate(`/CMS/posts/${id}`)
      }else setLoading(false)

    setSuccess(success)
    setSnackOpen(true)
  };
  const validationSchema = Yup.object({
    title: Yup.string()
        .required('Title required'),
    price: Yup.string()
        .required('Price is required'),
    currency: Yup.string()
        .required('Curency is required'),
    ownerName : Yup.string()
        .required('Owner Name is required'),
    ownerPhone : Yup.string()
        .required('Owner Phone is required'),
    ownerAddress: Yup.string()
        .required('Owner Addressis required')
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      price: '',
      currency: '',
      ownerName: '',
      ownerPhone:'',
      ownerAddress:''
    },
    validationSchema,
    onSubmit: (values) => {
      handleRegisterSubmit(values);
    }
  });
  return (
    <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
        <Typography variant="h4" component="h2" gutterBottom> Owner Information</Typography>
          
            <div style={{width:'50%'}}>
            <Input required name="ownerName" label="Owner Name" handleChange={formik.handleChange} handleBlur={formik.handleBlur}  type="text" autoFocus half />
            {formik.touched.ownerName && formik.errors.ownerName ? <div style={{color:'red'}}>{formik.errors.ownerName}</div>:null}
            </div>
            <div style={{width:'50%'}}>
            <Input required name="ownerPhone" label="Owner Phone" handleChange={formik.handleChange} handleBlur={formik.handleBlur}  type="text" autoFocus half />
            {formik.touched.ownerPhone && formik.errors.ownerPhone ? <div style={{color:'red'}}>{formik.errors.ownerPhone}</div>:null}
            </div>
            <div style={{width:'50%'}}>
            <Input required name="ownerAddress" label="Owner Address" handleChange={formik.handleChange} handleBlur={formik.handleBlur}  type="text" autoFocus half />
            {formik.touched.ownerAddress && formik.errors.ownerAddress ? <div style={{color:'red'}}>{formik.errors.ownerAddress}</div>:null}
            </div>
          <Typography variant="h4" component="h2" gutterBottom> Advertisement Information</Typography>
          <div sx={{marginBottom:20}}  style={{width:'50%'}}>
          <Input required name="title" label="Title" handleChange={formik.handleChange} handleBlur={formik.handleBlur} type="text" />
          {formik.touched.title && formik.errors.title ? <div style={{color:'red'}}>{formik.errors.title}</div>:null}
          </div>
          <div sx={{marginBottom:20}}  style={{width:'50%'}}>
          <Input required name="currency" label="currency" handleChange={formik.handleChange} handleBlur={formik.handleBlur} type="text" />
          {formik.touched.currency && formik.errors.currency ? <div style={{color:'red'}}>{formik.errors.currency}</div>:null}
          </div>
          <div sx={{marginBottom:20}}  style={{width:'50%'}}>
          <Input required name="price" label="price" handleChange={formik.handleChange} handleBlur={formik.handleBlur} type="text" />
          {formik.touched.price && formik.errors.price ? <div style={{color:'red'}}>{formik.errors.price}</div>:null}
          </div >
          <div style={{alignContent:'center'}}>
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
          >
            Create Image Ad
          </LoadingButton>
          </div>
        </Stack>
   </form>
  );
}

export default CMSCreateImgAd;
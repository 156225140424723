import React, { useMemo, useEffect, useState } from 'react'
import { Button, Grid } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import UseStyles from './style'
import { Link } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import { experimentalStyled as styled } from '@material-ui/core/styles';
import Page from '../../components/Page';
import { Trans, useTranslation } from 'react-i18next';

const RootStyle = styled(Page)(({ theme }) => ({
  paddingBottom: theme.spacing(10)
}));
const Termsofservice = () => {
    const classes = UseStyles();
    const { t, i18n } = useTranslation();

    return (
        <>
   <RootStyle title="Tersm of Service | eAkary">
        <Container>

            <Grid container alignItems="center" justify="space-between">
            <Typography className={classes.typoTitle} component="h4" variant="h4">
                  {t("description.tos")}
                </Typography>
            </Grid>

            <Grid container alignItems="center" justify="space-between">
                <Typography variant="subtitle2" component="p" className={classes.typoDate}>
                    Last updated on October 3, 2021.
                </Typography>

            </Grid>
            <Grid container alignItems="center" justify="space-between">
                <Typography className={classes.typoTitle} component="h5" variant="h5">
                I. {t("description.placeclassified")}
                </Typography>

            </Grid>

            <Grid container alignItems="center" justify="space-between">
                <Typography variant="body2" component="p" className={classes.typoText}>
                {t("description.placeclassifiedanswer")}
                </Typography>

            </Grid>
            <Grid container alignItems="center" justify="space-between">
                <Typography className={classes.typoTitle} component="h5" variant="h5">
                II. {t("description.restrictionpublic")}
                </Typography>

            </Grid>

            <Grid container alignItems="center" justify="space-between">
                <Typography variant="body2" component="p" className={classes.typoText}>
                {t("description.restrictionpublicanswer")}
                </Typography>

            </Grid>

            <Grid container alignItems="center" justify="space-between">
                <Typography className={classes.typoTitle} component="h5" variant="h5">
                III. {t("description.buyrent")}
                </Typography>

            </Grid>

            <Grid container alignItems="center" justify="space-between">
                <Typography variant="body2" component="p" className={classes.typoText}>
                {t("description.buyrentanswer")}
                </Typography>

            </Grid>

            <Grid container alignItems="center" justify="space-between">
                <Typography className={classes.typoTitle} component="h5" variant="h5">
                IV. {t("description.liability")}
                </Typography>

            </Grid>

            <Grid container alignItems="center" justify="space-between">
                <Typography variant="body2" component="p" className={classes.typoText}>
                {t("description.liabilityanswer")}
                </Typography>

            </Grid>

            

        </Container>
        </RootStyle>
</>
    )
}

export default Termsofservice
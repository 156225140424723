import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import HomeIcon from '@material-ui/icons/Home';
import HotelIcon from '@material-ui/icons/Hotel';
import BathtubIcon from '@material-ui/icons/Bathtub';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import UseStyles from './styles';
import apartment from "../../images/apartment.jpg";
import StarsIcon from '@material-ui/icons/Stars';
import 'boxicons';
import { ADD_TO_WISHLIST, REMOVE_FROM_WISHLIST } from '../../constants/actionTypes';
import { addToWishlist, getWishlist } from '../../actions/posts';
import { deleteWish } from '../../actions/posts';
import Chip from '@material-ui/core/Chip';
import Stack from '@material-ui/core/Stack';
import { fCurrency } from '../../utils/formatNumber';
import { ButtonBase, CardActionArea } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

const PostCard = ({ post, setCurrentId }) => {
  const { t, i18n } = useTranslation();

  const user = JSON.parse(localStorage.getItem('profile'));
  const navigate = useNavigate();

  const openPost = (e) => {
    // dispatch(getPost(post._id, navigate));

    navigate(`/posts/${post._id}`);
  };
  const classes = UseStyles();

  const [buttonFavoriteIsClicked, isFavoriteClicked] = useState(false);
  const [buttonFavoriteIsNotClicked, isFavoriteNotClicked] = useState(true);

  const dispatch = useDispatch();

  const [LoggedUser, setLoggedUser] = useState(JSON.parse(localStorage.getItem('profile')));

  const [arLang, setArLang] = useState(false);
  let cookieLanguage = Cookies.get('i18next');




  useEffect(() => {
    if (cookieLanguage == "ar") {
      setArLang(true);
    }
  }, [])

  const clickedFavoriteBtn = (idPost) => {
    const id = LoggedUser.result._id;

    const formData = {
      post: idPost
    };

    isFavoriteClicked(true);

    if (id !== null) {
      dispatch(addToWishlist(id, formData));
    }


  }

  const unClickedFavoriteBtn = (idPost) => {
    const userId = LoggedUser.result._id;

    // dispatch(getWishlist(userId));

    isFavoriteClicked(false);

    if (userId !== null) {
      dispatch(deleteWish(userId, idPost));
    }

  }

  return (
    <Card variant="outlined"  className={classes.root} elevation={0} >
      <Grid container>
        {(post?.bundleChosen === 'Gold') && (
          <Grid container >
            <Chip label={t("description.gold")} sx={{ mt: 1, ml: 1, backgroundColor: "#FFD700", color: "white" }} className={classes.Featured} size="small" />
          </Grid>

        )}

        {(post?.bundleChosen === 'Platinum') && (
          <Grid container >
            <Chip label={t("description.platinum")} sx={{ mt: 1, ml: 1, backgroundColor: "#E5E4E2", color: "white" }} className={classes.Featured} size="small" />
          </Grid>

        )}
      </Grid>
      {
        buttonFavoriteIsClicked ?
          (
            arLang ? (
              <IconButton aria-label="add to favorites" style={{ float: "left" }} onClick={() => unClickedFavoriteBtn(post._id)} className={classes.Favorite} >
                <FavoriteIcon sx={{ color: "red" }} />
              </IconButton>
            ) : (
              <IconButton aria-label="add to favorites" onClick={() => unClickedFavoriteBtn(post._id)} className={classes.Favorite} >
                <FavoriteIcon sx={{ color: "red" }} />
              </IconButton>
            )
          ) :
          (
            arLang ? (
              <IconButton aria-label="add to favorites" style={{ float: "left" }} className={classes.Favorite} onClick={() => clickedFavoriteBtn(post._id)}>
                <FavoriteIcon />
              </IconButton>
            ) : (
              <IconButton aria-label="add to favorites" className={classes.Favorite} onClick={() => clickedFavoriteBtn(post._id)}>
                <FavoriteIcon />
              </IconButton>
            )
          )
      }
<div onClick={openPost} style={{cursor: 'pointer'}}>
      <CardMedia
        className={classes.media}
        image={post.mainImage ? post.mainImage.url : null}
        title={apartment}
        alt={apartment}
      />

      <CardContent  style={{ textAlign: "Left" }}>
        <Typography variant="subtitle2" component="p" >
          {post.propertyType}
        </Typography>
        <Typography variant="h4" style={{ marginBottom: "5px", marginTop: "5px" }} component="p" >
          {fCurrency(post.price)}
        </Typography>
        <Typography variant="body2" component="p" noWrap>
          {post.mapPin ? post.mapPin.address : post.city}
        </Typography>
        <hr className={classes.solid} style={{ marginBottom: "10px", marginTop: "10px" }} />
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {(post?.numberOfBedrooms !== null) && (

            <Chip icon={<HotelIcon style={{ color: "#001329" }} />} label={post.numberOfBedrooms} size="small" style={{ backgroundColor: "white" }} />

          )}
          {(post?.numberOfBathrooms !== null) && (

            <Chip icon={<BathtubIcon style={{ color: "#001329" }} />} label={post.numberOfBathrooms} size="small" style={{ backgroundColor: "white" }} />

          )}
          {(post?.surfaceArea !== null) && (

            <Chip icon={<ZoomOutMapIcon style={{ color: "#001329" }} />} label={post.surfaceArea} size="small" style={{ backgroundColor: "white" }} />

          )}

        </Stack>

      </CardContent>
      </div>
    </Card>
  );
}


export default PostCard;
import { useRef, useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { alpha } from '@material-ui/core/styles';
import { Box, MenuItem, ListItemIcon, ListItemText, IconButton } from '@material-ui/core';
import MenuPopover from '../../layouts/NavbarLayout/MenuPopover';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FilterListIcon from '@material-ui/icons/FilterList';

export default function SortPopover({ setToSort, setSortByIncreasingOrder, setSortByDate }) {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const { t, i18n } = useTranslation();
    const sort = (type) => {
        handleClose();
        if (type === 'mr') {
            setToSort('true');
            setSortByDate('true');
        } else if (type === 'hp') {
            setToSort('true');
            setSortByIncreasingOrder('true');
        } else if (type === 'lp') {
            setToSort('true');
            setSortByIncreasingOrder('false');
        }
    };
    useEffect(() => {
    }, []);
    return (
        <div>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    width: 44,
                    height: 44,
                    ...(open && {
                        bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
                    })
                }}
            >
                <FilterListIcon />
            </IconButton>
            <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current}>
                <Box sx={{ py: 1 }}>
                    <MenuItem
                        key='mr'
                        onClick={handleClose, () => sort('mr')}
                        sx={{ py: 1, px: 2.5 }}
                    >
                        <ListItemIcon>
                            <ArrowUpwardIcon />
                        </ListItemIcon>
                        <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                            Most Recent
                        </ListItemText>
                    </MenuItem>
                    <MenuItem
                        key='lp'
                        onClick={handleClose, () => sort('lp')}
                        sx={{ py: 1, px: 2.5 }}
                    >
                        <ListItemIcon>
                            <ArrowDownwardIcon />
                        </ListItemIcon>
                        <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                            Lowest Price
                        </ListItemText>
                    </MenuItem>
                    <MenuItem
                        key='hp'
                        onClick={handleClose, () => sort('hp')}
                        sx={{ py: 1, px: 2.5 }}
                    >
                        <ListItemIcon>
                            <ArrowUpwardIcon />
                        </ListItemIcon>
                        <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                            Highest price
                        </ListItemText>
                    </MenuItem>
                </Box>
            </MenuPopover>
        </div>
    );
}

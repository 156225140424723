import * as React from 'react';
import { Container, Box, Grid, Typography, Alert, Snackbar, Link } from "@material-ui/core";
import forgetPassIm from '../../images/forgetPassword.svg'
import { LoadingButton } from "@material-ui/lab";
import { Link as RouterLink } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next';
import AuthLayout from '../../layouts/AuthLayout';

import { experimentalStyled as styled } from '@material-ui/core/styles';
import Page from '../../components/Page';
const RootStyle = styled(Page)(({ theme }) => ({
    paddingBottom: theme.spacing(10),
    paddingTop: theme.spacing(10)
  }));


const SuccessfulChange = () => {
    const { i18n } = useTranslation();

    return (
        <RootStyle title="Successful New Password | eAkary">
            <AuthLayout />
            <Container style={{marginTop:'10%'}}>
                <Grid  container rowSpacing={1} spacing={5} justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={6} md={5} lg={5} direction='column'  >
                        <Box
                            component="img"
                            src={forgetPassIm}
                    
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} direction='column' columnSpacing={5}>
                        <Typography variant="h3" mt={4} mb={4}>
                            <Trans i18nKey="description.passChanged">
                                Your password has been changed
                            </Trans>
                        </Typography>
                        <Link component={RouterLink} to="/auth/login">
                            <LoadingButton
                                size="large"
                                type="submit"
                                variant="contained"
                                style={{ width:"100%", marginTop: 5 }}

                            >
                                <Trans i18nKey="description.login">
                                    Login
                                </Trans>
                            </LoadingButton>
                        </Link>
                    </Grid>
                </Grid>
            </Container>
        </RootStyle>
    )
}
export default SuccessfulChange;
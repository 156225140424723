import React, { useState } from 'react';
import UserCard from "./UserCard";

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllUsers } from '../../actions/users';
import { Grid } from '@material-ui/core';
const Users = ({ setCurrentId }) => {
    //const [currentId, setCurrentId] = useState(0);
    const { users, isLoading } = useSelector((state) => state.users);  //callback funcion .posts because in the reducers we called posts

    const dispatch = useDispatch();

return (
    users.map((user) => (
      <Grid key={user._id} item xs={12} sm={6} md={4} lg={3}>
        <UserCard user={user} setCurrentId={setCurrentId} />
      </Grid>
    ))
);
}
export default Users;
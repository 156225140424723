import { useState ,useEffect} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAd , editAd } from "../../../actions/adsSection";
import Input from '../../../components/Input/Input';
import * as Yup from 'yup';
import { useFormik} from 'formik';
// material
import {Stack, Typography} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import * as React from "react";

// ----------------------------------------------------------------------

function CMSAdEdit() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openSnack, setSnackOpen] = useState(false);
  const [success,setSuccess] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const { ads } = useSelector((state) => state.ads);

  const {id} = useParams();
  useEffect( () => {
    dispatch(
        getAd(id));
}, [id,dispatch]);

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false)
  };
  const handleRegisterSubmit = async (values) => {
    setLoading(true)
    const form=
        {
          companyName: values.companyName,
          title: values.title,
          description: values.description,
          link: values.link
        }
      const success = await dispatch(editAd(id,form));
      if(success)
      {
        setLoading(false);
        navigate(`/CMS/ad/${id}`);
      }else setLoading(false)

    setSuccess(success)
    setSnackOpen(true)
  };
  const validationSchema = Yup.object({
    companyName: Yup.string()
      .required('CompanyName required'),
    title: Yup.string()
        .required('Title required'),
    description: Yup.string()
        .required('Description is required'),
    link: Yup.string()
        .required('Link is required')
  });

  const formik = useFormik({
    initialValues: {
      companyName: ads.companyName,
      title: ads.title,
      description: ads.description,
      link: ads.link
    },
    validationSchema,
    onSubmit: (values) => {
      handleRegisterSubmit(values);
    }
  });
  return (
    <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
        <Typography variant="h4" component="h2" gutterBottom> Advertisement Information</Typography>
          <Stack direction={{ xs: 'column', sm: 'row',lg:'row' }} spacing={2}>
            <div style={{width:'50%'}}>
            <Input required name="companyName" label="Company Name" handleChange={formik.handleChange} handleBlur={formik.handleBlur} autoFocus half defaultValue={ads.companyName} />
            {formik.touched.companyName && formik.errors.companyName ? <div style={{color:'red'}}>{formik.errors.companyName}</div>:null}
            </div>
            </Stack>
          <div sx={{marginBottom:20}}  style={{width:'50%'}}>
          <Input required name="title" label="Title" handleChange={formik.handleChange} handleBlur={formik.handleBlur} type="text"  defaultValue={ads.title} />
          {formik.touched.title && formik.errors.title ? <div style={{color:'red'}}>{formik.errors.title}</div>:null}
          </div>
          <div sx={{marginBottom:20}}  style={{width:'50%'}}>
          <Input required name="description" label="Description" handleChange={formik.handleChange} handleBlur={formik.handleBlur} type="text" defaultValue={ads.description}  />
          {formik.touched.description && formik.errors.description ? <div style={{color:'red'}}>{formik.errors.description}</div>:null}
          </div>
          <div sx={{marginBottom:20}}  style={{width:'50%'}}>
          <Input required name="link" label="Link" handleChange={formik.handleChange} handleBlur={formik.handleBlur} type="text" defaultValue={ads.link}  />
          {formik.touched.link && formik.errors.link ? <div style={{color:'red'}}>{formik.errors.link}</div>:null}
          </div >
          <div style={{alignContent:'center'}}>
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
          >
            Update
          </LoadingButton>
          </div>
        </Stack>
   </form>
  );
}

export default CMSAdEdit;
import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Notifications from '../../../layouts/CMSNavBarLayout/Notifications/Notifications';
import { getAllNotifications } from '../../../actions/posts';
// import Notifications from './Notifications/Notifications';
import { useDispatch } from "react-redux";
import NotificationCard from '../../../layouts/CMSNavBarLayout/Notifications/NotificationCard';

const CMS_Notification = () => {

    const [notifs, setNotifs] = useState([]);
    const dispatch = useDispatch();

    useEffect(async () => {
        const data = await dispatch(getAllNotifications());
        setNotifs(data);

    }, []);



    return (
        <>
            <Grid container>
                <Grid item md={12} sm={12} sx={12} align="center">
                    <Typography variant="h3" sx={{ marginBottom: "30px" }}>Notifications</Typography>
                    <Grid container>
                        <Grid item md={10}>
                            {
                                notifs.data !== undefined ? (
                                    notifs.data.map((notification) => {
                                        return (
                                            <NotificationCard notifs={notification} />
                                        )

                                    })
                                ) : (
                                    <p>Loading...</p>
                                )

                            }
                            {/* <Notifications notifs={notifs} /> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}

export default CMS_Notification;
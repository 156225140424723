import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import PostCards from './postCard';
import { Grid, CircularProgress } from '@material-ui/core';
import { getWishlist } from '../../actions/posts';
import PostCardLiked from './PostCardLiked';
import PostCardNoFav from './PostCardNoFav';
import { boolean } from 'yup';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const Posts = ({ setCurrentId }) => {
  const { posts, isLoading } = useSelector((state) => state.posts);  //callback funcion .posts because in the reducers we called posts

  
  const dispatch = useDispatch();

  const [LoggedUser, setLoggedUser] = useState(JSON.parse(localStorage.getItem('profile')));

  const [wishlist, setWishlist] = useState([]);

  useEffect(async () => {
    // const id = LoggedUser.result._id;

    if (LoggedUser !== null) {
      const id = LoggedUser.result._id;
      
      const wishlist = await dispatch(getWishlist(id));
      setWishlist(wishlist);
    }

  }, [dispatch]);




  if (!posts.length && !isLoading) return 'No Posts';

  return (
    isLoading ? <div class="d-flex justify-content-center">
      <CircularProgress />
    </div>
      : (  //if posts.length is 0 then show progress else showgrid
        <>
        
          <Grid container spacing={3} justifyContent="center">

            {
              // posts.map((post) => (
              //   <Grid key={post._id} item xs={12} sm={6} md={4} lg={3}>
              //     <PostCards post={post} setCurrentId={setCurrentId} />
              //   </Grid>
              // ))


              (LoggedUser !== null) && (LoggedUser.result.role !== 1) ? (

                wishlist.length === 0 ? (
                  posts.map((post) => {
                      const dateNow = new Date();
                      const expiredAt = Date.parse(post.expireAt);
                      if(expiredAt !== undefined) {
                          if(dateNow>expiredAt) {
                              return null
                          }else {
                              return (
                                  <Grid key={post._id} item xs={12} sm={6} md={4} lg={3}>
                                      <PostCards post={post} setCurrentId={setCurrentId}/>
                                  </Grid>
                              )
                          }
                      }else return (
                          <Grid key={post._id} item xs={12} sm={6} md={4} lg={3}>
                              <PostCards post={post} setCurrentId={setCurrentId}/>
                          </Grid>
                      )
                  })
                ) :
                  (
                    posts.map((post) => {
                        const dateNow = new Date();
                        const expiredAt = Date.parse(post.expireAt);
                        if(expiredAt !== undefined)
                        {
                            if(dateNow>expiredAt)
                            {
                                return null
                            }else {
                                if (JSON.stringify(wishlist).includes(post._id)) {
                                    return (
                                        <Grid key={post._id} item xs={12} sm={6} md={4} lg={3}>
                                            <PostCardLiked post={post} setCurrentId={setCurrentId}/>
                                        </Grid>
                                    )
                                } else {
                                    return (
                                        <Grid key={post._id} item xs={12} sm={6} md={4} lg={3}>
                                            <PostCards post={post} setCurrentId={setCurrentId}/>
                                        </Grid>
                                    )
                                }
                            }
                    }else{
                            if (JSON.stringify(wishlist).includes(post._id)) {
                                return (
                                    <Grid key={post._id} item xs={12} sm={6} md={4} lg={3}>
                                        <PostCardLiked post={post} setCurrentId={setCurrentId}/>
                                    </Grid>
                                )
                            } else {
                                return (
                                    <Grid key={post._id} item xs={12} sm={6} md={4} lg={3}>
                                        <PostCards post={post} setCurrentId={setCurrentId}/>
                                    </Grid>
                                )
                            }
                        }
                    })
                  )
              ) :
                (
                  posts.map((post) => {
                      const dateNow = new Date();
                      const expiredAt = Date.parse(post.expireAt);
                      if(expiredAt !== undefined) {
                          if(dateNow>expiredAt) {
                              return null
                          }else {
                              return (
                                  <Grid key={post._id} item xs={12} sm={6} md={4} lg={3}>
                                      <PostCardNoFav post={post} setCurrentId={setCurrentId}/>
                                  </Grid>
                              )
                          }
                      }else return (
                          <Grid key={post._id} item xs={12} sm={6} md={4} lg={3}>
                              <PostCardNoFav post={post} setCurrentId={setCurrentId}/>
                          </Grid>
                      )
                  })
                )


            }

          </Grid>
        </>
      )
  );
};

export default Posts;

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import React, { useState, useEffect } from 'react';
import 'typeface-roboto'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Box from '@material-ui/core/Box'
import { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom'
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Trans, useTranslation } from 'react-i18next';

import Fade from 'react-reveal/Fade';

const NewServicesCards = () => {
    const { t, i18n } = useTranslation();

    const ProductImgStyle = styled('img')({
        top: 0,
        width: '100%',
        height: '100%',
        objectFit: 'fill',
        position: 'absolute'
    });

    const ProductImgStyle2 = styled('img')({
        top: 0,
        left: 10,
        width: '90%',
        height: '90%',
        objectFit: 'fill',
        position: 'absolute'
    });

    const RootStyle = styled('div')(({ theme }) => ({
        [theme.breakpoints.down("xs")]: {
            maxWidth: 274,
            marginLeft: 0,
            marginTop: -10
        }, [theme.breakpoints.up("lg")]: {
            maxWidth: 280,
            boxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
            marginLeft: -40
        }
    }));

    return (
        <Grid container spacing={2}>


            <Grid item xs={12} sm={6} md={4} >
                <Fade left>
                    <Card>
                        <Box sx={{ pt: '65%', position: 'relative' }}>
                            <ProductImgStyle
                                alt="Service"
                                src="/static/illustrations/buy_service.svg"
                                title="Service"
                            />
                        </Box>
                        <CardContent>
                            <Typography gutterBottom align="center" variant="h5" component="h2">
                                {t("description.buyPage")}
                            </Typography>
                            <Typography align="center" variant="body1" color="textSecondary" component="p">
                                {t("description.buyDesc")}
                            </Typography>
                            <br />
                            <CardActionArea component={RouterLink} sx={{ width: 'fit-content', margin: '0 auto' }} to="/posts" >
                                <Typography sx={{
                                    textDecoration: 'none',
                                    fontSize: '0.875rem',
                                    position: 'relative',
                                    transition: 'all 0.6s',
                                    color: '#1890ff',
                                    '&:before': {
                                        content: '""',
                                        width: 0,
                                        height: '0.05em',
                                        position: 'absolute',
                                        borderRadius: '2px',
                                        bottom: '-5px',
                                        left: '50%',
                                        background: '#1890ff',
                                        transition: 'all 0.3s'
                                    },
                                    "&:hover": {
                                        "&:before": {
                                            width: '100%',
                                            left: 0,
                                            background: '#1890ff'
                                        }

                                    }
                                }} align="center" variant="button" color="primary" component="p">
                                    {t("description.searchHomes")}
                                </Typography>
                            </CardActionArea>
                        </CardContent>

                    </Card>
                </Fade>
            </Grid>


            <Grid item xs={12} sm={6} md={4} >
                <Fade top>
                    <Card>
                        <Box sx={{ pt: '65%', position: 'relative' }}>
                            <ProductImgStyle2
                                alt="Service"
                                src="/static/illustrations/rent_service.svg"
                                title="Service"
                            />
                        </Box>
                        <CardContent>
                            <Typography gutterBottom align="center" variant="h5" component="h2">
                                {t("description.rentPage")}
                            </Typography>
                            <Typography align="center" variant="body1" color="textSecondary" component="p">
                                {t("description.rentDesc")}
                            </Typography>
                            <br />
                            <CardActionArea component={RouterLink} sx={{ width: 'fit-content', margin: '0 auto' }} to="/posts" >
                                <Typography sx={{
                                    textDecoration: 'none',
                                    fontSize: '0.875rem',
                                    position: 'relative',
                                    transition: 'all 0.6s',
                                    color: '#1890ff',
                                    '&:before': {
                                        content: '""',
                                        width: 0,
                                        height: '0.05em',
                                        position: 'absolute',
                                        borderRadius: '2px',
                                        bottom: '-5px',
                                        left: '50%',
                                        background: '#1890ff',
                                        transition: 'all 0.3s'
                                    },
                                    "&:hover": {
                                        "&:before": {
                                            width: '100%',
                                            left: 0,
                                            background: '#1890ff'
                                        }

                                    }
                                }} align="center" variant="button" color="primary" component="p">
                                    {t("description.rentHomes")}
                                </Typography>
                            </CardActionArea>
                        </CardContent>
                    </Card>
                </Fade>
            </Grid>

            <Grid item xs={12} sm={6} md={4} >
                <Fade right>
                    <Card>
                        <Box sx={{ pt: '65%', position: 'relative' }}>
                            <ProductImgStyle
                                alt="Service"
                                src="/static/illustrations/sell_service.svg"
                                title="Service"
                            />
                        </Box>
                        <CardContent>
                            <Typography gutterBottom align="center" variant="h5" component="h2" >
                                {t("description.sellPage")}
                            </Typography>
                            <Typography align="center" variant="body1" color="textSecondary" component="p">
                                {t("description.sellDesc")}
                            </Typography>
                            <br />
                            <CardActionArea component={RouterLink} sx={{ width: 'fit-content', margin: '0 auto' }} to="/createpost" >
                                <Typography sx={{
                                    textDecoration: 'none',
                                    fontSize: '0.875rem',
                                    position: 'relative',
                                    transition: 'all 0.6s',
                                    color: '#1890ff',
                                    '&:before': {
                                        content: '""',
                                        width: 0,
                                        height: '0.05em',
                                        position: 'absolute',
                                        borderRadius: '2px',
                                        bottom: '-5px',
                                        left: '50%',
                                        background: '#1890ff',
                                        transition: 'all 0.3s'
                                    },
                                    "&:hover": {
                                        "&:before": {
                                            width: '100%',
                                            left: 0,
                                            background: '#1890ff'
                                        }

                                    }
                                }} align="center" variant="button" color="primary" component="p">
                                    {t("description.listProp")}
                                </Typography>
                            </CardActionArea>
                        </CardContent>
                    </Card>
                </Fade>
            </Grid>
        </Grid>
    )
}

export default NewServicesCards;
import { START_LOADING, END_LOADING, FETCH_ALL, FETCH_ALL_POSTS, FETCH_POST, FETCH_USER_POSTS, CREATE, UPDATE, DELETE, LIKE, ADD_TO_WISHLIST, GET_WISHLIST, REMOVE_FROM_WISHLIST, FETCH_USER, FETCH_BY_SEARCH } from '../constants/actionTypes';
import * as api from '../api/index.js';

export const getAllUsers = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchAllUsers();

    dispatch({ type: FETCH_ALL, payload: { data } });
    dispatch({ type: END_LOADING });

    return data;
  } catch (error) {
  }
};

export const getAllAgencies = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchAllAgencies();

    dispatch({ type: FETCH_ALL, payload: { data } });
    dispatch({ type: END_LOADING });

    return data;
  } catch (error) {
  }
};


export const getUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const { data } = await api.fetchUser(id);
    dispatch({ type: FETCH_USER, payload: { user: data } });
    return data;
  } catch (error) {
  }
};

export const deleteUser = (id) => async (dispatch) => {
  try {
    await await api.deleteUser(id);

    dispatch({ type: DELETE, payload: id });
  } catch (error) {
  }
};

export const deleteUserAcc = (id) => async (dispatch) => {
  try {
    await api.deleteUserAcc(id);

    dispatch({ type: DELETE, payload: id });
  } catch (error) {
  }
};

export const getUsersBySearch = (searchQuery) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data: { data } } = await api.fetchUsersBySearch(searchQuery);

    dispatch({ type: FETCH_BY_SEARCH, payload: { data } });
    dispatch({ type: END_LOADING });
  } catch (error) {
  }
};

import { Icon } from '@iconify/react';
import lockFill from '@iconify-icons/eva/lock-fill';
import i18n from 'i18next';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    key: 'posts',
    title: "Posts",
    path: '/posts',
  },
  {
    key: 'addListingPage',
    title: "Add Listing",
    path: '/createpost',
  },
  {
    key: 'virtualTour',
    title: "Virtual Tour",
    path: '/request360',
  },
  {
    key: 'privacyPol',
    title: "Privacy Policy",
    path: '/privacypolicy',
  },
  {
    key: 'tos',
    title: "Terms of Use",
    path: '/termsofservice',
  },
  {
    title: "FAQ",
    path: '/faq',
  },
  {
    key: 'contactUs',
    title: "Contact Us",
    path: '/contact',
  }
];

export default sidebarConfig;

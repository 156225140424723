import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import UseStyles from './styles';
import HotelIcon from '@material-ui/icons/Hotel';
import BathtubIcon from '@material-ui/icons/Bathtub';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import Chip from '@material-ui/core/Chip';
import Stack from '@material-ui/core/Stack';
import StarsIcon from '@material-ui/icons/Stars';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { borders } from '@material-ui/system';
import apartment from "../../images/apartment.jpg";
import { fCurrency } from '../../utils/formatNumber';
import { Trans, useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import 'boxicons';
const FeaturedPostCard = ({ post, setCurrentId }) => {
  const user = JSON.parse(localStorage.getItem('profile'));
  const navigate = useNavigate();
  const isMobileScreen = useMediaQuery('max-width:600px')
  const isTabletScreen = useMediaQuery('(max-width:1070px)');
  const openPost = (e) => {
    // dispatch(getPost(post._id, navigate));
    navigate(`/posts/${post._id}`);
  };
  const classes = UseStyles();
  const { t, i18n } = useTranslation();
  return (
    <Card variant="outlined" onClick={openPost} style={{ cursor: 'pointer' }} sx={{
      minWidth: isMobileScreen ? '10rem' : isTabletScreen ? '14rem' : '18rem',
      maxWidth: isMobileScreen ? '10rem' : isTabletScreen ? '14rem' : '18rem',
    }} className={classes.root} elevation={0}>
      <Grid container>
        {(post?.bundleChosen === 'Gold') && (
          <Grid container >
            <Chip label={t("description.gold")} sx={{ mt: 1, ml: 1, backgroundColor: "gold", color: "black" }} className={classes.Featured} size="small" />
          </Grid>
        )}
        {(post?.bundleChosen === 'Platinum') && (
          <Grid container >
            <Chip label={t("description.platinum")} sx={{ mt: 1, ml: 1, backgroundColor: "gray", color: "white" }} className={classes.Featured} size="small" />
          </Grid>
        )}
      </Grid>
      <CardMedia
        className={classes.media}
        image={post.mainImage ? post.mainImage.url : null}
        title={apartment}
        alt={apartment}
      >
      </CardMedia>
      <CardContent style={{ textAlign: "Left" }}>
        <Typography variant="subtitle2" component="p" >
          {post.propertyType}
        </Typography>
        <Typography variant="h4" style={{ marginBottom: "5px", marginTop: "5px" }} component="p" >
          {fCurrency(post.price)}
        </Typography>
        <Typography variant="body2" component="p" noWrap>
          {post.mapPin ? post.mapPin.address : post.city}
        </Typography>
        <hr className={classes.solid} style={{ marginBottom: "10px", marginTop: "10px" }} />
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {(post?.numberOfBedrooms !== null) && (
            <Chip icon={<HotelIcon style={{ color: "#001329" }} />} label={post.numberOfBedrooms} size="small" style={{ backgroundColor: "white" }} />
          )}
          {(post?.numberOfBathrooms !== null) && (
            <Chip icon={<BathtubIcon style={{ color: "#001329" }} />} label={post.numberOfBathrooms} size="small" style={{ backgroundColor: "white" }} />
          )}
          {(post?.surfaceArea !== null) && (
            <Chip icon={<ZoomOutMapIcon style={{ color: "#001329" }} />} label={post.surfaceArea} size="small" style={{ backgroundColor: "white" }} />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
export default FeaturedPostCard;
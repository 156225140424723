import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import { useDispatch } from "react-redux";
import useState from "react-hook-use-state";
// ----------------------------------------------------------------------
Logo.propTypes = {
  sx: PropTypes.object
};
const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});
export default function Logo({ sx }) {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('profile'))
  const Click = () => {
    if (user?.result?.role === 1) {
      navigate("/CMS/landingAdmin");
    }
    else
      navigate("/");
  };
  return <Img onClick={Click} src="/static/logo2.png" sx={{ width: 70, height: 60, cursor: 'pointer', ...sx }} />;
}
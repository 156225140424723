import { makeStyles } from '@material-ui/styles';

const UseStyles = makeStyles((theme) => {
  return ({
    root: {
      '&:hover': {
        boxShadow: " 4px 7px 17px -11px rgba(66,66,66,0.75)",
      }
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },

    solid: {
      borderTop: "0px solid #bbb",
    },

    Featured: {
      position: 'absolute',
    }

  })
});

export default UseStyles
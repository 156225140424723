import { AUTH } from '../constants/actionTypes';
import * as api from '../api/index.js';



export const signin = (formData) => async (dispatch) => {
    try {
        const { data } = await api.signIn(formData);

        dispatch({ type: AUTH, data });
        if (data.result.role === 1) {
            return "admin";
        }
        else
            return "user";
    } catch (error) {
        return false
    }
};

export const signup = (formData) => async (dispatch) => {
    try {
        const { data } = await api.signUp(formData);

        dispatch({ type: AUTH, data });

        return true

    } catch (error) {

        return false
    }
};

export const emailConfirmation = (formData) => async (dispatch) => {
    try {
        const { data } = await api.emailConfirmation(formData);

        dispatch({ type: AUTH, data });

        return true

    } catch (error) {

        return false
    }
};

export const createAgency = (formData) => async (dispatch) => {
    try {
        const { data } = await api.createAgency(formData);

        // dispatch({ type: AUTH, data });

        return true

    } catch (error) {

        return false
    }
};

export const updateUser = (id, updatedUser) => async (dispatch) => {
    try {
        const { data } = await api.updateUser(id, updatedUser);
        dispatch({ type: AUTH, data });


        return true

    } catch (error) {
        return false
    }
};

export const updateUserPassword = (id, updatedUserPassword) => async (dispatch) => {
    try {
        const { data } = await api.updateUserPassword(id, updatedUserPassword);
        dispatch({ type: AUTH, data });

        return true

    } catch (error) {
        return false
    }
};
export const deleteAccount = (id) => async (dispatch) => {
    try {
        const { data } = await api.deleteAccount(id);
        dispatch({ type: AUTH, data });
        return true

    } catch (error) {
        return false
    }
};

export const forgetPasswordEmail = (formData) => async (dispatch) => {
    try {
        const { data } = await api.forgetPasswordEmail(formData);
        dispatch({ type: AUTH, data });
        return true

    } catch (error) {
        return false
    }
}

export const resetPassword = (formData) => async (dispatch) => {
    try {
        const { data } = await api.resetPassword(formData);
        dispatch({ type: AUTH, data });
        return true

    } catch (error) {
        return false
    }
}

export const facebooklogin = (formData, history) => async (dispatch) => {
    try {
        const { data } = await api.facebooklogin(formData);

        dispatch({ type: AUTH, data });
        return data;
    } catch (error) {
    }
};

export const googlelogin = (formData) => async (dispatch) => {
    try {
        const { data } = await api.googlelogin(formData);

        dispatch({ type: AUTH, data });
        return data;
    } catch (error) {
    }
};
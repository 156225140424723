import * as React from 'react';
import { Container, Box, Grid, Typography, Alert, Snackbar, Stack } from "@material-ui/core";
import { styled } from '@material-ui/core/styles';
import { useParams } from "react-router-dom";
import ProfileField from "../../components/TextFields/TextField";
import forgetPassIm from '../../images/forgetPassword.svg'
import useState from "react-hook-use-state";
import { LoadingButton } from "@material-ui/lab";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import AuthLayout from '../../layouts/AuthLayout';
import { resetPassword } from "../../actions/auth";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

import { Trans, useTranslation } from 'react-i18next';

const RootStyle = styled('section')({
    display: "flex"
});
const NewPassword = () => {
    const dispatch = useDispatch();
    const { token } = useParams();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [success, setSuccess] = useState(undefined);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleShowNewPassword = () => setShowNewPassword(!showNewPassword);

    const minLenght = 8;
    const Upper = /^(?=.*[A-Z])/;
    const Numb = /^(?=.*[0-9])/;
    const SpecialChar = /^(?=.*[@#$%^&+=])/

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false)
    };
    const handleSubmit = async (values) => {
        setLoading(true)
        const form =
        {
            resetLink: token,
            newPassword: values.newPassword,
            confirmPassword: values.confirmPassword
        }
        const success = await dispatch(resetPassword(form));
        setSuccess(success)
        setOpen(true)
        if (success) {
            setLoading(false)
            navigate('/auth/successfulChange');
        } else setLoading(false)
    };
    const validationSchema = Yup.object({
        newPassword: Yup.string()
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                `Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character`
            )
            .required('Required!'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Password must match')
            .required('Required!')
    })
    const formik = useFormik({
        initialValues: {
            newPassword: '',
            confirmPassword: ''
        },
        onSubmit: values => {
            handleSubmit(values)
        },
        validationSchema,
    }
    )

    //////////////////////////////////////
    const { t, i18n } = useTranslation();

    return (
        <RootStyle title="New Password | eAkary">
            <AuthLayout />
            <Container style={{marginTop:'10%'}}>
                <Grid container rowSpacing={1} spacing={5} justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={6} md={5} lg={5} direction='column'  >
                        <Box
                            component="img"
                            src={forgetPassIm}
                            sx={{ height: 250, mx: 'auto', my: { xs: 5, sm: 10 } }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} direction='column' columnSpacing={5}>
                        <Typography variant="h3" mt={4} mb={4}>
                            <Trans i18nKey="description.changePass">
                                Change your password
                            </Trans>
                        </Typography>
                        <form onSubmit={formik.handleSubmit}>
                            <Stack spacing={3}>
                                <div style={{ marginBottom: 20 }}>
                                    <ProfileField onBlur={formik.handleBlur} handleChange={formik.handleChange} value={formik.values.newPassword} name='newPassword' type={showNewPassword ? 'text' : 'password'} handleShowPassword={handleShowNewPassword} placeholder={t("description.urNP")} label={t("description.urNP")} />
                                    {formik.values.newPassword.length >= minLenght ? (
                                        <>
                                            <CheckCircleIcon id='character_Check' sx={{ color: 'green' }} fontSize='s' />
                                            <span style={{ color: 'green', fontSize: '15px' }}>
                                                <Trans i18nKey="description.8char">
                                                    At least 8 characters
                                                </Trans>
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <CancelIcon id='character_Uncheck' sx={{ color: 'red'}} fontSize='s' />
                                            <span style={{ color: 'red', fontSize: '15px' }}>
                                                <Trans i18nKey="description.8char">
                                                    At least 8 characters
                                                </Trans>
                                            </span>
                                        </>
                                    )
                                    }
                                    <br />
                                    {
                                        Numb.test(formik.values.newPassword) ? (
                                            <>
                                                <CheckCircleIcon id='number_Check' sx={{ color: 'green' }} fontSize='s' />
                                                <span style={{ color: 'green', fontSize: '15px' }} >
                                                    <Trans i18nKey="description.1Nbr">
                                                        At least 1 number
                                                    </Trans>
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <CancelIcon id='number_Uncheck' sx={{ color: 'red'}} fontSize='s' />
                                                <span style={{ color: 'red', fontSize: '15px' }} >
                                                    <Trans i18nKey="description.1Nbr">
                                                        At least 1 number
                                                    </Trans>
                                                </span>
                                            </>
                                        )
                                    }
                                    <br />
                                    {
                                        Upper.test(formik.values.newPassword) ? (
                                            <>
                                                <CheckCircleIcon id='special_Check' sx={{ color: 'green' }} fontSize='s' />
                                                <span style={{ color: 'green', fontSize: '15px' }} >
                                                    <Trans i18nKey="description.1Uper">
                                                        At least 1 uppercase letter
                                                    </Trans>
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <CancelIcon id='special_Uncheck' sx={{ color: 'red' }} fontSize='s' />
                                                <span style={{ color: 'red', fontSize: '15px' }} >
                                                    <Trans i18nKey="description.1Uper">
                                                        At least 1 uppercase letter
                                                    </Trans>
                                                </span>
                                            </>
                                        )
                                    }
                                    <br />
                                    {
                                        SpecialChar.test(formik.values.newPassword) ? (
                                            <>
                                                <CheckCircleIcon id='upper_Check' sx={{ color: 'green' }} fontSize='s' />
                                                <span style={{ color: 'green', fontSize: '15px' }} >
                                                    <Trans i18nKey="description.1Spec">
                                                        At least 1 special character
                                                    </Trans>
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <CancelIcon id='upper_Uncheck' sx={{ color: 'red' }} fontSize='s' />
                                                <span style={{ color: 'red', fontSize: '15px' }} >
                                                    <Trans i18nKey="description.1Spec">
                                                        At least 1 special character
                                                    </Trans>
                                                </span>
                                            </>
                                        )
                                    }
                                </div>
                                <div style={{ marginBottom: 20 }}>
                                    <ProfileField onBlur={formik.handleBlur} handleChange={formik.handleChange} value={formik.values.confirmPassword} name='confirmPassword' type='password' placeholder={t("description.confurNP")} label={t("description.confurNP")} />
                                    {
                                        formik.values.confirmPassword === formik.values.newPassword
                                            &&
                                            formik.values.confirmPassword !== '' ? (
                                            <>
                                                <CheckCircleIcon id='upper_Check' sx={{ color: 'green' }} fontSize='s' />
                                                <span style={{ color: 'green', fontSize: '15px' }} >
                                                    <Trans i18nKey="description.passMatch">
                                                        Password matches
                                                    </Trans>
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <CancelIcon id='special_Uncheck' sx={{ color: 'red' }} fontSize='s' />
                                                <span style={{ color: 'red', fontSize: '15px' }} >
                                                    <Trans i18nKey="description.passMatch">
                                                        Password matches
                                                    </Trans>
                                                </span>
                                            </>
                                        )
                                    }
                                </div>
                            </Stack>
                            <LoadingButton
                                size="large"
                                type="submit"
                                variant="contained"
                                style={{ width: "100%",marginTop: 5 }}
                                loading={loading}

                            >
                                <Trans i18nKey="description.resetPass">
                                    Reset Password
                                </Trans>
                            </LoadingButton>
                        </form>
                    </Grid>
                </Grid>
                {
                    success !== undefined ? (
                        success ? null
                            : (
                                <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                                    <Alert onClose={handleSnackClose} severity="error">
                                        <Trans i18nKey="description.sessionExp">
                                            Session Has Expired please resend your email
                                        </Trans>
                                    </Alert>
                                </Snackbar>
                            )) : null
                }
            </Container>
        </RootStyle>
    )
}
export default NewPassword;
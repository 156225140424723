import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  ul: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
}));

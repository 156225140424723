import { Link as RouterLink } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Card, Link, Container, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
// layouts
import AuthLayout from '../../layouts/AuthLayout';

// components
import { MHidden } from '../../components/@material-extend';
import RegisterForm from '../../components/Auth/RegisterForm';
import AuthSocial from '../../components/Auth/AuthSocial';
import Page from '../../components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Register() {
  const { i18n } = useTranslation();

  return (
    <RootStyle title="Register | eAkary">
      <AuthLayout />

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 4, mt: 10, mb: 5 }}>
            <Trans i18nKey="description.find">
              Find your property with eAkary
            </Trans>
          </Typography>
          <img alt="register" src="/static/illustrations/register.svg" />
        </SectionStyle>
      </MHidden>

      <Container>
        <ContentStyle>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h4" gutterBottom>
              <Trans i18nKey="description.getStartedFree">
                Get started absolutely free.
              </Trans>
            </Typography>
          </Box>

          <AuthSocial />

          <RegisterForm />
          <Typography variant="subtitle2" sx={{ mt: 3, textAlign: 'center' }}>
            <Trans i18nKey="description.haveAcc">
              Already have an account?&nbsp;
            </Trans>
            &nbsp;&nbsp;
            <Link to="/auth/login" component={RouterLink}>
              <Trans i18nKey="description.loginPage">
                Login
              </Trans>
            </Link>
          </Typography>

          <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
            <Trans i18nKey="description.byReg">
              By registering, I agree to eAkary&nbsp;
            </Trans>
            &nbsp;
            <Link component={RouterLink} to="/termsofservice" underline="always" sx={{ color: 'text.primary' }}>
              <Trans i18nKey="description.tos">
                Terms of Service
              </Trans>
            </Link>
            &nbsp;<Trans i18nKey="description.and">and</Trans>&nbsp;
            <Link component={RouterLink} to="/privacypolicy" underline="always" sx={{ color: 'text.primary' }}>
              <Trans i18nKey="description.privacyPol">
                Privacy Policy
              </Trans>
            </Link>
            .
          </Typography>


        </ContentStyle>
      </Container>
    </RootStyle>
  );
}

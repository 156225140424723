import React, { useState } from 'react';
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import { Trans, useTranslation } from 'react-i18next';
import { deleteUserAcc } from '../../actions/users';
import { Container, Box, Grid, Typography, Alert, Snackbar, Slide } from "@material-ui/core";

const DialogDeleteUser = ({ open, id, close }) => {
    const dispatch = useDispatch();
    const [success, setSuccess] = useState(false);
    const [openSnack, setSnackOpen] = useState(false);

    const deleteUser = (id) => {
        const success = dispatch(deleteUserAcc(id));

        if (success) {
            setSuccess(true);
            
            setSnackOpen(true);
            setTimeout(function() {
                window.location.reload();
            }, 1000);
        }
        else {
            setSnackOpen(false);
        }

        

    };

    const { i18n } = useTranslation();
    // const [openSnack, setSnackOpen] = useState(false);

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    return (
        <>
            <Dialog
                open={open}
                keepMounted
                onClose={close}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Delete Post?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Trans i18nKey="description.deleteUser">
                            Are you sure you want to delete this user as there is no going back
                        </Trans>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} color="primary">
                        <Trans i18nKey="description.cancel">
                            Cancel
                        </Trans>
                    </Button>
                    <Button onClick={() => deleteUser(id)} color="primary">
                        <Trans i18nKey="description.delete">
                            Delete
                        </Trans>
                    </Button>
                </DialogActions>
            </Dialog>
            {
                success ? (
                    <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                        <Alert onClose={handleSnackClose} severity="success">
                            <Trans>
                                User Deleted successfully
                            </Trans>
                        </Alert>
                    </Snackbar>
                ) : (
                    <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                        <Alert onClose={handleSnackClose} severity="error">
                            <Trans i18nKey="description.wefound">
                                Couldn't Delete User
                            </Trans>
                        </Alert>
                    </Snackbar>
                )
            }
        </>
    )
}
export default DialogDeleteUser;
import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';

// ----------------------------------------------------------------------
LogoWhite.propTypes = {
  sx: PropTypes.object
};

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});



export default function LogoWhite({ sx }) {
  const navigate = useNavigate()
  const Click = () => {
    navigate("/")
  };
  return <Img onClick={Click} src="/static/logo.png" sx={{ minWidth: 150, minHeight: 145, maxWidth: 160,maxHeight:153, cursor: 'pointer' , ...sx }} />;
}
import { UPDATE_AD,START_LOADING,FETCH_SPECIFIC_AD, END_LOADING, FETCH_ALL, FETCH_AD, FETCH_BY_SEARCH, CREATE, UPDATE, DELETE, LIKE, ADD_TO_WISHLIST, GET_WISHLIST, REMOVE_FROM_WISHLIST } from '../constants/actionTypes';
import * as api from '../api/index.js';


export const getAllAds = () => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.fetchAllAds();
        
      dispatch({ type: FETCH_ALL, payload: { data } });
      dispatch({ type: END_LOADING });

      return data;
    } catch (error) {
    }
  };


  export const getSpecificAd = (id) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
  
      const { data: { data } } = await api.fetchSpecificAd(id);
  
      dispatch({ type: FETCH_SPECIFIC_AD, payload: {data} });
  
      dispatch({ type: END_LOADING });
  
      return data;
    } catch (error) {
    }
  };
  


  export const getAd = (id) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.fetchAd(id);
      dispatch({ type: FETCH_AD, payload: { ad : data } });
      dispatch({ type: END_LOADING });
      return data;
    } catch (error) {
    }
  };
  
  
export const createAd = (ad) => async (dispatch) => {

  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.createAd(ad);
    dispatch({ type: CREATE, payload: data });
    dispatch({ type: END_LOADING });
    return true;
  } catch (error) {
    return false;
  }
};

export const deleteAd = (id) => async (dispatch) => {
  try {
    await api.deleteAd(id);

    dispatch({ type: DELETE, payload: id });
  } catch (error) {
  }
};
export const editAd = (id, ad) => async (dispatch) => {
  try {
    const { data } = await api.editAd(id, ad);
    dispatch({ type: UPDATE_AD, payload: data });

    return true;
  } catch (error) {
    return false;
  }
};


import React, { useState } from 'react';
import AdImgCard from "./AdImgCard";

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllAds } from '../../actions/adsSection';
import { Grid, Typography } from '@material-ui/core';
import { getAllPostImgAds } from '../../actions/ImgAd';



const ImgAds = ({ setCurrentId }) => {

  const dispatch = useDispatch();
  const [img, setimg] = useState([]);

  useEffect(async () => {
    const img = await dispatch(getAllPostImgAds(setCurrentId));
    setimg(img);
  }, [dispatch]);

  //const [currentId, setCurrentId] = useState(0);
  // const { imgads, isLoading } = useSelector((state) => state.imgads) || {};  //callback funcion .posts because in the reducers we called posts

  return (
    img.map((imgad) => (
      <Grid key={imgad._id} item xs={12} sm={6} md={4} lg={3}>
        <AdImgCard imgad={imgad} setCurrentId={setCurrentId} />
      </Grid>
    ))
  );
}

export default ImgAds;
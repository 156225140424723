import { Grid, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import Posts from '../../components/postCards/Posts';
import WishlistCard from '../../components/Wishlist/WishlistCard';
import { useDispatch, useSelector } from "react-redux";
import { getWishlist } from '../../actions/posts';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Trans, useTranslation } from 'react-i18next';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import Page from '../../components/Page';
const RootStyle = styled(Page)(({ theme }) => ({
    paddingBottom: theme.spacing(10)

}));
const WishList = () => {
    // const classes = UseStyles();

    const dispatch = useDispatch();

    // const { wishlist, isLoading } = useSelector((state) => state.wish);
    // const { wishlistItems } = wishlist;
    const [LoggedUser, setLoggedUser] = useState(JSON.parse(localStorage.getItem('profile')));

    const [wishlist, setWishlist] = useState([]);

    useEffect(async () => {
        const id = LoggedUser.result._id;
        const wishlist = await dispatch(getWishlist(id));
        setWishlist(wishlist);

    }, [dispatch]);

    const { t, i18n } = useTranslation();



    return (

        <RootStyle title="Saved Listings | eAKary">
            <Typography component="h3" variant="h3" align="center">
                <Trans i18nKey="description.savedProperties">
                    Saved Properties
                </Trans>
            </Typography>

            {(!wishlist.length) && (

                <Grid container mt={5} justifyContent="center" alignItems="center">
                    <Typography component="subtitle1" variant="subtitle1" align="center">
                        No Wishlisted Posts
                    </Typography>

                </Grid>
            )}
            <Grid container spacing={3} justifyContent="center" sx={{ marginTop: "30px" }}>
                {
                    wishlist.map((wish) => (
                        wish.wishlistItems.map((item) => {
                            return (
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <WishlistCard wishlist={wish.wishlistItems} postId={item.post} />
                                </Grid>
                            )
                        })
                    ))
                }
            </Grid>


        </RootStyle>

        // )

    );
}

export default WishList;
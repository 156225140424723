import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { createAgency, signup } from "../../../actions/auth";
import Input from '../../../components/Input/Input';
import * as Yup from 'yup';
import { useFormik } from 'formik';
// material
import { Alert, Snackbar, Stack } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import * as React from "react";
import { Trans, useTranslation } from 'react-i18next';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// ----------------------------------------------------------------------

function CMS_Add_Agency() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [openSnack, setSnackOpen] = useState(false);
    const [success, setSuccess] = useState(undefined);
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleShowPassword = () => setShowPassword(!showPassword);

    const minLenght = 8;
    const Upper = /^(?=.*[A-Z])/;
    const Numb = /^(?=.*[0-9])/;
    const SpecialChar = /^(?=.*[@#$%^&+=])/

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false)
    };
    const handleRegisterSubmit = async (values) => {
        setLoading(true)
        const form =
        {
            email: values.email,
            password: values.password,
            firstName: values.firstName,
            lastName: values.lastName,
            confirmPassword: values.confirmPassword
        }
        const success = await dispatch(createAgency(form));

        if (success) {
            setLoading(false);
        } else setLoading(false)

        setSuccess(success)
        setSnackOpen(true)
    };
    const validationSchema = Yup.object({
        firstName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('First name required'),
        lastName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Last name required'),
        email: Yup.string()
            .email('Email must be a valid email address')
            .required('Email is required'),
        password: Yup.string()
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
            )
            .required('Password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Password must match')
            .required('Required!')
    });

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: ''
        },
        validationSchema,
        onSubmit: (values) => {
            handleRegisterSubmit(values);
        }
    });

    //////////////////////////////////////
    const { t, i18n } = useTranslation();

    return (
        <Grid container>
            <Grid item md={12} align="center">
                <Typography variant="h3">Create Agency</Typography>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container>
                        <Grid item md={6} sm={12}>
                            <div style={{ width: 'auto', width: "80%", marginTop: "50px", marginBottom: "35px" }}>
                                <Input required name="firstName" label={t("description.fn")} handleChange={formik.handleChange} handleBlur={formik.handleBlur} autoFocus half />
                                {formik.touched.firstName && formik.errors.firstName ? <div style={{ color: 'red' }}>{formik.errors.firstName}</div> : null}
                            </div>
                            <div style={{ width: 'auto', width: "80%", marginBottom: "35px" }}>
                                <Input required name="lastName" label={t("description.ln")} handleChange={formik.handleChange} handleBlur={formik.handleBlur} half />
                                {formik.touched.lastName && formik.errors.lastName ? <div style={{ color: 'red' }}>{formik.errors.lastName}</div> : null}
                            </div>
                            <div style={{ marginBottom: 20, width: "80%" }}>
                                <Input required name="email" label={t("description.emailAdd")} handleChange={formik.handleChange} handleBlur={formik.handleBlur} type="email" />
                                {formik.touched.email && formik.errors.email ? <div style={{ color: 'red' }}>{formik.errors.email}</div> : null}
                            </div>
                            {
                                success !== undefined ? (
                                    success ? null : (
                                        <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                                            <Alert onClose={handleSnackClose} severity="error">
                                                <Trans i18nKey="description.userExist">
                                                    User already exists, please try a different email
                                                </Trans>
                                            </Alert>
                                        </Snackbar>
                                    )) : null
                            }
                        </Grid>
                        {/* <Stack spacing={3}> */}
                        {/* <Stack direction={{ xs: 'column', sm: 'row', lg: 'row' }} spacing={2}>

                        </Stack> */}



                        <Grid item md={6} sm={12}>


                            <div style={{ marginBottom: 20, width: "80%", marginTop: "50px" }}>
                                <Input required name="password" label={t("description.password")} handleChange={formik.handleChange} handleBlur={formik.handleBlur} type={showPassword ? 'text' : 'password'} handleShowPassword={handleShowPassword} />
                                {formik.values.password.length >= minLenght ? (
                                    <>
                                        <CheckCircleIcon id='character_Check' sx={{ color: 'green', transform: 'translateY(0.15em)' }} fontSize='s' />
                                        <span style={{ color: 'green', fontSize: '15px' }}>
                                            <Trans i18nKey="description.8char">
                                                At least 8 characters
                                            </Trans>
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <CancelIcon id='character_Uncheck' sx={{ color: 'red', transform: 'translateY(0.15em)' }} fontSize='s' />
                                        <span style={{ color: 'red', fontSize: '15px' }}>
                                            <Trans i18nKey="description.8char">
                                                At least 8 characters
                                            </Trans>
                                        </span>
                                    </>
                                )
                                }
                                <br />
                                {
                                    Numb.test(formik.values.password) ? (
                                        <>
                                            <CheckCircleIcon id='number_Check' sx={{ color: 'green', transform: 'translateY(0.15em)' }} fontSize='s' />
                                            <span style={{ color: 'green', fontSize: '15px' }} >
                                                <Trans i18nKey="description.1Nbr">
                                                    At least 1 number
                                                </Trans>
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <CancelIcon id='number_Uncheck' sx={{ color: 'red', transform: 'translateY(0.15em)' }} fontSize='s' />
                                            <span style={{ color: 'red', fontSize: '15px' }} >
                                                <Trans i18nKey="description.1Nbr">
                                                    At least 1 number
                                                </Trans>
                                            </span>
                                        </>
                                    )
                                }
                                <br />
                                {
                                    Upper.test(formik.values.password) ? (
                                        <>
                                            <CheckCircleIcon id='special_Check' sx={{ color: 'green', transform: 'translateY(0.15em)' }} fontSize='s' />
                                            <span style={{ color: 'green', fontSize: '15px' }} >
                                                <Trans i18nKey="description.1Uper">
                                                    At least 1 uppercase letter
                                                </Trans>
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <CancelIcon id='special_Uncheck' sx={{ color: 'red', transform: 'translateY(0.15em)' }} fontSize='s' />
                                            <span style={{ color: 'red', fontSize: '15px' }} >
                                                <Trans i18nKey="description.1Uper">
                                                    At least 1 uppercase letter
                                                </Trans>
                                            </span>
                                        </>
                                    )
                                }
                                <br />
                                {
                                    SpecialChar.test(formik.values.password) ? (
                                        <>
                                            <CheckCircleIcon id='upper_Check' sx={{ color: 'green', transform: 'translateY(0.15em)' }} fontSize='s' />
                                            <span style={{ color: 'green', fontSize: '15px' }} >
                                                <Trans i18nKey="description.1Spec">
                                                    At least 1 special character
                                                </Trans>
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <CancelIcon id='upper_Uncheck' sx={{ color: 'red', transform: 'translateY(0.15em)' }} fontSize='s' />
                                            <span style={{ color: 'red', fontSize: '15px' }} >
                                                <Trans i18nKey="description.1Spec">
                                                    At least 1 special character
                                                </Trans>
                                            </span>
                                        </>
                                    )
                                }
                            </div>
                            <div style={{ marginBottom: 20, width: "80%" }}>
                                <Input required name="confirmPassword" label={t("description.repeatPassword")} handleChange={formik.handleChange} handleBlur={formik.handleBlur} type="password" />
                                {
                                    formik.values.confirmPassword === formik.values.password
                                        &&
                                        formik.values.confirmPassword !== '' ? (
                                        <>
                                            <CheckCircleIcon id='upper_Check' sx={{ color: 'green', transform: 'translateY(0.15em)' }} fontSize='s' />
                                            <span style={{ color: 'green', fontSize: '15px' }} >
                                                <Trans i18nKey="description.passMatch">
                                                    Password matches
                                                </Trans>
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <CancelIcon id='special_Uncheck' sx={{ color: 'red', transform: 'translateY(0.15em)' }} fontSize='s' />
                                            <span style={{ color: 'red', fontSize: '15px' }} >
                                                <Trans i18nKey="description.passMatch">
                                                    Password matches
                                                </Trans>
                                            </span>
                                        </>
                                    )
                                }
                            </div>

                            {/* </Stack> */}

                            {
                                success !== undefined ? (
                                    success ? null : (
                                        <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                                            <Alert onClose={handleSnackClose} severity="error">
                                                <Trans i18nKey="description.userExist">
                                                    User already exists, please try a different email
                                                </Trans>
                                            </Alert>
                                        </Snackbar>
                                    )) : null
                            }
                            {/* </Stack> */}
                        </Grid>

                    </Grid>
                    <div style={{ width: "10%", marginTop: "20px"}} align="center">
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="outlined"
                            loading={loading}
                            sx={{ borderColor: "black", color: "black" }}
                        >
                            <Trans i18nKey="description.register">
                                Create
                            </Trans>
                        </LoadingButton>
                    </div>
                </form >
            </Grid>
            {
                success ? (
                    <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                        <Alert onClose={handleSnackClose} severity="success">
                            <Trans>
                                Agent Created Successfully
                            </Trans>
                        </Alert>
                    </Snackbar>
                ) : (
                    <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                        <Alert onClose={handleSnackClose} severity="error">
                            <Trans i18nKey="description.wefound">
                                Couldn't Create Agent
                            </Trans>
                        </Alert>
                    </Snackbar>
                )
            }
        </Grid>
    );
}

export default CMS_Add_Agency;
import React, { useState, useCallback, useRef, useEffect } from "react";
import {
    GoogleMap,
    useLoadScript,
    Marker,
    InfoWindow,
} from "@react-google-maps/api";
import Grid from '@material-ui/core/Grid';

import "@reach/combobox/styles.css";
import mapStyles from "./mapStyles";
import Search from "./Search";
import Locate from './Locate';
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import PostCardNoFav from "../postCards/PostCardNoFav";
import {getMapRentPosts} from "../../actions/posts";


const libraries = ["places"];

const mapContainerStyle = {
    height: "100vh",
    width: "100%",
};
const options = {
    styles: mapStyles,
    zoomControl: true,
    fullscreenControl: false,
    mapTypeControl: true,
    streetViewControl: false
};
const center = {
    lat: 33.82543142169751,
    lng: 35.583106540518465,
};

export default function MapRent() {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyC-cRq-I-LZI5RcMt00vnXyhfz5Mchuihc',
        libraries, //Avoid many rerenders
    });
    const [markers, setMarkers] = useState([]);
    const dispatch = useDispatch();
    const [selected, setSelected] = useState(null);
    const [mapRentPost, setMapRentPost] = useState({});


    useEffect(async () => {
       const data = await dispatch(getMapRentPosts())
        setMapRentPost(data.data)
    }, []);

    const mapRef = useRef(); //retain state when you dont want to rerender
    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);

    const panTo = useCallback(({ lat, lng }) => {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(14);
    }, [])

    if (loadError) return "Error loading maps";
    if (!isLoaded) return "loding maps"

    return (
        <div>
            

            {/* <Search panTo={panTo}  /> */}
            <Grid container justifyContent="flex-end">
            <Search panTo={panTo} markers={markers} setMarkers={setMarkers} />
            <Locate panTo={panTo} />
</Grid>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={8}
                center={center}
                options={options}
                // onClick={onMapClick}
                onLoad={onMapLoad}
            >

                { mapRentPost.length>0 ? mapRentPost.map((marker) => {
                    const dateNow = new Date();
                    const expiredAt = Date.parse(marker.expireAt);
                    if (marker.mapPin !== undefined) {
                        if (expiredAt !== undefined) {
                            if (Date.parse(dateNow.toISOString()) > expiredAt) {
                                return null
                            } else {
                                return (
                                    <Marker
                                        key={marker.mapPin.lat}
                                        position={{lat: marker.mapPin.lat, lng: marker.mapPin.lng}}
                                        icon={{
                                            url: "/house.svg",
                                            scaledSize: new window.google.maps.Size(30, 30),
                                            origin: new window.google.maps.Point(0, 0),
                                            anchor: new window.google.maps.Point(15, 15),

                                        }}
                                        onClick={() => {
                                            setSelected(marker);

                                        }}

                                    />
                                )
                            }
                        } else return (
                            <Marker
                                key={marker.mapPin.lat}
                                position={{lat: marker.mapPin.lat, lng: marker.mapPin.lng}}
                                icon={{
                                    url: "/house.svg",
                                    scaledSize: new window.google.maps.Size(30, 30),
                                    origin: new window.google.maps.Point(0, 0),
                                    anchor: new window.google.maps.Point(15, 15),

                                }}
                                onClick={() => {
                                    setSelected(marker);

                                }}

                            />
                        )
                    }
                }):null}

                {selected ? (
                    <InfoWindow position={
                        { lat: selected.mapPin.lat, lng: selected.mapPin.lng }
                    }
                        onCloseClick={() => {
                            setSelected(null);
                        }} >
                        <PostCardNoFav post={selected}/>
                        {/*<div>*/}
                        {/*    /!* <p>Time:{formatRelative(selected.time, new Date())}</p> *!/*/}
                        {/*    <img src="../public/house.svg"></img>*/}
                        {/*    <p>{selected.lat}</p>*/}
                        {/*    <p>{selected.lng}</p>*/}
                        {/*    <p>{selected.address}</p>*/}
                        {/*</div>*/}
                    </InfoWindow>) : null}
            </GoogleMap>
        </div >
    );

}




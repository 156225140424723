
import {
  FETCH_ALL, FETCH_BY_SEARCH, FETCH_POST, FETCH_USER_POSTS, CREATE, UPDATE, UPDATE_POST, DELETE, LIKE, ADD_TO_WISHLIST, GET_WISHLIST, REMOVE_FROM_WISHLIST, FETCH_MY_POSTS, FETCH_NOTIFICATION, UPDATE_NOTIFICATION, CREATE_NOTIFICATION_REQ_360,
  FETCH_MAPBUY_POST
} from '../constants/actionTypes';

export default (state = { isLoading: true, posts: [] }, action) => {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isLoading: true };
    case 'END_LOADING':
      return { ...state, isLoading: false };
    case FETCH_ALL:
      return {
        ...state,
        posts: action.payload.data,
        currentPage: action.payload.currentPage,
        numberOfPages: action.payload.numberOfPages,
      };
    case FETCH_MY_POSTS:
      return { ...state, posts: action.payload.data }
    case FETCH_BY_SEARCH:
      return {
        ...state,
        posts: action.payload.data,
        currentPage: action.payload.currentPage,
        numberOfPages: action.payload.numberOfPages,
      };
    case FETCH_POST:
      return { ...state, post: action.payload.post };
    case FETCH_MAPBUY_POST:
      return { ...state, postbuymap: action.payload.data };
    case FETCH_USER_POSTS:
      return { ...state, posts: action.payload.posts };
    case LIKE:
      return { ...state, posts: state.posts.map((post) => (post._id === action.payload._id ? action.payload : post)) };
    case CREATE:
      return { ...state, posts: [...state.posts, action.payload] };
    case UPDATE_POST:
      return { ...state, updatedPost: action.payload.post };
    case UPDATE:
      return { ...state, posts: state.posts.map((post) => (post._id === action.payload._id ? action.payload : post)) };
    case DELETE:
      return { ...state, posts: state.posts.filter((post) => post._id !== action.payload) };
    case ADD_TO_WISHLIST:
      return { ...state, wish: action.payload };
    case GET_WISHLIST:
      return { ...state, wish: action.payload };
    case REMOVE_FROM_WISHLIST:
      return { ...state, wish: action.payload }
    case FETCH_NOTIFICATION:
      return { ...state, notification: action.payload.data };
    case UPDATE_NOTIFICATION:
      return { ...state, notifications: action.payload.data };
    case CREATE_NOTIFICATION_REQ_360:
      return { ...state, notifications: [...state.notifications, action.payload] };
    default:
      return state;
  }
};
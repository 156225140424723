import {makeStyles} from "@material-ui/styles";
const UseStyles = makeStyles((theme) => ({
    root:{
        // [theme.breakpoints.down("xs")]: {
        //     maxWidth: 274,
        //     marginLeft:0,
        // },
      
            maxWidth: 650,
            marginLeft:450,
        display: 'flex',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {

        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
  
        width: 151,
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 10,
      paddingBottom: 10,
    },
    playIcon: {
      height: 38,
      width: 38,
    },
  }));
export default UseStyles

import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ThreeDRotationIcon from '@material-ui/icons/ThreeDRotation';
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import { IconButton,Stack, useTheme } from "@material-ui/core";

import { Paper, CircularProgress, Divider, Container } from '@material-ui/core';


import Grid from '@material-ui/core/Grid';
import AdsCard from "../../../components/AdsCard/adsCard";
import useStyles from './style';
import Carousel, { Modal, ModalGateway } from "react-images";
import { render } from "@testing-library/react";
import { useState } from "react";
import HomePagePostCard from '../../../components/HomePagePostCards/homepagepostCard';

import { Fab } from "@material-ui/core";
import Popup360 from "../../../components/Popup360";
import Iframe from 'react-iframe';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, Navigate } from 'react-router-dom'
import { getPost, getPostsBySearch,deletePost } from '../../../actions/posts';
import { deleteImgAd, getAd  } from '../../../actions/ImgAd';
import Posts from '../../../components/postCards/Posts';


const CMSAdDetails = () => {
    const classes = useStyles();

    // let currentIndex = 0;
    // const [modalIsOpen, isOpen] = useState(false);

     const { ad, imgads, isLoading } = useSelector((state) => state.imgads);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    // const [currentId, setCurrentId] = useState(0);
   

    useEffect( () => {
        dispatch(
            getAd(id));
    }, [id,dispatch]);

    return (
        <>
        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mb: 5 }}
        >
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }} style={{marginRight:10}}>
          <Button
                disableRipple
                variant="contained"
                color="secondary"
                onClick ={() => {dispatch(deleteImgAd(id));navigate(`/CMS/posts/${imgads.propertyId}`)} }
            >
                Delete Ad&nbsp;
            </Button>
          </Stack>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }} style={{marginRight:10}}>
          <Button
                disableRipple
                variant="contained"
                color="secondary"
                onClick ={() => navigate(`/CMS/imgAd/${id}/editImgAd`)}
            >
                Edit Ad&nbsp;
            </Button>
          </Stack>
        </Stack>
           
            <div className="row">
               
                <Card className={classes.moveLeft}>
                    <div className={classes.details}>
                        <h1 className={classes.h1}>Image Ad Information</h1>
                        <div>
                              <Grid item xs={12} lg={6}>
                               <p className={classes.paper}>Company Name: {imgads.ownerName}</p>
                                <p className={classes.paper}>Title: {imgads.title}</p>
                                <p className={classes.paper}>Description: {imgads.price}</p>
                                <p className={classes.paper}>Link: {imgads.currency}</p>
                            </Grid> 
                        </div>
                    </div>
                </Card>
            </div>
        </>
    )

}
export default CMSAdDetails
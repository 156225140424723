import { FETCH_ALL, FETCH_BY_SEARCH, FETCH_USER_POSTS, FETCH_ALL_POSTS, FETCH_USER, CREATE, UPDATE, DELETE, LIKE, ADD_TO_WISHLIST, GET_WISHLIST, REMOVE_FROM_WISHLIST, FETCH_NOTIFICATION, UPDATE_NOTIFICATION, CREATE_NOTIFICATION_REQ_360 } from '../constants/actionTypes';

export default (state = { isLoading: true, users: [] }, action) => {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isLoading: true };
    case 'END_LOADING':
      return { ...state, isLoading: false };
    case FETCH_ALL:
      return {
        ...state,
        users: action.payload.data,
        currentPage: action.payload.currentPage,
        numberOfPages: action.payload.numberOfPages,
      };
    case FETCH_BY_SEARCH:
      return { ...state, users: action.payload.data };
    case FETCH_USER:
      return { ...state, user: action.payload.user };
    case LIKE:
      return { ...state, users: state.users.map((user) => (user._id === action.payload._id ? action.payload : user)) };
    case CREATE:
      return { ...state, users: [...state.users, action.payload] };
    case UPDATE:
      return { ...state, users: state.users.map((user) => (user._id === action.payload._id ? action.payload : user)) };
    case DELETE:
      return { ...state, users: state.users.filter((user) => user._id !== action.payload) };
    case ADD_TO_WISHLIST:
      return { ...state, wish: action.payload };
    case GET_WISHLIST:
      return { ...state, wish: action.payload };
    case REMOVE_FROM_WISHLIST:
      return { ...state, wish: action.payload };
    case FETCH_NOTIFICATION:
      return { ...state, notification: action.payload.data };
    case UPDATE_NOTIFICATION:
      return { ...state, notifications: action.payload.data };
    case CREATE_NOTIFICATION_REQ_360:
      return { ...state, notifications: [...state.notifications, action.payload] };
    default:
      return state;
  }
};
import { Card, CardContent, Typography } from "@material-ui/core";
import React from "react";
import UseStyles from './styles';
import { useNavigate } from 'react-router-dom';

const UserCard = ({ user , setcurrentId }) => {
    const navigate = useNavigate();
    const classes = UseStyles();
    const openUser = (e) => {
        navigate(`/CMS/user/${user._id}`);
      };

    return (
    <div>
        <div>
            <Card variant="outlined" className={classes.root} elevation={0} onClick={() => openUser()} >
            <CardContent style={{ textAlign: "Left" }}>

                <Typography variant="subtitle2" component="p" className={classes.typoName}>
                {user.firstName}  {user.lastName}
                </Typography>
                <Typography variant="h6" color="primary" component="p" className={classes.typoPrice}>
                {user.email}
                </Typography>

            </CardContent>
            </Card>
    </div>
</div>
);};
export default UserCard;
import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
    // fallbackLng: 'fr',
    debug: false,
    detection: {
        order: ['cookie', 'htmlTag', 'queryString', 'localStorage', 'path', 'subdomain'],
        caches: ['cookie']
    },
    interpolation: {
        escapeValue: false
    },
    backend: {
        localPath: '/locales/{{lng}}/translation.json'
    },
    react: {
        omitBoundRerender: false,
        wait: true
      }
})

export default i18n;
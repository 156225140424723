import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core'
import SearchBar from './SearchBar/SearchBar'
import Carousel from './Carousel/Carousel';
import PropertyItem from './PropertyItem/PropertyItem';


//each post has a main image at 
//post.mainImage.url


//each post has propertyType:
//post.propertyType=""
const HomePageCarousel = () => {
    const [propertyType, setPropertyType] = useState('PropertyType');
    return (
        <Grid container sx={{ position: 'relative', width: '100%', height: 'auto', marginTop: { xs: '0.5em', sm: '2.2rem', lg: '3em' } }} justifyContent="center">

            <Grid container justifyContent="center" style={{ position: 'relative' }}>
                <Carousel propertyType={propertyType} />
            </Grid>
            <SearchBar setPropertyType={setPropertyType} />

        </Grid >
    )
};
export default HomePageCarousel;


import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ThreeDRotationIcon from '@material-ui/icons/ThreeDRotation';
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import { IconButton,Stack, useTheme } from "@material-ui/core";

import { Paper, CircularProgress, Divider, Container } from '@material-ui/core';


import Grid from '@material-ui/core/Grid';
import AdsCard from "../../../components/AdsCard/adsCard";
import useStyles from './style';
import Carousel, { Modal, ModalGateway } from "react-images";
import { render } from "@testing-library/react";
import { useState } from "react";
import HomePagePostCard from '../../../components/HomePagePostCards/homepagepostCard';

import { Fab } from "@material-ui/core";
import Popup360 from "../../../components/Popup360";
import Iframe from 'react-iframe';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, Navigate } from 'react-router-dom'
import { getPost, getPostsBySearch,deletePost, getAllUserPosts } from '../../../actions/posts';
import {  deleteUser, getUser } from '../../../actions/users';
import Posts from '../../../components/postCards/Posts';


const CMSUserDetails = () => {
    const classes = useStyles();

    let currentIndex = 0;
    const [modalIsOpen, isOpen] = useState(false);

    const { user, users, isLoading } = useSelector((state) => state.users);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [currentId, setCurrentId] = useState(0);
    const [post , setpost] = useState([]);

    useEffect(() => {
        dispatch(getUser(id));
    }, [id]);

   useEffect( async () => {
        const post = await dispatch(getAllUserPosts(id) );
            setpost(post);
        }, [dispatch]);

    if (!user) return null;
    return (
        <>
        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mb: 5 }}
        >
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }} style={{marginRight:10}}>
          <Button
                disableRipple
                variant="contained"
                color="secondary"
                onClick ={() => { dispatch(deleteUser(id)); navigate('/CMS/CMSusers')} }
            >
                Delete User&nbsp;
            </Button>
          </Stack>
        </Stack>
            
            <div className="row">
               
                <Card className={classes.moveLeft}>
                    <div className={classes.details}>
                        <h1 className={classes.h1}>User Information</h1>
                        <div>
                             <Grid item xs={12} lg={6}>
                                <p className={classes.paper}>Name: {user.firstName} {user.lastName}</p>
                                <p className={classes.paper}>Email: {user.email}</p>
                            </Grid>
                        </div>
                    </div>
                </Card>
            </div>
            <div className="row">
                <div className={classes.moveLeft}>
                    <h1 className={classes.h1}>User Posts</h1>
                    <div className={classes.root_grid}>
                       
                        <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                                {/* <p className={classes.paper}>Email: {post.email}</p>
                                <p className={classes.paper}>Phone: {post.data[0].phoneNumber}</p> */}
                            </Grid>
                            <Posts setCurrentId={id} />

                        </Grid>
                        <br /><br />
                    </div>
                </div>
            </div>
        </>
    )

}
export default CMSUserDetails